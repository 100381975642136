import gql from 'graphql-tag'
import React from 'react'
import { InflowInviteTemplate } from '~/types/InflowInviteTemplate'
import { InflowMoment, InflowMomentInviteType } from '~/types/InflowMoments'
import { Mutator, toast } from '~/utils'

import { CenterModal, PdfModal } from '..'
import { InflowMomentInvitesForm } from './Forms/InflowMomentInvitesForm'
import { InflowMomentSelectInviteForm } from './Forms/InflowMomentSelectInviteForm'
import { InflowMomentInviteAttatchmentsModalContent } from './InflowMomentInviteAttatchmentsModalContent'

interface Props {
    closeModal: () => void
    inflowMoment: InflowMoment
    onSubmitSuccess: () => void
}

interface State {
    activeModalIndex: number
    inflowInviteTemplate?: InflowInviteTemplate
    freeText?: string
    freeTextEnglish?: string
    manualInviteIds?: string[]
    emailInviteIds?: string[]
    pdfFile?: {
        fileId: string
        fileName: string
        onClose: () => void
    }
}

export class InflowMomentSendInvitesModal extends React.Component<Props, State> {
    public state: State = {
        activeModalIndex: 0,
    }

    private inviteUsersMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inviteUsersMutator = new Mutator({
            mutation: INFLOW_MOMENT_INVITE_USERS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { closeModal, inflowMoment } = this.props
        const { activeModalIndex, pdfFile } = this.state

        if (activeModalIndex === 3 && pdfFile) {
            return (
                <PdfModal
                    title={`Intake-uitnodigingen voor ${inflowMoment.inflowModule.name}`}
                    fileName={pdfFile.fileName}
                    getFileId={() => pdfFile.fileId}
                    onClose={pdfFile.onClose}
                />
            )
        }

        return (
            <CenterModal title={'Lesuitnodiging'} onClose={closeModal} enableEasyClose>
                {this.renderModalContent()}
            </CenterModal>
        )
    }

    private renderModalContent() {
        const { closeModal, inflowMoment } = this.props
        const { activeModalIndex, inflowInviteTemplate, manualInviteIds, emailInviteIds, freeText, freeTextEnglish } =
            this.state

        if (activeModalIndex === 0) {
            return (
                <InflowMomentSelectInviteForm
                    onCancel={closeModal}
                    onNext={this.onSelectInviteNext}
                    inflowMoment={inflowMoment}
                    defaultInflowInviteTemplate={inflowInviteTemplate}
                    defaultFreeText={freeText}
                    defaultFreeTextEnglish={freeTextEnglish}
                />
            )
        }

        if (activeModalIndex === 1) {
            return (
                <InflowMomentInvitesForm
                    onPrev={() => this.setState({ activeModalIndex: 0 })}
                    onNext={this.onInflowMomentInvitesSubmit}
                    users={inflowMoment.candidates || []}
                    inflowMoment={inflowMoment}
                    inflowInviteTemplate={inflowInviteTemplate}
                    loading={this.inviteUsersMutator.loading}
                    manualInviteIds={manualInviteIds}
                    emailInviteIds={emailInviteIds}
                />
            )
        }

        if (activeModalIndex === 2) {
            return (
                <InflowMomentInviteAttatchmentsModalContent
                    inflowInviteTemplate={inflowInviteTemplate}
                    onPrev={() => this.setState({ activeModalIndex: 1 })}
                    onNext={this.sendInvites}
                />
            )
        }

        return null
    }

    private onSelectInviteNext = (
        inflowInviteTemplate: InflowInviteTemplate,
        freeText?: string,
        freeTextEnglish?: string
    ) => {
        this.setState({
            inflowInviteTemplate,
            freeText,
            freeTextEnglish,
            activeModalIndex: 1,
        })
    }

    private onInflowMomentInvitesSubmit = (manualIds: string[], emailIds: string[]) => {
        const { inflowInviteTemplate } = this.state

        const hasAttatchments =
            inflowInviteTemplate && inflowInviteTemplate.attachments && inflowInviteTemplate.attachments.length > 0
        const showAttatchmentModal = manualIds.length > 0 && hasAttatchments

        this.setState(
            {
                manualInviteIds: manualIds,
                emailInviteIds: emailIds,
                activeModalIndex: showAttatchmentModal ? 2 : 1,
            },
            () => {
                if (!showAttatchmentModal) {
                    this.sendInvites()
                }
            }
        )
    }

    private sendInvites = async () => {
        const {
            inflowInviteTemplate,
            freeText,
            freeTextEnglish,
            manualInviteIds = [],
            emailInviteIds = [],
        } = this.state
        const { closeModal, onSubmitSuccess, inflowMoment } = this.props

        if (!inflowInviteTemplate) {
            toast.error('Er is geen template geselecteerd')
            return
        }

        const data = await this.inviteUsersMutator.mutate({
            inflowMomentId: inflowMoment._id,
            inflowInviteTemplateId: inflowInviteTemplate._id,
            freeText,
            freeTextEnglish,
            invites: [
                ...manualInviteIds.map(inviteId => ({ userId: inviteId, type: InflowMomentInviteType.Manual })),
                ...emailInviteIds.map(inviteId => ({ userId: inviteId, type: InflowMomentInviteType.Email })),
            ],
        })

        if (data) {
            if (
                data.inflowMoment_sendInvites &&
                data.inflowMoment_sendInvites.fileId &&
                data.inflowMoment_sendInvites.fileName
            ) {
                this.setState({
                    pdfFile: {
                        fileId: data.inflowMoment_sendInvites.fileId,
                        fileName: data.inflowMoment_sendInvites.fileName,
                        onClose: () => {
                            closeModal()
                            onSubmitSuccess()
                            this.setState({ pdfFile: undefined })
                        },
                    },
                    activeModalIndex: 3,
                })
            } else {
                closeModal()
                onSubmitSuccess()
            }
        }
    }
}

const INFLOW_MOMENT_INVITE_USERS_MUTATION = gql`
    mutation _(
        $inflowMomentId: MongoID
        $inflowInviteTemplateId: MongoID
        $freeText: String
        $freeTextEnglish: String
        $invites: [InflowMomentInviteInvitesInputType]
    ) {
        inflowMoment_sendInvites(
            inflowMomentId: $inflowMomentId
            inflowInviteTemplateId: $inflowInviteTemplateId
            freeText: $freeText
            freeTextEnglish: $freeTextEnglish
            invites: $invites
        ) {
            fileId
            fileName
            html
        }
    }
`
