import * as React from 'react'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Spinner from '~/components/Spinner'
import Subtle from '~/components/Subtle'
import Link from '~/components/Link'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import FilterFieldCollection from '~/components/FilterFieldCollection'
import Field from '~/components/Field'
import { TagPicker, TagPickerChangeHandler } from '~/components/TagPicker'
import { Fetcher, Sorter, Filter } from '~/utils'
import gql from 'graphql-tag'
import { InflowMomentTimeslot, InflowMoment } from '~/types/InflowMoments'
import { Paginator } from '~/utils/Paginator'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import { InflowMomentSlotUsersForm, RenderUsersTableHandler } from '~/components/InflowMoment/InflowMomentSlotUsersForm'
import Search from '~/components/Search'
import { Organization } from '~/types/Organization'
import { User } from '~/types/User'
import { CheckBox, MultiInput, ToolTip } from '..'
import { Placement } from '../ToolTip'

const GET_LEARNER_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: UsersFilterInputType) {
        users(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            roles
            profile {
                name
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            learner {
                organizations {
                    organization {
                        _id
                        name
                    }
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities

        organizations(sortBy: "name") {
            _id
            name
        }
    }
`

const START = 40
const INCREASE = 40
const DEFAULT_SORT_DIR = 'ASC'
const DEFAULT_SORT_BY = 'profile.surName'

interface Props {
    onCancel: () => void
    onSubmitSuccess: () => void
    timeslot: InflowMomentTimeslot
    drawerTitle: string | JSX.Element
    inflowMoment: InflowMoment
}

interface State {
    skip: number
    limit: number
    sortBy: string
    sortDir: string
}

export class InflowMomentSlotCandidatesForm extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private candidatesFetcher: Fetcher
    private usersPaginator: Paginator
    private sorter: Sorter
    private filter: Filter
    private filterSuggestionsFetcher: Fetcher
    private STATIC_CANDIDATE_USERS_FILTERS: { [key: string]: any }

    constructor(props: Props) {
        super(props)

        const { skip, limit, sortBy, sortDir } = this.state

        this.usersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.STATIC_CANDIDATE_USERS_FILTERS = {
            roles: ['LEARNER'],
            filterLearnerActive: true,
            filterByIsAvailableForInflow: true,
            filterByHasNoInflowMomentsAfterEnrollmentDate: true,
            excludeUsersInInflowMomentInOtherSlots: {
                inflowMomentId: this.props.inflowMoment._id,
                inflowMomentTimeslotId: this.props.timeslot._id,
            },
        }

        if (props.inflowMoment.isForOrganization) {
            this.STATIC_CANDIDATE_USERS_FILTERS.filterByOrganizationIds = [props.inflowMoment.organization!._id]
        }

        this.candidatesFetcher = new Fetcher({
            query: GET_LEARNER_USERS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    ...this.STATIC_CANDIDATE_USERS_FILTERS,
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.usersPaginator.reset()
            },
        })

        this.filter = new Filter({
            allowedKeys: [
                'searchText',
                'filterByCityNames',
                'filterByOrganizationIds',
                'filterByHasNoInflowMomentsAfterEnrollmentDate',
            ],
            onChange: (filters: any) => {
                const mergedFilters = {
                    ...this.STATIC_CANDIDATE_USERS_FILTERS,
                    ...filters,
                }

                // 'false', in this case, means remove the filter
                if (filters.filterByHasNoInflowMomentsAfterEnrollmentDate === false) {
                    delete mergedFilters['filterByHasNoInflowMomentsAfterEnrollmentDate']
                }

                this.candidatesFetcher.refetch({
                    silent: true,
                    filters: mergedFilters,
                } as any)
            },
        } as any)

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })
    }

    public render() {
        const { onCancel, timeslot, drawerTitle, onSubmitSuccess } = this.props

        return (
            <InflowMomentSlotUsersForm
                usersFetcher={this.candidatesFetcher}
                usersPaginator={this.usersPaginator}
                renderUsersTable={this.renderUsersTable}
                renderActionBar={this.renderActionBar}
                onCancel={onCancel}
                onSuccess={onSubmitSuccess}
                timeslot={timeslot}
                title={drawerTitle}
            />
        )
    }

    private renderUsersTable: RenderUsersTableHandler = (users, addUser, removeUser, areUsersAdded, selectedUsers) => {
        const { loading } = this.candidatesFetcher

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem forIconButton={true} />
                    <TableHeaderItem sorter={this.sorter} sortBy={`profile.surName`}>
                        Naam
                    </TableHeaderItem>
                    <TableHeaderItem>Woonplaats</TableHeaderItem>
                    <TableHeaderItem>Organisatie</TableHeaderItem>
                </TableHeader>

                {loading && this.renderLoadingRow()}
                {!loading && users && users.length > 0
                    ? this.renderCandidateRows(users, addUser, removeUser, areUsersAdded, selectedUsers)
                    : this.renderEmptyRow(areUsersAdded)}
                {areUsersAdded && this.renderMaxCandidateCountRow()}
            </Table>
        )
    }

    private renderLoadingRow = () => (
        <TableRow key={`loading`}>
            <TableCell colSpan={4}>
                <Spinner />
            </TableCell>
        </TableRow>
    )

    private renderActionBar = () => {
        const { inflowMoment } = this.props
        const { data } = this.filterSuggestionsFetcher
        const { loading: loadingUsers } = this.candidatesFetcher
        const { addresses_usedCities = [], organizations = [] } = data
        const addressOptions = addresses_usedCities.map((city: string) => ({ value: city, label: city }))
        const organizationOptions = organizations.map((organization: Organization) => ({
            value: organization._id,
            label: organization.name,
        }))
        const { isForOrganization } = inflowMoment

        return (
            <ActionBar
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                leftIcon={<Icon name={`filter`} />}
                                onClick={() => toggleDrawer()}
                                isActive={drawerActive}
                            >
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Search isLoading={loadingUsers} onSearch={this.onSearch} />
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        <Field isLabel title={`Plaats`} style={`compact`}>
                            <TagPicker
                                onChange={this.onFilterTagPicker}
                                name={`filterByCityNames`}
                                options={addressOptions}
                                placeholder={`Selecteer plaats`}
                            />
                        </Field>
                        <Field title={`Organisatie`} style={`compact`}>
                            <TagPicker
                                onChange={this.onFilterTagPicker}
                                name={`filterByOrganizationIds`}
                                isDisabled={isForOrganization}
                                options={organizationOptions}
                                placeholder={`Selecteer organisatie(s)`}
                                multi={true}
                            />
                        </Field>
                        <Field title="Status" style="compact">
                            <MultiInput type="checkbox">
                                <CheckBox
                                    name="filterByHasNoInflowMomentsAfterEnrollmentDate"
                                    onChange={this.onFilter}
                                    defaultChecked={true}
                                >
                                    Verberg reeds ingeplande kandidaten
                                </CheckBox>
                            </MultiInput>
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    private renderEmptyRow = (areUsersAdded: boolean) => {
        const placeholder = areUsersAdded
            ? 'Voeg kandidaten toe middels de onderstaande lijst.'
            : 'Er zijn geen kandidaten gevonden.'

        return (
            <TableRow key={`emptyresult`}>
                <TableCell colSpan={4}>
                    <Subtle>{placeholder}</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    private renderMaxCandidateCountRow = () => {
        const { inflowMoment } = this.props
        const { amountOfSlotsPerTimeslot } = inflowMoment

        if (typeof amountOfSlotsPerTimeslot !== 'number') {
            return null
        }

        return (
            <TableRow key={`maxCandidateCount`}>
                <TableCell colSpan={4}>
                    <Subtle>Maximaal {amountOfSlotsPerTimeslot} kandidaten</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    private renderCandidateRows: RenderUsersTableHandler = (users, addUser, removeUser, areUsersAdded, selectedUsers) =>
        users.map((user, i) => {
            const { inflowMoment } = this.props
            const { _id, profile, learner } = user
            const city =
                profile.address &&
                profile.address.nl &&
                profile.address.nl.extraInfo &&
                profile.address.nl.extraInfo.city
            const organizationNames =
                learner &&
                learner.organizations &&
                learner.organizations.map(({ organization }) => organization && organization.name)
            const onAdd = () => addUser(user)
            const onRemove = () => removeUser(user)

            const hasAdviceReport =
                learner &&
                learner.adviceReports &&
                learner.adviceReports.find(adviceReport => {
                    return adviceReport.inflowMoment._id === inflowMoment._id
                })

            return (
                <TableRow key={i}>
                    <TableCell>
                        {!areUsersAdded && this.renderAddButton(onAdd, selectedUsers)}
                        {areUsersAdded && this.renderRemoveButton(onRemove, !!hasAdviceReport)}
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/learners/${_id}`} target={`_blank`} type={`emphasized`}>
                            {profile.name}
                        </Link>
                    </TableCell>
                    <TableCell>{city}</TableCell>
                    <TableCell>
                        {organizationNames &&
                            organizationNames.map((organizationName, index) => [index > 0 && ', ', organizationName])}
                    </TableCell>
                </TableRow>
            )
        })

    private renderRemoveButton = (removeUser: () => void, canDelete: boolean) => {
        if (canDelete) {
            return (
                <ToolTip
                    text={'Kandidaten die een adviesrapport hebben kunnen niet meer worden verwijderd uit tijdslots'}
                    placement={Placement.left}
                >
                    <Button type={`in-row`} leftIcon={<Icon name={`trash`} />} isDisabled={canDelete} />
                </ToolTip>
            )
        }

        return <Button type={`in-row`} onClick={removeUser} leftIcon={<Icon name={`trash`} />} isDisabled={canDelete} />
    }

    private renderAddButton = (addUser: () => void, selectedUsers: User[]) => {
        const { inflowMoment } = this.props
        const { amountOfSlotsPerTimeslot } = inflowMoment
        const isButtonDisabled = !!amountOfSlotsPerTimeslot && selectedUsers.length >= amountOfSlotsPerTimeslot

        return (
            <Button type={`in-row`} onClick={addUser} isDisabled={isButtonDisabled} leftIcon={<Icon name={`plus`} />} />
        )
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.candidatesFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({ finished: moreData.users.length === 0 })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }

    private onFilterTagPicker: TagPickerChangeHandler = (values, tagPicker) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    private onFilter = (event: any) => {
        this.filter.applyFromInputEvent(event)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.candidatesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }
}
