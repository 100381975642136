import { get } from 'lodash'
import { browserHistory } from 'react-router'

import apolloClient from '~/services/apolloClient'
import { parseGraphQLError, toast } from '~/utils'
import { removeSession } from '~/services/session'
import { GraphQLErrors } from './GraphQLErrors'

/**
 * Mutate utility which parses error messages and exposes them
 *
 * @param {Object}   options
 * @param {any}      options.*          - Anything you would otherwise pass to apolloClient.query()
 * @param {Function} options.onRefetch  - Refetch handler.
 * @param {Function} options.reactComponentToUpdate  - A reference to the react component instance. Optional, to call the forceUpdate method.
 * @param {Function} options.preventRedirectWhenUnauthenticated  - By default, a global authentication handler will be fired when the
 *                                                                  'unauthenticated'-error is returned.
 */
export default class Mutator {
    public loading: any
    public errors: any
    public data: any
    public variables: any

    private _mutation: any
    private _reactComponentToUpdate: any
    private _preventRedirectWhenUnauthenticated: any

    constructor(options: any) {
        const { mutation, reactComponentToUpdate = null, preventRedirectWhenUnauthenticated = false } = options

        this._mutation = mutation
        this._reactComponentToUpdate = reactComponentToUpdate

        this.loading = false
        this.errors = new GraphQLErrors()
        this._preventRedirectWhenUnauthenticated = preventRedirectWhenUnauthenticated

        this.data = {}
        this.variables = {}
    }

    /**
     * Performes a mutation
     *
     * @param {Object} variables - Variables for mutation
     */
    public async mutate(variables: any = {}) {
        this.loading = true
        this.data = {}
        this.variables = variables
        this.errors = new GraphQLErrors()
        this._updateReactComponent()

        try {
            const result = await apolloClient.mutate({
                mutation: this._mutation,
                variables,
            })

            this.loading = false
            this.data = result.data
            this._updateReactComponent()

            return this.data
        } catch (error) {
            this.loading = false
            this.data = {}

            const graphQLErrors = get(error, 'graphQLErrors') || []

            if (graphQLErrors.length === 0) {
                // When error has no graphQLErrors, just toast the message
                toast.error(error.message)
                this._updateReactComponent()
                return
            }

            for (const graphQLError of graphQLErrors) {
                const namespace = (get(graphQLError, 'path') || []).join('.')
                const { field, message, name } = parseGraphQLError(error, { namespace })

                if (name === 'unauthenticated' && !this._preventRedirectWhenUnauthenticated) {
                    this._onUnAuthenticated()
                    return
                }

                this.errors = new GraphQLErrors([...this.errors.getErrors(), { field, message }])

                // Let React consume the errors (errors.consumeErrorsForField(field) has to be called to consume the error)
                await this._updateReactComponent()

                // Toast all unconsumed errors
                const unconsumedErrors = this.errors.getUnconsumedErrors()
                for (const { field, message } of unconsumedErrors) {
                    const errMessage = field ? `[${field}] - ${message}` : message

                    // tslint:disable-next-line:no-console
                    console.error(errMessage)

                    toast.error(message)
                }

                if (unconsumedErrors.length === 0 && this.errors.hasErrors()) {
                    toast.error('Er zijn fouten bij het invullen van het formulier')
                }
            }
        }
    }

    private _onUnAuthenticated() {
        removeSession()

        const { pathname } = browserHistory.getCurrentLocation()
        const redirect = '/login'
        if (pathname !== redirect) {
            browserHistory.replace(redirect)
        }
    }

    private async _updateReactComponent() {
        if (!this._reactComponentToUpdate) {
            return
        }

        await new Promise<void>(resolve => {
            this._reactComponentToUpdate.forceUpdate(() => {
                resolve()
            })
        })
    }
}
