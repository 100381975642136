import gql from 'graphql-tag'
import * as React from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Header,
    Link,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { get } from 'lodash'
import { Exam } from '~/types/Exam'
import { translateType } from '~/shared/utils'
import { View } from '~/components/View'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { TableView } from '~/components/TableView'
import { CreateExamForm } from '~/forms/CreateExamForm'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const GET_EXAMS_QUERY = gql`
    query _($searchText: String, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        exams(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            name
            level
            type
        }
    }
`

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export default class MasterView extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private filter: Filter
    private paginator: Paginator
    private examsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: (filters: any) => {
                this.examsFetcher.refetch({
                    searchText: filters.searchText,
                } as any)
            },
        } as any)

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.examsFetcher = new Fetcher({
            query: GET_EXAMS_QUERY,
            variables: { limit, skip, sortDir, sortBy, searchText: this.filter.getFilters().searchText },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public loadMore = (skip: number, limit: number, callback: any) => {
        this.examsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.exams.length === 0,
                })

                return {
                    exams: removeDuplicateDocuments([...(prevData.exams || []), ...moreData.exams]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.examsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    public onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }

    public render() {
        const { loading, data } = this.examsFetcher
        const { exams = [] } = data
        const filters = this.filter.getFilters()
        const { paginator, sorter } = this

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={() => {
                                return (
                                    <List horizontal>
                                        <ListItem>
                                            <Search
                                                isLoading={loading}
                                                onSearch={this.onSearch}
                                                defaultValue={filters.searchText}
                                            />
                                        </ListItem>
                                        <ListItem right>
                                            <ModalManager
                                                render={openModal => (
                                                    <Button type={`edit`} onClick={openModal}>
                                                        Toets toevoegen
                                                    </Button>
                                                )}
                                                getModal={closeModal => (
                                                    <CenterModal onClose={closeModal} title={`Toets toevoegen`}>
                                                        <CreateExamForm
                                                            onSubmitSuccess={closeModal}
                                                            onCancel={closeModal}
                                                        />
                                                    </CenterModal>
                                                )}
                                            />
                                        </ListItem>
                                    </List>
                                )
                            }}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`name`}>
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={sorter} sortBy={`type`}>
                                        Soort
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={sorter} sortBy={`level`}>
                                        Toetsniveau
                                    </TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={3} isLoading />
                                    </TableRow>
                                ) : exams.length > 0 ? (
                                    this.renderExamRows(exams)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={3}>
                                            <Subtle>Er zijn geen toetsen gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    public renderExamRows(exams: Exam[]) {
        return exams.map(exam => {
            const examType = get(exam, 'type')
            const readableExamType = examType === 'alpha' ? 'Alfatoets' : examType === 'language' ? 'Taaltoets' : null
            const examLevel = get(exam, 'level')

            return (
                <TableRow key={exam._id}>
                    <TableCell>
                        <Link route={`/properties/exams/${exam._id}`}>{exam.name}</Link>
                    </TableCell>
                    <TableCell>{readableExamType}</TableCell>
                    <TableCell>{examLevel ? translateType('level', examLevel) : ''}</TableCell>
                </TableRow>
            )
        })
    }
}
