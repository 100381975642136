import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

export default class DetailView extends Component {
    static propTypes = {
        children: PropTypes.any,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }

    constructor(props) {
        super(props)

        this.programFetcher = new Fetcher({
            query: GET_PROGRAM_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.programFetcher.refetch({
                _id: params.id,
            })
        }
    }

    render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.programFetcher
        const program = get(data, 'programs[0]')

        return (
            <RouteView crumbLabel={get(program, 'name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                </Header>
                {program && cloneElement(children, { program, refetch })}
            </RouteView>
        )
    }
}

const GET_PROGRAM_QUERY = gql`
    query _($_id: MongoID) {
        programs(byId: $_id) {
            _id
            name
            modules {
                _id
                name
            }
            projectType
            integrationType
            usedByProject
        }
    }
`
