import './ProcessList.scss'

import React from 'react'

import { BEM, ClassValue } from '~/services/BEMService'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { processType, Process } from '~/types/Process'
import gql from 'graphql-tag'
import { Fetcher, Mutator } from '~/utils'
import { ProcessItem } from './ProcessItem'
import { InflowMoment } from '~/types/InflowMoments'
import { capitalize, isNumber } from 'lodash'
import { User, GroupUserEnrollmentType, GroupUserRemovedReason } from '~/types/User'
import { getUserDetailRoute } from '~/utils/getUserDetailRoute'
import {
    Link,
    ReadableDate,
    Spinner,
    Subtle,
    Icon,
    PdfModal,
    TableWrap,
    TableHeader,
    TableHeaderItem,
    Button,
    CenterModal,
    Emphasis,
    Paragraph,
} from '~/components'
import { translateType } from '~/shared/utils'
import { Group } from '~/types/Group'
import { FinalExam, FinalExamResult } from '~/types/FinalExam'
import { AdviceReport } from '~/types/AdviceReports'
import { ModalManager } from '~/components/ModalManager'
import { getCurrentUser } from '~/services/session'
import { Lesson } from '~/types/Lesson'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import { ExamIsDoneIcon, ExamIsSoonIcon } from '~/components/ExamStatus/icons'
import { ChunkedProcessLessonRows } from './ChunkedProcessLessonRows'
import * as moment from 'moment'
import { LearnerGroupParticipationHistoryDisenrollReason } from '~/components/Group/Participants/Misc/LearnerGroupParticipationHistoryDisenrollReason/LearnerGroupParticipationHistoryDisenrollReason'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TimelineItem } from '~/components/Timeline/TimelineItem'
import { CreateSignupForm } from './Signup/CreateSignupForm'
import { EditSignupForm } from './Signup/EditSignupForm'
import { UserLearnerSignupType } from '~/generated/graphql'

enum Status {
    Active = 'Active',
    Ended = 'Ended',
    SignedOut = 'SignedOut',
}

interface StatusWithData {
    status: Status
    data: {
        endedAt?: Date
        disenrolledAt?: Date
    }
}

interface Props {
    className?: ClassValue
    userId: string
    hideFieldsForOrganizationContact?: boolean
    timeline?: boolean
}

export interface ExtendedUserLearnerSignupType extends UserLearnerSignupType {
    isWaitingForInflowMoment: boolean
}

const GET_USER_PROCESS = gql`
    query _($userId: MongoID!) {
        users(filters: { byId: $userId }) {
            _id
            learner {
                groups {
                    _id
                    name
                    dateFrom
                    isConcept
                    dateTo
                    endedAt
                    module {
                        _id
                        name
                    }
                    learnerUsers(filterByUserIds: [$userId]) {
                        _id
                        hasLearnerFinalEnrollment
                        enrollments {
                            _id
                            type
                            date
                            removedReason
                            removedInfo
                        }
                        hasFinishedGroup
                        learnerAttendanceRate
                    }
                    employeeUsers {
                        _id
                        user {
                            _id
                            roles
                            profile {
                                name
                            }
                            isEmployee
                        }
                    }
                    teacherUsers {
                        _id
                        user {
                            _id
                            roles
                            profile {
                                name
                            }
                            isEmployee
                        }
                    }
                    lessons {
                        _id
                        date
                        order
                        teacherUser {
                            _id
                            roles
                            profile {
                                name
                            }
                        }
                        group {
                            _id
                        }
                        room {
                            _id
                            name
                            location {
                                _id
                                name
                            }
                        }
                        lessonUsers(filterByLearnerUserIds: [$userId]) {
                            _id
                            attendance
                            reasonOfAbsence
                            attendanceNote
                        }
                        location {
                            _id
                            name
                        }
                    }
                }
                isAvailableForInflow
                signups {
                    _id
                    signupDate
                    enrollmentType
                }
                finalExams {
                    _id
                    date
                    attempt
                    part
                    level
                    result
                }
                inflowMoments {
                    _id
                    dateRange {
                        from
                        to
                    }
                    intakerUsers {
                        _id
                        roles
                        profile {
                            name
                        }
                        isEmployee
                    }
                    employeeUsers {
                        _id
                        roles
                        profile {
                            name
                        }
                        isEmployee
                    }
                    inflowModule {
                        _id
                        name
                        type
                    }
                    adviceReports(filterByLearnerUserId: $userId) {
                        _id
                        intakerUser {
                            _id
                            roles
                            profile {
                                name
                            }
                        }
                        file {
                            _id
                            fileId
                            html
                            fileName
                        }
                    }
                }
            }
        }
    }
`
const CREATE_ADVICE_REPORT_FILE_MUTATION = gql`
    mutation _($adviceReportId: MongoID!) {
        adviceReports_generateFile(adviceReportId: $adviceReportId) {
            fileId
        }
    }
`

export class ProcessList extends React.Component<Props> {
    private bem = new BEM('ProcessList')

    private processFetcher: Fetcher
    private createAdviceReportFileMutator: Mutator

    private DynamicComponent = this.props.hideFieldsForOrganizationContact ? 'span' : Link

    constructor(props: Props) {
        super(props)

        this.processFetcher = new Fetcher({
            query: GET_USER_PROCESS,
            variables: {
                userId: this.props.userId,
            },
            onChange: () => this.forceUpdate(),
        })

        this.createAdviceReportFileMutator = new Mutator({
            mutation: CREATE_ADVICE_REPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render(): React.ReactNode {
        const currentUser = getCurrentUser()
        // const { date, type } = this.state
        const { className } = this.props
        const { data = {}, loading } = this.processFetcher
        const { users = [] } = data
        const user: User = users && users[0]
        const userLearner = user && user.learner

        if (loading) {
            return <Spinner />
        }

        if (!userLearner) {
            return <Subtle>Deze gebruiker is niet gevonden</Subtle>
        }

        const { groups, finalExams, inflowMoments, signups } = userLearner

        const allProcessItems = [
            ...(groups && groups.length > 0
                ? groups.map(group => ({
                      type: processType.Group,
                      date: group.dateFrom as Date,
                      data: group,
                  }))
                : []),
            ...(finalExams && finalExams.length > 0
                ? finalExams.map(finalExam => ({
                      type: processType.FinalExam,
                      date: finalExam.date as Date,
                      data: finalExam,
                  }))
                : []),
            ...(inflowMoments && inflowMoments.length > 0
                ? inflowMoments.map(inflowMoment => ({
                      type: processType.InflowMoment,
                      date: inflowMoment.dateRange.from,
                      data: inflowMoment,
                  }))
                : []),
            ...(signups && signups.length > 0
                ? signups.map(signup => ({
                      type: processType.SignUps,
                      date: signup.signupDate as Date,
                      data: { ...signup, isWaitingForInflowMoment: false },
                  }))
                : []),
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
        ].sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())

        /**
         * If the learner is available for inflow,
         * it means that the latest signup item must be marked as "waiting for inflow moment".
         */
        if (userLearner.isAvailableForInflow) {
            const firstSignupProcessItem = allProcessItems.find(item => {
                return item.type === processType.SignUps
            })

            if (firstSignupProcessItem) {
                const data = firstSignupProcessItem.data as ExtendedUserLearnerSignupType
                data.isWaitingForInflowMoment = true
            }
        }

        return (
            <div className={this.bem.getClassName(className)}>
                <List horizontal>
                    <ListItem className={'tt-small-ListItem'}>Traject tijdlijn</ListItem>
                    {currentUser.isEmployee && (
                        <ListItem right>
                            <ModalManager
                                render={openModal => (
                                    <Button
                                        onClick={() => {
                                            openModal()
                                            this.setState({ isEdited: false })
                                        }}
                                        type={`edit`}
                                    >
                                        Aanmelding registreren
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal onClose={closeModal} title={`Aanmelding registreren`}>
                                        <CreateSignupForm
                                            userId={this.props.userId}
                                            onSubmitSuccess={() => {
                                                closeModal()
                                                this.processFetcher.refetch({ silent: true })
                                            }}
                                            onCancel={() => {
                                                closeModal()
                                            }}
                                        />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                    )}
                </List>
                <br />
                <div>{this.renderTimelineProcessList(allProcessItems)}</div>
            </div>
        )
    }

    private renderTimelineProcessList = (process: Process[]) => {
        const size = process.length - 1
        if (!process || process.length <= 0) {
            return <Subtle>Geen trajectacties gevonden voor deze gebruiker</Subtle>
        }

        const groupsWithNoDate = process.flatMap(item => {
            if (item.type === processType.Group && !item.date) {
                return item
            }
            return []
        })

        const processItemsWithoutGroupsWithNoDate = process.filter(item => item.date)

        return [
            [...groupsWithNoDate, ...processItemsWithoutGroupsWithNoDate].map((processItem, index) => {
                let position = 'regular-item'
                if (index === size) {
                    position = 'last'
                } else if (processItem.type === processType.Group && !processItem.date) {
                    position = 'no-date'
                } else if (index === 0) {
                    position = 'first'
                }

                return (
                    <TimelineItem position={position} key={processItem.data._id}>
                        {this.renderListItem(processItem)}
                    </TimelineItem>
                )
            }),
        ]
    }

    private renderListItem = (item: Process) => {
        switch (item.type) {
            case processType.Group:
                return this.renderGroup(item)
            case processType.FinalExam:
                return this.renderFinalExam(item)
            case processType.InflowMoment:
                return this.renderInflowMoment(item)
            case processType.SignUps:
                return this.renderSignUp(item)
            default:
                return null
        }
    }

    private renderSignUp = (item: Process) => {
        const currentUser = getCurrentUser()
        const signup = item.data as ExtendedUserLearnerSignupType

        const enrollementType = translateType('enrollmentType', signup.enrollmentType)
        const date = new Date(signup.signupDate)
        const signupDate = this.formateDate(date)

        return (
            <ProcessItem
                type={'Aanmelding'}
                name={
                    <div style={{ display: 'flex' }}>
                        <Paragraph>{signupDate}</Paragraph>
                    </div>
                }
                rows={[
                    <React.Fragment key={'group-1a'}>
                        {signup.isWaitingForInflowMoment && (
                            <SimpleTableRow key={'group-1a'}>
                                <SimpleTableCell colSpan={4}>
                                    <Paragraph>
                                        <Subtle>Wacht op instroommoment</Subtle>
                                    </Paragraph>
                                </SimpleTableCell>
                            </SimpleTableRow>
                        )}
                    </React.Fragment>,
                    <SimpleTableRow key={'group-1b'}>
                        <SimpleTableCell isBold={true}>Type instroom</SimpleTableCell>
                        <SimpleTableCell>{enrollementType}</SimpleTableCell>
                        <SimpleTableCell isBold={true}>Aangemeld op</SimpleTableCell>
                        <SimpleTableCell>{signupDate}</SimpleTableCell>
                        <SimpleTableCell></SimpleTableCell>
                        <SimpleTableCell right>
                            {currentUser.isEmployee && (
                                <ModalManager
                                    render={openModal => (
                                        <Button type={'button'} small={true} onClick={openModal}>
                                            Bewerken
                                        </Button>
                                    )}
                                    getModal={closeModal => (
                                        <CenterModal onClose={closeModal} title={`Aanmelding bewerken`}>
                                            <EditSignupForm
                                                defaultSignUp={signup}
                                                onSubmitSuccess={() => {
                                                    closeModal()
                                                    this.processFetcher.refetch({ silent: true })
                                                }}
                                                onCancel={() => {
                                                    closeModal()
                                                }}
                                                onDeleteSuccess={() => {
                                                    closeModal()
                                                    this.processFetcher.refetch({ silent: true })
                                                }}
                                            />
                                        </CenterModal>
                                    )}
                                />
                            )}
                        </SimpleTableCell>
                    </SimpleTableRow>,
                ]}
                key={signup._id}
            />
        )
    }

    private renderGroup = (item: Process) => {
        const group = item.data as Group

        const intakerUsers =
            (group &&
                group.teacherUsers &&
                (group.teacherUsers.map(user => user.user).filter(user => !!user) as User[])) ||
            []
        const employeeUsers =
            (group &&
                group.employeeUsers &&
                (group.employeeUsers.map(user => user.user).filter(user => !!user) as User[])) ||
            []

        const learnerUser = group && group.learnerUsers && group.learnerUsers[0]

        const formattedDate = this.renderGroupHeaderDate(group)

        const groupIsConcept = this.isGroupConcept(group)
        const groupDateDifference = moment(group.dateFrom).diff(moment(), 'days')
        const conceptGroupWarning =
            groupDateDifference >= -14 && groupDateDifference <= 0
                ? `Groep is ${Math.abs(groupDateDifference)} ${
                      groupDateDifference === 1 ? 'dag' : 'dagen'
                  } geleden gestart`
                : `Groep start over ${Math.abs(groupDateDifference)} ${groupDateDifference === -1 ? 'dag' : 'dagen'}`

        return (
            <ProcessItem
                type={groupIsConcept ? 'Conceptgroep' : 'Groep'}
                warning={groupIsConcept && group.dateFrom ? conceptGroupWarning : null}
                name={
                    <div style={{ display: 'flex' }}>
                        {formattedDate}
                        <this.DynamicComponent
                            className={this.bem.getElement('title-header')}
                            leftIcon={this.renderGroupIcon(group)}
                            route={`/groups/${groupIsConcept ? 'concept/' : ''}${group._id}`}
                        >
                            {this.DynamicComponent === 'span' ? this.renderGroupIcon(group) : ''} {group.name}
                        </this.DynamicComponent>
                    </div>
                }
                rows={[
                    <SimpleTableRow key={'group-1'}>
                        <SimpleTableCell isBold={true}>Lesmodule</SimpleTableCell>
                        <SimpleTableCell>{group.module ? group.module.name : '-'}</SimpleTableCell>
                        <SimpleTableCell isBold={true}>Huidige status</SimpleTableCell>
                        <SimpleTableCell>{this.renderGroupStatus(group)}</SimpleTableCell>
                    </SimpleTableRow>,
                    <SimpleTableRow key={'group-2'}>
                        <SimpleTableCell isBold={true}>Tijdvak</SimpleTableCell>
                        <SimpleTableCell>
                            {group.dateFrom && `Week ${moment(group.dateFrom).format('WW')}`}
                            {` `}
                            {/* tslint:disable-next-line:max-line-length */}(
                            {group.dateFrom ? moment(group.dateFrom).format('DD-MM-YYYY') : 'onbekend'} t/m{' '}
                            {group.dateTo ? moment(group.dateTo).format('DD-MM-YYYY') : 'onbekend'})
                        </SimpleTableCell>
                        <SimpleTableCell isBold={true}>Aanwezigheid</SimpleTableCell>
                        <SimpleTableCell>
                            {learnerUser
                                ? (isNumber(learnerUser.learnerAttendanceRate) &&
                                      `${Math.round(learnerUser.learnerAttendanceRate * 100)}%`) ||
                                  '-'
                                : '-'}
                        </SimpleTableCell>
                    </SimpleTableRow>,
                    <SimpleTableRow key={'group-3'}>
                        <SimpleTableCell isBold={true}>Interne medewerkers</SimpleTableCell>
                        <SimpleTableCell>
                            {this.renderEmployeeList([...intakerUsers, ...employeeUsers])}
                        </SimpleTableCell>
                    </SimpleTableRow>,
                ]}
                key={group._id}
                extension={
                    group.lessons && group.lessons.length > 0 ? this.renderLessonsTable(group, group.lessons) : null
                }
            />
        )
    }

    private renderGroupHeaderDate(group: Group) {
        if (!group.dateFrom) {
            return <Emphasis>Datum onbreekt</Emphasis>
        }

        const date = new Date(group.dateFrom)
        const formattedDate = this.formateDate(date)
        return <Paragraph>{formattedDate}</Paragraph>
    }

    private renderGroupStatus(group: Group) {
        if (!group || this.isGroupConcept(group) || !group.learnerUsers || !group.learnerUsers[0]) {
            return 'In concept'
        }

        const [groupLearnerUser] = group.learnerUsers
        const enrollments = groupLearnerUser.enrollments || []

        const lastEnrollment = enrollments[enrollments.length - 1]

        if (!lastEnrollment) {
            return null
        }

        const formattedDate = moment(lastEnrollment.date).format('LL')

        // Last known history-log is 'Enrollment'
        if (lastEnrollment.type === GroupUserEnrollmentType.Enrollment) {
            return <>Ingeschreven per {formattedDate}</>
        }

        // Last known history-log is 'Disenrollment'
        if (lastEnrollment.type === GroupUserEnrollmentType.Disenrollment) {
            return (
                <>
                    Uitgeschreven per {formattedDate}
                    {lastEnrollment.removedReason && (
                        <LearnerGroupParticipationHistoryDisenrollReason
                            reason={lastEnrollment.removedReason}
                            info={lastEnrollment.removedInfo}
                        />
                    )}
                </>
            )
        }

        return null
    }

    private renderLessonsTable(group: Group, lessons: Lesson[]) {
        const { refetch } = this.processFetcher
        const { userId } = this.props

        return (
            <TableWrap>
                <TableView full={true}>
                    <Table hasAutoLayout={true}>
                        <TableHeader>
                            <TableHeaderItem>Naam</TableHeaderItem>
                            <TableHeaderItem>Presentie</TableHeaderItem>
                            <TableHeaderItem>Docent</TableHeaderItem>
                            <TableHeaderItem>Datum</TableHeaderItem>
                            <TableHeaderItem>Locatie</TableHeaderItem>
                            <TableHeaderItem />
                        </TableHeader>
                        <ChunkedProcessLessonRows
                            learnerUserId={userId}
                            isForConceptGroup={group.isConcept}
                            lessons={lessons}
                            baseSize={6}
                            refetch={refetch}
                        />
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    private renderFinalExam = (item: Process) => {
        const finalExam = item.data || ({} as FinalExam)
        const isCurrentDateTwoWeeksBefore = moment(finalExam.date).diff(moment(), 'days') > 14
        const formattedDate = this.renderExamHeaderDate(finalExam)

        return (
            <ProcessItem
                type={'Examen'}
                warning={
                    isCurrentDateTwoWeeksBefore ? `Examen is nog te annuleren tot 14 dagen voor de examendatum` : null
                }
                name={
                    <div style={{ display: 'flex' }}>
                        {formattedDate}
                        <this.DynamicComponent
                            className={this.bem.getElement('title-header')}
                            leftIcon={this.renderFinalExamIcon(finalExam)}
                            route={`/final-exams/planned/${finalExam._id}`}
                        >
                            {this.DynamicComponent === 'span' ? this.renderFinalExamIcon(finalExam) : ''} Examen{' '}
                            <ReadableDate date={finalExam.date} />
                        </this.DynamicComponent>
                    </div>
                }
                rows={[
                    <SimpleTableRow key={'exam-1'}>
                        <SimpleTableCell isBold={true}>Examen</SimpleTableCell>
                        <SimpleTableCell>
                            {finalExam ? translateType('finalExamPart', finalExam.part) : '-'} {finalExam.level || ''}
                        </SimpleTableCell>
                        <SimpleTableCell isBold={true}>Datum</SimpleTableCell>
                        <SimpleTableCell>
                            <ReadableDate date={finalExam.date} />
                        </SimpleTableCell>
                    </SimpleTableRow>,
                    <SimpleTableRow key={'exam-2'}>
                        <SimpleTableCell isBold={true}>Resultaat</SimpleTableCell>
                        <SimpleTableCell>
                            {finalExam.result ? translateType('finalExamResult', finalExam.result) : 'Nog onbekend'}
                        </SimpleTableCell>
                        <SimpleTableCell isBold={true}>Poging</SimpleTableCell>
                        <SimpleTableCell>{finalExam.attempt}</SimpleTableCell>
                    </SimpleTableRow>,
                ]}
                key={finalExam._id}
            />
        )
    }

    private renderExamHeaderDate(exam: FinalExam) {
        if (!exam.date) {
            return <Emphasis>Datum onbreekt</Emphasis>
        }
        const formattedDate = this.formateDate(new Date(exam.date))
        return <Paragraph>{formattedDate}</Paragraph>
    }

    private renderInflowMoment = (item: Process) => {
        const inflowMoment = item.data || ({} as InflowMoment)
        const adviceReport =
            inflowMoment && inflowMoment.adviceReports && (inflowMoment.adviceReports[0] as AdviceReport)

        const intakerUsers = (inflowMoment && inflowMoment.intakerUsers) || []
        const employeeUsers = (inflowMoment && inflowMoment.employeeUsers) || []

        const formattedDate = this.renderInflowMomentHeaderDate(inflowMoment)

        return (
            <ProcessItem
                type={'Instroom'}
                name={
                    <div style={{ display: 'flex' }}>
                        {formattedDate}
                        <this.DynamicComponent
                            route={`/inflow-moments/definitive/${inflowMoment._id}`}
                            className={this.bem.getElement('title-header')}
                        >
                            {inflowMoment && inflowMoment.inflowModule.name}
                        </this.DynamicComponent>
                    </div>
                }
                rows={[
                    <SimpleTableRow key={'exam-1'}>
                        <SimpleTableCell isBold={true}>Type instroom</SimpleTableCell>
                        <SimpleTableCell>
                            {inflowMoment ? translateType('inflowModuleType', inflowMoment.inflowModule.type) : '-'}
                        </SimpleTableCell>
                        {adviceReport && (
                            <React.Fragment>
                                <SimpleTableCell isBold={true}>Toetsdocent</SimpleTableCell>
                                <SimpleTableCell>
                                    <Link route={getUserDetailRoute(adviceReport.intakerUser)}>
                                        {adviceReport.intakerUser.profile.name}
                                    </Link>
                                </SimpleTableCell>
                            </React.Fragment>
                        )}
                    </SimpleTableRow>,
                    <SimpleTableRow key={'exam-2'}>
                        <SimpleTableCell isBold={true}>Datum</SimpleTableCell>
                        <SimpleTableCell>{this.renderInflowMomentDate(inflowMoment)}</SimpleTableCell>
                        {adviceReport && (
                            <React.Fragment>
                                <SimpleTableCell isBold={true}>Adviesrapport</SimpleTableCell>
                                <SimpleTableCell>{this.renderAdviceReportModal(adviceReport)}</SimpleTableCell>
                            </React.Fragment>
                        )}
                    </SimpleTableRow>,
                    <SimpleTableRow key={'exam-3'}>
                        <SimpleTableCell isBold={true}>Interne medewerkers</SimpleTableCell>
                        <SimpleTableCell>
                            {this.renderEmployeeList([...intakerUsers, ...employeeUsers])}
                        </SimpleTableCell>
                    </SimpleTableRow>,
                ]}
                key={inflowMoment._id}
            />
        )
    }

    private renderInflowMomentHeaderDate(inflowMoment: InflowMoment) {
        if (!inflowMoment || !inflowMoment.dateRange || !inflowMoment.dateRange.from || !inflowMoment.dateRange.to) {
            return <Emphasis>Datum onbreekt</Emphasis>
        }

        const date = new Date(inflowMoment.dateRange.from)

        const formattedDate = this.formateDate(date)
        return <Paragraph>{formattedDate}</Paragraph>
    }

    private renderInflowMomentDate(inflowMoment: InflowMoment) {
        if (!inflowMoment || !inflowMoment.dateRange || !inflowMoment.dateRange.from || !inflowMoment.dateRange.to) {
            return '-'
        }

        const startDate = moment(inflowMoment.dateRange.from)
        const endDate = moment(inflowMoment.dateRange.to)

        return `${capitalize(startDate.format('ddd DD MMMM YYYY HH:mm'))} - ${endDate.format('H:mm')}`
    }

    private renderEmployeeList = (users: User[]) => {
        const { hideFieldsForOrganizationContact } = this.props

        const filteredUsers = users.filter(user => user.isEmployee)

        return filteredUsers.map((user, index) => {
            if (hideFieldsForOrganizationContact) {
                return `${index === 0 ? '' : ', '}${user.profile.name}`
            }

            return (
                <React.Fragment key={user._id}>
                    {index === 0 ? '' : ', '}
                    <Link route={getUserDetailRoute(user)}>{user.profile.name}</Link>
                </React.Fragment>
            )
        })
    }

    private isGroupConcept = (group: Group) => {
        return !!group.isConcept
    }

    private renderAdviceReportModal = (adviceReport: AdviceReport) => {
        const currentUser = getCurrentUser()
        const { isOrganizationContact } = currentUser

        return (
            <ModalManager
                render={openModal => (
                    <Link
                        onClick={openModal}
                        rightIcon={
                            !isOrganizationContact && adviceReport.releasedAt ? (
                                <Icon name={'status_done'} color={'#2cc472'} />
                            ) : (
                                ''
                            )
                        }
                    >
                        {this.getAdviceReportFileName(adviceReport)}
                    </Link>
                )}
                getModal={closeModal => (
                    <PdfModal
                        title={this.getAdviceReportFileName(adviceReport)}
                        fileName={this.getAdviceReportFileName(adviceReport)}
                        getFileId={async () => await this.getAdviceReportFileId(adviceReport)}
                        onClose={closeModal}
                    />
                )}
            />
        )
    }

    private getAdviceReportFileName = (adviceReport: AdviceReport): string => {
        return `${adviceReport.learnerUser.profile.name}-advies-rapport.pdf`
    }

    private getAdviceReportFileId = async (adviceReport: AdviceReport) => {
        if (adviceReport.file) {
            return adviceReport.file.fileId
        }

        const res = await this.createAdviceReportFileMutator.mutate({
            adviceReportId: adviceReport._id,
        })

        if (res && res.adviceReports_generateFile) {
            return res.adviceReports_generateFile.fileId
        }
    }

    private getGroupStatus = (group: Group): StatusWithData | void => {
        if (!group || this.isGroupConcept(group) || !group.learnerUsers || !group.learnerUsers[0]) {
            return
        }

        const [groupLearnerUser] = group.learnerUsers
        const enrollments = groupLearnerUser.enrollments || []

        if (groupLearnerUser && groupLearnerUser.hasLearnerFinalEnrollment === false) {
            const finalDisenrollment = enrollments[enrollments.length - 1]

            if (finalDisenrollment.removedReason === GroupUserRemovedReason.FinishedSuccesful && group.endedAt) {
                return {
                    status: Status.Ended,
                    data: {
                        endedAt: group.endedAt,
                    },
                }
            }

            return {
                status: Status.SignedOut,
                data: {
                    disenrolledAt: finalDisenrollment ? finalDisenrollment.date : undefined,
                },
            }
        }

        if (group.endedAt) {
            return {
                status: Status.Ended,
                data: {
                    endedAt: group.endedAt,
                },
            }
        }

        return {
            status: Status.Active,
            data: {},
        }
    }

    private renderGroupIcon = (group: Group) => {
        const groupStatus = this.getGroupStatus(group)

        switch (groupStatus && groupStatus.status) {
            case Status.Ended:
                return <Icon name={`status_done`} color={'#2bc572'} />
            case Status.Active:
                return <Icon name={`status_light`} color={'#314E93'} />
            case Status.SignedOut:
                return <Icon name={`remove`} color={'#e95156'} />
            default:
                return <Icon name={`status_pencil`} color={'#a8aeb3'} />
        }
    }

    private renderFinalExamIcon = (finalExam: FinalExam) => {
        const { result } = finalExam
        const isWaitingForResult = moment(finalExam.date).isBefore(moment())

        if (result === FinalExamResult.Exempt || result === FinalExamResult.Passed) {
            return ExamIsDoneIcon()
        } else if (result === FinalExamResult.Failed) {
            return <Icon name={`remove`} color={'#E03844'} />
        } else if (result === FinalExamResult.NoShow) {
            return <Icon name={`remove`} color={'#e95156'} />
        }

        if (isWaitingForResult) {
            return <Icon name={`status_light`} color={'#314E93'} />
        } else {
            return ExamIsSoonIcon
        }
    }

    private formateDate(date: Date) {
        return `${date.getDate().toString().padStart(2, '0')}-${(date.getMonth() + 1)
            .toString()
            .padStart(2, '0')}-${date.getFullYear()}`
    }
}
