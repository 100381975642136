import gql from 'graphql-tag'
import { get } from 'lodash'
import React from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { View } from '~/components/View'
import { Fetcher } from '~/utils'
import { RouteComponentProps } from 'react-router'
import { AddressFieldsFragment } from '~/types/Address'

const GET_TEACHER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            roles(filterMutable: true)
            invitedAt
            lastActivityAt
            profile {
                firstName
                initials
                name
                surNamePrefix
                surName
                gender
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
                alternativeEmail
                address {
                    ...AddressFieldsFragment
                }
                notes
                isMfaConfigured
                cognitoUserId
                mfaMobileNumber
            }
            teacher {
                paymentAddress {
                    ...AddressFieldsFragment
                }
                groups {
                    _id
                    name
                    module {
                        _id
                        name
                    }
                    dateFrom
                    dateTo
                    lessons {
                        _id
                        date
                        order
                    }
                    isConcept
                }
                paymentIban
                hasOwnCompany
                companyName
                companyCocNumber
                companyVatNumber
                isNT2Certified
                NT2Certificates {
                    _id
                    fileName
                }
                NT2CertifiedDate
                lessonCompetence
                lessonCompetenceDate
                lessonCompetenceCertificates {
                    _id
                    fileName
                }
                isActive
            }
        }
    }
    ${AddressFieldsFragment}
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: any
}

export default class DetailView extends React.Component<Props> {
    private teacherFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.teacherFetcher = new Fetcher({
            query: GET_TEACHER_QUERY,
            variables: {
                filters: {
                    byId: props.params.id,
                },
            },

            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.teacherFetcher
        const user = get(data, 'users[0]')

        return (
            <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    <PageTabs>
                        <PageTab>
                            <Link onlyActiveOnIndex={true} route={`/users/teachers/${this.props.params.id}/timeline`}>
                                Correspondentie
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex={true} route={`/users/teachers/${this.props.params.id}/groups`}>
                                Groepen
                            </Link>
                        </PageTab>
                        {(get(user, 'roles') || []).indexOf('INTAKER') > -1 && (
                            <PageTab>
                                <Link
                                    onlyActiveOnIndex={true}
                                    route={`/users/teachers/${this.props.params.id}/intakes`}
                                >
                                    Intakes
                                </Link>
                            </PageTab>
                        )}
                        <PageTab>
                            <Link
                                onlyActiveOnIndex={true}
                                route={`/users/teachers/${this.props.params.id}/availability`}
                            >
                                Beschikbaarheid
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex={true} route={`/users/teachers/${this.props.params.id}/documents`}>
                                Documenten
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex={true} route={`/users/teachers/${this.props.params.id}`}>
                                Gegevens
                            </Link>
                        </PageTab>
                    </PageTabs>
                </Header>
                <View>{user && React.cloneElement(children, { user, userId: user._id, refetch })}</View>
            </RouteView>
        )
    }
}
