import gql from 'graphql-tag'
import url from 'url'

import env from '~/services/env'
import { Mutator } from '~/utils'

export async function getFileUrl(fileId: string, fileName: string, options = {} as any) {
    const { asAttachment = false } = options

    const mutator = new Mutator({
        mutation: gql`
            mutation _($fileSession: FileSessionInputType!) {
                fileSessions_create(fileSession: $fileSession) {
                    _id
                    token
                }
            }
        `,
    })

    const res = await mutator.mutate({
        fileSession: {
            fileId,
        },
    })

    if (res && res.fileSessions_create) {
        const { token } = res.fileSessions_create

        if (token) {
            return url.resolve(
                env.serverBase,
                `/files/${fileId}/${encodeURIComponent(fileName)}?token=${encodeURIComponent(token)}${
                    asAttachment ? `&as_attachment=true` : ``
                }`
            )
        } else {
            return null
        }
    } else {
        return null
    }
}
