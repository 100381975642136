import * as React from 'react'
import Form from '~/components/Form'
import DrawerHeader from '~/components/DrawerHeader'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { User } from '~/types/User'
import { ContentView } from '~/components/ContentView'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { Wrap } from '~/components/Wrap'
import { Paginator } from '~/utils/Paginator'
import { Fetcher, Mutator } from '~/utils'
import gql from 'graphql-tag'
import { InflowMomentTimeslot } from '~/types/InflowMoments'
import DrawerFooter from '../DrawerFooter'

const INFLOW_MOMENTS_CHANGE_USERS_MUTATION = gql`
    mutation _($inflowMomentTimeslotId: MongoID, $userIds: [MongoID]) {
        inflowMomentTimeslots_changeUsers(inflowMomentTimeslotId: $inflowMomentTimeslotId, userIds: $userIds) {
            _id
        }
    }
`

export type RenderUsersTableHandler = (
    users: User[],
    addUser: (user: User) => void,
    removeUser: (user: User) => void,
    areUsersAdded: boolean,
    selectedUsers: User[]
) => JSX.Element | JSX.Element[]

interface Props {
    onCancel?: () => void
    onSuccess: () => void
    renderActionBar?: () => JSX.Element
    renderUsersTable: RenderUsersTableHandler
    timeslot: InflowMomentTimeslot
    title: string | JSX.Element
    usersFetcher: Fetcher
    usersPaginator: Paginator
}

interface State {
    checkedUsers: User[]
    uncheckedUsers: User[]
}

export class InflowMomentSlotUsersForm extends React.Component<Props, State> {
    public state: State = {
        checkedUsers: this.props.timeslot.timeslotUsers.map(timeslotUser => timeslotUser.user) || [],
        uncheckedUsers: [],
    }

    private changeUsersMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.changeUsersMutator = new Mutator({
            mutation: INFLOW_MOMENTS_CHANGE_USERS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { title, onCancel, renderUsersTable, usersPaginator, renderActionBar, usersFetcher } = this.props

        const { data, loading } = usersFetcher
        const { checkedUsers } = this.state

        const { loading: mutationLoading } = this.changeUsersMutator
        const selectedUsers = checkedUsers

        const users = data.users || []
        const notSelectedUsers = users
            ? users.filter(({ _id }: User) => !checkedUsers.find(({ _id: checkedId }) => checkedId === _id))
            : []

        return (
            <Form onSubmit={this.onSubmit}>
                <DrawerHeader title={title} close={onCancel} />
                <TableWrap insetTable={true}>
                    <TableView>
                        {renderUsersTable(selectedUsers, this.addUser, this.removeUser, true, selectedUsers)}
                    </TableView>
                </TableWrap>
                <ContentView>
                    <InfiniteScroll paginator={usersPaginator} preventLoad={loading}>
                        <Wrap full={true}>{renderActionBar && renderActionBar()}</Wrap>
                        <TableWrap>
                            <TableView>
                                {renderUsersTable(
                                    notSelectedUsers,
                                    this.addUser,
                                    this.removeUser,
                                    false,
                                    selectedUsers
                                )}
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter close={onCancel} isLoading={mutationLoading} />
            </Form>
        )
    }

    private onSubmit = async () => {
        const { onCancel, onSuccess, timeslot } = this.props
        const { _id } = timeslot
        const { checkedUsers } = this.state

        const data = await this.changeUsersMutator.mutate({
            inflowMomentTimeslotId: _id,
            userIds: checkedUsers.map(checkedUser => checkedUser._id),
        })

        if (data) {
            onSuccess()
        } else if (onCancel) {
            onCancel()
        }
    }

    private addUser = (user: User) => {
        this.setState({
            checkedUsers: [...this.state.checkedUsers, user],
            uncheckedUsers: this.state.uncheckedUsers.filter(({ _id }) => _id !== user._id),
        })
    }

    private removeUser = (user: User) => {
        this.setState({
            uncheckedUsers: [...this.state.uncheckedUsers, user],
            checkedUsers: this.state.checkedUsers.filter(({ _id }) => _id !== user._id),
        })
    }
}
