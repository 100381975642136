import * as React from 'react'
import { Wrap } from '~/components/Wrap'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import gql from 'graphql-tag'
import Fetcher from '~/utils/Fetcher'
import Mutator from '~/utils/Mutator'
import Spinner from '~/components/Spinner'
import { PhoneNumber, User } from '~/types/User'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { browserHistory } from 'react-router'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import { PageQuitConfirm } from '~/components/PageQuitConfirm'
import Form from '~/components/Form'
import MultiInput from '~/components/MultiInput'
import Input from '~/components/Input'
import RadioButton from '~/components/RadioButton'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import PhoneInput from '~/components/PhoneInput'
import { transformFormFields, toast } from '~/utils'
import { lib as transformLib } from '~/utils/transformFormFields'

interface ExternalOrganizationColleague {
    _id: string
    email?: string
    profile: {
        firstName?: string
        initials?: string
        surNamePrefix?: string
        surName?: string
        gender?: string
        phoneNumbers?: PhoneNumber[]
    }
    organizationContact?: {
        isActive?: boolean
    }
}

const GET_COLLEAGUE_QUERY = gql`
    query colleague($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            profile {
                firstName
                initials
                surNamePrefix
                surName
                gender
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
            }
            organizationContact {
                isActive
            }
        }
    }
`

const EDIT_COLLEAGUE_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`

const DELETE_COLLEAGUE_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_delete(userId: $userId) {
            _id
        }
    }
`

interface Props {
    userId: string
    currentUser?: User
    refetch: () => void
    route?: any
    router?: any
}

export class ColleagueProfileEdit extends React.Component<Props> {
    private colleagueFetcher: Fetcher
    private colleagueMutator: Mutator
    private colleagueDeleteMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.colleagueMutator = new Mutator({
            mutation: EDIT_COLLEAGUE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.colleagueDeleteMutator = new Mutator({
            mutation: DELETE_COLLEAGUE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.colleagueFetcher = new Fetcher({
            query: GET_COLLEAGUE_QUERY,
            variables: {
                filters: {
                    byId: props.userId,
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { route, router } = this.props
        const { data, loading } = this.colleagueFetcher
        const user = data && data.users && data.users[0]

        if (loading || !user) {
            return <Spinner />
        }

        return (
            <PageQuitConfirm
                renderComponent={(markAsDirty, markAsPristine) =>
                    this.renderWithUser(user, markAsDirty, markAsPristine)
                }
                route={route}
                router={router}
            />
        )
    }

    private renderWithUser = (
        user: ExternalOrganizationColleague,
        markAsDirty: () => void,
        markAsPristine: () => void
    ) => {
        const { userId, currentUser } = this.props
        const { profile, email, organizationContact } = user
        const { gender, phoneNumbers, firstName, initials, surNamePrefix, surName } = profile
        const { errors } = this.colleagueMutator
        const editingCurrentUser = userId === (currentUser && currentUser._id)

        return (
            <Wrap>
                <Form
                    onSubmit={(e: React.FormEvent, f: any) => this.onSubmit(e, f, markAsPristine)}
                    onChange={markAsDirty}
                >
                    <FieldCollection>
                        <FieldGroup title={`Persoonlijke gegevens`} isForm={true}>
                            <Field title={`Naam`} errors={errors}>
                                <MultiInput type={`name`}>
                                    <Input
                                        name={`user.profile.firstName`}
                                        type={`text`}
                                        placeholder={`Voornaam`}
                                        defaultValue={firstName}
                                    />
                                    <Input
                                        name={`user.profile.initials`}
                                        type={`text`}
                                        placeholder={`Voorletters`}
                                        defaultValue={initials}
                                    />
                                    <Input
                                        name={`user.profile.surNamePrefix`}
                                        type={`text`}
                                        placeholder={`Tussenvoegsel`}
                                        defaultValue={surNamePrefix}
                                    />
                                    <Input
                                        name={`user.profile.surName`}
                                        type={`text`}
                                        placeholder={`Achternaam`}
                                        defaultValue={surName}
                                    />
                                </MultiInput>
                            </Field>
                            <Field isLabel={true} title={`Geslacht`} errors={errors}>
                                <MultiInput type={`radio`}>
                                    <RadioButton
                                        name={`user.profile.gender`}
                                        value={`MALE`}
                                        defaultChecked={gender === 'MALE'}
                                    >
                                        Man
                                    </RadioButton>
                                    <RadioButton
                                        name={`user.profile.gender`}
                                        value={`FEMALE`}
                                        defaultChecked={gender === 'FEMALE'}
                                    >
                                        Vrouw
                                    </RadioButton>
                                    <RadioButton
                                        name={`user.profile.gender`}
                                        value={`OTHER`}
                                        defaultChecked={gender === 'OTHER'}
                                    >
                                        Anders
                                    </RadioButton>
                                </MultiInput>
                            </Field>
                        </FieldGroup>
                        <FieldGroup title={`Contactgegevens`} isForm={true}>
                            <Field isLabel={true} title={`E-mailadres`} errors={errors}>
                                <Input type={`email`} name={`user.email`} defaultValue={email} />
                            </Field>
                            <Field title={`Telefoonnummer`} errors={errors}>
                                <VariableMultiInput
                                    defaultAmount={phoneNumbers && phoneNumbers.length}
                                    getNewInput={i => (
                                        <PhoneInput
                                            defaultValue={phoneNumbers && phoneNumbers[i]}
                                            key={i}
                                            name={`user.profile.phoneNumbers[${i}]`}
                                        />
                                    )}
                                    addButtonLabel={`Extra veld`}
                                />
                            </Field>
                            {!editingCurrentUser && (
                                <Field title={`Status`} errors={errors}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`user.organizationContact.isActive`}
                                            value={true}
                                            defaultChecked={!!(organizationContact && organizationContact.isActive)}
                                        >
                                            Actief
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.organizationContact.isActive`}
                                            value={false}
                                            defaultChecked={!(organizationContact && organizationContact.isActive)}
                                        >
                                            Non-actief
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            )}
                        </FieldGroup>
                    </FieldCollection>
                    {this.renderFooter()}
                </Form>
            </Wrap>
        )
    }

    private renderFooter = () => {
        const { userId, currentUser } = this.props
        const { loading } = this.colleagueMutator
        const editingCurrentUser = userId === (currentUser && currentUser._id)

        return (
            <ActionBar
                fixed={true}
                isWide={true}
                getButtons={() => (
                    <List horizontal={true}>
                        {!editingCurrentUser && (
                            <ListItem>
                                <Button
                                    shouldPreventSubmit={true}
                                    linkStyle={`danger`}
                                    confirm={{
                                        title: 'Verwijderen',
                                        message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                        execute: {
                                            buttonType: 'danger',
                                            title: 'Verwijderen',
                                        },
                                    }}
                                    onClick={this.onDelete}
                                >
                                    Verwijderen
                                </Button>
                            </ListItem>
                        )}
                        <ListItem right={true}>
                            <Button type={`submit`} isLoading={loading} shouldPreventSubmit={false}>
                                Opslaan
                            </Button>
                        </ListItem>
                        <ListItem right={true}>
                            <Button onClick={() => browserHistory.push(this.getUserUrl())} shouldPreventSubmit={true}>
                                Annuleren
                            </Button>
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private getUser = () => {
        const { data } = this.colleagueFetcher
        return data && data.users && data.users[0]
    }

    private getUserUrl = () => {
        const user = this.getUser()

        if (user) {
            return `/colleagues/${user._id}`
        }

        return '/colleagues/'
    }

    private onDelete = async (event: React.MouseEvent<HTMLButtonElement>) => {
        const { refetch } = this.props
        const { userId } = this.props

        const data = await this.colleagueDeleteMutator.mutate({
            userId,
        })

        if (data) {
            refetch()
            browserHistory.push(`/colleagues/`)
        }
    }

    private onSubmit = async (event: React.FormEvent, fields: any, markAsPristine: () => void) => {
        const { userId, refetch } = this.props
        const userReturnUrl = this.getUserUrl()

        const data = await this.colleagueMutator.mutate({
            user: {
                _id: userId,
                ...transformFormFields(fields.user, {
                    profile: {
                        fields: (v: any) =>
                            transformFormFields(v, {
                                phoneNumbers: transformLib.phoneNumbers(),
                            }),
                    },
                    organizationContact: {
                        fields: (v: any) => transformFormFields(v, {}),
                    },
                }),
            },
        })

        if (data) {
            refetch()
            toast.success('Collega succesvol opgeslagen')
            markAsPristine()
            browserHistory.push(userReturnUrl)
        }
    }
}
