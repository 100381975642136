import gql from 'graphql-tag'
import { get, isNumber, merge, reduce } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import {
    Button,
    CenterModal,
    Currency,
    Icon,
    InvoiceStatusIndicator,
    Link,
    ReadableDate,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    ToolTip,
    Spinner,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Label } from '~/components/Label/Label'
import { ActivateGroupParticipationInvoiceForm } from '~/forms/ActivateGroupParticipationInvoiceForm'
import { CreateInvoiceForm } from '~/forms/CreateInvoiceForm'
import { CreateDUODeclarationForm } from '~/forms/CreateDUODeclarationForm'
import { InvoiceLink, InvoiceRowExpansionContent } from '~/implementations'
import { DUOFundInfo } from '~/implementations/DUOFundInfo'
import { InvoiceDescriptionText } from '~/implementations/InvoiceDescriptionText'
import { SendInvoiceProvider } from '~/implementations/SendInvoiceProvider'
import { UploadInvoiceSignatureProvider } from '~/implementations/UploadInvoiceSignatureProvider'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter, Mutator, toast } from '~/utils'
import { ActivateFinalExamInvoiceForm } from '~/forms/FinalExams/ActivateFinalExamInvoiceForm'
import { translateType } from '~/shared/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { InvoicingDisabledView } from './InvoicingDisabledView'
import { AddressFieldsFragment } from '~/types/Address'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'createdAt'
const DEFAULT_SORT_DIR = 'DESC'

// TODO: add typing
export default class LearnerInvoicingView extends Component {
    static propTypes = {
        userId: PropTypes.string,
        refetch: PropTypes.func,
    }

    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['byTextSearch'],
            onChange: filters => {
                this.invoicesFetcher.refetch({
                    filters,
                    silent: true,
                })
            },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.invoiceFetcherBaseVariables = {
            usersFilters: {
                byId: this.props.userId,
            },
            filters: {
                byTextSearch: this.filter.getFilters().byTextSearch,
            },
        }

        this.invoicesFetcher = new Fetcher({
            query: INVOICES_QUERY,
            variables: {
                ...this.invoiceFetcherBaseVariables,
                skip,
                limit,
                sortDir,
                sortBy,
            },

            onChange: () => this.forceUpdate(),
            transformData(data) {
                const user = get(data, 'users[0]')

                return {
                    user,
                    invoices: (get(user, 'learner.invoices') || []).map(invoice => merge({ user }, invoice)),
                }
            },
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.activatableGroupParticipationInvoicesFetcher = new Fetcher({
            query: ACTIVATABLE_GROUP_PARTICIPATION_INVOICES,
            variables: {
                usersFilters: {
                    byId: props.userId,
                },
            },

            onChange: () => this.forceUpdate(),
            transformData(data) {
                return {
                    activatableGroupParticipationInvoices:
                        get(data, 'users[0].learner.activatableGroupParticipationInvoices') || [],
                }
            },
        })

        this.finalExamsFetcher = new Fetcher({
            query: FINAL_EXAMS_QUERY,
            variables: {
                usersFilters: {
                    byId: props.userId,
                },
                filters: {
                    byActivatable: true,
                },
            },
            onChange: () => this.forceUpdate(),
            transformData(data) {
                return {
                    finalExams: get(data, 'users[0].learner.finalExams') || [],
                }
            },
        })

        this.activateDUOInvoicingMutator = new Mutator({
            mutation: INVOICE_ACTIVATE_DUO_INVOICING,
            reactComponentToUpdate: this,
        })

        this.deleteDUODeclarationMutator = new Mutator({
            mutation: DELETE_DUO_DECLARATION_MUTATION,
            reactComponentToUpdate: this,
        })

        this.updateInternalDUODeclarationsMutator = new Mutator({
            mutation: UPDATE_DUO_DECLARATIONS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    loadMore = (skip, limit, callback) => {
        this.invoicesFetcher.fetchMore({
            variables: { limit, skip, ...this.invoiceFetcherBaseVariables },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.invoices.length === 0,
                })

                return {
                    user: moreData.user,
                    invoices: removeDuplicateDocuments([...(prevData.invoices || []), ...moreData.invoices]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.invoicesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('byTextSearch', searchText)
    }

    hasPaymentByDUO() {
        const { data } = this.invoicesFetcher
        const { user } = data

        return !!(
            user &&
            get(user, 'learner.privatePaymentIsDUO') &&
            get(user, 'learner.privatePaymentDUOHasApprovedFund')
        )
    }

    updateInternalDUODeclarations = async () => {
        const { userId } = this.props
        const { refetch: refetchInvoices } = this.invoicesFetcher

        const result = await this.updateInternalDUODeclarationsMutator.mutate({
            userId,
        })

        if (result && result.users_updateInternalDUODeclarations) {
            toast.success('Interne DUO declaraties zijn opgehaald')
            refetchInvoices({ silent: true }) // = to update user
        }
    }

    deleteDUODeclaration = async DUODeclarationId => {
        const { refetch: refetchInvoices } = this.invoicesFetcher

        const res = await this.deleteDUODeclarationMutator.mutate({
            DUODeclarationId,
        })

        if (res) {
            toast.success('Declaratie verwijderd')
            refetchInvoices({ silent: true })
        }
    }

    render() {
        const { data, loading, refetch } = this.invoicesFetcher
        const { user } = data

        if (loading) {
            return <Spinner />
        }

        if (user && user.hasInvoicingEnabled) {
            return this.renderViewForInvoicingActivated()
        } else {
            return <InvoicingDisabledView user={user} refetch={refetch} />
        }
    }

    renderViewForInvoicingActivated() {
        const { loading, data } = this.invoicesFetcher
        const { invoices = [], user } = data
        const { loading: isActivateDUOInvoicingLoading } = this.activateDUOInvoicingMutator
        const filters = this.filter.getFilters()
        const { paginator, sorter } = this

        const { activatableGroupParticipationInvoices = [] } = this.activatableGroupParticipationInvoicesFetcher.data

        const { data: finalExamsData } = this.finalExamsFetcher
        const finalExamsToActivate = finalExamsData.finalExams || []

        const DUODeclarations = get(user, 'learner.DUODeclarations')
        const dateOfLastInternalDUODeclarationsUpdate = get(user, 'learner.latestInternalDUODeclarationsUpdateDate')

        const showActions = user.learner && user.learner.hasPrivatePayment

        return (
            <InfiniteScroll
                paginator={paginator}
                preventLoad={loading}
                component={View}
                className={`tt-LearnerInvoicingView`}
            >
                <ContentView>
                    <Wrap full>
                        <List horizontal>
                            <ListItem>
                                <Search
                                    isLoading={loading}
                                    onSearch={this.onSearch}
                                    defaultValue={filters.byTextSearch}
                                />
                            </ListItem>
                        </List>
                    </Wrap>
                    {this.hasPaymentByDUO() && (
                        <Wrap full>
                            <DUOFundInfo
                                initialFund={get(user, 'learner.privatePaymentDUOInitialRemainingFund')}
                                remainingFund={get(user, 'learner.privatePaymentDUORemainingLifeFund')}
                            />
                        </Wrap>
                    )}

                    {user && finalExamsToActivate.length > 0 && (
                        <TableWrap>
                            <TableView title={`Nieuwe examenfacturen`}>
                                <Table hasAutoLayout={true}>
                                    <TableHeader>
                                        <TableHeaderItem>Datum</TableHeaderItem>
                                        <TableHeaderItem>Onderdeel</TableHeaderItem>
                                        <TableHeaderItem>Niveau</TableHeaderItem>
                                        <TableHeaderItem>Poging</TableHeaderItem>
                                        <TableHeaderItem>Bedrag</TableHeaderItem>
                                        <TableHeaderItem />
                                        {/* status */}
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={6} isLoading={true} />
                                        </TableRow>
                                    ) : (
                                        this.renderFinalExamsToActivateRows(finalExamsToActivate)
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    )}
                    {user && user.mayHaveNewInvoices && activatableGroupParticipationInvoices.length > 0 && (
                        <TableWrap>
                            <TableView title="Nieuwe groepsfacturen">
                                <Table hasAutoLayout={true}>
                                    <TableHeader>
                                        <TableHeaderItem>Periode</TableHeaderItem>
                                        <TableHeaderItem width="35%">Groep</TableHeaderItem>
                                        <TableHeaderItem>Contractnummer</TableHeaderItem>
                                        <TableHeaderItem containsNumber>Bedrag</TableHeaderItem>
                                        <TableHeaderItem />
                                        {/* status */}
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={5} isLoading />
                                        </TableRow>
                                    ) : (
                                        this.renderActivatableGroupParticipationInvoices(
                                            activatableGroupParticipationInvoices
                                        )
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    )}
                    {((user && user.mayHaveNewInvoices) || invoices.length > 0) && (
                        <TableWrap>
                            <TableView
                                title="Facturen"
                                buttons={
                                    showActions && (
                                        <List horizontal>
                                            {user && user.mayHaveNewInvoices && (
                                                <ListItem left>{this.renderAddInvoiceModal()}</ListItem>
                                            )}
                                        </List>
                                    )
                                }
                            >
                                <Table hasAutoLayout={true}>
                                    <TableHeader>
                                        <TableHeaderItem sorter={sorter} sortBy={`invoiceNumber`}>
                                            Factuurnummer
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={sorter} sortBy={`invoiceDate`}>
                                            Factuurdatum
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={sorter} sortBy={`expirationDate`}>
                                            Vervaldatum
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={sorter} sortBy={`description`}>
                                            Omschrijving
                                        </TableHeaderItem>
                                        <TableHeaderItem containsNumber sorter={sorter} sortBy={`amount`}>
                                            Bedrag
                                        </TableHeaderItem>
                                        <TableHeaderItem />
                                        {/* status */}
                                        <TableHeaderItem />
                                        {/* collapse icon */}
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={7} isLoading />
                                        </TableRow>
                                    ) : invoices.length > 0 ? (
                                        this.renderInvoiceRows(invoices)
                                    ) : (
                                        <TableRow key={`emptyresult`}>
                                            <TableCell colSpan={7}>
                                                <Subtle>Er zijn geen facturen gevonden.</Subtle>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    )}
                    {this.hasPaymentByDUO() && (
                        <TableWrap>
                            <TableView
                                title={
                                    <Fragment>
                                        Overige declaraties voor DUO
                                        <ToolTip isLargeExplanation text={this.getDUODeclarationsExplanation()}>
                                            <Icon name="info" color="#a8aeb3" />
                                        </ToolTip>
                                    </Fragment>
                                }
                                buttons={
                                    <List horizontal>
                                        {showActions && (
                                            <ListItem left>{this.renderAddExternalDUODeclarationButton()}</ListItem>
                                        )}
                                        {showActions && user && !user.learner.isInvoicingByDUOActive && (
                                            <ListItem left>
                                                <Button
                                                    styleOverride="submit"
                                                    onClick={this.activateDUOInvoicing}
                                                    isLoading={isActivateDUOInvoicingLoading}
                                                    isDisabled={!dateOfLastInternalDUODeclarationsUpdate}
                                                    confirm={{
                                                        title: 'DUO-facturatie activeren',
                                                        message: `Je staat op het punt om DUO-facturatie te activeren. Vanaf dat moment kunnen alle TopTaal medewerkers DUO-facturen aanmaken voor deze kandidaat. Het is belangrijk dat hiervoor alle tot dusver bekende declaraties zijn ingevoerd, zodat de facturen goed verdeeld kunnen worden over de kwartalen.`,
                                                        execute: {
                                                            buttonType: 'submit',
                                                            title: 'Activeren',
                                                            buttonCountdown: 4,
                                                        },
                                                    }}
                                                >
                                                    DUO-facturatie activeren
                                                </Button>
                                            </ListItem>
                                        )}
                                    </List>
                                }
                            >
                                <Table hasAutoLayout={true}>
                                    <TableHeader>
                                        <TableHeaderItem>Bedrag</TableHeaderItem>
                                        <TableHeaderItem>Door TopTaal</TableHeaderItem>
                                        <TableHeaderItem>Kwartaal</TableHeaderItem>
                                        <TableHeaderItem>Omschrijving</TableHeaderItem>
                                        <TableHeaderItem />
                                    </TableHeader>
                                    {showActions && (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                {this.renderSyncInternalDUODeclarationsButton()}
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={5} isLoading />
                                        </TableRow>
                                    ) : DUODeclarations.length > 0 ? (
                                        this.renderDUODeclarationsRows(DUODeclarations)
                                    ) : (
                                        <TableRow>
                                            <TableCell colSpan={5}>
                                                <Subtle>Er zijn geen declaraties gevonden.</Subtle>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    )}
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderAddInvoiceModal() {
        const { userId } = this.props
        const { refetch: refetchInvoices } = this.invoicesFetcher

        const { data: groupsData, refetch: refetchGroups } = this.activatableGroupParticipationInvoicesFetcher
        const [groupToActivate] = groupsData.groups || []

        return (
            <ModalManager
                render={openModal => (
                    <Button
                        leftIcon={<Icon name="plus" />}
                        onClick={openModal}
                        confirm={
                            groupToActivate
                                ? {
                                      title: 'Meerdere facturen aanwezig',
                                      message: `Op dit moment dient er nog een factuur te worden geactiveerd van de groep ${groupToActivate.name}. Weet je zeker dat je nog een factuur wil toevoegen?`,
                                      execute: {
                                          buttonType: 'edit',
                                          title: 'Factuur toevoegen',
                                      },
                                  }
                                : undefined
                        }
                    >
                        Factuur toevoegen
                    </Button>
                )}
                getModal={closeModal => (
                    <CenterModal onClose={closeModal} title={`Factuur toevoegen`}>
                        <SendInvoiceProvider
                            render={sendInvoiceById => (
                                <CreateInvoiceForm
                                    userId={userId}
                                    isForDUO={this.hasPaymentByDUO()}
                                    onSubmitSuccess={() => {
                                        closeModal()
                                        refetchGroups({ silent: true })
                                        refetchInvoices({ silent: true })
                                    }}
                                    onCancel={closeModal}
                                    onRequestSend={invoiceId => sendInvoiceById(invoiceId)}
                                />
                            )}
                        />
                    </CenterModal>
                )}
            />
        )
    }

    renderAddExternalDUODeclarationButton() {
        const { userId } = this.props
        const { refetch: refetchInvoices } = this.invoicesFetcher

        return (
            <ModalManager
                render={openModal => (
                    <Button leftIcon={<Icon name="plus" />} onClick={openModal}>
                        Externe declaratie toevoegen
                    </Button>
                )}
                getModal={closeModal => (
                    <CenterModal onClose={closeModal} title={`Externe declaratie voor DUO-toevoegen`}>
                        <CreateDUODeclarationForm
                            userId={userId}
                            onSubmitSuccess={() => {
                                closeModal()
                                refetchInvoices({ silent: true }) // = to update user
                            }}
                            onCancel={closeModal}
                        />
                    </CenterModal>
                )}
            />
        )
    }

    renderSyncInternalDUODeclarationsButton() {
        const { loading: isUpdatingInternalDUODeclarations } = this.updateInternalDUODeclarationsMutator
        const {
            data: { user },
        } = this.invoicesFetcher

        const dateOfLastUpdate = get(user, 'learner.latestInternalDUODeclarationsUpdateDate')

        return (
            <Fragment>
                <Button
                    small
                    leftIcon={<Icon name="replay" />}
                    onClick={this.updateInternalDUODeclarations}
                    isLoading={isUpdatingInternalDUODeclarations}
                >
                    Synchroniseer interne declaraties
                </Button>
                {dateOfLastUpdate && (
                    <Subtle>
                        Laatste update: <ReadableDate date={dateOfLastUpdate} showTime />
                    </Subtle>
                )}
            </Fragment>
        )
    }

    renderInvoiceRows(invoices) {
        return invoices.map((invoice, i) => {
            const combinedInvoices = [invoice]

            if (invoice.descendantInstallmentInvoices && invoice.descendantInstallmentInvoices.length > 0) {
                for (const descendantInstallmentInvoice of invoice.descendantInstallmentInvoices) {
                    combinedInvoices.push({
                        ...descendantInstallmentInvoice,

                        // Reuse from leading invoice (is same)
                        user: invoice.user,
                        group: invoice.group,
                    })
                }
            }

            return <Fragment key={i}>{combinedInvoices.map(this.renderInvoiceRow)}</Fragment>
        })
    }

    renderInvoiceRow = invoice => {
        const {
            refetch: refetchInvoices,
            data: { user },
        } = this.invoicesFetcher
        const showActions = user.learner && user.learner.hasPrivatePayment

        return (
            <TableRow key={invoice._id} getExpansion={() => this.renderInvoiceExpansionCells(invoice)}>
                <TableCell>
                    {invoice.isDescendantInstallment && <Icon name={`nesting`} />}
                    <InvoiceLink invoice={invoice} />
                    {invoice.isForDUO && <Label>DUO</Label>}
                    {invoice.hasMultipleInstallments && isNumber(invoice.installmentIndex) && (
                        <Subtle>Termijn {invoice.installmentIndex + 1}</Subtle>
                    )}
                </TableCell>
                <TableCell>
                    <ReadableDate date={invoice.invoiceDate} />
                </TableCell>
                <TableCell>
                    <ReadableDate date={invoice.expirationDate} />
                </TableCell>
                <TableCell>
                    <InvoiceDescriptionText invoice={invoice} />
                </TableCell>
                <TableCell containsNumber>
                    <Currency amount={invoice.isCredit ? -invoice.amount : invoice.amount} />
                </TableCell>
                <TableCell className={`tt-LearnerInvoicingView__status-cell`}>
                    {showActions ? (
                        <UploadInvoiceSignatureProvider
                            render={(uploadSignatureByInvoiceId, isUploadingSignature) => (
                                <SendInvoiceProvider
                                    render={(sendInvoiceById, isSendingInvoice) => (
                                        <InvoiceStatusIndicator
                                            invoice={invoice}
                                            onRequestMakeFinal={async () => {
                                                await sendInvoiceById(invoice._id)
                                                refetchInvoices({ silent: true })
                                            }}
                                            isMakeFinalLoading={isSendingInvoice}
                                            onRequestUploadSignature={async () => {
                                                await uploadSignatureByInvoiceId(invoice._id)
                                                refetchInvoices({ silent: true })
                                            }}
                                            isUploadSignatureLoading={isUploadingSignature}
                                        />
                                    )}
                                />
                            )}
                        />
                    ) : (
                        <InvoiceStatusIndicator invoice={invoice} ensureShowIconOnly={true} />
                    )}
                </TableCell>
            </TableRow>
        )
    }

    renderInvoiceExpansionCells(invoice) {
        const { refetch: refetchGroups } = this.activatableGroupParticipationInvoicesFetcher
        const { refetch: refetchInvoices, data } = this.invoicesFetcher
        const { refetch: refetchFinalExams } = this.finalExamsFetcher

        return (
            <Fragment>
                <TableCell colSpan={7}>
                    {data && data.user && (
                        <InvoiceRowExpansionContent
                            userId={data.user._id}
                            userName={data.user.profile.name}
                            invoiceId={invoice._id}
                            hideActions={!(data.user.learner && data.user.learner.hasPrivatePayment)}
                            onChange={() => {
                                refetchGroups({ silent: true })
                                refetchInvoices({ silent: true })
                                refetchFinalExams({ silent: true })
                            }}
                        />
                    )}
                </TableCell>
            </Fragment>
        )
    }

    renderFinalExamsToActivateRows(finalExams) {
        const { refetch: refetchFinalExamInvoices } = this.finalExamsFetcher
        const {
            refetch: refetchInvoices,
            data: { user },
        } = this.invoicesFetcher
        const showActions = user.learner && user.learner.hasPrivatePayment

        return finalExams.map(finalExam => {
            const readableFinalExamPart = translateType('finalExamPart', finalExam.part)

            return (
                <TableRow key={finalExam._id}>
                    <TableCell>
                        <ReadableDate date={finalExam.date} />
                    </TableCell>
                    <TableCell>{readableFinalExamPart}</TableCell>
                    <TableCell>{finalExam.level}</TableCell>
                    <TableCell>{finalExam.attempt}</TableCell>
                    <TableCell>
                        <Currency amount={finalExam.cost} />
                    </TableCell>
                    <TableCell>
                        {showActions ? (
                            <ModalManager
                                render={openModal => (
                                    <InvoiceStatusIndicator
                                        isActivatableForFinalExamId={finalExam._id}
                                        onRequestActivate={openModal}
                                    />
                                )}
                                getModal={closeModal => (
                                    <CenterModal
                                        onClose={closeModal}
                                        title={`Factuur activeren voor ${user.profile.name}`}
                                    >
                                        <SendInvoiceProvider
                                            render={sendInvoiceById => (
                                                <ActivateFinalExamInvoiceForm
                                                    finalExam={finalExam}
                                                    onSubmitSuccess={() => {
                                                        refetchFinalExamInvoices({ silent: true })
                                                        refetchInvoices({ silent: true })
                                                        closeModal()
                                                    }}
                                                    onCancel={closeModal}
                                                    isForDUO={this.hasPaymentByDUO()}
                                                    onRequestSend={invoiceId => sendInvoiceById(invoiceId)}
                                                />
                                            )}
                                        />
                                    </CenterModal>
                                )}
                            />
                        ) : (
                            <InvoiceStatusIndicator
                                isActivatableForFinalExamId={finalExam._id}
                                ensureShowIconOnly={true}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    renderActivatableGroupParticipationInvoices(activatableInvoices) {
        const { userId } = this.props
        const { refetch: refetchActivatableInvoices } = this.activatableGroupParticipationInvoicesFetcher
        const {
            refetch: refetchInvoices,
            data: { user },
        } = this.invoicesFetcher
        const showActions = user.learner && user.learner.hasPrivatePayment

        return activatableInvoices.map((activatableInvoice, i) => {
            // const startMoment = activatableInvoice.group.dateFrom
            //     && moment(activatableInvoice.group.dateFrom)

            const dates = this.getFirstAndLastLesson(activatableInvoice.lessons)

            const startMoment = moment(dates.firstLesson.date)
            const endMoment = moment(dates.lastLesson.date)

            return (
                <TableRow key={i}>
                    <TableCell>
                        {startMoment && <ReadableDate date={startMoment.toDate()} />}
                        <span> - </span>
                        {endMoment && <ReadableDate date={endMoment.toDate()} />}
                    </TableCell>
                    <TableCell>
                        <Link route={`/groups/${activatableInvoice.group._id}`}>{activatableInvoice.group.name}</Link>
                    </TableCell>
                    <TableCell>{activatableInvoice.contract.contractNumber}</TableCell>
                    <TableCell containsNumber>
                        <Currency amount={activatableInvoice.totalCost} />
                    </TableCell>
                    <TableCell className={`tt-LearnerInvoicingView__status-cell`}>
                        {showActions ? (
                            <ModalManager
                                render={openModal => (
                                    <InvoiceStatusIndicator
                                        isActivatableForGroupId={activatableInvoice.group._id}
                                        onRequestActivate={openModal}
                                    />
                                )}
                                getModal={closeModal => (
                                    <CenterModal
                                        onClose={closeModal}
                                        title={`Factuur activeren voor ${user.profile.name}`}
                                    >
                                        <SendInvoiceProvider
                                            render={sendInvoiceById => (
                                                <ActivateGroupParticipationInvoiceForm
                                                    activatableInvoice={activatableInvoice}
                                                    userId={userId}
                                                    isForDUO={this.hasPaymentByDUO()}
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        refetchActivatableInvoices({ silent: true })
                                                        refetchInvoices({ silent: true })
                                                    }}
                                                    onCancel={closeModal}
                                                    onRequestSend={invoiceId => sendInvoiceById(invoiceId)}
                                                />
                                            )}
                                        />
                                    </CenterModal>
                                )}
                            />
                        ) : (
                            <InvoiceStatusIndicator
                                isActivatableForGroupId={activatableInvoice.group._id}
                                ensureShowIconOnly={true}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    getFirstAndLastLesson(lessons) {
        return {
            firstLesson: reduce(
                lessons,
                (first, current) => (first = moment(current.date).isBefore(first.date) ? current : first),
                lessons[0]
            ),
            lastLesson: reduce(
                lessons,
                (last, current) => (last = moment(current.date).isAfter(last.date) ? current : last),
                lessons[0]
            ),
        }
    }

    renderDUODeclarationsRows(DUODeclarations) {
        const {
            data: { user },
        } = this.invoicesFetcher
        const showActions = user.learner && user.learner.hasPrivatePayment

        return DUODeclarations.map((DUODeclaration, i) => (
            <TableRow key={i}>
                <TableCell>
                    <Currency amount={DUODeclaration.amount} />
                    {DUODeclaration.hasBeenImported && <Label>Twinfield</Label>}
                </TableCell>
                <TableCell>
                    {DUODeclaration.isExternal === true && 'Nee'}
                    {DUODeclaration.isExternal === false && 'Ja'}
                </TableCell>
                <TableCell>
                    {DUODeclaration.isExternal === true
                        ? 'n.v.t.'
                        : `Q${moment(DUODeclaration.date).quarter()} ${moment(DUODeclaration.date).year()}`}
                </TableCell>
                <TableCell>
                    {DUODeclaration.isFinalExamDeclaration ? (
                        <Link route={`/final-exams/planned/${DUODeclaration.finalExam._id}`}>
                            {`Examen op ${moment(DUODeclaration.finalExam.date).format('DD-MM-YYYY')}`}
                        </Link>
                    ) : (
                        DUODeclaration.description
                    )}
                </TableCell>
                <TableCell>
                    {showActions && (
                        <Button
                            className={`tt-TableRow__button__icon tt-TableRow__show-on-row-hover`}
                            type={`in-row`}
                            style={{ float: 'right' }}
                            leftIcon={<Icon name={`trash`} />}
                            onClick={() => this.deleteDUODeclaration(DUODeclaration._id)}
                            confirm={{
                                title: 'Verwijderen',
                                message: (
                                    <span>
                                        Je staat op het punt om deze declaratie te verwijderen. Weet je het zeker?
                                    </span>
                                ),
                                execute: {
                                    buttonType: 'danger',
                                    title: 'Verwijderen',
                                },
                            }}
                        />
                    )}
                </TableCell>
            </TableRow>
        ))
    }

    getDUODeclarationsExplanation() {
        const {
            data: { user },
        } = this.invoicesFetcher

        return `Het is mogelijk dat ${user.profile.name} facturen heeft gedeclareerd bij DUO die niet door TopTaal zijn gemaakt. Hier moeten we rekening mee houden om de resterende leenruimte te bepalen. Vul hieronder de DUO-facturen in die door een andere taalaanbieder bij DUO zijn gedeclareerd, na de eerste aanmelding van de kandidaat bij TopTaal.`
    }

    activateDUOInvoicing = async () => {
        const { userId } = this.props
        const {
            data: { user },
            refetch: refetchInvoices,
        } = this.invoicesFetcher
        const { refetch: refetchActivatableInvoices } = this.activatableGroupParticipationInvoicesFetcher

        const result = await this.activateDUOInvoicingMutator.mutate({
            userId: userId,
        })

        if (result && result.users_activateInvoicingByDUO) {
            toast.success(`DUO-facturatie is geactiveerd voor ${user.profile.name}`)
            refetchInvoices({ silent: true })
            refetchActivatableInvoices({ silent: true })
        }
    }
}

const INVOICES_QUERY = gql`
    query _(
        $usersFilters: UsersFilterInputType
        $skip: Int
        $limit: Int
        $sortBy: String
        $sortDir: String
        $filters: InvoicesFilterInputType
    ) {
        users(filters: $usersFilters) {
            _id
            email
            profile {
                name
            }
            hasInvoicingEnabled
            mayHaveNewInvoices
            learner {
                privatePaymentIsDUO
                hasPrivatePayment
                isIntegrationCourse
                integrationLawType
                paymentBy
                privatePaymentAddress {
                    ...AddressFieldsFragment
                }
                privatePaymentIsDUO
                twinfieldCustomer {
                    name
                    code
                }
                privatePaymentDUOHasApprovedFund
                privatePaymentDUOInitialRemainingFund
                privatePaymentDUORemainingLifeFund
                isInvoicingByDUOActive
                invoices(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
                    ...invoice
                    isCredit
                    installments
                    debitInvoice {
                        _id
                        isGroupParticipationInvoice
                        invoiceNumber
                        amount
                        group {
                            _id
                            name
                        }
                    }
                    group {
                        _id
                        name
                        module {
                            _id
                        }
                    }
                    finalExam {
                        _id
                        part
                        date
                    }
                    isExpired
                    daysExpired
                    descendantInstallmentInvoices {
                        ...invoice
                        descendantInstallmentInvoiceHasFailedToSend
                    }
                }
                latestInternalDUODeclarationsUpdateDate
                DUODeclarations {
                    _id
                    amount
                    isExternal
                    hasBeenImported
                    date
                    description
                    isFinalExamDeclaration
                    finalExam {
                        _id
                        date
                        part
                        level
                    }
                }
            }
        }
    }
    fragment invoice on InvoiceType {
        _id
        description
        isGroupParticipationInvoice
        isFinalExamInvoice
        invoiceNumber
        isForDUO
        amount
        hasMultipleInstallments
        hasSignatureForDUO
        installmentIndex
        invoiceDate
        expirationDate
        status
        openStatus
        paymentStatus
        isDescendantInstallment
        isFullyCredited
        isDownloadable
        invoiceSentAt
    }

    ${AddressFieldsFragment}
`

const ACTIVATABLE_GROUP_PARTICIPATION_INVOICES = gql`
    query _($usersFilters: UsersFilterInputType) {
        users(filters: $usersFilters) {
            _id
            learner {
                activatableGroupParticipationInvoices {
                    group {
                        _id
                        name
                        dateFrom
                        module {
                            _id
                            name
                        }
                    }
                    lessonCost
                    totalCost
                    lessons {
                        _id
                        date
                        order
                    }
                    lessonCount
                    month
                    contract {
                        _id
                        contractNumber
                    }
                }
            }
        }
    }
`

const FINAL_EXAMS_QUERY = gql`
    query _($usersFilters: UsersFilterInputType, $filters: UserLearnerFinalExamsFilterInputType) {
        users(filters: $usersFilters) {
            _id
            learner {
                finalExams(filters: $filters) {
                    _id
                    part
                    level
                    date
                    cost
                    attempt
                }
            }
        }
    }
`

const INVOICE_ACTIVATE_DUO_INVOICING = gql`
    mutation _($userId: MongoID!) {
        users_activateInvoicingByDUO(userId: $userId) {
            _id
        }
    }
`

const DELETE_DUO_DECLARATION_MUTATION = gql`
    mutation _($DUODeclarationId: MongoID!) {
        DUODeclarations_delete(DUODeclarationId: $DUODeclarationId) {
            _id
        }
    }
`

const UPDATE_DUO_DECLARATIONS_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_updateInternalDUODeclarations(userId: $userId) {
            _id
        }
    }
`
