import c from 'classnames'
import * as React from 'react'

import gql from 'graphql-tag'
import { Form, FieldCollection, Field, DateTimePicker, RadioButton, MultiInput, Button } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import Mutator from '~/utils/Mutator'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'

interface Props {
    groupId: string
    userId: string
    className?: string
    onSuccess: () => void
    onCancel: () => void
}

interface State {
    now: boolean
}

const ENROLL_MUTATION = gql`
    mutation _($groupId: MongoID, $userId: MongoID, $date: Date) {
        groups_enrollUser(groupId: $groupId, userId: $userId, date: $date, role: LEARNER) {
            _id
        }
    }
`

export class AddSingleLearnerUserToGroupForm extends React.Component<Props, State> {
    public state: State = {
        now: true,
    }

    private enrollMutator = new Mutator({
        mutation: ENROLL_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const { onCancel } = this.props
        const { loading, errors } = this.enrollMutator

        return (
            <Form onSubmit={this.onSubmit} className={this.getClassName()}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title={`Moment van inschrijving`} errors={errors}>
                            <MultiInput type="radio">
                                <RadioButton checked={this.state.now} onClick={() => this.setState({ now: true })}>
                                    Per direct
                                </RadioButton>
                                <RadioButton checked={!this.state.now} onClick={() => this.setState({ now: false })}>
                                    Ander moment
                                </RadioButton>
                            </MultiInput>
                        </Field>
                        {!this.state.now && (
                            <FieldGroup isInsetGroup>
                                <Field title={`Specifieke datum`} errors={errors}>
                                    <DateTimePicker name={`date`} />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { onSuccess } = this.props

        const { date } = transformFormFields(fields, {
            date: transformLib.date(),
        }) as any

        const result = await this.enrollMutator.mutate({
            groupId: this.props.groupId,
            userId: this.props.userId,
            date: date ? date : new Date(),
        })

        if (result && result.groups_enrollUser) {
            onSuccess()
        }
    }

    private getClassName() {
        const { className } = this.props

        return c('tt-AddSingleLearnerUserToGroupForm', className)
    }
}
