import * as React from 'react'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Field from '~/components/Field'
import Paragraph from '~/components/Paragraph'
import { Contract } from '~/types/Contracts'
import TextArea from '~/components/TextArea'
import DatePicker from '~/components/DatePicker'
import gql from 'graphql-tag'
import { Mutator, toast } from '~/utils'
import * as moment from 'moment'

interface Props {
    userContract: Contract
    onClose: () => void
    onSubmitSuccess?: () => void
}

export class LearnerEndContractForm extends React.Component<Props> {
    private endContractMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.endContractMutator = new Mutator({
            mutation: END_CONTRACT_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose, userContract } = this.props
        const { loading, errors } = this.endContractMutator
        const learnerName = userContract.user && userContract.user.profile.name

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field>
                            <Paragraph>Weet je zeker dat je dit contract van {learnerName} wil beëindigen?</Paragraph>
                        </Field>
                        <Field title={`Reden`} errors={errors}>
                            <TextArea name={`terminatedReason`} placeholder={`Voer een reden in`} />
                        </Field>
                        <Field title={`Beëindigen per`} errors={errors}>
                            <DatePicker
                                min={moment(userContract.dateFrom).format('YYYY-MM-DD')}
                                max={moment(userContract.dateTo).format('YYYY-MM-DD')}
                                name={`terminationDate`}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button isLoading={loading} type={`submit`} styleOverride={`danger`}>
                                    Contract beëindigen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { userContract, onSubmitSuccess, onClose } = this.props

        const data = await this.endContractMutator.mutate({
            contractId: userContract._id,
            terminatedReason: fields.terminatedReason,
            terminationDate: fields.terminationDate,
        })

        if (data && data.contracts_terminate) {
            if (onSubmitSuccess) {
                onSubmitSuccess()
            }

            onClose()
            toast.success(`Contract is beëindigd`)
        }
    }
}

const END_CONTRACT_MUTATION = gql`
    mutation _($contractId: MongoID!, $terminationDate: Date, $terminatedReason: String) {
        contracts_terminate(
            contractId: $contractId
            terminationDate: $terminationDate
            terminatedReason: $terminatedReason
        ) {
            _id
        }
    }
`
