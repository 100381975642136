import * as React from 'react'
import { capitalize } from 'lodash'
import gql from 'graphql-tag'
const moment = require('moment')
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import Highlight from '~/components/Highlight'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import {
    InflowMoment,
    InflowMomentTimeslot,
    InflowMomentTimeslotsAddType,
    InflowMomentTimeslotUser,
    InflowMomentTimeslotUserStatus,
} from '~/types/InflowMoments'
import ReadableDate from '~/components/ReadableDate'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import { ModalManager } from '~/components/ModalManager'
import { AddTimeslotModal } from '~/components/InflowMoment/AddTimeslotModal'
import { getCurrentUser } from '~/services/session'
import Link from '~/components/Link'
import DrawerModal from '~/components/DrawerModal'
import { InflowMomentSlotCandidatesForm } from '~/components/InflowMoment/InflowMomentSlotCandidatesForm'
import ActionBar from '~/components/ActionBar'
// import { InflowMomentInvitesForm } from './Forms/InflowMomentInvitesForm'
import { CounterIcon } from '../CounterIcon'
import { AdviceReportModalButton } from './AdviceReportModalButton'
import { Row } from '../Core/Layout/Row'
import Mutator from '~/utils/Mutator'
import { User } from '~/types/User'
import { ButtonGroup } from '~/components/ButtonGroup'
import ToolTip from '~/components/ToolTip'
import { InflowMomentSendInvitesModal } from './InflowMomentSendInvitesModal'

const ModifyCandidateButtonType = {
    Add: Symbol('add'),
    Edit: Symbol('edit'),
}

interface Props {
    inflowMoment: InflowMoment
    onSubmitSuccess: () => void
    refetch: (options?: any) => void
}

const SET_NOSHOW_MUTATION = gql`
    mutation _($inflowMomentTimeslotId: MongoID, $userId: MongoID) {
        inflowMomentTimeslotUser_setNoShowStatus(inflowMomentTimeslotId: $inflowMomentTimeslotId, userId: $userId) {
            status
        }
    }
`

const UNSET_NOSHOW_MUTATION = gql`
    mutation _($inflowMomentTimeslotId: MongoID, $userId: MongoID) {
        inflowMomentTimeslotUser_unsetNoShowStatus(inflowMomentTimeslotId: $inflowMomentTimeslotId, userId: $userId) {
            status
        }
    }
`

export class InflowMomentsSlotsTable extends React.Component<Props> {
    private setNoShowMutator: Mutator
    private unsetNoShowMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.setNoShowMutator = new Mutator({
            mutation: SET_NOSHOW_MUTATION,
            reactComponentToUpdate: this,
        })

        this.unsetNoShowMutator = new Mutator({
            mutation: UNSET_NOSHOW_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        return (
            <View>
                <Wrap full={true}>
                    {this.renderActionBar()}
                    {this.renderHighlight()}
                </Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </View>
        )
    }

    private renderActionBar = () => {
        const currentUser = getCurrentUser()

        if (!currentUser.isEmployee && !currentUser.isOrganizationContact) {
            return null
        }

        const { inflowMoment, onSubmitSuccess } = this.props

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem>
                            <ModalManager
                                render={openModal => (
                                    <Button
                                        onClick={openModal}
                                        leftIcon={<Icon name={`mail`} />}
                                        rightIcon={
                                            !!inflowMoment.candidatesInviteNeededCount && (
                                                <CounterIcon amount={inflowMoment.candidatesInviteNeededCount} />
                                            )
                                        }
                                    >
                                        Toets uitnodiging
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <InflowMomentSendInvitesModal
                                        inflowMoment={inflowMoment}
                                        closeModal={closeModal}
                                        onSubmitSuccess={onSubmitSuccess}
                                    />
                                )}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private renderHighlight = () => {
        const { inflowMoment } = this.props
        const { location, dateRange } = inflowMoment
        const date = moment(dateRange.from)

        return (
            <Highlight>
                <List horizontal={true} stretch={true}>
                    <ListItem>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold={true}>Datum</SimpleTableCell>
                                <SimpleTableCell>
                                    {dateRange ? `${capitalize(date.format('ddd DD MMMM YYYY'))}` : '-'}
                                </SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </ListItem>
                    <ListItem>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold={true}>Locatie</SimpleTableCell>
                                <SimpleTableCell>{location ? location.name : '-'}</SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </ListItem>
                </List>
            </Highlight>
        )
    }

    private renderTable = () => {
        const { inflowMoment } = this.props
        const { timeslots } = inflowMoment
        const loading = false
        const totalColSpan = 3

        return (
            <TableView>
                <Table hasAutoLayout={true}>
                    <TableHeader>
                        <TableHeaderItem width={'200px'}>Tijdslot</TableHeaderItem>
                        <TableHeaderItem>Kandidaten</TableHeaderItem>
                        <TableHeaderItem />
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={totalColSpan} isLoading={true} />
                        </TableRow>
                    ) : timeslots && timeslots.length > 0 ? (
                        this.renderTimeslotRows(inflowMoment, timeslots)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={totalColSpan}>
                                <Subtle>Er zijn geen tijdslots gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderTimeslotRows = (inflowMoment: InflowMoment, timeslots: InflowMomentTimeslot[]) => {
        const currentUser = getCurrentUser()
        const userCanAddTimeslot = currentUser.isEmployee
        const inflowMomentHasNotYetStarted = moment(inflowMoment.dateRange.to).isAfter(moment().startOf('day'))
        const renderAddTimeslotButtons =
            userCanAddTimeslot && inflowMoment.canHaveNewTimeslots && inflowMomentHasNotYetStarted

        if (renderAddTimeslotButtons) {
            return (
                <React.Fragment>
                    {renderAddTimeslotButtons && this.renderExtraTimeSlotButtonRow(InflowMomentTimeslotsAddType.Before)}
                    {timeslots.map(this.renderTimeslotRow)}
                    {renderAddTimeslotButtons && this.renderExtraTimeSlotButtonRow(InflowMomentTimeslotsAddType.After)}
                </React.Fragment>
            )
        }

        return <React.Fragment>{timeslots.map(this.renderTimeslotRow)}</React.Fragment>
    }

    private renderTimeslotRow = (timeslot: InflowMomentTimeslot) => {
        const { inflowMoment } = this.props
        const { amountOfSlotsPerTimeslot } = inflowMoment
        const { _id: timeslotId, timeslotUsers } = timeslot
        const currentUser = getCurrentUser()

        const userMayAddCandidates = currentUser.isEmployee || currentUser.isOrganizationContact
        const timeslotsAvailable = !timeslotUsers || timeslotUsers.length < amountOfSlotsPerTimeslot
        const shouldRenderActions = userMayAddCandidates && timeslotsAvailable

        return (
            <React.Fragment key={timeslotId}>
                {timeslotUsers && timeslotUsers.length > 0 ? (
                    <React.Fragment>
                        {timeslotUsers.map((candidate: InflowMomentTimeslotUser, candidateIndex: number) => {
                            const isFirstIteration = candidateIndex === 0

                            return (
                                <TableRow key={candidateIndex}>
                                    {this.renderTimeslotTableCell(isFirstIteration, timeslot)}
                                    <TableCell>
                                        {this.renderLearnerName(candidate.user, () => (
                                            <Row spacingBetween={true}>
                                                <span>{candidate.user.profile.name}</span>
                                                {this.renderStatusIconForCandidate(candidate, timeslot)}
                                            </Row>
                                        ))}
                                    </TableCell>
                                    <TableCell>
                                        {this.renderActions(timeslot, ModifyCandidateButtonType.Edit, candidate)}
                                    </TableCell>
                                </TableRow>
                            )
                        })}
                        {shouldRenderActions && (
                            <TableRow>
                                {this.renderTimeslotTableCell(false, timeslot)}
                                <TableCell colSpan={2}>
                                    {this.renderActions(timeslot, ModifyCandidateButtonType.Add)}
                                </TableCell>
                            </TableRow>
                        )}
                    </React.Fragment>
                ) : (
                    <TableRow>
                        {this.renderTimeslotTableCell(true, timeslot)}
                        <TableCell>
                            {shouldRenderActions && this.renderActions(timeslot, ModifyCandidateButtonType.Add)}
                        </TableCell>
                        <TableCell />
                    </TableRow>
                )}
            </React.Fragment>
        )
    }

    private renderLearnerName = (user: User, getContent: () => React.ReactNode): React.ReactNode => {
        const currentUser = getCurrentUser()

        if (currentUser.isOrganizationContact) {
            return <Link route={this.getCandidateUrl(user._id)}>{getContent()}</Link>
        }

        return getContent()
    }

    private renderStatusIconForCandidate = (candidate: InflowMomentTimeslotUser, timeslot: InflowMomentTimeslot) => {
        const { status } = candidate

        if (status === InflowMomentTimeslotUserStatus.Present) {
            return <Icon name={'status_done'} color={'#2cc472'} />
        } else if (status === InflowMomentTimeslotUserStatus.NoShow) {
            return <Icon name={'status_error'} color={'#a8aeb3'} />
        }

        return null
    }

    private getCandidateUrl(candidateId: string) {
        const { inflowMoment } = this.props
        const currentUser = getCurrentUser()

        if (currentUser.isEmployee) {
            return `/users/learners/${candidateId}`
        }

        if (currentUser.isOrganizationContact) {
            return `/learners/${candidateId}`
        }

        if (currentUser.isIntaker) {
            return `/inflow-moments/definitive/${inflowMoment._id}/intakes/${candidateId}`
        }

        return ''
    }

    private renderTimeslotTableCell = (isFirstIteration: boolean, timeslot: InflowMomentTimeslot) => {
        return (
            <TableCell forMultiRow={!isFirstIteration}>
                {isFirstIteration && this.getReadableTimeslot(timeslot)}
            </TableCell>
        )
    }

    private renderActions = (timeslot: InflowMomentTimeslot, type: Symbol, candidate?: InflowMomentTimeslotUser) => {
        const { onSubmitSuccess, inflowMoment, refetch } = this.props

        const typeIsEdit = type === ModifyCandidateButtonType.Edit
        const currentUser = getCurrentUser()
        const existingAdviceReport = candidate && this.getAdviceReportForCandidate(candidate.user, inflowMoment._id)
        const existingAdviceReportIsManuallyReleased = existingAdviceReport && existingAdviceReport.isManuallyReleased
        const userMayEditAdviceReport = !existingAdviceReportIsManuallyReleased

        return (
            <Row spacingBetween={true} horizontalEnd={candidate ? true : false}>
                <ButtonGroup smallButtons={true}>
                    {!currentUser.isExternalTeacher && (
                        <ModalManager
                            render={openModal => (
                                <Button
                                    className={`tt-TableRow__show-on-row-hover`}
                                    small={true}
                                    leftIcon={typeIsEdit ? null : <Icon name={`plus`} />}
                                    onClick={openModal}
                                >
                                    {typeIsEdit ? 'Tijdslot bewerken' : 'Kandidaten toevoegen'}
                                </Button>
                            )}
                            getModal={closeModal => (
                                <DrawerModal onClose={closeModal}>
                                    <InflowMomentSlotCandidatesForm
                                        onCancel={closeModal}
                                        timeslot={timeslot}
                                        inflowMoment={inflowMoment}
                                        onSubmitSuccess={() => {
                                            onSubmitSuccess()
                                            closeModal()
                                        }}
                                        drawerTitle={
                                            <div>
                                                Kandidaten voor {this.getReadableTimeslot(timeslot)}
                                                {` `}
                                                op <ReadableDate date={timeslot.startTime} format={`DD MMMM`} />
                                            </div>
                                        }
                                    />
                                </DrawerModal>
                            )}
                        />
                    )}

                    {candidate && inflowMoment.hasStarted && !currentUser.isOrganizationContact && (
                        <React.Fragment>
                            {candidate.status === InflowMomentTimeslotUserStatus.NoShow && (
                                <Button
                                    onClick={() => this.unmarkUserAsNoShow(timeslot._id, candidate)}
                                    small={true}
                                    className={`tt-TableRow__show-on-row-hover`}
                                >
                                    Aanwezig
                                </Button>
                            )}

                            {candidate.status !== InflowMomentTimeslotUserStatus.NoShow && (
                                <Button
                                    onClick={() => this.markUserAsNoShow(timeslot._id, candidate)}
                                    small={true}
                                    className={`tt-TableRow__show-on-row-hover`}
                                >
                                    Niet verschenen
                                </Button>
                            )}

                            {(candidate.status !== InflowMomentTimeslotUserStatus.NoShow || existingAdviceReport) && (
                                <ToolTip text={userMayEditAdviceReport ? '' : 'Adviesrapport is reeds vrijgegeven'}>
                                    <AdviceReportModalButton
                                        refetch={refetch}
                                        candidate={candidate.user}
                                        inflowMomentId={inflowMoment._id}
                                        existingAdviceReport={existingAdviceReport}
                                        isDisabled={!userMayEditAdviceReport}
                                        editButtonClassName={`tt-TableRow__show-on-row-hover`}
                                    />
                                </ToolTip>
                            )}
                        </React.Fragment>
                    )}
                </ButtonGroup>
            </Row>
        )
    }

    private getAdviceReportForCandidate = (candidate: User, inflowMomentId?: string) => {
        const adviceReports = (candidate.learner && candidate.learner.adviceReports) || []
        return adviceReports.find(adviceReport => adviceReport.inflowMoment._id === inflowMomentId)
    }

    private markUserAsNoShow = async (timeslotId: string, candidate: InflowMomentTimeslotUser) => {
        const { refetch } = this.props
        const { inflowMomentTimeslotUser_setNoShowStatus: data } = await this.setNoShowMutator.mutate({
            inflowMomentTimeslotId: timeslotId,
            userId: candidate.user._id,
        })

        if (data) {
            refetch({ silent: true })
        }
    }

    private unmarkUserAsNoShow = async (timeslotId: string, candidate: InflowMomentTimeslotUser) => {
        const { refetch } = this.props
        const result = await this.unsetNoShowMutator.mutate({
            inflowMomentTimeslotId: timeslotId,
            userId: candidate.user._id,
        })

        if (result && result.inflowMomentTimeslotUser_unsetNoShowStatus) {
            refetch({ silent: true })
        }
    }

    private getReadableTimeslot = (timeslot: InflowMomentTimeslot) => {
        const { startTime, endTime } = timeslot

        return (
            <React.Fragment>
                <ReadableDate date={startTime} format={`HH:mm`} />
                <span> - </span>
                <ReadableDate date={endTime} format={`HH:mm`} />
            </React.Fragment>
        )
    }

    private renderExtraTimeSlotButtonRow = (insertPosition: InflowMomentTimeslotsAddType) => {
        const { inflowMoment, onSubmitSuccess } = this.props
        return (
            <TableRow key={`add-timeslot-${insertPosition}`}>
                <TableCell>
                    <ModalManager
                        render={openModal => (
                            <Button
                                linkStyle={`default`}
                                type={`button`}
                                leftIcon={<Icon name={`plus-bold`} />}
                                onClick={openModal}
                            >
                                Extra tijdslot
                            </Button>
                        )}
                        getModal={closeModal => (
                            <AddTimeslotModal
                                onClose={closeModal}
                                inflowMoment={inflowMoment}
                                insertPosition={insertPosition}
                                onSubmitSuccess={onSubmitSuccess}
                                insertType={insertPosition}
                            />
                        )}
                    />
                </TableCell>
                <TableCell colSpan={2} />
            </TableRow>
        )
    }
}
