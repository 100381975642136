import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import Input from '~/components/Input'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { Button } from '~/components/buttons/Button/Button'
import { ListItem } from '~/components/ListItem'
import { List } from '~/components/List'
import transformFormFields from '~/utils/transformFormFields'
import Mutator from '~/utils/Mutator'
import gql from 'graphql-tag'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { getCurrentUser } from '~/services/session'
import FileInput from '~/components/FileInput'

const CREATE_USER_LEARNER_HOUR_MUTATION = gql`
    mutation _($userLearnerHour: UserLearnerHourInputType!) {
        userLearnerHours_create(userLearnerHour: $userLearnerHour) {
            _id
        }
    }
`

interface Props {
    onCancel: () => void
    onSubmitSuccess: () => void
    userId?: string
}

interface State {
    selectedDescriptionValue: TagPickerOption['value'] | null
}

interface FormFields {
    userLearnerHour: {
        amountOfHours: number | null
        description: string | null
    }
}

type FormSubmit<Fields> = (event: React.SyntheticEvent<HTMLFormElement>, fields: Fields) => void

export class AddHourManagementForm extends React.Component<Props, State> {
    public state: State = {
        selectedDescriptionValue: null,
    }

    private createUserLearnerHourMutator: Mutator

    private descriptionOptions = [
        {
            value: `EXTERNAL`,
            label: `Extern`,
        },
        {
            value: `ONA`,
            label: `ONA praktijkuren`,
        },
    ]

    private externalTeacherMayOnlyAddValues = [`ONA`]

    private documentIsEnabledForValues = [`ONA`]

    constructor(props: Props) {
        super(props)

        this.createUserLearnerHourMutator = new Mutator({
            mutation: CREATE_USER_LEARNER_HOUR_MUTATION,
            reactComponentToUpdate: this,
        })

        const descriptionOptions = this.getDescriptionOptions()

        if (descriptionOptions.length === 1) {
            this.state.selectedDescriptionValue = descriptionOptions[0].value
        }
    }

    public render() {
        const { onCancel } = this.props
        const { selectedDescriptionValue } = this.state
        const { loading: isLoading, errors } = this.createUserLearnerHourMutator
        const descriptionOptions = this.getDescriptionOptions()

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field errors={errors} isLabel={true} title={`Aantal uren`}>
                            <Input
                                name={`userLearnerHour.amountOfHours`}
                                type={`number`}
                                placeholder={`Vul het aantal uren in`}
                                min={0}
                            />
                        </Field>
                        <Field errors={errors} isLabel={true} title={`Omschrijving`}>
                            <TagPicker
                                name={`userLearnerHour.description`}
                                placeholder={`Vul een beschrijving van de uren in`}
                                options={descriptionOptions}
                                multi={false}
                                onChange={this.onChangeDescription}
                                value={selectedDescriptionValue}
                            />
                        </Field>
                        {selectedDescriptionValue && this.isDocumentEnabledForValue(selectedDescriptionValue) && (
                            <FieldGroup isInsetGroup={true}>
                                <Field title={`Formulier`} errors={errors}>
                                    <FileInput
                                        name={`userLearnerHour.document`}
                                        accept={`application/pdf`}
                                        multiple={false}
                                    >
                                        Kies bestand
                                    </FileInput>
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={isLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit: FormSubmit<FormFields> = async (event, fields) => {
        const { onSubmitSuccess, userId } = this.props

        const res = await this.createUserLearnerHourMutator.mutate({
            userLearnerHour: {
                userId,
                ...transformFormFields(fields.userLearnerHour),
            },
        })

        if (res && res.userLearnerHours_create) {
            onSubmitSuccess()
        }
    }

    private getDescriptionOptions(): TagPickerOption[] {
        const currentUser = getCurrentUser()

        if (currentUser.isExternalTeacher) {
            return this.descriptionOptions.filter(descriptionOption => {
                return this.externalTeacherMayOnlyAddValues.includes(descriptionOption.value)
            })
        }

        return this.descriptionOptions
    }

    private onChangeDescription = (descriptionOption: TagPickerOption) => {
        this.setState({
            selectedDescriptionValue: descriptionOption ? descriptionOption.value : null,
        })
    }

    private isDocumentEnabledForValue(value: TagPickerOption['value']): boolean {
        return this.documentIsEnabledForValues.includes(value)
    }
}
