import * as React from 'react'
import { Table } from '~/components/Table'
import { TableRow, TableHeaderItem, Button, Icon, TableWrap, CenterModal, Subtle, PdfModal } from '~/components'
import TableHeader from '~/components/TableHeader'
import ReadableDate from '~/components/ReadableDate'
import { TableView } from '~/components/TableView'
import { AddHourManagementForm } from '~/forms/AddHourManagementForm'
import Fetcher from '~/utils/Fetcher'
import gql from 'graphql-tag'
import { UserLearnerHour } from '~/types/UserLearnerHour'
import { View } from '~/components/View'
import Sorter from '~/utils/Sorter'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import translateType from '~/shared/utils/translateType'
import { Mutator } from '~/utils'
import { User } from '~/types/User'
import { TableCell } from '~/components/TableCell'
import { ModalManager } from '~/components/ModalManager'
import { getCurrentUser } from '~/services/session'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { SimpleTable } from '../SimpleTable'
import { SimpleTableRow } from '../SimpleTableRow'
import { SimpleTableCell } from '../SimpleTableCell'
import { DividerDot } from '~/components/Core/DividerDot/DividerDot'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'dateTo'
const DEFAULT_SORT_DIR = 'DESC'

const USER_LEARNER_HOURS_QUERY = gql`
    query _($filters: UsersFilterInputType, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        users(filters: $filters) {
            _id
            learner {
                hoursSummary {
                    hoursCitizenship
                    hoursCitizenshipOfWhichONA
                    hoursAlfa
                    hoursOther
                    hoursOtherOfWhichONA
                    hoursTotal
                }
                hours(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
                    _id
                    userId
                    amountOfHours
                    description
                    document {
                        _id
                        fileName
                    }
                    createdAt
                    createdByUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
            }
        }
    }
`

const DELETE_USER_LEARNER_HOUR_MUTATION = gql`
    mutation _($userLearnerHourId: MongoID!) {
        userLearnerHours_delete(userLearnerHourId: $userLearnerHourId) {
            _id
        }
    }
`

interface Props {
    userId: string
    onSubmitSuccess?: () => void
}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export class UserLearnerHoursTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private paginator: Paginator
    private userLearnerHourFetcher: Fetcher
    private deleteUserLearnerHourMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { userId } = this.props
        const { limit, skip, sortBy, sortDir } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.userLearnerHourFetcher = new Fetcher({
            query: USER_LEARNER_HOURS_QUERY,
            variables: {
                limit,
                skip,
                sortBy,
                sortDir,
                filters: {
                    byId: userId,
                },
            },
            onChange: () => this.forceUpdate(),
        })

        this.deleteUserLearnerHourMutator = new Mutator({
            mutation: DELETE_USER_LEARNER_HOUR_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { loading: isLoading } = this.userLearnerHourFetcher

        return (
            <TableWrap>
                <TableView buttons={this.renderButtons()}>
                    <InfiniteScroll paginator={this.paginator} preventLoad={isLoading} component={View}>
                        <Table>
                            {this.renderTableHeader()}
                            {isLoading ? (
                                <TableRow key={`loading`}>
                                    <TableCell colSpan={5} isLoading={true} />
                                </TableRow>
                            ) : (
                                <>
                                    {this.renderFixedRows()}
                                    {this.renderTableRows()}
                                </>
                            )}
                            <TableRow isStatisticRow={true} isHighlighted={true}>
                                <TableCell colSpan={5}>
                                    <SimpleTable>
                                        <SimpleTableRow>
                                            <SimpleTableCell>
                                                <Subtle regularSize={true}>Totaal aantal lesuren</Subtle>
                                            </SimpleTableCell>
                                            <SimpleTableCell>{this.getTotalHours()}</SimpleTableCell>
                                        </SimpleTableRow>
                                    </SimpleTable>
                                </TableCell>
                            </TableRow>
                        </Table>
                    </InfiniteScroll>
                </TableView>
            </TableWrap>
        )
    }

    private renderButtons = () => {
        if (!this.mayCurrentUserEditHours()) {
            return null
        }

        return this.renderHourManagementAddButton()
    }

    private renderHourManagementAddButton = () => {
        const { userId } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button onClick={openModal} leftIcon={<Icon name={`plus`} />}>
                        Uren toevoegen
                    </Button>
                )}
                getModal={closeModal => (
                    <CenterModal title={`Urenverantwoording toevoegen`} onClose={closeModal}>
                        <AddHourManagementForm
                            onCancel={closeModal}
                            onSubmitSuccess={() => {
                                closeModal()
                                this.onCloseModal()
                            }}
                            userId={userId}
                        />
                    </CenterModal>
                )}
            />
        )
    }

    private onCloseModal = () => {
        const { refetch: refetchHours } = this.userLearnerHourFetcher
        const { sortBy, sortDir } = this.state

        refetchHours({
            silent: true,
            sortBy,
            sortDir,
        } as any)

        if (this.props.onSubmitSuccess) {
            this.props.onSubmitSuccess()
        }
    }

    private renderTableHeader = () => {
        const { sorter } = this

        return (
            <TableHeader>
                <TableHeaderItem sorter={sorter} sortBy={`amountOfHours`}>
                    Uren
                </TableHeaderItem>
                <TableHeaderItem sorter={sorter} sortBy={`description`}>
                    Omschrijving
                </TableHeaderItem>
                <TableHeaderItem sorter={sorter} sortBy={`createdAt`}>
                    Invoerdatum
                </TableHeaderItem>
                <TableHeaderItem sorter={sorter} sortBy={`createdByUser.profile.name`}>
                    Medewerker
                </TableHeaderItem>
                <TableHeaderItem width={`5%`} />
            </TableHeader>
        )
    }

    private renderFixedRows = () => {
        const { data } = this.userLearnerHourFetcher
        const user: User = data && data.users && data.users[0]
        const hoursSummary = user && user.learner && user.learner.hoursSummary

        if (!hoursSummary) {
            return null
        }

        return (
            <React.Fragment>
                {!!hoursSummary.hoursCitizenship && hoursSummary.hoursCitizenship > 0 && (
                    <TableRow isHighlighted={true}>
                        <TableCell>
                            {hoursSummary.hoursCitizenship}
                            <Subtle>
                                (
                                {translateType(
                                    'userLearnerHourDescription',
                                    'CITIZENSHIP_OF_WHICH_ONA',
                                    hoursSummary.hoursCitizenshipOfWhichONA
                                )}
                                )
                            </Subtle>
                        </TableCell>
                        <TableCell>
                            {translateType('userLearnerHourDescription', 'CITIZENSHIP', hoursSummary.hoursCitizenship)}
                        </TableCell>
                        <TableCell colSpan={3} />
                    </TableRow>
                )}
                {!!hoursSummary.hoursAlfa && hoursSummary.hoursAlfa > 0 && (
                    <TableRow isHighlighted={true}>
                        <TableCell>{hoursSummary.hoursAlfa}</TableCell>
                        <TableCell>
                            {translateType('userLearnerHourDescription', 'ALFA', hoursSummary.hoursAlfa)}
                        </TableCell>
                        <TableCell colSpan={3} />
                    </TableRow>
                )}
            </React.Fragment>
        )
    }

    private renderTableRows = () => {
        const { data } = this.userLearnerHourFetcher
        const user = data && data.users && data.users[0]
        const userLearnerHours = (user && user.learner && user.learner.hours) || []

        return userLearnerHours.map((userLearnerHour: UserLearnerHour, i: number) => {
            const { _id, amountOfHours, document, description, createdAt, createdByUser } = userLearnerHour

            return (
                <TableRow key={i}>
                    <TableCell>
                        {amountOfHours}
                        {document && this.renderDocument(document)}
                    </TableCell>
                    <TableCell>{translateType('userLearnerHourDescription', description)}</TableCell>
                    <TableCell>
                        <ReadableDate date={createdAt} />
                    </TableCell>
                    <TableCell>{createdByUser && createdByUser.profile.name}</TableCell>
                    <TableCell>
                        {this.mayCurrentUserEditHours() && (
                            <Button
                                className={`tt-TableRow__show-on-row-hover`}
                                type={`in-row`}
                                onClick={() => this.onDeleteUserLearnerHour(_id)}
                                style={{ float: 'right' }}
                                confirm={{
                                    title: 'Verwijderen',
                                    message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                    execute: {
                                        buttonType: 'danger',
                                        title: 'Verwijderen',
                                    },
                                }}
                                leftIcon={<Icon name={`trash`} />}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }

    private onDeleteUserLearnerHour = async (userLearnerHourId: string) => {
        const { refetch } = this.userLearnerHourFetcher
        const { sortDir, sortBy } = this.state
        const { userId } = this.props

        await this.deleteUserLearnerHourMutator.mutate({
            userLearnerHourId,
        })

        refetch({
            sortDir,
            sortBy,
            silent: true,
            filters: {
                byId: userId,
            },
        } as any)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        const { userId } = this.props

        this.userLearnerHourFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
            filters: {
                byId: userId,
            },
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: any) => void) => {
        const { userId } = this.props

        this.userLearnerHourFetcher.fetchMore({
            variables: {
                limit,
                skip,
                filters: {
                    byId: userId,
                },
            },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.every((user: User) => {
                        return (
                            user && user.learner && user.learner.hours !== undefined && user.learner.hours.length === 0
                        )
                    }),
                })

                return {
                    ...prevData,
                    users: moreData.users.map((user: User) => {
                        const moreDataHours = (user && user.learner && user.learner.hours) || []
                        const prevDataUser =
                            prevData.users && prevData.users.filter((prevUser: User) => prevUser._id === user._id)
                        const prevDataHours =
                            (prevDataUser &&
                                prevDataUser[0] &&
                                prevDataUser[0].learner &&
                                prevDataUser[0].learner.hours) ||
                            []

                        return {
                            ...user,
                            learner: {
                                ...user.learner,
                                hours: removeDuplicateDocuments([...prevDataHours, ...moreDataHours]),
                            },
                        }
                    }),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private mayCurrentUserEditHours = () => {
        const currentUser = getCurrentUser()

        return !!currentUser && currentUser.isEmployee
    }

    private getTotalHours = () => {
        const { data } = this.userLearnerHourFetcher
        const user: User = data && data.users && data.users[0]
        const hoursSummary = user && user.learner && user.learner.hoursSummary

        if (!hoursSummary) {
            return null
        }

        return hoursSummary.hoursTotal
    }

    private renderDocument(document: UserLearnerHour['document']) {
        if (!document) {
            return null
        }

        return (
            <>
                <DividerDot />
                {this.renderDocumentModal(document)}
            </>
        )
    }

    private renderDocumentModal(document: UserLearnerHour['document']) {
        if (!document) {
            return null
        }

        return (
            <ModalManager
                render={openModal => (
                    <Button linkStyle={'default'} small={true} onClick={openModal}>
                        {document.fileName}
                    </Button>
                )}
                getModal={closeModal => (
                    <PdfModal
                        title={document.fileName}
                        fileName={document.fileName}
                        getFileId={() => document._id}
                        onClose={closeModal}
                    />
                )}
            />
        )
    }
}
