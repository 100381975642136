import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Icon,
    PdfModal,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { Wrap } from '~/components/Wrap'
import { CreateGroupDocumentForm } from '~/forms'
import { getCurrentUser } from '~/services/session'
import { downloadFile, Fetcher, Mutator } from '~/utils'

export default class DocumentsView extends Component {
    static propTypes = {
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }

    static defaultProps = {}

    constructor(props) {
        super(props)
        const groupId = this.props.params.id

        this.documentsFetcher = new Fetcher({
            query: GET_GROUP_DOCUMENTS_QUERY,
            variables: {
                filters: {
                    filterById: groupId,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.removeDocumentMutator = new Mutator({
            mutation: REMOVE_DOCUMENTS_MUTATION,
            reactComponentToUpdate: this,
        })

        this.boundOnDocumentAdd = this.onDocumentAdd.bind(this)
        this.boundOnRemoveDocument = this.onRemoveDocument.bind(this)
    }

    onDocumentAdd() {
        this.documentsFetcher.refetch()
    }

    onRemoveDocument(doc) {
        this.removeDocumentMutator
            .mutate({
                groupId: this.props.params.id,
                documentIds: [doc._id],
            })
            .then(() => {
                this.documentsFetcher.refetch()
            })
    }

    documentMayBeRemovedByUser() {
        const currentUser = getCurrentUser()

        return currentUser && currentUser.isEmployee
    }

    render() {
        return (
            <ContentView>
                <Wrap full>{this.renderUploadManager()}</Wrap>
                <TableWrap>{this.renderDocumentsTable()}</TableWrap>
            </ContentView>
        )
    }

    renderDocumentsTable() {
        const { data, loading } = this.documentsFetcher
        const documents = get(data, 'groups[0].documents') || []

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem width="35%">Naam</TableHeaderItem>
                        <TableHeaderItem>Geüpload op</TableHeaderItem>
                        <TableHeaderItem>Door</TableHeaderItem>
                        <TableHeaderItem />
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={5} isLoading />
                        </TableRow>
                    ) : documents.length > 0 ? (
                        this.renderDocumentRows(documents)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={5}>
                                <Subtle>Er zijn geen documenten gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    renderUploadManager() {
        const { id: groupId } = this.props.params

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal>
                        <ListItem left>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} leftIcon={<Icon name={`upload`} />}>
                                        Document uploaden
                                    </Button>
                                )}
                                getModal={closeModal => {
                                    return (
                                        <CenterModal onClose={closeModal} title={`Document uploaden`}>
                                            <CreateGroupDocumentForm
                                                groupId={groupId}
                                                onSubmitSuccess={() => {
                                                    closeModal()
                                                    this.boundOnDocumentAdd()
                                                }}
                                                onCancel={closeModal}
                                            />
                                        </CenterModal>
                                    )
                                }}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    renderDocumentRows(documents) {
        return documents.map(doc => {
            return (
                <TableRow key={doc._id}>
                    <TableCell>
                        {doc.isPDF ? (
                            <ModalManager
                                render={openModal => (
                                    <Button linkStyle={`default`} onClick={openModal}>
                                        {doc.fileName}
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <PdfModal
                                        title={doc.fileName}
                                        fileName={doc.fileName}
                                        getFileId={() => doc._id}
                                        onClose={closeModal}
                                    />
                                )}
                            />
                        ) : (
                            <Button linkStyle={`default`} onClick={() => downloadFile(doc._id, doc.fileName)}>
                                {doc.fileName}
                            </Button>
                        )}
                    </TableCell>
                    <TableCell>
                        <ReadableDate date={new Date(doc.createdAt)} />
                    </TableCell>
                    <TableCell>{get(doc.createdByUser, 'profile.name')}</TableCell>
                    <TableCell>
                        {this.documentMayBeRemovedByUser() && (
                            <Button
                                className={`tt-TableRow__show-on-row-hover`}
                                type={`in-row`}
                                onClick={() => this.boundOnRemoveDocument(doc)}
                                style={{ float: 'right' }}
                                confirm={{
                                    title: 'Verwijderen',
                                    message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                    execute: {
                                        buttonType: 'danger',
                                        title: 'Verwijderen',
                                    },
                                }}
                                leftIcon={<Icon name={`trash`} />}
                            />
                        )}
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const GET_GROUP_DOCUMENTS_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            documents(sortBy: "createdAt", sortDir: "DESC") {
                _id
                fileName
                isPDF
                createdAt
                createdByUser {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`

const REMOVE_DOCUMENTS_MUTATION = gql`
    mutation _($groupId: MongoID!, $documentIds: [MongoID]) {
        groups_removeDocuments(groupId: $groupId, documentIds: $documentIds) {
            _id
        }
    }
`
