import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    CheckBox,
    Field,
    FilterFieldCollection,
    Header,
    Icon,
    Link,
    MultiInput,
    ReadablePhoneNumbers,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { CreateOrganizationContactForm } from '~/forms'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const STATIC_ORGANIZATION_CONTACT_USERS_FILTERS = {
    roles: ['ORGANIZATION_CONTACT'],
}

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'profile.firstName'
const DEFAULT_SORT_DIR = 'ASC'

export default class MasterView extends Component {
    constructor(props) {
        super(props)

        bindAll(this, ['onFilterTagPicker', 'onFilter'])

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText', 'filterByContactOrganizationIds', 'filterOrganizationContactActive'],
            onChange: filters => {
                this.usersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_ORGANIZATION_CONTACT_USERS_FILTERS,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsActive', 'filterIsInactive'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterIsActive) activeStates.push(true)
                        if (filters.filterIsInactive) activeStates.push(false)

                        return {
                            filterOrganizationContactActive: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterOrganizationContactActive'],
                    parseFromHistory: historyFilters => {
                        if ('filterOrganizationContactActive' in historyFilters) {
                            if (historyFilters.filterOrganizationContactActive) {
                                return { filterIsActive: true }
                            } else {
                                return { filterIsInactive: true }
                            }
                        }
                    },
                },
            ],
            defaultFilterState: { filterIsActive: true },
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.onSort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.onLoadMore,
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.usersFetcher = new Fetcher({
            query: GET_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_ORGANIZATION_CONTACT_USERS_FILTERS,
                    ...this.filter.getFilters(),
                },
                limit: START,
                skip: 0,
                sortDir: DEFAULT_SORT_DIR,
                sortBy: DEFAULT_SORT_BY,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    onSort = ({ sortBy, sortDir }) => {
        this.usersFetcher.refetch({
            sortBy,
            sortDir,
            silent: true,
        })
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    onLoadMore = (skip, limit, callback) => {
        this.usersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    render() {
        const { loading, data } = this.usersFetcher
        const { users = [] } = data
        const filterIsActive = this.filter.hasFilters({
            excludeKeys: ['searchText'],
            excludeKeyValuePairs: [{ key: 'filterOrganizationContactActive', value: true }],
        })

        return (
            <InfiniteScroll paginator={this.paginator} preventLoading={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={this.renderActionbarButtons.bind(this)}
                            getDrawer={this.renderActionBarDrawer.bind(this)}
                            isActiveByDefault={filterIsActive}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={this.sorter} sortBy={`profile.firstName`}>
                                        Voornaam
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={this.sorter} sortBy={`profile.surName`}>
                                        Achternaam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Organisatie</TableHeaderItem>
                                    <TableHeaderItem sorter={this.sorter} sortBy={`email`}>
                                        E-mail
                                    </TableHeaderItem>
                                    <TableHeaderItem>Telefoonnummer</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={5} isLoading />
                                    </TableRow>
                                ) : users.length > 0 ? (
                                    this.renderContactRows(users)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={5}>
                                            <Subtle>Er zijn geen organisatie contactpersonen gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderActionbarButtons(toggleDrawer, isDrawerActive) {
        const { loading } = this.usersFetcher
        const filters = this.filter.getFilters()
        const hasFilters = this.filter.hasFilters({
            excludeKeys: ['searchText'],
        })

        return (
            <List horizontal>
                <ListItem>
                    <Button
                        leftIcon={<Icon name={`filter`} />}
                        hasNotification={hasFilters}
                        onClick={toggleDrawer}
                        isActive={isDrawerActive}
                    >
                        Filteropties
                    </Button>
                </ListItem>
                <ListItem>
                    <Search
                        placeholder={'Zoeken'}
                        isLoading={loading}
                        onSearch={this.onSearch}
                        defaultValue={filters.searchText}
                    />
                </ListItem>
                <ListItem right>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type={`edit`}>
                                Contactpersoon toevoegen
                            </Button>
                        )}
                        getModal={closeModal => {
                            return (
                                <CenterModal onClose={closeModal} title={`Contactpersoon toevoegen`}>
                                    <CreateOrganizationContactForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                                </CenterModal>
                            )
                        }}
                    />
                </ListItem>
            </List>
        )
    }

    renderActionBarDrawer() {
        const { data: filterParams } = this.filterSuggestionsFetcher
        const filters = this.filter.getFilters()
        const { organizations } = filterParams
        const organizationOptions =
            organizations && organizations.map(organization => ({ value: organization._id, label: organization.name }))
        const customFilters = this.filter.getCustomFilters()

        return (
            <FilterFieldCollection>
                <Field isLabel title={`Organisatie`} style={`compact`}>
                    <TagPicker
                        name={`filterByContactOrganizationIds`}
                        onChange={this.onFilterTagPicker}
                        defaultValue={filters.filterByContactOrganizationIds}
                        options={organizationOptions}
                        placeholder={`Selecteer organisatie`}
                    />
                </Field>
                <Field title={`Status`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox
                            name={`filterIsActive`}
                            defaultChecked={customFilters.filterIsActive}
                            onChange={this.onFilter}
                        >
                            Actief
                        </CheckBox>
                        <CheckBox
                            name={`filterIsInactive`}
                            defaultChecked={customFilters.filterIsInactive}
                            onChange={this.onFilter}
                        >
                            Non-actief
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    renderContactRows(users) {
        return users.map(user => {
            const phoneNumbers = get(user, 'profile.phoneNumbers')

            return (
                <TableRow key={user._id}>
                    <TableCell>
                        <Link route={`/users/organization-contacts/${user._id}`}>{user.profile.firstName}</Link>
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/organization-contacts/${user._id}`}>{user.profile.fullSurName}</Link>
                    </TableCell>
                    <TableCell>
                        <Link route={`/properties/organizations/${get(user.organizationContact, 'organization._id')}`}>
                            {get(user.organizationContact, 'organization.name')}
                        </Link>
                    </TableCell>
                    <TableCell>{user.email}</TableCell>
                    <TableCell>
                        <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                    </TableCell>
                </TableRow>
            )
        })
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }
}

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }
    }
`

const GET_USERS_QUERY = gql`
    query _($filters: UsersFilterInputType, $limit: Int, $skip: Int, $sortBy: String, $sortDir: String) {
        users(filters: $filters, limit: $limit, skip: $skip, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            profile {
                firstName
                fullSurName
                name
                gender
                phoneNumbers {
                    _id
                    internationalPhoneNumber
                    country
                    info
                }
            }
            email
            organizationContact {
                organization {
                    _id
                    name
                }
            }
        }
    }
`
