import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import FileInput from '~/components/FileInput'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components'
import DatePicker from '~/components/DatePicker'
import { FinalExam, FinalExamSubmitters } from '~/types/FinalExam'
import Mutator from '~/utils/Mutator'
import gql from 'graphql-tag'
import transformFormFields from '~/utils/transformFormFields'
import { toast } from '~/utils'

const FINAL_EXAM_CHANGE_DATE_MUTATION = gql`
    mutation finalExams_update($type: FinalExamEditTypesEnum, $finalExamId: MongoID, $date: Date, $file: UploadedFile) {
        finalExams_update(type: $type, finalExamId: $finalExamId, date: $date, file: $file)
    }
`

interface Props {
    finalExam: FinalExam
    onClose: () => void
    onSubmitSuccess: () => void
}

export class FinalExamChangeDateModalForm extends React.Component<Props> {
    private finalExamChangeDateMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.finalExamChangeDateMutator = new Mutator({
            mutation: FINAL_EXAM_CHANGE_DATE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { finalExam, onClose } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        {finalExam.submitter === FinalExamSubmitters.TopTaal && (
                            <Field>
                                Om de datum te verzetten dient er een ‘PDF examenafspraak’ te worden geupload. De
                                kandidaat zal een e-mail ontvangen dat zijn examenafspraak is gewijzigd.
                            </Field>
                        )}
                        {finalExam.submitter === FinalExamSubmitters.External && (
                            <Field>
                                Het examen is niet door TopTaal aangevraagd. Om deze reden is er geen mogelijkheid om
                                een ‘PDF examenafspraak’ te uploaden. Geef deze wijziging alleen door als je zeker weet
                                dat de nieuwe datum, buiten Toptaal om, correct is doorgeven bij DUO.
                            </Field>
                        )}
                    </FieldGroup>
                    <FieldGroup isInsetGroup={true} isForm={true} noNextField={true}>
                        {finalExam.submitter === FinalExamSubmitters.TopTaal && (
                            <Field title={`Upload afspraakbevestiging`}>
                                <FileInput name={`file`} multiple={false} accept={`application/pdf`}>
                                    Kies bestand
                                </FileInput>
                            </Field>
                        )}
                        <Field title={`Nieuwe datum`}>
                            <DatePicker name={`date`} />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`}>Wijziging doorgeven</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { finalExam, onSubmitSuccess } = this.props

        const data = await this.finalExamChangeDateMutator.mutate({
            finalExamId: finalExam._id,
            type: 'dateChanged',
            ...transformFormFields(fields),
        })

        if (data && data.finalExams_update === true) {
            toast.success(`De datum is verzet`)
            onSubmitSuccess()
        }
    }
}
