import gql from 'graphql-tag'
import * as React from 'react'
import moment from 'moment'
import { times, find } from 'lodash'
import { RouteComponentProps } from 'react-router'

import { Currency, Header, Subtle, TableHeaderItem, TableRow, TableWrap } from '~/components'
import { TableCell } from '~/components/TableCell'
import { TagPicker } from '~/components/TagPicker'
import { TableView } from '~/components/TableView'
import { RevenueTable } from '~/components/tables/RevenueTable/RevenueTable'
import { RevenueTableHeader } from '~/components/tables/RevenueTable/RevenueTableHeader'
import { RevenueAmountTableCell } from '~/components/tables/RevenueTable/RevenueAmountTableCell'
import { RevenueTableArticleCodeCell } from '~/components/tables/RevenueTable/RevenueTableArticleCodeCell'
import { RevenueTableArticleRow } from '~/components/tables/RevenueTable/RevenueTableArticleRow'
import { RevenueTableTotalsRow } from '~/components/tables/RevenueTable/RevenueTableTotalsRow'
import { RevenueTableHeaderMonthItem } from '~/components/tables/RevenueTable/RevenueTableHeaderMonthItem'
import { HeaderAction } from '~/components/Chrome/Header/HeaderAction/HeaderAction'
import { HeaderActionList } from '~/components/Chrome/Header/HeaderActionList/HeaderActionList'
import { Fetcher } from '~/utils'
import { ContentView } from '~/components/ContentView'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

const REVENUE_QUERY = gql`
    query _($years: [Int!]!) {
        revenueYears(years: $years) {
            year
            articles {
                twinfieldArticleCode
                months {
                    index
                    amount
                }
                amount
            }
            months {
                index
                amount
            }
            amount
        }
    }
`

interface Props extends RouteComponentProps<{}, {}> {}

interface RevenueYearData {
    articles: RevenueArticleData[]
    months: RevenueMonthData[]
    amount: number
}

interface RevenueArticleData {
    twinfieldArticleCode: string
    months: RevenueMonthData[]
    amount: number
}

interface RevenueMonthData {
    index: number
    amount: number
}

interface State {
    year: number
}

export default class RevenueView extends React.Component<Props, State> {
    public state: State = {
        year: moment().year(),
    }

    private revenueFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.revenueFetcher = new Fetcher({
            query: REVENUE_QUERY,
            variables: {
                years: [this.state.year],
            },
            onChange: () => this.forceUpdate(),
            transformData: data => {
                const revenueYears = data.revenueYears || []

                return {
                    yearData: find(revenueYears, { year: this.state.year }),
                }
            },
        })
    }

    public render() {
        const { ...routeProps } = this.props
        const { data, loading } = this.revenueFetcher

        const yearData = data.yearData as RevenueYearData

        const currentYear = moment().year()
        const fromYear = 2016
        const toYear = currentYear + 5
        const years = times(toYear - fromYear + 1, n => n + fromYear)

        return (
            <RouteView crumbLabel={'Omzet'} routeProps={routeProps}>
                <Header>
                    <BreadCrumbs />
                    <HeaderActionList>
                        <HeaderAction label="Jaar">
                            <TagPicker
                                name="yearFilter"
                                multi={false}
                                options={years.map((year, i) => ({
                                    value: year,
                                    label: year,
                                }))}
                                defaultValue={moment().year()}
                                placeholder="Jaar"
                                isClearable={false}
                                onChange={this.onSelectYear}
                            />
                        </HeaderAction>
                    </HeaderActionList>
                </Header>
                <ContentView>
                    <TableWrap>
                        <TableView>
                            <RevenueTable>
                                <RevenueTableHeader>
                                    <TableHeaderItem>Artikel</TableHeaderItem>
                                    {times(12, i => (
                                        <RevenueTableHeaderMonthItem key={i}>
                                            {moment().month(i).format('MMM')}
                                        </RevenueTableHeaderMonthItem>
                                    ))}
                                    <RevenueTableHeaderMonthItem isSpacer />
                                    <RevenueTableHeaderMonthItem isYearTotal>
                                        {this.state.year}
                                    </RevenueTableHeaderMonthItem>
                                </RevenueTableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={15} isLoading />
                                    </TableRow>
                                ) : yearData && yearData.articles && yearData.articles.length > 0 ? (
                                    this.renderRevenueRows(yearData)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={15}>
                                            <Subtle>Er is geen omzet bekend voor dit jaar</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </RevenueTable>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </RouteView>
        )
    }

    private renderRevenueRows(yearData: RevenueYearData) {
        const { articles, months, amount } = yearData

        return (
            <>
                {articles.map((article, index) => (
                    <RevenueTableArticleRow key={index}>
                        <RevenueTableArticleCodeCell>{article.twinfieldArticleCode}</RevenueTableArticleCodeCell>
                        {times(12, i => this.renderMonthCell(article.months, i))}
                        <RevenueAmountTableCell />
                        <RevenueAmountTableCell isYearTotal>
                            <Currency amount={article.amount} />
                        </RevenueAmountTableCell>
                    </RevenueTableArticleRow>
                ))}
                <RevenueTableTotalsRow>
                    <RevenueTableArticleCodeCell />
                    {times(12, i => this.renderMonthCell(months, i))}
                    <RevenueAmountTableCell />
                    <RevenueAmountTableCell isYearTotal>
                        <Currency amount={amount} />
                    </RevenueAmountTableCell>
                </RevenueTableTotalsRow>
            </>
        )
    }

    private renderMonthCell(months: RevenueMonthData[], monthIndex: number) {
        const monthData = find(months, { index: monthIndex })

        if (!monthData) {
            return <RevenueAmountTableCell key={monthIndex} isPlaceholder />
        }

        return (
            <RevenueAmountTableCell key={monthIndex}>
                <Currency amount={monthData.amount} />
            </RevenueAmountTableCell>
        )
    }

    private onSelectYear = (selection: any) => {
        if (selection) {
            this.setState({ year: selection.value })
            this.revenueFetcher.fetch({
                years: [selection.value],
            })
        } else {
            this.setState({ year: moment().year() })
            this.revenueFetcher.clear()
        }
    }
}
