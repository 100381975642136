import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { TagPicker } from '~/components/TagPicker'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components'
import { Lesson, LessonSpecialActivity } from '~/types/Lesson'
import ReadableDate from '~/components/ReadableDate'
import Mutator from '~/utils/Mutator'
import gql from 'graphql-tag'
import Fetcher from '~/utils/Fetcher'
import { ExamAbility, PlannedAbilityExam } from '~/types/Exam'
import apolloClient from '~/services/apolloClient'

const GET_LESSONS_QUERY = gql`
    query _($groupFilters: GroupsFilterInputType, $examId: MongoID) {
        groups(filters: $groupFilters) {
            _id
            lessons(filterByIsAvailableToPlanExamId: $examId) {
                _id
                date
                specialActivity
                order
            }
        }
    }
`

const EDIT_LESSON_MUTATION = gql`
    mutation _($lesson: LessonInputType!) {
        lessons_edit(lesson: $lesson) {
            _id
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: () => void
    groupId: string
    examId: string
    examAbility: ExamAbility
}

export class SelectExamLessonForm extends React.Component<Props> {
    private lessonsFetcher = new Fetcher({
        query: GET_LESSONS_QUERY,
        variables: {
            groupFilters: {
                filterById: this.props.groupId,
            },
            examId: this.props.examId,
        },

        onChange: () => this.forceUpdate(),
    })

    private lessonMutator = new Mutator({
        mutation: EDIT_LESSON_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const { onClose } = this.props
        const { loading: isSubmitting } = this.lessonMutator
        const { loading: isLoadingLessons } = this.lessonsFetcher

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title={`Les`}>{this.getLessonTagPicker({ isLoadingLessons })}</Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onClose}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button isLoading={isSubmitting} type={`submit`}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private getLessons(): Pick<Lesson, '_id' | 'date' | 'specialActivity' | 'order'>[] {
        const { data } = this.lessonsFetcher
        const [group = null] = data.groups || []

        return (group && group.lessons) || []
    }

    private getLessonTagPicker({ isLoadingLessons }: { isLoadingLessons?: boolean }): React.ReactNode {
        const options = this.getLessons().map(lesson => ({
            value: lesson._id,
            label: (
                <React.Fragment>
                    Les {lesson.order + 1} (<ReadableDate date={lesson.date} showTime={true} />)
                </React.Fragment>
            ),
        }))

        return (
            <TagPicker
                name="lesson._id"
                multi={false}
                options={options}
                placeholder="Selecteer een les"
                isLoading={isLoadingLessons}
            />
        )
    }

    private onSubmit = async (event: React.SyntheticEvent<HTMLFormElement>, fields: any) => {
        const { onSubmitSuccess, examId, examAbility } = this.props

        const lessonId = fields.lesson._id

        if (!lessonId) {
            return
        }

        const existingPlannedAbilityExams = await this.getExistingPlannedAbilityExamsByLessonId(lessonId)

        const result = await this.lessonMutator.mutate({
            lesson: {
                _id: lessonId,
                specialActivity: LessonSpecialActivity.Exam,
                plannedAbilityExams: [
                    /**
                     * Send the existing planned ability-exams along
                     */
                    ...existingPlannedAbilityExams.map(plannedAbilityExam => ({
                        _id: plannedAbilityExam._id,
                        examId: plannedAbilityExam.examId,
                        examAbility: plannedAbilityExam.examAbility,
                    })),

                    /**
                     * Add new planned ability-exam
                     */
                    {
                        examAbility: examAbility,
                        examId: examId,
                    },
                ],
            },
        })

        if (result && result.lessons_edit && result.lessons_edit._id) {
            onSubmitSuccess()
        }
    }

    /**
     * Query the existing planned ability exams for given lesson id
     *
     */
    private getExistingPlannedAbilityExamsByLessonId = async (lessonId: string): Promise<PlannedAbilityExam[]> => {
        const result = await apolloClient.query({
            query: gql`
                query _($groupFilters: GroupsFilterInputType, $lessonId: MongoID) {
                    groups(filters: $groupFilters) {
                        _id
                        lessons(byId: $lessonId) {
                            _id
                            plannedAbilityExams {
                                _id
                                examId
                                examAbility
                            }
                        }
                    }
                }
            `,
            variables: {
                groupFilters: {
                    filterById: this.props.groupId,
                },
                lessonId: lessonId,
            },
            fetchPolicy: 'network-only',
        })

        const { data } = result
        const { groups } = data as any
        const [group = null] = groups || []
        const [lesson = null] = (group && group.lessons) || []

        return lesson.plannedAbilityExams
    }
}
