import { LearnerLevelWithAlpha } from '~/types/LearnerLevel'
import { LocationProperty } from '~/types/Location'
import { Exam, ExamAbilityDictionary } from '~/types/Exam'

export interface CourseMaterial {
    _id: string
    name?: string
    amount?: number
}

export interface ModuleExam {
    _id?: string
    abilities: ExamAbilityDictionary
    examId?: string
    exam?: Exam
}

export enum ModuleProjectType {
    Integration = 'Integration',
    Organization = 'Organization',
    Other = 'Other',
}
export enum ModuleIntegrationType {
    ONA = 'ONA',
    Standard = 'Standard',
    Alfa = 'Alfa',
    StateExam = 'StateExam',
    Participation = 'Participation',
    MAP = 'MAP',
    Other = 'Other',
}

export interface Module {
    _id: string
    name?: string
    isActive?: boolean
    isLiteracyCourse?: boolean
    isIntegrationCourse?: boolean
    hasFinalReview?: boolean
    lessonDuration?: number
    amountOfWeeklyLessons?: number
    amountOfTotalLessons?: number
    capacity?: number
    courseMaterials?: CourseMaterial[]
    exams?: ModuleExam[]
    locationProperties?: LocationProperty[]
    startLevel?: LearnerLevelWithAlpha
    endLevel?: LearnerLevelWithAlpha
    isUsedInNonConceptGroups?: boolean
    isTemplateModule?: boolean
    isFinalExamTraining?: boolean
    projectType?: ModuleProjectType
    integrationType?: ModuleIntegrationType
    usedByProgram?: boolean
}
