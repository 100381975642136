import React, { Component } from 'react'
import gql from 'graphql-tag'
import { get } from 'lodash'

import { getCurrentUser } from '~/services/session'
import { OrganizationContactAgreementModal } from '~/modals'
import { Mutator } from '~/utils'

export default class OrganizationContactAgreement extends Component {
    constructor(props) {
        super(props)

        this.state = {
            user: null,
        }

        const user = getCurrentUser()

        if (!user.isOrganizationContact) {
            return
        }

        if (!get(user, 'organizationContact.hasAcceptedAgreement')) {
            this.state.showModal = true
        }

        this.agreeMutator = new Mutator({
            mutation: AGREE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    render() {
        const { showModal } = this.state

        return <div>{showModal && <OrganizationContactAgreementModal onAgree={this.agreeAndClose} />}</div>
    }

    agreeAndClose = async () => {
        await this.agreeMutator.mutate()
        this.setState({ showModal: false })
    }
}

const AGREE_MUTATION = gql`
    mutation _ {
        users_accept_organizationContactAgreement
    }
`
