import gql from 'graphql-tag'
import { get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    Bold,
    Button,
    DatePicker,
    Field,
    FieldCollection,
    Form,
    MultiInput,
    Paragraph,
    RadioButton,
    TextArea,
} from '~/components'
import { ValidationError } from '~/components/ValidationError'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import translateType from '~/shared/utils/translateType'
import { sortedGroupParticipationDisenrollmentReasons } from '~/utils/sortedGroupParticipationDisenrollmentReasons'

export default class GroupRemoveLearnerForm extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        learner: PropTypes.object.isRequired,
        onSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.state = {
            showModules: false,
        }

        this.modulesFetcher = new Fetcher({
            query: GET_MODULES_QUERY,

            onChange: () => this.forceUpdate(),
        })

        this.checkHasAttendanceFetcher = new Fetcher({
            query: USER_HAS_ATTENDANCE,

            onChange: () => this.forceUpdate(),
            variables: {
                afterDate: new Date(),
                groupFilters: { filterById: this.props.group._id },
                userId: this.props.learner._id,
            },
        })

        // this.removeUserMutator = new Mutator({
        //     mutation: REMOVE_USER_MUTATION,
        //     reactComponentToUpdate: this,
        // })
    }

    // onSubmit = async (event, fields) => {
    //     const { onSuccess } = this.props

    //     // if (await this.removeUserMutator.mutate({
    //     //     groupId: this.props.group._id,
    //     //     groupUsersToRemove: [{
    //     //         role: 'LEARNER',
    //     //         userId: this.props.learner._id,
    //     //         ...transformFormFields(get(fields, 'groupUsersToRemove[0]') || {}, {
    //     //             removedAt: transformLib.date(),
    //     //         }),
    //     //     }],
    //     // })) {
    //     //     onSuccess()
    //     // }
    // }

    checkAttendance = ({ nativeEvent }) => {
        const value = nativeEvent.target.value

        this.checkHasAttendanceFetcher.refetch({
            silent: true,
            ...transformFormFields(
                { afterDate: value || new Date() },
                {
                    afterDate: transformLib.date(),
                }
            ),
        })
    }

    render() {
        const { onCancel, learner, group } = this.props
        const { showModules } = this.state
        // const { errors, loading: isSaving } = this.removeUserMutator
        const { data } = this.modulesFetcher
        const modules = data.modules || []
        const { data: attendanceData } = this.checkHasAttendanceFetcher

        const hasAttendances = get(attendanceData, 'groups[0].learnerUsers[0].hasAttendances')

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field>
                            <Paragraph>
                                Je staat op het punt om <Bold>{learner.profile.name}</Bold> te verwijderen uit de groep{' '}
                                <Bold>{group.name}</Bold>, terwijl de lessen al zijn begonnen. De kandidaat zal op
                                inactief worden gezet in de vervolg lessen.
                            </Paragraph>
                        </Field>
                        <Field title={`Uitschrijfdatum`}>
                            {/* <Field title={`Uitschrijfdatum`} errors={errors}> */}
                            <DatePicker
                                name={`groupUsersToRemove[0].removedAt`}
                                defaultValue={moment().format('YYYY-MM-DD')}
                                onChange={this.checkAttendance}
                            />
                            {hasAttendances && (
                                <ValidationError
                                    text={`Na deze datum is al presentie ingevuld bij de kandidaat. Door deze gebruiker uit te schrijven wordt deze presentie verwijderd.`}
                                />
                            )}
                        </Field>
                        <Field title={`Reden uitschrijving`}>
                            {/* <Field title={`Reden uitschrijving`} errors={errors}> */}
                            <TagPicker
                                multi={false}
                                options={sortedGroupParticipationDisenrollmentReasons.map(o => ({
                                    value: o,
                                    label: translateType('groupParticipationDisenrollmentReason', o),
                                }))}
                                name={`groupUsersToRemove[0].removedReason`}
                                placeholder={`Selecteer een reden`}
                                autoFocus={true}
                            />
                        </Field>
                        <Field title={`Toelichting`}>
                            {/* <Field title={`Toelichting`} errors={errors}> */}
                            <TextArea name={`groupUsersToRemove[0].removedInfo`} />
                        </Field>
                        <Field title={`Status`}>
                            {/* <Field title={`Status`} errors={errors}> */}
                            <MultiInput type={`radio`}>
                                <RadioButton
                                    name={`groupUsersToRemove[0].status`}
                                    onChange={({ currentTarget }) => {
                                        if (currentTarget.checked) {
                                            this.setState({ showModules: false })
                                        }
                                    }}
                                    value={'INACTIVE'}
                                    checked={!showModules}
                                >
                                    Non-actief
                                </RadioButton>
                                <RadioButton
                                    name={`groupUsersToRemove[0].status`}
                                    onChange={({ currentTarget }) => {
                                        if (currentTarget.checked) {
                                            this.setState({ showModules: true })
                                        }
                                    }}
                                    value={'ADVANCE'}
                                    checked={showModules}
                                >
                                    Doorstroom
                                </RadioButton>
                            </MultiInput>
                            {this.state.showModules && (
                                <TagPicker
                                    multi={false}
                                    options={modules.map(m => ({ value: m._id, label: m.name }))}
                                    placeholder={`Selecteer een lesmodule`}
                                    name={`groupUsersToRemove[0].adviceModuleId`}
                                    placeholder={`Advies-lesmodule`}
                                />
                            )}
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={false /* isSaving*/}>
                                    Bevestigen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const GET_MODULES_QUERY = gql`
    query _ {
        modules(sortBy: "name", filterActive: true) {
            _id
            name
        }
    }
`

const USER_HAS_ATTENDANCE = gql`
    query _($groupFilters: GroupsFilterInputType, $userId: MongoID!, $afterDate: Date!) {
        groups(filters: $groupFilters) {
            learnerUsers(filterByUserIds: [$userId]) {
                hasAttendances(afterDate: $afterDate)
            }
        }
    }
`

// const REMOVE_USER_MUTATION = gql`
//     mutation _(
//         $groupId: MongoID!
//         $groupUsersToRemove: [RemovedGroupUserInputType!]!
//     ) {
//         groups_changeUsers (
//             groupId: $groupId
//             groupUsersToRemove: $groupUsersToRemove
//         ) {
//             _id
//         }
//     }
// `
