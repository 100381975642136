import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import React, { Component } from 'react'

import {
    Currency,
    Header,
    Link,
    ReadableDate,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Wrap } from '~/components/Wrap'
import { View } from '~/components/View'
import { InvoiceRowExpansionContent } from '~/implementations'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { InvoiceStatus } from '~/types/Invoice'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'createdAt'
const DEFAULT_SORT_DIR = 'DESC'

const STATIC_INVOICE_FILTERS = {
    byCredit: true,
    byStatus: InvoiceStatus.Draft,
}

export default class CreditInvoicesView extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        bindAll(this, ['onFilter', 'onFilterTagPicker'])

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'byTextSearch',
                'byCredit',
                'byStatus',
                'byInvoiceDateFrom',
                'byInvoiceDateTo',
                'byExpiredAtDateFrom',
                'byExpiredAtDateTo',
            ],
            onChange: filters => {
                this.invoicesFetcher.refetch({
                    filters: {
                        ...STATIC_INVOICE_FILTERS,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsDebetInvoice', 'filterIsCreditInvoice'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterIsDebetInvoice) activeStates.push(false)
                        if (filters.filterIsCreditInvoice) activeStates.push(true)

                        return {
                            byCredit: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                },
            ],
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.invoicesFetcher = new Fetcher({
            query: INVOICES_QUERY,
            variables: {
                skip,
                limit,
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_INVOICE_FILTERS,
                    byTextSearch: this.filter.getFilters().byTextSearch,
                },
            },

            onChange: () => this.forceUpdate(),
            transformData(data) {
                return {
                    invoices: get(data, 'invoices') || [],
                }
            },
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.invoicesFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.invoices.length === 0,
                })

                return {
                    invoices: removeDuplicateDocuments([...(prevData.invoices || []), ...moreData.invoices]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.invoicesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('byTextSearch', searchText)
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    render() {
        const { ...routeProps } = this.props
        const { loading, data } = this.invoicesFetcher
        const filters = this.filter.getFilters()
        const { invoices = [] } = data
        const { paginator, sorter } = this

        return (
            <RouteView crumbLabel={'Controle creditfacturen'} routeProps={routeProps}>
                <InfiniteScroll
                    paginator={paginator}
                    preventLoad={loading}
                    component={View}
                    className={`tt-LearnerInvoicingView`}
                >
                    <Header>
                        <BreadCrumbs />
                    </Header>
                    <ContentView>
                        <Wrap full>
                            <List horizontal>
                                <ListItem>
                                    <Search
                                        isLoading={loading}
                                        onSearch={this.onSearch}
                                        defaultValue={filters.byTextSearch}
                                    />
                                </ListItem>
                            </List>
                        </Wrap>
                        <TableWrap>
                            <TableView>
                                <Table hasAutoLayout={true}>
                                    <TableHeader>
                                        <TableHeaderItem sorter={sorter} sortBy={`invoiceDate`} width="35%">
                                            Aanvraagdatum
                                        </TableHeaderItem>
                                        <TableHeaderItem>Kandidaat</TableHeaderItem>
                                        <TableHeaderItem>Factuuromschrijving</TableHeaderItem>
                                        <TableHeaderItem containsNumber sorter={sorter} sortBy={`amount`}>
                                            Bedrag
                                        </TableHeaderItem>
                                        <TableHeaderItem />
                                        {/* expansion button */}
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={5} isLoading />
                                        </TableRow>
                                    ) : invoices.length > 0 ? (
                                        this.renderInvoiceRows(invoices)
                                    ) : (
                                        <TableRow key={`emptyresult`}>
                                            <TableCell colSpan={5}>
                                                <Subtle>Er zijn geen facturen gevonden.</Subtle>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </ContentView>
                </InfiniteScroll>
            </RouteView>
        )
    }

    // getCreditInvoicesView() {
    //     const { ...routeProps } = this.props

    //     return (props) => {
    //         <RouteView crumbLabel={'Controle creditfacturen'} routeProps={routeProps}>
    //             {props.chilren}
    //         </RouteView>
    //     }
    // }

    // renderActionBar() {
    //     const hasFilters = this.filter.hasFilters({excludeKeys: ['searchText']});
    //     const filters = this.filter.getFilters();
    //     const customFilters = this.filter.getCustomFilters();
    //     const { loading } = this.invoicesFetcher;

    //     return (
    //         <ActionBar
    //             isActiveByDefault={hasFilters}
    //             getButtons={(toggleDrawer, drawerActive) => (
    //                 <List horizontal>
    //                     <ListItem>
    //                         <Search isLoading={loading} onSearch={this.onSearch} defaultValue={filters.byTextSearch} />
    //                     </ListItem>
    //                 </List>
    //             )}
    //             getDrawer={() => (
    //                 <FilterFieldCollection>
    //                     <Field title='Type' style='compact'>
    //                         <MultiInput type='checkbox'>
    //                             <CheckBox name='filterIsDebetInvoice' onChange={this.onFilter} defaultChecked={customFilters.filterIsDebetInvoice}>Debetfactuur</CheckBox>
    //                             <CheckBox name='filterIsCreditInvoice' onChange={this.onFilter} defaultChecked={customFilters.filterIsCreditInvoice}>Creditnota</CheckBox>
    //                         </MultiInput>
    //                     </Field>
    //                     <Field isLabel title={`Status`} style={`compact`}>
    //                         <TagPicker
    //                             onChange={this.onFilterTagPicker}
    //                             name='byStatus'
    //                             defaultValue={filters.byStatus}
    //                             multi={false}
    //                             options={[
    //                                 { value: 'Draft', label: 'Concept' },
    //                                 { value: 'Canceled', label: 'Geannuleerd' },
    //                                 { value: 'Final', label: 'Definitief' },
    //                             ]}
    //                             placeholder='Selecteer een status'
    //                         />
    //                     </Field>
    //                     <Field title={`Factuurdatum`} style={`compact`}>
    //                         <MultiInput type={`select-range`}>
    //                             <DatePicker
    //                                 onChange={this.onFilter}
    //                                 name={'byInvoiceDateFrom'}
    //                                 defaultValue={filters.byInvoiceDateFrom}
    //                             />
    //                             <label>t/m</label>
    //                             <DatePicker
    //                                 onChange={this.onFilter}
    //                                 name={'byInvoiceDateTo'}
    //                                 defaultValue={filters.byInvoiceDateTo}
    //                             />
    //                         </MultiInput>
    //                     </Field>
    //                     <Field title={`Vervaldatum`} style={`compact`}>
    //                         <MultiInput type={`select-range`}>
    //                             <DatePicker
    //                                 onChange={this.onFilter}
    //                                 name={'byExpiredAtDateFrom'}
    //                                 defaultValue={filters.byExpiredAtDateFrom}
    //                             />
    //                             <label>t/m</label>
    //                             <DatePicker
    //                                 onChange={this.onFilter}
    //                                 name={'byExpiredAtDateTo'}
    //                                 defaultValue={filters.byExpiredAtDateTo}
    //                             />
    //                         </MultiInput>
    //                     </Field>
    //                 </FilterFieldCollection>
    //             )}
    //         />
    //     );
    // }

    renderInvoiceRows(invoices) {
        return invoices.map(invoice => {
            return (
                <TableRow key={invoice._id} getExpansion={() => this.renderInvoiceExpansionCells(invoice)}>
                    <TableCell>
                        <ReadableDate date={invoice.createdAt} asDifferenceWith={new Date()} /> (
                        <ReadableDate date={invoice.createdAt} />)
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/learners/${get(invoice, 'user._id')}/`}>
                            {get(invoice, 'user.profile.name')}
                        </Link>
                    </TableCell>
                    <TableCell>{invoice.creditDescription}</TableCell>
                    <TableCell containsNumber>
                        <Currency amount={-invoice.amount} />
                    </TableCell>
                </TableRow>
            )
        })
    }

    renderInvoiceExpansionCells(invoice) {
        const { refetch } = this.invoicesFetcher

        return (
            <TableCell colSpan={5}>
                <InvoiceRowExpansionContent
                    userId={invoice.user._id}
                    userName={invoice.user.profile.name}
                    invoiceId={invoice._id}
                    onChange={() => {
                        refetch({ silent: true })
                    }}
                    showInvoiceNumber={true}
                    showGroup={true}
                    showCreditApprovalButtons={true}
                />
            </TableCell>
        )
    }
}

const INVOICES_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: InvoicesFilterInputType) {
        invoices(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            invoiceNumber
            invoiceDate
            expirationDate
            amount
            notes
            isGroupParticipationInvoice
            isFinalExamInvoice
            isCredit
            creditDescription
            debitInvoice {
                _id
                invoiceNumber
                amount
                isGroupParticipationInvoice
                isFinalExamInvoice
                group {
                    _id
                    name
                }
            }
            user {
                _id
                profile {
                    name
                }
            }
            group {
                _id
                name
                module {
                    _id
                }
            }
            status
            openStatus
            paymentStatus
            hasSignatureForDUO
            isForDUO
            isExpired
            daysExpired
            createdAt
        }
    }
`
