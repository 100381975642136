import gql from 'graphql-tag'
import { browserHistory, RouteComponentProps } from 'react-router'
import * as React from 'react'

import { Header, Link, Icon, PageTab, PageTabs, Subtle } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { IconPageTab } from '~/components/IconPageTab'
import { View } from '~/components/View'
import { FlagIcon, WarningFlagIcon } from '~/components/InvoiceStatusIndicator/icons'
import { getCurrentUser } from '~/services/session'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Group } from '~/types/Group'
import { AddressFieldsFragment } from '~/types/Address'

const GET_GROUP_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            name
            dateFrom
            dateTo
            generalWeekLessonDates
            startWeekDate
            endedAt
            isLegacyGroup
            hasParticipantsWithExpiredInvoices
            hasParticipantsWithInvoicingDisabled
            hasParticipantsWithInactiveInvoices
            hasParticipantsThatRequireSignatureOnContract
            hasParticipantsThatRequireSignatureOnInvoice
            hasParticipantsWithInvoicesThatAreDeniedByDUO
            canBeDeleted
            project {
                _id
                name
                programs {
                    _id
                    name
                }
            }
            program {
                _id
                name
            }
            module {
                _id
                name
                lessonDuration
                startLevel
                endLevel
                courseMaterials {
                    _id
                    name
                    amount
                }
                amountOfTotalLessons
                amountOfWeeklyLessons
            }
            location {
                _id
                name
                address {
                    ...AddressFieldsFragment
                }
            }
            rooms {
                _id
                name
            }
            timetableLessons {
                weekIndex
            }
            learnerUsers {
                _id
                user {
                    ...userFields
                }
                isNowEnrolled
            }
            generalWeekLessonRooms {
                _id
                name
            }
            teacherUsers {
                _id
                user {
                    ...userFields
                }
            }
            employeeUsers {
                _id
                user {
                    ...userFields
                    roles(filterMutable: true)
                }
            }
            localizedExtraLearnerInformation {
                nl
                en
            }
            dateBlockers {
                from
                to
            }
            notesCount
            hasExamWhichRequiresAction
        }
    }

    fragment userFields on UserType {
        _id
        profile {
            name
        }
    }

    ${AddressFieldsFragment}
`

const GET_LESSON_QUERY = gql`
    query _($filters: GroupsFilterInputType, $lessonId: MongoID) {
        groups(filters: $filters) {
            _id
            lessons(byId: $lessonId) {
                _id
                order
            }
        }
    }
`

interface Params {
    id: string
    lessonId: string
}

interface Props extends RouteComponentProps<Params, {}> {
    children: any
}

export default class DetailView extends React.Component<Props> {
    private groupFetcher: Fetcher
    private lessonFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.groupFetcher = new Fetcher({
            query: GET_GROUP_QUERY,
            variables: {
                filters: {
                    filterById: props.params.id,
                    filterByConcepts: false,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.lessonFetcher = new Fetcher({
            query: GET_LESSON_QUERY,
            variables: {
                lessonId: props.params.lessonId,
                filters: {
                    filterById: props.params.id,
                },
            },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate({ params: prevParams }: any) {
        const { params, routes, children } = this.props
        const currentUser = getCurrentUser()
        const groupHasChanged = prevParams.id !== params.id
        const lessonHasChanged = prevParams.lessonId !== params.lessonId

        if (groupHasChanged) {
            this.groupFetcher.refetch({
                filters: {
                    filterById: params.id,
                },
            } as any)
        }

        if (groupHasChanged || lessonHasChanged) {
            this.lessonFetcher.refetch({
                lessonId: params.lessonId,
                filters: {
                    filterById: params.id,
                },
            } as any)
        }

        if (!children && currentUser) {
            if (routes.length === 4) {
                const base = `/groups/${params.id}`

                if (currentUser.isEmployee) {
                    browserHistory.replace(`${base}/lessons`)
                } else {
                    browserHistory.replace(`${base}/info`)
                }
            }
        }
    }

    public render() {
        const { children, ...routeComponentProps } = this.props
        const { data, refetch, loading } = this.groupFetcher
        const group = data && data.groups && data.groups[0]
        const isEnded = group && !!group.endedAt

        const groupName =
            group && group.name && (group.name.trim() ? group.name : 'Geen naam') + (isEnded ? ' (afgerond)' : '')

        return (
            <RouteView crumbLabel={groupName} routeProps={routeComponentProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {group && this.renderPageTabs(group)}
                </Header>
                <View>
                    {group && children && React.cloneElement(children, { group, refetch })}
                    {!loading && !group && <Subtle>Er is een fout opgetreden tijdens het ophalen van de groep.</Subtle>}
                </View>
            </RouteView>
        )
    }

    private renderPageTabs(group: Group) {
        const { params } = this.props
        const currentUser = getCurrentUser()

        return (
            <PageTabs>
                {(currentUser.isEmployee || currentUser.isExternalTeacher) && (
                    <React.Fragment>
                        <PageTab>
                            <Link route={`/groups/${params.id}/lessons`}>Lessen</Link>
                        </PageTab>
                        <PageTab>
                            <Link route={`/groups/${params.id}/participants`}>
                                Deelnemers {this.getFlagIconForParticipantsTab(group)}
                            </Link>
                        </PageTab>
                    </React.Fragment>
                )}
                <PageTab>
                    <Link route={`/groups/${params.id}/attendance`}>Presentie</Link>
                </PageTab>
                <PageTab>
                    <Link route={`/groups/${params.id}/exams`}>
                        Toetsen {group && group.hasExamWhichRequiresAction && <FlagIcon />}
                    </Link>
                </PageTab>
                {(currentUser.isEmployee || currentUser.isExternalTeacher) && (
                    <PageTab>
                        <Link route={`/groups/${params.id}/documents`}>Documenten</Link>
                    </PageTab>
                )}
                <PageTab>
                    <Link onlyActiveOnIndex={true} route={`/groups/${params.id}/info`}>
                        Gegevens
                    </Link>
                </PageTab>
                {currentUser.isEmployee && (
                    <IconPageTab counter={group.notesCount}>
                        <Link onlyActiveOnIndex={true} route={`/groups/${params.id}/notes`}>
                            <Icon name="note2" />
                        </Link>
                    </IconPageTab>
                )}
            </PageTabs>
        )
    }

    private getFlagIconForParticipantsTab = (group: Group) => {
        const {
            hasParticipantsWithExpiredInvoices,
            hasParticipantsWithInvoicingDisabled,
            hasParticipantsWithInactiveInvoices,
            hasParticipantsThatRequireSignatureOnContract,
            hasParticipantsThatRequireSignatureOnInvoice,
            hasParticipantsWithInvoicesThatAreDeniedByDUO,
        } = group

        if (hasParticipantsWithExpiredInvoices || hasParticipantsWithInvoicesThatAreDeniedByDUO) {
            return <FlagIcon />
        } else if (
            hasParticipantsWithInvoicingDisabled ||
            hasParticipantsWithInactiveInvoices ||
            hasParticipantsThatRequireSignatureOnContract ||
            hasParticipantsThatRequireSignatureOnInvoice
        ) {
            return <WarningFlagIcon />
        } else {
            return null
        }
    }
}
