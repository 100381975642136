import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { View } from '~/components/View'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { AddressFieldsFragment } from '~/types/Address'

const STATIC_EMPLOYEE_USER_FILTERS = {
    roles: ['EMPLOYEE'],
}

export default class DetailView extends Component {
    static propTypes = {
        children: PropTypes.any,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }

    constructor(props) {
        super(props)

        this.userFetcher = new Fetcher({
            query: GET_USER_QUERY,
            variables: {
                filters: {
                    ...STATIC_EMPLOYEE_USER_FILTERS,
                    byId: props.params.id,
                },
            },

            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.userFetcher.refetch({
                filters: {
                    byId: params.id,
                },
            })
        }
    }

    render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.userFetcher
        const user = get(data, 'users[0]')

        return (
            <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    <PageTabs>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`/users/employees/${this.props.params.id}/groups`}>
                                Groepen
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`/users/employees/${this.props.params.id}/documents`}>
                                Documenten
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`/users/employees/${this.props.params.id}`}>
                                Gegevens
                            </Link>
                        </PageTab>
                    </PageTabs>
                </Header>
                <View>{user && cloneElement(children, { user, userId: user._id, refetch })}</View>
            </RouteView>
        )
    }
}

const GET_USER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            roles(filterMutable: true)
            invitedAt
            lastActivityAt
            profile {
                initials
                name
                firstName
                surName
                surNamePrefix
                gender
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
                alternativeEmail
                address {
                    ...AddressFieldsFragment
                }
                notes
                isMfaConfigured
                cognitoUserId
                mfaMobileNumber
            }
            teacher {
                isNT2Certified
                NT2CertifiedDate
                NT2Certificates {
                    _id
                    fileName
                }
                lessonCompetence
                lessonCompetenceDate
                lessonCompetenceCertificates {
                    _id
                    fileName
                }
                groups {
                    _id
                    name
                    module {
                        _id
                        name
                    }
                    dateFrom
                    dateTo
                    isConcept
                    lessons {
                        _id
                        date
                        order
                    }
                }
            }
            employee {
                isActive
                groups {
                    _id
                    name
                    module {
                        _id
                        name
                    }
                    dateFrom
                    dateTo
                    isConcept
                    lessons {
                        _id
                        date
                        order
                    }
                }
            }
        }
    }
    ${AddressFieldsFragment}
`
