import gql from 'graphql-tag'
import { bindAll } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Link, Paragraph, Spinner, VideosList, VideosListItem } from '~/components'
import { VideosModal } from '~/implementations'
import { Fetcher, scrollToElement } from '~/utils'
import { AnchorIndexList } from '~/components/AnchorIndexList'
import { AnchorIndexListItem } from '~/components/AnchorIndexListItem'

export default class InstructionVideosModal extends Component {
    static propTypes = {
        onClose: PropTypes.func,
        user: PropTypes.object.isRequired,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onAnchorClick'])

        const { user } = this.props
        const role = user.isEmployee
            ? `EMPLOYEE`
            : user.isExternalTeacher
            ? `TEACHER`
            : user.isOrganizationContact
            ? `ORGANIZATION_CONTACT`
            : ``

        this.instructionVideosFetcher = new Fetcher({
            query: INSTRUCTION_VIDEOS_QUERY,
            variables: { role },

            onChange: () => this.forceUpdate(),
        })
    }

    formatUrl(url) {
        const regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#\&\?]*).*/
        const match = url.match(regExp)

        return match && match[7].length == 11 ? `//www.youtube.com/embed/${match[7]}` : url
    }

    onAnchorClick(videoId) {
        scrollToElement({
            element: window.document.getElementById(videoId),
            getScroller: () => {
                return window.document.querySelector('.tt-Modal')
            },
            offset: 60,
        })
    }

    render() {
        const { onClose } = this.props
        const { data, loading } = this.instructionVideosFetcher
        const { instructionVideos = [] } = data

        return (
            <VideosModal onClose={onClose} title={`Instructievideo's`}>
                {loading ? (
                    <Spinner />
                ) : instructionVideos.length > 0 ? (
                    this.renderVideos(instructionVideos)
                ) : (
                    <Paragraph>Er zijn geen instrucievideo's gevonden.</Paragraph>
                )}
            </VideosModal>
        )
    }

    renderVideos(videos) {
        return (
            <div>
                <AnchorIndexList>
                    {videos.map((video, i) => (
                        <AnchorIndexListItem key={i}>
                            <Link onClick={() => this.onAnchorClick(video._id)}>{video.name}</Link>
                        </AnchorIndexListItem>
                    ))}
                </AnchorIndexList>
                <VideosList>
                    {videos.map((video, i) => (
                        <VideosListItem
                            key={i}
                            anchorId={video._id}
                            title={video.name}
                            url={this.formatUrl(video.url)}
                        />
                    ))}
                </VideosList>
            </div>
        )
    }
}

const INSTRUCTION_VIDEOS_QUERY = gql`
    query _($role: String) {
        instructionVideos(byRole: $role) {
            _id
            name
            url
            targetRoles
        }
    }
`
