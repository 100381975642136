import React from 'react'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import { Wrap } from '~/components/Wrap'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { User, LearnerEnrollmentType } from '~/types/User'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Link from '~/components/Link'
import { getUserDetailRoute } from '~/utils/getUserDetailRoute'
import { Paginator } from '~/utils/Paginator'
import { Fetcher, Mutator, Sorter } from '~/utils'
import translateType from '~/shared/utils/translateType'
import ReadableDate from '~/components/ReadableDate'
import { ModalManager } from '~/components/ModalManager'
import CenterModal from '~/components/CenterModal'
import { AddCandidatesToInflowMomentForm } from '~/components/InflowMoment/AddCandidatesToInflowMomentForm'
import { Label } from '../Label/Label'
import { List } from '../List'
import { ListItem } from '../ListItem'
import gql from 'graphql-tag'

interface Props {
    users: User[]
    userPaginator: Paginator
    userSorter: Sorter
    renderActionBar: () => JSX.Element
    userFetcher: Fetcher
}

interface State {
    checkedUsers: User[]
    selectedEnrollmentType?: LearnerEnrollmentType | null
}

export class InflowMomentsCandidatesForm extends React.Component<Props, State> {
    public state: State = {
        checkedUsers: [],
    }

    private setUsersOnHoldStatusMutator: Mutator
    private removeUsersOnHoldStatusMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.setUsersOnHoldStatusMutator = new Mutator({
            mutation: SET_USERS_ON_HOLD_STATUS_MUTATION,
            reactComponentToUpdate: this,
        })

        this.removeUsersOnHoldStatusMutator = new Mutator({
            mutation: REMOVE_USERS_ON_HOLD_STATUS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { userPaginator, renderActionBar, userFetcher } = this.props
        const { checkedUsers } = this.state
        const { loading } = userFetcher

        return (
            <React.Fragment>
                {checkedUsers.length > 0 && (
                    <TableWrap insetTable={true}>
                        <TableView>{this.renderUsersTable(checkedUsers, true)}</TableView>
                    </TableWrap>
                )}
                <InfiniteScroll paginator={userPaginator} preventLoad={loading}>
                    <Wrap full={true}>{renderActionBar()}</Wrap>
                    <TableWrap>
                        <TableView>{this.renderUsersTable(this.getUncheckedUsers())}</TableView>
                    </TableWrap>
                </InfiniteScroll>
            </React.Fragment>
        )
    }

    private renderUsersTable = (users: User[], forSelected?: boolean) => {
        const { userFetcher } = this.props
        const { loading } = userFetcher
        const { userSorter } = this.props

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem forIconButton={true} />
                    <TableHeaderItem sorter={userSorter} sortBy={`profile.firstName`}>
                        Naam
                    </TableHeaderItem>
                    <TableHeaderItem>Organisatie</TableHeaderItem>
                    <TableHeaderItem>Dossiernummer</TableHeaderItem>
                    <TableHeaderItem>Woonplaats</TableHeaderItem>
                    <TableHeaderItem sorter={userSorter} sortBy={`learner.enrollmentDate`}>
                        Aangemeld op
                    </TableHeaderItem>
                    <TableHeaderItem sorter={userSorter} sortBy={`learner.inflowMomentsNoShowStreak`}>
                        Niet verschenen
                    </TableHeaderItem>
                    <TableHeaderItem />
                </TableHeader>
                {loading ? (
                    <TableRow key={`loading-row`}>
                        <TableCell colSpan={7} isLoading={true} />
                    </TableRow>
                ) : (
                    this.renderTableRows(users, forSelected)
                )}
                {forSelected && (
                    <TableRow key={`selected-row`}>
                        <TableCell colSpan={7}>
                            <List horizontal={true} forSmallButtons={true}>
                                <ListItem>
                                    <ModalManager
                                        render={openModal => (
                                            <Button leftIcon={<Icon name={`plus`} />} small={true} onClick={openModal}>
                                                Toevoegen aan toetsmoment
                                            </Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal
                                                title={`${users.length} ${
                                                    users.length === 1 ? 'kandidaat' : 'kandidaten'
                                                } toevoegen aan toetsmoment`}
                                                onClose={closeModal}
                                            >
                                                <AddCandidatesToInflowMomentForm
                                                    onCancel={closeModal}
                                                    selectedUsers={users}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </ListItem>
                                <ListItem>
                                    <Button
                                        leftIcon={<Icon name={`pause`} />}
                                        small={true}
                                        onClick={() => this.setUsersToOnHold()}
                                    >
                                        Kandidaat pauzeren
                                    </Button>
                                </ListItem>
                                <ListItem>
                                    <Button
                                        leftIcon={<Icon name={`play`} />}
                                        small={true}
                                        onClick={() => this.removeUsersToOnHold()}
                                    >
                                        Kandidaat hervatten
                                    </Button>
                                </ListItem>
                            </List>
                        </TableCell>
                    </TableRow>
                )}
            </Table>
        )
    }

    private renderTableRows = (users: User[], forSelected?: boolean) => {
        return users.length > 0 ? (
            users.map(user => {
                const { learner } = user
                const learnerOrganizations = (learner && learner.organizations) || []
                const inflowMomentsNoShowStreak = learner && learner.inflowMomentsNoShowStreak

                return (
                    <TableRow key={user._id}>
                        <TableCell forIconButton={true}>
                            {forSelected ? (
                                <Button
                                    type={'in-row'}
                                    leftIcon={<Icon name={`trash`} />}
                                    onClick={() => this.removeUser(user)}
                                />
                            ) : (
                                <Button
                                    type={'in-row'}
                                    leftIcon={<Icon name={`plus`} />}
                                    onClick={() => this.addUser(user)}
                                    isDisabled={this.canAddUser(user)}
                                />
                            )}
                        </TableCell>
                        <TableCell>
                            <Link route={getUserDetailRoute(user)}>{user.profile.name}</Link>
                            <Label>{this.getEnrollmentValue(user)}</Label>
                            {this.getOnHoldStatus(user)}
                        </TableCell>
                        <TableCell>
                            {learnerOrganizations
                                .map(
                                    learnerOrganization =>
                                        (learnerOrganization.organization && learnerOrganization.organization.name) ||
                                        '-'
                                )
                                .join(', ')}
                        </TableCell>
                        <TableCell>
                            {learnerOrganizations
                                .map(learnerOrganization => learnerOrganization.organizationFileNumber || '-')
                                .join(', ')}
                        </TableCell>
                        <TableCell>
                            {user.profile.address &&
                                user.profile.address.nl &&
                                user.profile.address.nl.extraInfo &&
                                user.profile.address.nl.extraInfo.city}
                        </TableCell>
                        <TableCell>
                            <ReadableDate date={user.learner && user.learner.enrollmentDate} />
                        </TableCell>
                        <TableCell>
                            {!!inflowMomentsNoShowStreak && inflowMomentsNoShowStreak > 0 && (
                                <Label hasInverseColors={true}>{inflowMomentsNoShowStreak}x niet verschenen</Label>
                            )}
                        </TableCell>
                    </TableRow>
                )
            })
        ) : (
            <TableRow>
                <TableCell colSpan={7}>Geen gebruikers gevonden</TableCell>
            </TableRow>
        )
    }

    private getEnrollmentValue = (user: User) => {
        const { learner } = user

        if (!learner || !learner.enrollmentType) {
            return null
        }

        const enrollmentType =
            learner.enrollmentType === LearnerEnrollmentType.Intake
                ? LearnerEnrollmentType.Intake
                : LearnerEnrollmentType.Assessment

        return translateType('enrollmentType', enrollmentType)
    }

    private getOnHoldStatus = (user: User) => {
        if (user.learner && user.learner.isInflowOnHold) {
            return <Icon name={`pause`} />
        }

        return null
    }

    private addUser = (user: User) => {
        this.setState({
            checkedUsers: [...this.state.checkedUsers, user],
            selectedEnrollmentType: user.learner && user.learner.enrollmentType,
        })
    }

    private removeUser = (user: User) => {
        const { checkedUsers } = this.state
        const updatedCheckedUsers = checkedUsers.filter(({ _id }) => _id !== user._id)

        this.setState({
            checkedUsers: updatedCheckedUsers,
            ...(updatedCheckedUsers.length === 0 && { selectedEnrollmentType: null }),
        })
    }

    private canAddUser = (user: User) => {
        const { selectedEnrollmentType } = this.state

        if (!user.learner || !user.learner.enrollmentType || !selectedEnrollmentType) {
            return false
        }

        return selectedEnrollmentType !== user.learner.enrollmentType
    }

    private getUncheckedUsers() {
        const { users } = this.props
        const { checkedUsers } = this.state
        return users.filter(({ _id }) => {
            return !checkedUsers.map(({ _id: userId }) => userId).includes(_id)
        })
    }

    private setUsersToOnHold = async () => {
        const { userFetcher } = this.props
        const { checkedUsers } = this.state

        await this.setUsersOnHoldStatusMutator.mutate({
            userIds: checkedUsers.map(user => user._id),
        })

        userFetcher.refetch()
        this.setState({ checkedUsers: [] })
    }

    private removeUsersToOnHold = async () => {
        const { userFetcher } = this.props
        const { checkedUsers } = this.state

        await this.removeUsersOnHoldStatusMutator.mutate({
            userIds: checkedUsers.map(user => user._id),
        })

        userFetcher.refetch()
        this.setState({ checkedUsers: [] })
    }
}

const SET_USERS_ON_HOLD_STATUS_MUTATION = gql`
    mutation _($userIds: [MongoID]!) {
        users_inflowOnHoldSet(userIds: $userIds) {
            _id
            learner {
                isInflowOnHold
            }
        }
    }
`

const REMOVE_USERS_ON_HOLD_STATUS_MUTATION = gql`
    mutation _($userIds: [MongoID]!) {
        users_inflowOnHoldRemove(userIds: $userIds) {
            _id
            learner {
                isInflowOnHold
            }
        }
    }
`
