import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, FileInput, Form, Option, Select } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { USER_DOCUMENT_TYPES } from '~/shared/entities/files'
import { Mutator, transformFormFields } from '~/utils'

export default class CreateUserDocumentForm extends Component {
    static propTypes = {
        userId: PropTypes.string,
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
        hideTypesForTeacherOfUser: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.documentMutator = new Mutator({
            mutation: CREATE_DOCUMENTS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.documentMutator
            .mutate({
                userId: this.props.userId,
                documents: fields.documents.map(doc => ({
                    ...transformFormFields(doc, {
                        type: { isNull: v => !v },
                    }),
                })),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                } else {
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    getTypes() {
        const { hideTypesForTeacherOfUser } = this.props

        if (hideTypesForTeacherOfUser) {
            return USER_DOCUMENT_TYPES.filter(type => type.isAccessibleByTeacherOfUser)
        }

        return USER_DOCUMENT_TYPES
    }

    render() {
        const { errors, loading } = this.documentMutator
        const { onCancel } = this.props
        const types = this.getTypes()

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Bestand`} errors={errors}>
                            <FileInput name={`documents[0].file`}>Bladeren</FileInput>
                        </Field>
                        <Field isLabel title={`Soort`} errors={errors}>
                            <Select name={`documents[0].type`} placeholder={`Selecteer soort document`}>
                                {types.map(({ name }, i) => (
                                    <Option key={i}>{name}</Option>
                                ))}
                            </Select>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button isLoading={loading} type={`submit`}>
                                    Uploaden
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const CREATE_DOCUMENTS_MUTATION = gql`
    mutation _($userId: MongoID!, $documents: [UserDocumentInputType]) {
        users_addDocuments(userId: $userId, documents: $documents) {
            _id
        }
    }
`
