import gql from 'graphql-tag'
import * as React from 'react'

import { Button, FieldCollection, Form } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { Mutator, toast } from '~/utils'

const USERS_CHANGE_STATUS = gql`
    mutation _($userId: MongoID, $userShouldBeDisabled: Boolean) {
        users_changeStatus(userId: $userId, userShouldBeDisabled: $userShouldBeDisabled) {
            _id
        }
    }
`
interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
    userId: string
    userStatus: boolean
}

export class ChangeUserStatusForm extends React.Component<Props> {
    private userMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: USERS_CHANGE_STATUS,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { loading } = this.userMutator
        const { onCancel, userStatus } = this.props

        let copyIntro =
            'Door op bevestigen te klikken zet je deze gebruiker op actief en kan de gebruiker toegang krijgen tot het systeem.'
        if (userStatus === true) {
            copyIntro =
                'Door op bevestigen te klikken zet je deze gebruiker op non-actief en heeft de gebruiker geen toegang meer tot het systeem.'
        }

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`} className={`tt-FieldCollection--password-form`}>
                    <FieldCollectionFooter>
                        <p>{copyIntro}</p>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading}>
                                    Bevestigen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed, userId, userStatus } = this.props
        // if user is active(===true) we should disable the user hence the below
        const userShouldBeDisabled = userStatus
        this.userMutator
            .mutate({
                userId: userId,
                userShouldBeDisabled: userShouldBeDisabled,
            })
            .then(data => {
                if (data) {
                    toast.success('Status gewijzigd')
                    onSubmitSuccess()
                } else {
                    if (onSubmitFailed) {
                        onSubmitFailed()
                    }
                }
            })
    }
}
