import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class EditGroupNameForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
        group: PropTypes.object.isRequired,
        refetch: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.groupEditMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, group } = this.props

        this.groupEditMutator
            .mutate({
                group: {
                    _id: group._id,
                    ...transformFormFields(fields.group),
                },
            })
            .then(data => {
                const { refetch } = this.props

                if (data) {
                    refetch()
                    onSubmitSuccess()
                }
            })
    }

    render() {
        const { onCancel, group } = this.props

        const { errors, loading: isMutationLoading } = this.groupEditMutator

        const groupName = get(group, 'name') || null

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={'Groepsnaam'} errors={errors}>
                            <Input
                                name={`group.name`}
                                defaultValue={groupName}
                                type={`text`}
                                placeholder={`Naam van de nieuwe groep`}
                                autoFocus={true}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={isMutationLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`
