import gql from 'graphql-tag'
import { bindAll, debounce, get, omit } from 'lodash'
import * as React from 'react'

import { FieldCollection, Form, Spinner } from '~/components'
import { Wrap } from '~/components/Wrap'
import { Fetcher } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { UserLearnerGeneralFieldGroup } from '~/components/User/Learner/FieldGroups/GeneralFieldGroup'
import { UserLearnerContactDetailsFieldGroup } from '~/components/User/Learner/FieldGroups/ContactDetailsFieldGroup'
import { UserLearnerPersonalDetailsFieldGroup } from '~/components/User/Learner/FieldGroups/PersonalDetailsFieldGroup'
import { UserLearnerBackgroundInformationFieldGroup } from '~/components/User/Learner/FieldGroups/BackgroundInformationFieldGroup'
import { UserLearnerEducationFieldGroup } from '~/components/User/Learner/FieldGroups/EducationFieldGroup'
import { UserLearnerIntakeResultsFieldGroup } from '~/components/User/Learner/FieldGroups/IntakeResultsFieldGroup'
import { UserLearnerOrganizationContactOrganizationFieldGroup } from '~/components/User/Learner/FieldGroups/OrganizationContactOrganizationFieldGroup'
import { UserLearnerOrganizationFieldGroup } from '~/components/User/Learner/FieldGroups/OrganizationFieldGroup'
import { UserLearnerOtherFieldGroup } from '~/components/User/Learner/FieldGroups/OtherFieldGroup'
import { UserLearnerPaymentDetailsFieldGroup } from '~/components/User/Learner/FieldGroups/PaymentDetailsFieldGroup'
import { User } from '~/types/User'
import { FormFields } from '~/components/Form'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { AddressFieldsFragment } from '~/types/Address'
import { PaymentByType } from '~/components/User/Learner/UserLearnerAddInvoicingForm'
import { UserLearnerTargetGroup } from '~/components/User/Learner/FieldGroups/TargetGroupFieldGroup'
import { TagPickerOption } from '~/components/TagPicker'
import {
    UserLearningRouteTypesEnum,
    UserTargetLevelTypesEnum,
    UserTypeOfLearnerTypesEnum,
    UserSharePanteiaTypesEnum,
} from '~/generated/graphql'

interface Props {
    learnerUserId: string
    hideFieldsForGroupTeacher?: boolean
    hideFieldsForIntakeTeacher?: boolean
    hideFieldsForOrganizationContacts?: boolean
    markAsDirty?: () => void
    markAsPristine?: () => void
    onSubmit: (user: any, markAsPristine?: () => void) => void
    graphqlErrors: GraphQLErrors
    children: (renderFormContent: () => JSX.Element) => JSX.Element
    forIntake?: boolean
    forNewIntake?: boolean
}

interface State {
    initialLoading: boolean
    obligedCitizenshipEducationEndDateExtensionFiles: any[]
    hasPrivatePayment?: boolean
    useAddressAsPrivatePaymentAddress?: boolean
    privatePaymentIsDUO?: boolean
    privatePaymentDUOHasApprovedFund?: boolean
    twinfieldCustomerCode?: string
    doesNotExistInTwinfield?: boolean
    lookupTwinfieldCustomerExists?: boolean
    lookupTwinfieldCustomerName?: string
    lookupTwinfieldCustomerLoading?: boolean
    bsnVerified?: boolean
    paymentBy?: PaymentByType
    isCitizenshipEducationObliged?: boolean
    typeOfLearner?: UserTypeOfLearnerTypesEnum
    learningRoute?: UserLearningRouteTypesEnum
    targetLevelPIP?: UserTargetLevelTypesEnum
    sharePanteia?: UserSharePanteiaTypesEnum
}

export class LearnerProfileEditForm extends React.Component<Props, State> {
    public static defaultProps = {
        hideFieldsForGroupTeacher: false,
        hideFieldsForIntakeTeacher: false,
        hideFieldsForOrganizationContacts: false,
    }

    public userCanLeavePage = true

    public state: State = {
        initialLoading: true,
        obligedCitizenshipEducationEndDateExtensionFiles: [],
    }

    private learnerFetcher: Fetcher | any
    private dataFetcher: Fetcher
    private lookupTwinfieldCustomerByCodeFetcher: Fetcher
    private debouncedLookupTwinfieldCustomer: any

    constructor(props: Props) {
        super(props)

        const { learnerUserId } = props

        bindAll(this, [
            'onSubmit',
            'onChangeTwinfieldCustomerCode',
            'onChangeDoesNotExistInTwinfield',
            'setDoesNotExistInTwinfield',
            'onFormChange',
            'onChangeBSNVerifiedCheckbox',
            'onChangeBSNInput',
            'onChangeCitizenshipEducationObliged',
            'onChangeTypeOfLearner',
            'onChangeLearningRoute',
            'onChangeTargetLevel',
            'onChangeSharePanteia',
        ])

        this.learnerFetcher = new Fetcher({
            query: GET_LEARNER_QUERY,
            variables: {
                filters: {
                    byId: learnerUserId,
                },
            },

            onChange: () => this.forceUpdate(),
            preventInitialFetch: true,
        })
        ;(async () => {
            const data = await this.learnerFetcher.fetch()
            const user = get(data, 'users[0]')

            this.setState({
                hasPrivatePayment: !!get(user, 'learner.hasPrivatePayment', true),
                useAddressAsPrivatePaymentAddress: !get(user, 'learner.privatePaymentAddress'),
                privatePaymentIsDUO: !!get(user, 'learner.privatePaymentIsDUO'),
                privatePaymentDUOHasApprovedFund: !!get(user, 'learner.privatePaymentDUOHasApprovedFund'),
                twinfieldCustomerCode: get(user, 'learner.twinfieldCustomer.code'),
                doesNotExistInTwinfield: false,
                lookupTwinfieldCustomerExists: !!get(user, 'learner.twinfieldCustomer.code'),
                lookupTwinfieldCustomerName: get(user, 'learner.twinfieldCustomer.name'),
                initialLoading: false,
                bsnVerified: get(user, 'profile.bsnVerified'),
                paymentBy: get(user, 'learner.paymentBy'),
                isCitizenshipEducationObliged: !!get(user, 'learner.isCitizenshipEducationObliged', false),
                typeOfLearner: get(user, 'learner.typeOfLearner'),
                learningRoute: get(user, 'learner.learningRoute'),
                targetLevelPIP: get(user, 'learner.targetLevelPIP'),
                sharePanteia: get(user, 'learner.sharePanteia'),
            })
        })()

        const dataQuery = this.getDataQuery()
        if (dataQuery) {
            this.dataFetcher = new Fetcher({
                query: dataQuery,
                variables: {},

                onChange: () => this.forceUpdate(),
                transformData: data => ({
                    ...data,
                }),
            })
        }

        this.lookupTwinfieldCustomerByCodeFetcher = new Fetcher({
            query: LOOKUP_TWINFIELD_CUSTOMER_BY_CODE_QUERY,
            preventInitialFetch: true,
        })

        this.debouncedLookupTwinfieldCustomer = debounce(this.lookupTwinfieldCustomer, 1000)
    }

    public getUser() {
        const { data } = this.learnerFetcher
        const user = get(data, 'users[0]')
        return user
    }

    public getDataQuery() {
        const { hideFieldsForGroupTeacher, hideFieldsForOrganizationContacts } = this.props

        if (hideFieldsForGroupTeacher) {
            return GET_DATA_QUERY_FOR_TEACHER
        }

        if (hideFieldsForOrganizationContacts) {
            return
        }

        return GET_DATA_QUERY_FOR_EMPLOYEE
    }

    public onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const user = this.getUser()
        const { hasPrivatePayment, privatePaymentIsDUO, doesNotExistInTwinfield, twinfieldCustomerCode, paymentBy } =
            this.state

        const { onSubmit, markAsPristine } = this.props

        fields = omit(fields, ['addressAsVisitingAddress', 'addressAsPrivatePaymentAddress'])
        fields.user.learner.sharePanteia = fields.user.learner.sharePanteia ? fields.user.learner.sharePanteia : null

        const organizations = (get(fields, 'user.learner.organizations') || []).filter(
            (organization: any) => !!organization.organizationId
        )

        const adviceModuleIsTheSame = !this.hasAdviceModuleChanges(fields)

        if (adviceModuleIsTheSame) {
            fields = omit(fields, [
                'user.learner.adviceModuleId',
                'user.learner.adviceModuleFilterByProjectId',
                'user.learner.adviceModuleFilterByProgramId',
            ])
        }

        const transformedUserFields = {
            _id: user._id,
            ...transformFormFields(
                fields.user,
                {
                    profile: {
                        fields: (v: any) => ({
                            ...transformFormFields(
                                v,
                                {
                                    address: transformLib.address(),
                                    visitAddress: transformLib.address(),
                                    phoneNumbers: transformLib.phoneNumbers(),
                                    countriesOfNationality: {
                                        value: (value?: any) => {
                                            if (!value || !value.length) {
                                                return null
                                            }

                                            // split and remove empty values.
                                            value = value.split(',').filter((v: any) => v)

                                            if (value.includes('none')) {
                                                return null
                                            }

                                            return value
                                        },
                                    },
                                    hasNoCountryOfNationality: {
                                        isNull: false,
                                        value: () => {
                                            if (!v.countriesOfNationality || !v.countriesOfNationality.length) {
                                                return true
                                            }

                                            if (v.countriesOfNationality.split(',').includes('none')) {
                                                return true
                                            }

                                            return false
                                        },
                                    },
                                },
                                []
                            ),
                        }),
                    },
                    learner: {
                        fields: (v: any) => ({
                            ...transformFormFields(
                                v,
                                {
                                    // tslint:disable-next-line:max-line-length
                                    twinfieldCustomerCode: {
                                        value: twinfieldCustomerCode,
                                        compare: (v: any) => v === get(user, 'learner.twinfieldCustomer.code'),
                                    },
                                    doesNotExistInTwinfield: { value: doesNotExistInTwinfield },
                                    privatePaymentIsDUO: {
                                        value: privatePaymentIsDUO,
                                        isNull: (_v: any) => !_v || !hasPrivatePayment,
                                    },
                                    // tslint:disable-next-line:max-line-length
                                    privatePaymentAddress: transformLib.address(),
                                    privatePaymentDUOHasApprovedFund: {
                                        isNull: (_v: any) => !_v || !hasPrivatePayment || !privatePaymentIsDUO,
                                    },
                                    otherLanguages: transformLib.split(),
                                    intakeGoals: transformLib.split(),
                                    NT2: {
                                        value: (ntV: any) => ntV && ntV.map((v: any) => transformFormFields(v)),
                                        isNull: (ntV: any) => v.NT2HasAttempted === 'false' || !ntV || ntV.length === 0,
                                    },
                                    immigratedAt: transformLib.preciseDate(),
                                    currentOccupationStartDate: transformLib.preciseDate(),
                                    obligedCitizenshipEducationStartDate: transformLib.preciseDate(),
                                    obligedCitizenshipEducationInitialEndDate: transformLib.date(),
                                    obligedCitizenshipEducationEndDateExtensions: {
                                        value: (extensions: any[]) =>
                                            extensions
                                                ? extensions.map((extension, i) => ({
                                                      ...extension,
                                                      document: this.getFileByExtensionNumber(i),
                                                  }))
                                                : null,
                                    },
                                    obligedCitizenshipEducationCompletedAt: transformLib.date(),
                                    nativeLanguages: transformLib.split(),
                                    homeLanguages: transformLib.split(),
                                    currentOccupationMainLanguages: transformLib.split(),
                                    preferredLocationIds: transformLib.split(),
                                    intakeGrades: {
                                        value: (v: any) => transformFormFields(v),
                                    },
                                    intakeGradesLearnability: transformLib.int(),
                                    intakeReadingExamScoreTotal: transformLib.int(),
                                    intakeReadingExamScore: transformLib.int(),
                                    highestEducationCountryOfOriginYear: {
                                        isNull: () =>
                                            this.cannotHaveYearOption(
                                                get(fields, 'user.learner.highestEducationCountryOfOrigin')
                                            ),
                                    },
                                    highestEducationLocalYear: {
                                        isNull: () =>
                                            this.cannotHaveYearOption(
                                                get(fields, 'user.learner.highestEducationLocal')
                                            ),
                                    },
                                    paymentBy: {
                                        value: () => paymentBy,
                                    },
                                    organizations: {
                                        value: () =>
                                            organizations && organizations.map((v: any) => transformFormFields(v)),
                                        isNull: () => !organizations || organizations.length === 0,
                                    },
                                },
                                []
                            ),
                        }),
                    },
                },
                []
            ),
        }

        onSubmit(transformedUserFields, markAsPristine)
    }

    public onFormChange() {
        const { markAsDirty } = this.props

        if (markAsDirty) {
            markAsDirty()
        }
    }

    public onChangeTwinfieldCustomerCode(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.currentTarget

        this.setState({
            twinfieldCustomerCode: value,
            lookupTwinfieldCustomerLoading: true,
        })

        if (value) {
            this.debouncedLookupTwinfieldCustomer(value)
        }
    }

    public onChangeDoesNotExistInTwinfield(event: React.ChangeEvent<HTMLInputElement>) {
        const { checked } = event.currentTarget

        this.setState({
            doesNotExistInTwinfield: checked,
            twinfieldCustomerCode: checked ? undefined : this.state.twinfieldCustomerCode,
        })
    }

    public onChangeBSNVerifiedCheckbox(value: boolean) {
        this.setState({
            bsnVerified: value,
        })
    }

    public onChangeCitizenshipEducationObliged(value: boolean) {
        this.setState({
            isCitizenshipEducationObliged: value,
        })
    }

    public onChangeBSNInput() {
        this.setState({
            bsnVerified: false,
        })
    }

    public setDoesNotExistInTwinfield() {
        this.setState({
            doesNotExistInTwinfield: true,
            twinfieldCustomerCode: undefined,
        })
    }

    public onChangeTargetLevel(value: UserTargetLevelTypesEnum) {
        this.setState({
            targetLevelPIP: value,
        })
    }

    public onChangeSharePanteia(value: UserSharePanteiaTypesEnum | undefined) {
        this.setState({
            sharePanteia: value,
        })
    }

    public onChangeLearningRoute(option?: TagPickerOption<any, UserLearningRouteTypesEnum>) {
        this.setState({ learningRoute: option ? option.value : undefined })
    }

    public onChangeTypeOfLearner(option?: TagPickerOption<any, UserTypeOfLearnerTypesEnum>) {
        if (!option) {
            return this.setState({
                paymentBy: PaymentByType.Organization,
                typeOfLearner: undefined,
            })
        }
        const validPaymentByOptions = LearnerProfileEditForm.getPaymentByOptionsForIntegrationLawType(option.value)

        const newState: Partial<State> = { typeOfLearner: option.value }

        if (!this.state.paymentBy || !validPaymentByOptions.includes(this.state.paymentBy)) {
            newState.paymentBy = PaymentByType.Organization
        }

        this.setState(newState as State)
    }

    public async lookupTwinfieldCustomer(code: string) {
        this.setState({
            lookupTwinfieldCustomerLoading: true,
            lookupTwinfieldCustomerExists: false,
            lookupTwinfieldCustomerName: undefined,
        })

        try {
            const result = await this.lookupTwinfieldCustomerByCodeFetcher.fetch({
                twinfieldCustomerCode: code,
            })

            this.setState({ lookupTwinfieldCustomerLoading: false })

            if (result) {
                const { twinfield_customer } = result

                if (twinfield_customer) {
                    this.setState({
                        lookupTwinfieldCustomerExists: true,
                        lookupTwinfieldCustomerName: twinfield_customer.name,
                    })
                }
            }
        } catch (err) {
            this.setState({ lookupTwinfieldCustomerLoading: false })
            throw err
        }
    }

    public render() {
        const { initialLoading } = this.state
        const user = this.getUser()

        if (initialLoading || !user) {
            return <Spinner />
        }

        return this.renderWithUser(user)
    }

    private renderWithUser(user: User) {
        const { children } = this.props

        return (
            <Form onSubmit={this.onSubmit} onChange={this.onFormChange}>
                {children(() => this.renderFormContent(user))}
            </Form>
        )
    }

    private renderFormContent(user: User) {
        const { graphqlErrors, forIntake, forNewIntake } = this.props
        const { hideFieldsForGroupTeacher, hideFieldsForOrganizationContacts } = this.props
        const { data = {}, loading = false } = this.dataFetcher || {}
        const { organizations = [], locations = [] } = data
        const {
            hasPrivatePayment,
            paymentBy,
            useAddressAsPrivatePaymentAddress,
            privatePaymentIsDUO,
            twinfieldCustomerCode,
            doesNotExistInTwinfield,
            lookupTwinfieldCustomerLoading,
            lookupTwinfieldCustomerExists,
            lookupTwinfieldCustomerName,
            privatePaymentDUOHasApprovedFund,
            bsnVerified,
            isCitizenshipEducationObliged,
            typeOfLearner,
            learningRoute,
            targetLevelPIP,
            sharePanteia,
        } = this.state

        if (!user) {
            return (
                <Wrap>
                    <Spinner />
                </Wrap>
            )
        }

        return (
            <>
                <FieldCollection errors={graphqlErrors}>
                    <UserLearnerGeneralFieldGroup
                        hideFieldsForOrganizationContacts={hideFieldsForOrganizationContacts}
                        errors={graphqlErrors}
                        user={user}
                        isForIntake={forIntake}
                        showEnrollmentTypeAndDate={false}
                    />
                    {!hideFieldsForGroupTeacher && !hideFieldsForOrganizationContacts && (
                        <React.Fragment>
                            <UserLearnerOrganizationFieldGroup
                                organizations={organizations}
                                hasPrivatePayment={hasPrivatePayment}
                                user={user}
                                loading={loading}
                                errors={graphqlErrors}
                                hideFieldsForOrganizationContacts={hideFieldsForOrganizationContacts}
                            />
                            <UserLearnerTargetGroup
                                user={user}
                                errors={graphqlErrors}
                                isCitizenshipEducationObliged={isCitizenshipEducationObliged}
                                onChangeCitizenshipEducationObliged={this.onChangeCitizenshipEducationObliged}
                                onAddObligedCitizenshipEducationEndDateExtension={
                                    this.onAddObligedCitizenshipEducationEndDateExtension
                                }
                                onEditObligedCitizenshipEducationEndDateExtension={
                                    this.onEditObligedCitizenshipEducationEndDateExtension
                                }
                                onRemoveObligedCitizenshipEducationEndDateExtension={
                                    this.onRemoveObligedCitizenshipEducationEndDateExtension
                                }
                                onChangeBSNVerifiedCheckbox={this.onChangeBSNVerifiedCheckbox}
                                bsnVerified={bsnVerified}
                                typeOfLearner={typeOfLearner}
                                onChangeTypeOfLearner={this.onChangeTypeOfLearner}
                                learningRoute={learningRoute}
                                onChangeLearningRoute={this.onChangeLearningRoute}
                                targetLevelPIP={targetLevelPIP}
                                onChangeTargetLevel={this.onChangeTargetLevel}
                            />
                            <UserLearnerPaymentDetailsFieldGroup
                                user={user}
                                errors={graphqlErrors}
                                hasPrivatePayment={hasPrivatePayment}
                                paymentBy={paymentBy}
                                changePaymentBy={value => this.setState({ paymentBy: value })}
                                privatePaymentIsDUO={privatePaymentIsDUO}
                                privatePaymentDUOHasApprovedFund={privatePaymentDUOHasApprovedFund}
                                useAddressAsPrivatePaymentAddress={useAddressAsPrivatePaymentAddress}
                                twinfieldCustomerCode={twinfieldCustomerCode}
                                doesNotExistInTwinfield={doesNotExistInTwinfield}
                                lookupTwinfieldCustomerLoading={lookupTwinfieldCustomerLoading}
                                lookupTwinfieldCustomerExists={lookupTwinfieldCustomerExists}
                                lookupTwinfieldCustomerName={lookupTwinfieldCustomerName}
                                onChangeDoesNotExistInTwinfield={this.onChangeDoesNotExistInTwinfield}
                                setDoesNotExistInTwinfield={this.setDoesNotExistInTwinfield}
                                onChangeTwinfieldCustomerCode={this.onChangeTwinfieldCustomerCode}
                                changePrivatePaymentIsDUO={value => this.setState({ privatePaymentIsDUO: value })}
                                changePrivatePaymentDUOHasApprovedFund={value =>
                                    this.setState({ privatePaymentDUOHasApprovedFund: value })
                                }
                                changeUseAddressAsPrivatePaymentAddress={value =>
                                    this.setState({ useAddressAsPrivatePaymentAddress: value })
                                }
                                typeOfLearner={typeOfLearner}
                            />
                        </React.Fragment>
                    )}
                    <UserLearnerContactDetailsFieldGroup
                        hideFieldsForGroupTeacher={hideFieldsForGroupTeacher}
                        hideFieldsForOrganizationContacts={hideFieldsForOrganizationContacts}
                        errors={graphqlErrors}
                        user={user}
                    />
                    {!hideFieldsForOrganizationContacts && (
                        <React.Fragment>
                            {/* <UserLearnerIntakeFieldGroup
                                errors={graphqlErrors}
                                hideFieldsForGroupTeacher={hideFieldsForGroupTeacher}
                                hideFieldsForIntakeTeacher={hideFieldsForIntakeTeacher}
                                learnerUserId={learnerUserId}
                                loading={loading}
                                teacherUsers={teacherUsers}
                                user={user}
                            /> */}
                            <UserLearnerPersonalDetailsFieldGroup
                                errors={graphqlErrors}
                                user={user}
                                hideFieldsForGroupTeacher={hideFieldsForGroupTeacher}
                                hideFieldsForOrganizationContacts={hideFieldsForOrganizationContacts}
                                bsnVerified={bsnVerified}
                                onChangeBSNVerifiedCheckbox={this.onChangeBSNVerifiedCheckbox}
                                onChangeBSNInput={this.onChangeBSNInput}
                            />
                            <UserLearnerBackgroundInformationFieldGroup
                                user={user}
                                errors={graphqlErrors}
                                forNewIntake={forNewIntake}
                            />
                            <UserLearnerEducationFieldGroup
                                user={user}
                                errors={graphqlErrors}
                                forNewIntake={forNewIntake}
                            />
                            <UserLearnerIntakeResultsFieldGroup
                                user={user}
                                errors={graphqlErrors}
                                locations={locations}
                                forNewIntake={forNewIntake}
                            />
                        </React.Fragment>
                    )}
                    {!hideFieldsForGroupTeacher && hideFieldsForOrganizationContacts && (
                        <UserLearnerOrganizationContactOrganizationFieldGroup
                            user={user}
                            loading={loading}
                            errors={graphqlErrors}
                        />
                    )}
                    {!hideFieldsForGroupTeacher && !hideFieldsForOrganizationContacts && (
                        <React.Fragment>
                            <UserLearnerOtherFieldGroup
                                user={user}
                                errors={graphqlErrors}
                                sharePanteia={sharePanteia}
                                onChangeSharePanteia={this.onChangeSharePanteia}
                            />
                        </React.Fragment>
                    )}
                </FieldCollection>
            </>
        )
    }

    private onAddObligedCitizenshipEducationEndDateExtension = (index: number, document: any) => {
        const { obligedCitizenshipEducationEndDateExtensionFiles } = this.state

        const dataToPush = document ? document : null

        while (obligedCitizenshipEducationEndDateExtensionFiles.length < index) {
            // we need to fill the array to keep the order of the extensions
            // we also need undefined values to not accidentally remove the documents
            obligedCitizenshipEducationEndDateExtensionFiles.push(undefined)
        }

        obligedCitizenshipEducationEndDateExtensionFiles.push(dataToPush)
        this.setState({ obligedCitizenshipEducationEndDateExtensionFiles })
    }

    private onEditObligedCitizenshipEducationEndDateExtension = (i: number, document: any) => {
        const { obligedCitizenshipEducationEndDateExtensionFiles } = this.state

        if (!document) {
            return
        }

        obligedCitizenshipEducationEndDateExtensionFiles[i] = document
        this.setState({ obligedCitizenshipEducationEndDateExtensionFiles })
    }

    private onRemoveObligedCitizenshipEducationEndDateExtension = (i: number) => {
        const { obligedCitizenshipEducationEndDateExtensionFiles } = this.state

        obligedCitizenshipEducationEndDateExtensionFiles.splice(i, 1)
        this.setState({ obligedCitizenshipEducationEndDateExtensionFiles })
    }

    private getFileByExtensionNumber = (i: number) => {
        const { obligedCitizenshipEducationEndDateExtensionFiles } = this.state
        return obligedCitizenshipEducationEndDateExtensionFiles[i]
    }

    private hasAdviceModuleChanges = (fields: FormFields) => {
        const user = this.getUser()

        if (!user.learner.adviceModule) {
            return true
        }

        return (
            fields.user.learner.adviceModuleId !== user.learner.adviceModule._id ||
            fields.user.learner.adviceModuleFilterByProjectId !== user.learner.adviceModuleFilterByProjectId ||
            fields.user.learner.adviceModuleFilterByProgramId !== user.learner.adviceModuleFilterByProgramId
        )
    }

    static getPaymentByOptionsForIntegrationLawType(typeOfLearner?: UserTypeOfLearnerTypesEnum): PaymentByType[] {
        switch (typeOfLearner) {
            case UserTypeOfLearnerTypesEnum.Integration2013:
                return [PaymentByType.Organization, PaymentByType.Private]
            case UserTypeOfLearnerTypesEnum.Integration2021:
                return [PaymentByType.Organization, PaymentByType.SelfReporter, PaymentByType.FamilyMigrant]
            default:
                return [PaymentByType.Organization]
        }
    }

    private cannotHaveYearOption(value?: string | null) {
        return !value || value === '(Bijna) geen'
    }
}

const GET_DATA_QUERY_FOR_TEACHER = gql`
    query _ {
        locations(sortBy: "name") {
            _id
            name
        }
    }
`

const GET_DATA_QUERY_FOR_EMPLOYEE = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
            contactUsers {
                _id
                profile {
                    name
                }
            }
        }

        locations(sortBy: "name") {
            _id
            name
        }
    }
`

const LOOKUP_TWINFIELD_CUSTOMER_BY_CODE_QUERY = gql`
    query _($twinfieldCustomerCode: String!) {
        twinfield_customer(twinfieldCustomerCode: $twinfieldCustomerCode) {
            name
        }
    }
`

const GET_LEARNER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            profile {
                initials
                name
                firstName
                surName
                surNamePrefix
                givenName
                gender
                countriesOfNationality
                hasNoCountryOfNationality
                bsn
                bsnVerified
                dateOfBirth
                countryOfBirth
                cityOfBirth
                countryOfOrigin
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
                alternativeEmail
                address {
                    ...AddressFieldsFragment
                }
                visitAddress {
                    ...AddressFieldsFragment
                }
                notes
            }
            learner {
                enrollmentType
                enrollmentDate
                organizations {
                    organization {
                        _id
                        name
                    }
                    organizationContactUser {
                        _id
                        profile {
                            name
                        }
                    }
                    organizationFileNumber
                }
                hasPrivatePayment
                isIntegrationCourse
                typeOfLearner
                learningRoute
                targetLevelPIP
                paymentBy
                integrationLawType
                sharePanteia
                twinfieldCustomer {
                    code
                    name
                }
                canTwinfieldCustomerCodeChange
                privatePaymentIsDUO
                privatePaymentDUOHasApprovedFund
                privatePaymentDUOInitialRemainingFund
                canPrivatePaymentDUOInitialRemainingLifeFundBeChangedByContextUser
                privatePaymentAddress {
                    ...AddressFieldsFragment
                }
                oldFileNumber
                immigratedAt {
                    date
                    precision
                }
                isCitizenshipEducationObliged
                obligedCitizenshipEducationStartDate {
                    date
                    precision
                }
                obligedCitizenshipEducationInitialEndDate
                obligedCitizenshipEducationEndDateExtensions {
                    _id
                    date
                    description
                    document {
                        _id
                        fileName
                        type
                        createdAt
                        createdByUser {
                            _id
                            profile {
                                name
                            }
                        }
                    }
                    createdAt
                    createdByUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
                obligedCitizenshipEducationCompletedAt
                obligedCitizenshipEducationCompletedByImport
                socialStatus
                socialStatusExtraInfo
                familyCompositionLanguageExposure
                nativeLanguages
                homeLanguages
                otherLanguages
                englishAsSupportLanguage
                workExperienceCountryOfOrigin
                workExperienceLocal
                workLiteracy
                workLiteracyLevel
                workLiteracyInfo
                currentOccupationMainLanguages
                currentOccupationVocalInteraction
                currentOccupationVocalInteractionQuality
                currentOccupationVocalInteractionInfo
                currentOccupationStartDate {
                    date
                    precision
                }
                currentOccupationResponsibilities
                currentOccupationFunction
                highestEducationCountryOfOrigin
                highestEducationLocal
                highestEducationCountryOfOriginYear
                highestEducationLocalYear
                educationDesire
                educationExtraInfo
                computerSkill
                NT2HasAttempted
                NT2Info
                NT2 {
                    year
                    duration
                    level
                    organizationName
                    courseMaterials
                }
                intakeDate
                intakeLiteracy
                intakeLiteracyNativeLanguage
                intakeReadingExamScore
                intakeReadingExamScoreTotal
                intakeGrades {
                    levelConversations
                    levelTalking
                    levelWriting
                    levelListening
                    levelReading
                }
                intakeGradesLearnability
                adviceModule {
                    _id
                    name
                    isActive
                }
                adviceModuleFilterByProjectId
                adviceModuleFilterByProgramId
                adviceModuleInfo
                intakeGoals
                intakeOtherGoal
                intakeGoalsAdditionalInfo
                preferredLocations {
                    _id
                    name
                }
                intakeResultsInfo
                intakeMotivation
                isActive
                howDoYouKnowTopTaal
                edisa {
                    edisaData
                }
            }
        }
    }
    ${AddressFieldsFragment}
`
