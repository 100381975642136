import gql from 'graphql-tag'
import { capitalize, times } from 'lodash'
import PropTypes from 'prop-types'
import * as moment from 'moment'
import React, { Component, Fragment } from 'react'

import {
    Bold,
    Button,
    Currency,
    Field,
    FieldCollection,
    FieldSeparator,
    Form,
    Link,
    CheckBox,
    ReadableDate,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { TagPicker } from '~/components/TagPicker'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { InlineInputs } from '~/components/Core/Form/InlineInputs/InlineInputs'
import Subtle from '~/components/Subtle'

export class ActivateGroupParticipationInvoiceForm extends Component {
    static propTypes = {
        userId: PropTypes.string.isRequired,
        isForDUO: PropTypes.bool,
        activatableInvoice: PropTypes.object.isRequired,
        onSubmitSuccess: PropTypes.func.isRequired,
        onCancel: PropTypes.func,
        onRequestSend: PropTypes.func.isRequired,
    }

    constructor(props) {
        super(props)

        this.invoiceMutator = new Mutator({
            mutation: CREATE_DEBIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.state = {
            isCreatingInvoice: false,
            selectedInstallments: null,
            checkedLessons: this.props.activatableInvoice.lessons,
        }
    }

    // onSubmit = async (event, fields) => {
    onSubmit = async (event, fields) => {
        const { sendInvoiceOnSubmit } = this

        const { onSubmitSuccess, userId, activatableInvoice, onRequestSend } = this.props

        const { checkedLessons } = this.state

        this.setState({ isCreatingInvoice: true }, () => {
            this.sendInvoiceOnSubmit = false
        })

        const data = await this.invoiceMutator.mutate({
            invoice: {
                ...transformFormFields(fields.invoice, {}),
                userId: userId,
                isGroupParticipationInvoice: true,
                groupId: activatableInvoice.group._id,
                lessonIds: checkedLessons.map(lesson => lesson._id),
            },
        })

        if (data && data.invoices_createDebit) {
            if (sendInvoiceOnSubmit) {
                await onRequestSend(data.invoices_createDebit._id)
            }

            onSubmitSuccess()
        }

        this.setState({ isCreatingInvoice: false })
    }

    onChangeInstallments = option => {
        const { value } = option || {}

        this.setState({
            selectedInstallments: value || 1,
        })
    }

    render() {
        const { onCancel, isForDUO, activatableInvoice } = this.props
        const { errors } = this.invoiceMutator
        const { isCreatingInvoice, selectedInstallments } = this.state

        const totalAmount = this.getTotalAmount()

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field title={`Groep`}>
                            <Link route={`/groups/${activatableInvoice.group._id}`} target={`_blank`}>
                                {activatableInvoice.group.name}
                            </Link>
                        </Field>
                        <Field title={`Lesmodule`}>
                            <Link
                                route={`/properties/modules/${activatableInvoice.group.module._id}`}
                                target={`_blank`}
                            >
                                {activatableInvoice.group.module.name}
                            </Link>
                        </Field>
                        {typeof activatableInvoice.month === 'number' && (
                            <Field title={`Maand`}>
                                {capitalize(moment().month(activatableInvoice.month).format('MMMM'))}
                            </Field>
                        )}
                        <Field title={`Lessen`} errors={errors}>
                            {activatableInvoice.lessons && activatableInvoice.lessons.length > 0 ? (
                                <InlineInputs>
                                    {activatableInvoice.lessons.map(lesson => (
                                        <CheckBox
                                            onClick={this.getChangeLessonHandler(lesson)}
                                            key={lesson._id}
                                            checked={this.isLessonChecked(lesson)}
                                        >
                                            Les {lesson.order + 1}{' '}
                                            <Subtle>
                                                (<ReadableDate date={lesson.date} format={`DD-MM-YYYY`} />)
                                            </Subtle>
                                        </CheckBox>
                                    ))}
                                </InlineInputs>
                            ) : (
                                'Geen lessen gevonden'
                            )}
                        </Field>
                        {/* <Field title={`Prijs`} errors={errors}>
                            <Currency amount={activatableInvoice.totalCost} />
                        </Field> */}
                        {!isForDUO && (
                            <Field title={`Betalen in termijnen`} errors={errors}>
                                <TagPicker
                                    multi={false}
                                    name={`invoice.installments`}
                                    defaultValue={1}
                                    isClearable={false}
                                    options={[
                                        { label: '1 termijn', value: 1 },
                                        { label: '2 termijnen (+ €25)', value: 2 },
                                        { label: '3 termijnen (+ €50)', value: 3 },
                                    ]}
                                    onChange={this.onChangeInstallments}
                                />
                            </Field>
                        )}
                    </FieldGroup>
                    <FieldGroup highlight>
                        {(!selectedInstallments || selectedInstallments === 1) && (
                            <Fragment>
                                <Field title={`Factuurbedrag`}>
                                    <Bold>
                                        <Currency amount={totalAmount} />
                                    </Bold>
                                </Field>
                            </Fragment>
                        )}
                        {selectedInstallments > 1 && (
                            <Fragment>
                                <Field title={`Totaalbedrag`}>
                                    <Bold>
                                        <Currency amount={totalAmount} />
                                    </Bold>
                                </Field>
                                <FieldSeparator />
                                {times(selectedInstallments).map(installmentIndex => {
                                    const factor = this.getInvoiceFactorForInstallment(installmentIndex)
                                    const amount = this.getInstallmentAmount(factor, totalAmount)

                                    return (
                                        <Field
                                            key={installmentIndex}
                                            title={`Factuurbedrag termijn ${installmentIndex + 1} (${factor * 100}%)`}
                                        >
                                            <Bold>
                                                <Currency amount={amount} />
                                            </Bold>
                                        </Field>
                                    )
                                })}
                            </Fragment>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    styleOverride={`secondary-submit`}
                                    isLoading={isCreatingInvoice && !this.sendInvoiceOnSubmit}
                                >
                                    Activeren
                                </Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    shouldNotPreventDefaultOnClick
                                    isLoading={isCreatingInvoice && this.sendInvoiceOnSubmit}
                                    onClick={() => {
                                        this.sendInvoiceOnSubmit = true
                                    }}
                                >
                                    Activeren en {isForDUO ? `definitief maken` : `versturen`}
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    getAmount() {
        const { activatableInvoice } = this.props
        const { checkedLessons } = this.state

        return checkedLessons.length * activatableInvoice.lessonCost
    }

    getTotalAmount() {
        const { selectedInstallments } = this.state

        const amount = this.getAmount()

        // Additional installment costs
        switch (selectedInstallments) {
            case 2:
                return amount + 25
            case 3:
                return amount + 50
        }

        return amount
    }

    getInvoiceFactorForInstallment(installmentIndex) {
        const { selectedInstallments } = this.state

        switch (selectedInstallments) {
            case 2:
                switch (installmentIndex) {
                    case 0:
                        return 0.6
                    case 1:
                        return 0.4
                }

                break
            case 3:
                switch (installmentIndex) {
                    case 0:
                        return 0.4
                    case 1:
                        return 0.3
                    case 2:
                        return 0.3
                }

                break
        }

        return 1
    }

    getInstallmentAmount(factor, totalAmount) {
        return totalAmount * factor
    }

    getChangeLessonHandler = lesson => () => {
        const { checkedLessons } = this.state
        const lessonIndexInCheckedLessons = checkedLessons.findIndex(checkedLesson => checkedLesson._id === lesson._id)
        const checkedLessonsContainsId = lessonIndexInCheckedLessons > -1

        if (!checkedLessonsContainsId) {
            return this.setState({ checkedLessons: [...checkedLessons, lesson] })
        } else {
            const checkedLessonsShallow = [...checkedLessons]
            checkedLessonsShallow.splice(lessonIndexInCheckedLessons, 1)
            return this.setState({ checkedLessons: checkedLessonsShallow })
        }
    }

    isLessonChecked(lesson) {
        const { checkedLessons } = this.state

        if (!checkedLessons) {
            return false
        }

        return !!checkedLessons.find(checkedLesson => {
            return checkedLesson._id === lesson._id
        })
    }
}

const CREATE_DEBIT_INVOICE_MUTATION = gql`
    mutation _($invoice: InvoiceInputType!) {
        invoices_createDebit(invoice: $invoice) {
            _id
        }
    }
`
