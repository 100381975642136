import gql from 'graphql-tag'
import * as React from 'react'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { Mutator, toast } from '~/utils'

const USERS_CHANGE_EMAIL = gql`
    mutation _($userId: MongoID, $newEmail: String) {
        users_changeEmail(userId: $userId, newEmail: $newEmail) {
            _id
        }
    }
`
interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
    userId: string
    emailProps: string
}

interface State {
    emailState: string
}

export class ChangeEmailForm extends React.Component<Props, State> {
    public state: State = {
        emailState: '',
    }

    private userMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: USERS_CHANGE_EMAIL,
            reactComponentToUpdate: this,
        })
    }

    componentDidMount() {
        this.setState({ emailState: this.props.emailProps })
    }

    public render() {
        const { errors, loading } = this.userMutator
        const { onCancel, emailProps } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`} className={`tt-FieldCollection--password-form`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Email`} errors={errors}>
                            <Input
                                autoFocus={true}
                                type={`text`}
                                defaultValue={emailProps}
                                name={`email`}
                                placeholder={``}
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                    this.setState({ emailState: event.target.value })
                                }
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed, userId } = this.props
        const { emailState } = this.state

        this.userMutator
            .mutate({
                userId: userId,
                newEmail: emailState,
            })
            .then(data => {
                if (data) {
                    toast.success('E-mail succesvol gewijzigd')
                    onSubmitSuccess()
                } else {
                    if (onSubmitFailed) {
                        onSubmitFailed()
                    }
                }
            })
    }
}
