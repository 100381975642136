import gql from 'graphql-tag'
import $ from 'jquery'
import { get } from 'lodash'
import React, { Component } from 'react'

import { Calendar, CenterModal, Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Wrap } from '~/components/Wrap'
import { CreateHolidayEventForm, EditHolidayEventForm } from '~/forms'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class HolidaysView extends Component {
    state = {
        createEventModalActive: false,
        createEventModalData: null,
        editEventModalActive: false,
        editEventModalData: null,
    }

    constructor(props) {
        super(props)

        this.createEventMutator = new Mutator({
            mutation: CREATE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.editEventMutator = new Mutator({
            mutation: EDIT_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteEventMutator = new Mutator({
            mutation: DELETE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.calendarEventsFetcher = new Fetcher({
            query: HOLIDAY_EVENTS_QUERY,
            variables: {},

            onChange: () => this.forceUpdate(),
        })
    }

    isTimelessMoment(m) {
        return Boolean(m.hours() === 0 && m.minutes() === 0 && m.seconds() === 0 && m.milliseconds() === 0)
    }

    onSelectSlot = (start, end) => {
        const allDay = this.isTimelessMoment(start) && this.isTimelessMoment(end)

        this.setState({
            createEventModalActive: true,
            createEventModalData: { start, end, allDay },
        })
    }

    onClickEvent = event => {
        this.setState({
            editEventModalActive: true,
            editEventModalData: event,
        })
    }

    onMoveEvent = event => {
        const { _id, start, end } = event

        this.editEventMutator
            .mutate({
                calendarEvent: {
                    _id: _id,
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                },
            })
            .then(data => {
                if (data) {
                    this.calendarEventsFetcher.refetch({ silent: true })
                }
            })
    }

    onRequestCloseCreateEventModal = () => {
        $(this.refs.Calendar.refs.calendar).fullCalendar('unselect')

        this.setState({
            createEventModalActive: false,
            createEventModalData: null,
        })
    }

    onRequestCloseEditEventModal = () => {
        this.setState({
            editEventModalActive: false,
            editEventModalData: null,
        })
    }

    createEvent = fields => {
        this.createEventMutator
            .mutate({
                calendarEvent: {
                    type: 'HOLIDAY',
                    typeData: {},
                    ...transformFormFields(fields),
                },
            })
            .then(data => {
                if (data) {
                    this.onRequestCloseCreateEventModal()
                    this.calendarEventsFetcher.refetch()
                }
            })
    }

    editEvent = fields => {
        const { editEventModalData: event } = this.state

        this.editEventMutator
            .mutate({
                calendarEvent: {
                    _id: event._id,
                    ...transformFormFields(fields),
                },
            })
            .then(data => {
                if (data) {
                    this.onRequestCloseEditEventModal()
                    this.calendarEventsFetcher.refetch()
                }
            })
    }

    removeEvent = () => {
        const { editEventModalData: event } = this.state

        this.deleteEventMutator
            .mutate({
                calendarEventId: event._id,
            })
            .then(data => {
                if (data) {
                    this.onRequestCloseEditEventModal()
                    this.calendarEventsFetcher.refetch()
                }
            })
    }

    getCalendarEvents() {
        const { data } = this.calendarEventsFetcher
        const calendarEvents = get(data, 'calendarEvents') || []
        return calendarEvents.map(e => ({
            ...e,
            start: e.startDate,
            startDate: undefined,
            end: e.endDate,
            endDate: undefined,
            allDay: e.allDay,
        }))
    }

    render() {
        const { ...routeProps } = this.props
        const { loading } = this.calendarEventsFetcher
        const { createEventModalActive, editEventModalActive } = this.state

        return (
            <RouteView crumbLabel={'Feestdagen'} routeProps={routeProps}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full>
                        {createEventModalActive && this.renderCreateEventModal()}
                        {editEventModalActive && this.renderEditEventModal()}

                        <Calendar
                            // TODO: Fix this issue and re-enable the no-string-refs rule
                            // eslint-disable-next-line react/no-string-refs
                            ref="Calendar"
                            height="auto"
                            isLoading={loading}
                            events={this.getCalendarEvents()}
                            defaultView="month"
                            disableViewChange
                            allowCreateEvents
                            allowEditEvents
                            selectSlotType="HOLIDAY"
                            onSelectSlot={this.onSelectSlot}
                            onMoveEvent={this.onMoveEvent}
                            onResizeEvent={this.onMoveEvent}
                            onClickEvent={this.onClickEvent}
                        />
                    </Wrap>
                </ContentView>
            </RouteView>
        )
    }

    renderCreateEventModal() {
        const { errors, loading } = this.createEventMutator
        const { createEventModalData: { start, end, allDay } = {} } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseCreateEventModal} title={`Feestdag toevoegen`}>
                <CreateHolidayEventForm
                    defaultValues={{
                        allDay: allDay,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.createEvent}
                    onCancel={this.onRequestCloseCreateEventModal}
                />
            </CenterModal>
        )
    }

    renderEditEventModal() {
        const { errors, loading } = this.editEventMutator
        const { editEventModalData: { title, start, end, allDay } = {} } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseEditEventModal} title={`Feestdag aanpassen`}>
                <EditHolidayEventForm
                    defaultValues={{
                        allDay,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                        title,
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.editEvent}
                    onRemove={this.removeEvent}
                    onCancel={this.onRequestCloseEditEventModal}
                />
            </CenterModal>
        )
    }
}

const HOLIDAY_EVENTS_QUERY = gql`
    query _ {
        calendarEvents(type: "HOLIDAY") {
            _id
            title
            type
            startDate
            endDate
        }
    }
`

const CREATE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_create(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const EDIT_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_edit(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const DELETE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEventId: MongoID!) {
        calendarEvents_delete(calendarEventId: $calendarEventId) {
            _id
        }
    }
`
