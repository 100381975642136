import * as React from 'react'
import gql from 'graphql-tag'

import { RouteComponentProps } from 'react-router'
import Fetcher from '~/utils/Fetcher'
import { Location } from '~/types/Location'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'

interface Props extends RouteComponentProps<{ roomId: string }, {}> {
    children: any
    locationObject: Location
    refetch: () => void
}

const GET_ROOM_QUERY = gql`
    query _($locationId: MongoID, $roomId: MongoID) {
        locations(byId: $locationId) {
            rooms(byId: $roomId) {
                _id
                name
                capacity
                locationProperties {
                    _id
                    name
                }
            }
        }
    }
`

export class AppPropertiesLocationsDetailRoomsDetail extends React.Component<Props> {
    private roomFetcher = new Fetcher({
        query: GET_ROOM_QUERY,
        variables: {
            roomId: this.props.params.roomId,
            locationId: this.props.locationObject._id,
        },
        onChange: () => this.forceUpdate(),
    })

    public componentDidUpdate({ params: prevParams }: Props) {
        const { params, locationObject } = this.props
        const roomHasChanged = prevParams.roomId !== params.roomId

        if (roomHasChanged) {
            this.roomFetcher.refetch({
                locationId: locationObject._id,
                roomId: params.roomId,
            } as any)
        }
    }

    public render() {
        const { children, locationObject, ...routeComponentProps } = this.props
        const { data, loading, refetch } = this.roomFetcher

        const room =
            data && data.locations && data.locations[0] && data.locations[0].rooms && data.locations[0].rooms[0]

        return (
            <RouteView crumbLabel={room && room.name} routeProps={routeComponentProps} isLoading={loading}>
                {room && React.cloneElement(children, { locationObject, room, refetch })}
            </RouteView>
        )
    }
}
