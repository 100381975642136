import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { DrawerHeader, Form, TableWrap } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Mutator } from '~/utils'
import { TableView } from '~/components/TableView'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import DrawerFooter from '~/components/DrawerFooter'

export default class ConceptGroupUsersForm extends Component {
    static propTypes = {
        group: PropTypes.object.isRequired,
        role: PropTypes.string.isRequired,
        usersFetcher: PropTypes.instanceOf(Fetcher).isRequired,
        usersPaginator: PropTypes.instanceOf(Paginator).isRequired,
        title: PropTypes.string,
        renderUsersTable: PropTypes.func.isRequired,
        renderActionBar: PropTypes.func,
        onSuccess: PropTypes.func,
        onCancel: PropTypes.func,
    }

    static defaultProps = {
        onSuccess: () => {},
        onCancel: () => {},
    }

    static POSSIBLE_USER_ROLES_BY_GROUPUSER_ROLE = {
        EMPLOYEE: ['EMPLOYEE'],
        TEACHER: ['INTERNAL_TEACHER', 'TEACHER'],
        LEARNER: ['LEARNER'],
    }

    constructor(props) {
        super(props)

        this.possbileUserRoles = this.constructor.POSSIBLE_USER_ROLES_BY_GROUPUSER_ROLE[props.role]

        this.state = {
            checkedUsers: [],
            uncheckedUsers: [],
            initialUsers: [],
        }

        this.selectedGroupUsersFetcher = new Fetcher({
            query: GET_SELECTED_GROUP_USERS_QUERY,
            variables: {
                filters: {
                    filterByRoleInGroup: props.role,
                    roles: this.possbileUserRoles,
                    filterByGroupIds: [props.group._id],
                },
            },

            preventInitialFetch: true,
        })

        this.selectedGroupUsersFetcher.fetch().then(({ users }) => {
            this.setState({
                checkedUsers: users || [],
                initialUsers: users || [],
            })
        })

        this.changeUsersMutator = new Mutator({
            mutation: CHANGE_USERS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    addUser = user => {
        this.setState({
            checkedUsers: [...this.state.checkedUsers, user],
            uncheckedUsers: this.state.uncheckedUsers.filter(({ _id }) => _id !== user._id),
        })
    }

    removeUser = user => {
        this.setState({
            uncheckedUsers: [...this.state.uncheckedUsers, user],
            checkedUsers: this.state.checkedUsers.filter(({ _id }) => _id !== user._id),
        })
    }

    onSubmit = async () => {
        const { onCancel, onSuccess, role } = this.props

        const toAddUsers = this.state.checkedUsers
            .filter(({ _id }) => !this.state.initialUsers.find(({ _id: initialUserId }) => _id === initialUserId))
            .map(({ _id }) => ({
                userId: _id,
                role: role,
            }))

        const toRemoveUsers = this.state.uncheckedUsers
            .filter(({ _id }) => this.state.initialUsers.find(({ _id: initialUserId }) => _id === initialUserId))
            .map(({ _id }) => ({
                userId: _id,
                role: role,
            }))

        if (toAddUsers.length > 0 || toRemoveUsers.length > 0) {
            const result = await this.changeUsersMutator.mutate({
                groupId: this.props.group._id,
                groupUsersToAdd: toAddUsers,
                groupUsersToRemove: toRemoveUsers,
            })

            if (result) onSuccess()
        } else {
            onCancel()
        }
    }

    render() {
        const { onCancel, usersPaginator, usersFetcher, title, renderActionBar, renderUsersTable } = this.props
        const { data, loading } = usersFetcher
        const { checkedUsers } = this.state

        const { loading: mutationLoading } = this.changeUsersMutator
        const users = data.users || []

        const selectedUsers = checkedUsers
        const notSelectedUsers = users
            ? users.filter(({ _id }) => !checkedUsers.find(({ _id: checkedId }) => checkedId === _id))
            : []

        return (
            <Form onSubmit={this.onSubmit}>
                <DrawerHeader title={title} close={onCancel} />
                <TableWrap insetTable>
                    <TableView>{renderUsersTable(selectedUsers, this.addUser, this.removeUser, true)}</TableView>
                </TableWrap>
                <ContentView>
                    <InfiniteScroll paginator={usersPaginator} preventLoad={loading}>
                        <Wrap full>{renderActionBar && renderActionBar()}</Wrap>
                        <TableWrap>
                            <TableView>
                                {renderUsersTable(notSelectedUsers, this.addUser, this.removeUser, false)}
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter close={onCancel} isLoading={mutationLoading} />
            </Form>
        )
    }
}

const CHANGE_USERS_MUTATION = gql`
    mutation _(
        $groupId: MongoID!
        $groupUsersToAdd: [AddedGroupUserInputType!]!
        $groupUsersToRemove: [RemovedGroupUserInputType!]!
    ) {
        groups_changeConceptUsers(
            groupId: $groupId
            groupUsersToAdd: $groupUsersToAdd
            groupUsersToRemove: $groupUsersToRemove
        ) {
            _id
        }
    }
`

const GET_SELECTED_GROUP_USERS_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(sortBy: "profile.firstName", filters: $filters) {
            _id
            profile {
                name
                notes
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            learner {
                intakeGradesLearnability
                intakeGrades {
                    levelConversations
                    levelTalking
                    levelWriting
                    levelListening
                    levelReading
                }
                latestAbilityLevels {
                    converse {
                        ...abilityLevelFields
                    }
                    talk {
                        ...abilityLevelFields
                    }
                    write {
                        ...abilityLevelFields
                    }
                    listen {
                        ...abilityLevelFields
                    }
                    read {
                        ...abilityLevelFields
                    }
                }
                englishAsSupportLanguage
                adviceModule {
                    _id
                    name
                }
                adviceModuleInfo
                preferredLocations {
                    _id
                    name
                }
            }
        }
    }

    fragment abilityLevelFields on AbilityLevelType {
        level
        accomplishedAt
    }
`
