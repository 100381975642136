import * as React from 'react'
import { ExportsCard } from '~/components/Exports/ExportsCard'
import { Button, Icon, CenterModal } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { ExamCandidateIntegrationExportForm } from '~/components/Exports/Forms/ExamCandidateIntegrationExportForm'
import { downloadFile } from '~/utils'
import translateType from '~/shared/utils/translateType'

interface Props {
    lastDate?: Date
    refreshDates: () => void
}

export class ExamCandidateIntegrationCard extends React.Component<Props> {
    public render() {

        // Examenkandidaten inburgering voor TVO
        const title = translateType('exportType', 'EnrollmentCitizenship')

        return (
            <ExportsCard
                title={title}
                date={this.props.lastDate}
                description={`Lijst van inburgeringsplichtige kandidaten die examentraining hebben (gehad) in een te selecteren periode.`}
            >
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} leftIcon={<Icon name={`download`} />}>
                            Download export
                        </Button>
                    )}
                    getModal={closeModal => (
                        <CenterModal onClose={closeModal} title={title}>
                            <ExamCandidateIntegrationExportForm
                                onClose={closeModal}
                                onSubmitSuccess={(fileId, fileName) => {
                                    downloadFile(fileId, fileName)
                                    closeModal()
                                    this.props.refreshDates()
                                }}
                            />
                        </CenterModal>
                    )}
                />
            </ExportsCard>
        )
    }
}
