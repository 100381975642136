import gql from 'graphql-tag'
import { get } from 'lodash'
const moment = require('moment')
import React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Button, DatePicker, Field, FieldCollection, Form, Input, PhoneInput } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import apolloClient from '~/services/apolloClient'
import { Mutator, parseGraphQLError, toast } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import { Location } from '~/types/Location'
import { FormFields } from '~/components/Form'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'

const EDIT_LOCATION_MUTATION = gql`
    mutation _($location: LocationInputType!) {
        locations_edit(location: $location) {
            _id
        }
    }
`

const DELETE_LOCATION_MUTATION = gql`
    mutation _($_id: MongoID!) {
        locations_delete(locationId: $_id) {
            _id
        }
    }
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    refetch: (options?: any) => void
    locationObject: Location
}

export default class EditView extends React.Component<Props> {
    private locationMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.locationMutator = new Mutator({
            mutation: EDIT_LOCATION_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { locationObject } = this.props
        const { errors, loading } = this.locationMutator
        const rentalDateFrom = get(locationObject, 'rentalDateFrom')
        const rentalDateTo = get(locationObject, 'rentalDateTo')

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup>
                                <Field isLabel={true} title={'Locatienaam'} errors={errors}>
                                    <Input
                                        name={`location.name`}
                                        type={`text`}
                                        placeholder={`Locatienaam`}
                                        defaultValue={get(locationObject, `name`)}
                                    />
                                </Field>
                                <LocationFieldsInputGroup
                                    title={'Adres'}
                                    errors={errors}
                                    withoutCountry={true}
                                    name={'location.address'}
                                    location={locationObject && locationObject.address}
                                />
                                <Field title={`Telefoonnummer`} errors={errors}>
                                    <VariableMultiInput
                                        defaultAmount={get(locationObject, 'address.phoneNumbers.length')}
                                        getNewInput={i => (
                                            <PhoneInput
                                                key={i}
                                                name={`location.address.phoneNumbers[${i}]`}
                                                defaultValue={get(locationObject, `address.phoneNumbers[${i}]`)}
                                            />
                                        )}
                                        addButtonLabel={`Extra veld`}
                                    />
                                </Field>
                                <Field isLabel={true} title={'Naam bedrijf'} errors={errors}>
                                    <Input
                                        name={`location.organizationName`}
                                        type={`text`}
                                        defaultValue={get(locationObject, `organizationName`)}
                                    />
                                </Field>
                                <Field isLabel={true} title={'Naam contactpersoon'} errors={errors}>
                                    <Input
                                        name={`location.contactPersonName`}
                                        type={`text`}
                                        defaultValue={get(locationObject, `contactPersonName`)}
                                    />
                                </Field>
                                <Field isLabel={true} title={'Startdatum'} errors={errors}>
                                    <DatePicker
                                        name={`location.rentalDateFrom`}
                                        defaultValue={rentalDateFrom && moment(rentalDateFrom).format('YYYY-MM-DD')}
                                    />
                                </Field>
                                <Field isLabel={true} title={'Einddatum'} errors={errors}>
                                    <DatePicker
                                        name={`location.rentalDateTo`}
                                        defaultValue={rentalDateTo && moment(rentalDateTo).format('YYYY-MM-DD')}
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal={true}>
                                    <ListItem right={true}>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/locations/${locationObject._id}`)
                                            }
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right={true}>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { params, refetch } = this.props

        const data = await this.locationMutator.mutate({
            location: {
                _id: params.id,
                ...transformFormFields(fields.location, {
                    address: transformLib.addressWithCountryNL(),
                    rentalDateFrom: transformLib.date(),
                    rentalDateTo: transformLib.date(),
                }),
            },
        })

        if (data) {
            refetch()
            browserHistory.push(`/properties/locations/${params.id}`)
        }
    }

    private onDelete = async () => {
        const { params } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_LOCATION_MUTATION,
                variables: {
                    _id: params.id,
                },
            })

            browserHistory.push(`/properties/locations`)
        } catch (error) {
            const { message } = parseGraphQLError(error, { namespace: 'locations_delete' })
            toast.error(`${message}`)
        }
    }
}
