import * as React from 'react'
import gql from 'graphql-tag'

import { Button, Field, FieldCollection, Form, Input } from '~/components'

import { Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { UnitInput, unitType } from '~/components/UnitInput'

interface FormFields {
    DUODeclaration: {
        description: string | null
        amount: string | null
    }
}

type FormSubmit<Fields> = (event: React.SyntheticEvent<HTMLFormElement>, fields: Fields) => void

interface Props {
    userId: string
    onSubmitSuccess?: () => void
    onCancel?: () => void
}

const CREATE_DUO_DECLARATION_MUTATION = gql`
    mutation _($DUODeclaration: DUODeclarationInputType!) {
        DUODeclarations_create(DUODeclaration: $DUODeclaration) {
            _id
        }
    }
`

export class CreateDUODeclarationForm extends React.Component<Props, {}> {
    private createDUODeclarationMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.createDUODeclarationMutator = new Mutator({
            mutation: CREATE_DUO_DECLARATION_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onCancel } = this.props
        const { errors, loading } = this.createDUODeclarationMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style="modal">
                    <FieldGroup isForm>
                        {/* <Field isLabel title='Door TopTaal' errors={errors} isRequired>
                            <MultiInput type='radio'>
                                <RadioButton
                                    name='DUODeclaration.isExternal'
                                    onClick={() => this.setState({ isExternal: false })}
                                >
                                    Ja
                                </RadioButton>
                                <RadioButton
                                    name='DUODeclaration.isExternal'
                                    onClick={() => this.setState({ isExternal: true })}
                                >
                                    Nee
                                </RadioButton>
                            </MultiInput>
                        </Field> */}
                        {/* {isExternal === false && (
                            <FieldGroup isInsetGroup>
                                <Field isLabel title='Verbruiksdatum' errors={errors} isRequired>
                                    <DatePicker name='DUODeclaration.date' />
                                </Field>
                            </FieldGroup>
                        )} */}
                        <Field isLabel title="Omschrijving" errors={errors} isRequired>
                            <Input name="DUODeclaration.description" placeholder="Toelichting" />
                        </Field>
                        <Field isLabel title="Bedrag" errors={errors} isRequired>
                            <UnitInput name="DUODeclaration.amount" placeholder="Bedrag" unitType={unitType.euro} />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Aanmaken
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit: FormSubmit<FormFields> = async (event, fields) => {
        const { onSubmitSuccess, userId } = this.props
        // const { isExternal } = this.state

        const data = await this.createDUODeclarationMutator.mutate({
            DUODeclaration: {
                ...transformFormFields(fields.DUODeclaration, {
                    // isExternal: { value: isExternal, isNull: !isBoolean(isExternal) },
                    // date: {
                    //     value: transformLib.date().value,
                    //     isNull: (v: any) => defaultIsNullFunction(v) || isExternal,
                    // },
                    description: transformLib.string(),
                }),
                userId,
            },
        })

        if (data && data.DUODeclarations_create) {
            if (onSubmitSuccess) {
                onSubmitSuccess()
            }
        }
    }
}
