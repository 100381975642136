import { FinalExamSubmitters } from '~/types/FinalExam'

const lessonReasonOfAbsence = {
    OTHER_INTEGRATION_ACTIVITY: () => 'Andere activiteit Inburgering',
    SICK: () => 'Ziek',
    WORK_STUDY: () => 'Werk/Studie',
    VACATION: () => 'Vakantie',
    PRIVATE_REASONS: () => 'Privé-omstandigheden',
    TRANSPORT_ISSUES: () => 'Problemen met vervoer',
    EXAM: () => 'Examen',
    OTHER: () => 'Anders, namelijk;',
}

export const types = {
    role: {
        LEARNER: () => `Kandidaat`,
        TEACHER: () => `Docent`,
        EMPLOYEE: () => `Medewerker`,
        ORGANIZATION_CONTACT: () => `Organisatie`,
    },
    userGender: {
        MALE: () => `Man`,
        FEMALE: () => `Vrouw`,
        OTHER: () => `Anders`,
    },
    learnerSocialStatus: {
        WORKING: () => `Werkend`,
        SEEKING: () => `Werk zoekend`,
        EDUCATION: () => 'Volgt opleiding',
        RETIRED: () => 'Gepensioneerd',
        DISABLED: () => 'Arbeidsongeschikt',
        NOTLOOKINGFORWORK: () => 'Niet werk zoekend',
        NA: () => `n.v.t.`,
        OTHER: () => `Anders`,
    },
    mutableRole: {
        PROJECT_MANAGER: () => `Projectleider`,
        INTAKER: () => `Intaker`,
        PROJECT_ASSISTANT: () => `Projectmedewerker`,
        TEAM_LEADER: () => `Teamleider`,
        RECEPTIONIST: () => `Receptioniste`,
        INTERNAL_TEACHER: () => `Interne docent`,
        MANAGEMENT: () => `Directie`,
        OFFICE_ASSISTENT: () => `Kantoorassistent`,
        ACCOUNTANT: () => `Administrateur`,
        TELEPHONIST: () => `Telefoniste`,
        FINANCIAL_EMPLOYEE: () => `Financieel medewerker`,
        FACILITY_EMPLOYEE: () => `Facilitair medewerker`,
        ON_CALL_EMPLOYEE: () => `Oproepkracht`,
        MANAGER: () => `Manager`,
        IT_EMPLOYEE: () => `IT medewerker`,
        ACCOUNT_MANAGER: () => `Accountmanager`,
        INTAKE_EMPLOYEE: () => `Toetsmedewerker`,
    },
    lessonAttenance: {
        PRESENT: () => `Aanwezig`,
        ABSENT_WITH_REASON: () => `Afwezig met afmelding`,
        ABSENT_WITHOUT_REASON: () => `Afwezig zonder afmelding`,
    },
    lessonReasonOfAbsence,
    learnerStatus: {
        ASSIGNED: () => `Ingedeeld`,
        UNASSIGNED: () => `Niet ingedeeld`,
        REGISTERED: () => `Aangemeld`,
    },
    userExportColumns: {
        Name: () => 'Naam',
        FirstName: () => 'Voornaam',
        SurName: () => 'Achternaam',
        Bsn: () => 'BSN',
        Gender: () => 'Geslacht',
        Email: () => 'E-mailadres',
        PhoneNumber: () => 'Telefoonnummer',
        Country: () => 'Land',
        Street: () => 'Straat',
        Number: () => 'Huisnummer',
        NumberAddition: () => 'Huisnr. toevoeging',
        Zipcode: () => 'Postcode',
        City: () => 'Stad',
        State: () => 'Provincie/staat',
        AddressLine1: () => 'Adresregel 1',
        AddressLine2: () => 'Adresregel 2',
        Learnability: () => 'Leerbaarheid (max 25)',
        HighestEducationLocal: () => 'Opleidingsniveau',
        HighestEducationCountryOfOrigin: () => 'Opleidingsniveau land van herkomst',
        IntakeGradesReading: () => 'Lezen',
        IntakeGradesWriting: () => 'Schrijven',
        IntakeGradesListening: () => 'Luisteren',
        IntakeGradesTalking: () => 'Spreken',
        IntakeGradesConversations: () => 'Gespreksvaardigheid',
        EnglishAsSupportLanguage: () => 'Engels als steuntaal',
        Notes: () => 'Notities',
        IsNT2Certified: () => 'NT2-gecertificeerd',
        LessonCompetence: () => 'Lesbevoegd',
        DateOfBirth: () => 'Geboortedatum',
        CityOfBirth: () => 'Geboorteplaats',
        CountryOfBirth: () => 'Geboorteland',
        Groups: () => 'Groepen',
        CountriesOfNationality: () => 'Nationaliteit(en)',
        NativeLanguages: () => 'Moedertaal',
        HomeLanguages: () => 'Voertaal(en) thuis',
        OtherLanguages: () => 'Overige talen',
        CountryOfOrigin: () => 'Land van herkomst',
        PreferredLocations: () => 'Voorkeurslocatie(s)',
        WriteLevel: () => 'Niveau Schrijven',
        WriteDate: () => 'Datum Schrijven',
        ReadLevel: () => 'Niveau Lezen',
        ReadDate: () => 'Datum Lezen',
        ListenLevel: () => 'Niveau Luisteren',
        ListenDate: () => 'Datum Luisteren',
        TalkLevel: () => 'Niveau Spreken',
        TalkDate: () => 'Datum Spreken',
        ConverseLevel: () => 'Niveau Gesprekken',
        ConverseDate: () => 'Datum Gesprekken',
        HasOwnCompany: () => 'Is Freelancer',
    },
    timelineEventTargetGroups: {
        ORGANIZATION: () => 'organisatie',
        TEACHER: () => 'docent',
    },
    invoiceDescription: {
        lessonMaterials: () => 'Lesmateriaal',
    },
    invoiceDescriptionEN: {
        lessonMaterials: () => 'Lesson materials',
    },
    projectArticlePaymentType: {
        privateDuo: () => 'Particulier, DUO',
        privateSelf: () => 'Particulier, zelfbetaler',
        organization: () => 'Organisatie',
    },
    projectArticleCodeType: () => ({
        groupParticipation: () => 'Groepsparticipatie',
        exams: () => 'Examens',

        // Re-use invoice description translations
        ...types.invoiceDescription,
    }),
    level: {
        Alpha: () => 'Alfa',
        A0: () => 'A0',
        A1Minus: () => 'A1-',
        A1: () => 'A1',
        A2Minus: () => 'A2-',
        A2: () => 'A2',
        B1Minus: () => 'B1-',
        B1: () => 'B1',
        B2Minus: () => 'B2-',
        B2: () => 'B2',
        C1Minus: () => 'C1-',
        C1: () => 'C1',
        Inflow: () => 'Instroom',
        _1FMinus: () => '1F-',
        _1F: () => '1F',
        _2FMinus: () => '2F-',
        _2F: () => '2F',
        _3FMinus: () => '3F-',
        _3F: () => '3F',
        _4FMinus: () => '4F-',
        _4F: () => '4F',
    },
    examAbility: {
        read: () => 'Lezen',
        listen: () => 'Luisteren',
        talk: () => 'Spreken',
        converse: () => 'Gespreksvaardigheid',
        write: () => 'Schrijven',
    },
    examAbilityColumnTitle: {
        read: () => 'Lezen',
        listen: () => 'Luisteren',
        talk: () => 'Spreken',
        converse: () => 'Gesprekken',
        write: () => 'Schrijven',
    },
    finalExamPart: {
        read: () => 'Lezen',
        listen: () => 'Luisteren',
        talk: () => 'Spreken',
        write: () => 'Schrijven',
        ona: () => 'ONA',
        knm: () => 'KNM',
    },
    finalExamResult: {
        passed: () => 'Gehaald',
        failed: () => 'Niet geslaagd',
        no_show: () => 'Niet verschenen',
        exempt: () => 'Vrijgesteld',
    },
    finalExamSubmitter: {
        [FinalExamSubmitters.External]: () => 'Extern',
        [FinalExamSubmitters.TopTaal]: () => 'TopTaal',
    },
    examRating: {
        level: () => 'Niveau',
        score: () => 'Score',
        passedNotPassed: () => 'Voldoende/onvoldoende',
    },
    lessonSpecialActivity: {
        portfolioDeadline: () => 'Deadline portfolio',
        reportMoment: () => 'Rapportagemoment',
        exam: () => 'Toets',
        assessment: () => 'Voortgangsgesprek',
    },
    userLearnerHourDescription: {
        CITIZENSHIP: () => 'Lesuren standaard en ONA',
        CITIZENSHIP_OF_WHICH_ONA_SHORT: (count: any) => `/ ${count} ONA uren`,
        CITIZENSHIP_OF_WHICH_ONA: (count: any) => `waarvan ${count} ONA lesuren`,
        ALFA: () => 'Lesuren alfa',
        OTHER: () => 'Overige uren',
        OTHER_OF_WHICH_ONA_SHORT: (count: any) => `/ ${count} ONA uren`,
        EXTERNAL: () => 'Extern',
        ONA: () => 'ONA praktijkuren',
        E_LEARNING: () => 'E-learning',
    },
    passwordStrength: {
        0: () => 'Te zwak',
        1: () => 'Te zwak',
        2: () => 'Te zwak',
        3: () => 'Te zwak',
        4: () => 'Sterk',
    },
    passwordWarnings: {
        'Straight rows of keys are easy to guess': () => 'Let op! Een reeks van karakters is makkelijk te gokken.',
        'Short keyboard patterns are easy to guess': () =>
            'Let op! Een korte reeks van toesten combinaties is makkelijk te gokken.',
        'Repeats like aaa are easy to guess': () => "Let op! Een patroon zoals 'aaa' is makkelijk te gokken.",
        'Repeats like abcabcabc are only slightly harder to guess than abc': () =>
            "Let op! Een patroon zoals 'abcabcabc' is niet veel moeilijker te gokken dan 'abc'.",
        'Sequences like abc or 6543 are easy to guess': () =>
            "Let op! Een reeks zoals 'abc' of '6543' is makkelijk te gokken.",
        'Recent years are easy to guess': () => 'Let op! Recente jaren zijn makkelijk te gokken.',
        'Dates are often easy to guess': () => 'Let op! Data zijn vaak makkelijk te gokken.',
        'This is a top-10 common password': () => 'Let op! Dit is een veel voorkomend wachtwoord.',
        'This is a top-100 common password': () => 'Let op! Dit is een veel voorkomend wachtwoord.',
        'This is a very common password': () => 'Let op! Dit is een erg veel voorkomend wachtwoord.',
        'This is similar to a commonly used password': () => 'Dit lijkt op een is een veel voorkomend wachtwoord.',
        'A word by itself is easy to guess': () => 'Let op! Een woord opzichzelf is makkelijk te gokken.',
        'Names and surnames by themselves are easy to guess': () =>
            'Let op! Namen en achternamen zijn makkelijk te gokken.',
        'Common names and surnames are easy to guess': () => 'Let op! Namen en achternamen zijn makkelijk te gokken.',
    },
    inflowModuleType: {
        intake: () => 'Intake',
        levelDetermination: () => 'Toetsing',
    },
    enrollmentType: {
        intake: () => 'Intake',
        assessment: () => 'Toetsing',
    },
    lessonModuleProjectType: {
        Integration: () => 'Inburgering',
        Organization: () => 'Organisatie',
        Other: () => 'Overig',
    },
    lessonModuleIntegrationType: {
        ONA: () => 'ONA',
        Standard: () => 'Standaard',
        Alfa: () => 'Alfabetisering',
        StateExam: () => 'Staatsexamen',
        Participation: () => 'Participatie',
        MAP: () => 'MAP',
        Other: () => 'Overig (geen inburgeringsuren)',
    },
    contractType: {
        Standard: () => 'Inburgering (BOW)',
        Standard150min: () => 'Inburgering (BOW) 2,5 uur',
        Standard180min: () => 'Inburgering (BOW) 3 uur',
        ONA: () => 'ONA (BOW)',
        Alfa: () => 'Alfabetisering (BOW)',
        Alfa150min: () => 'Alfabetisering (BOW) 2,5 uur',
        Alfa180min: () => 'Alfabetisering (BOW) 3 uur',
    },
    projectType: {
        Integration: () => 'Inburgering',
        Organization: () => 'Organisatie',
        Other: () => 'Overig',
    },
    invoiceType: {
        GroupParticipation: () => 'Groepsparticipatie',
        FinalExam: () => 'Examen',
        LessonMaterials: () => 'Lesmaterialen',
    },
    groupParticipationDisenrollmentReason: {
        FinishedSuccesful: () => `Afgerond, succesvol`,
        FinishedUnsuccesful: () => `Afgerond, niet succesvol`,
        EarlyDisenrollmentNotSatisfiedWithTopTaal: () => `Uitval, niet tevreden (TopTaal verwijtbaar)`,
        EarlyDisenrollmentMovedHouse: () => `Uitval, verhuizing`,
        EarlyDisenrollmentWentToOtherSchool: () => `Uitval, naar andere taalaanbieder`,
        EarlyDisenrollmentUnreachable: () => `Uitval, niet meer bereikbaar`,
        EarlyDisenrollmentFoundWork: () => `Uitval, werk gevonden`,
        EarlyDisenrollmentLevelUnsuitable: () => `Uitval, niveau niet passend`,
        EarlyDisenrollmentUnavailable: () => `Uitval, beschikbaarheidsprobleem`,
        EarlyDisenrollmentMedical: () => `Uitval, medische reden`,
        EarlyDisenrollmentMotivation: () => `Uitval, niet (meer) gemotiveerd`,
        EarlyDisenrollmentLeavingEmployer: () => `Uitval, vertrek bij werkgever`,
        EarlyDisenrollmentOtherPersonalReasons: () => `Uitval, overige persoonlijke problemen`,
        ReturnBeforeStart: () => `Retour voor start`,
        ExemptionRoute: () => `Uitval, ontheffingsroute`,
        OnHold: () => 'Uitval, on hold',
        Other: () => 'Uitval, overige',
        AlmostSuccessfull: () => 'Uitval, (bijna) geslaagd',
    },
    organizationVatExempt: {
        No: () => `BTW plichtig (Taal in het Bedrijf BV)`,
        Yes: () => `BTW vrij (TopTaal NT2-experts BV)`,
    },
    projectInvoiceGranularity: {
        Learner: () => `Kandidaatsniveau`,
        Group: () => `Groepsniveau`,
    },
    amountPerInvoiceGranularity: {
        Learner: () => `Bedrag per kandidaat`,
        Group: () => `Bedrag per groep`,
    },
    projectInvoicingSettingsLessonAttendanceType: {
        Any: () => `Alle lessen`,
        Present: () => `Alleen aanwezige lessen`,
        PresentAndAbsent: () => `Aanwezige lessen + afwezig met reden`,
    },
    projectInvoicingVariableBy: {
        Hour: () => `Gebaseerd op lesuren`,
        Learner: () => `Gebaseerd op groepsgrootte`,
    },
    projectPeriodicalTimingPeriod: {
        Weekly: () => `Elke week`,
        Monthly: () => `Elke maand`,
        Quarterly: () => `Elk kwartaal`,
    },
    projectChargeForLessonReasonOfAbsence: {
        SICK: lessonReasonOfAbsence.SICK,
        WORK_STUDY: lessonReasonOfAbsence.WORK_STUDY,
        VACATION: lessonReasonOfAbsence.VACATION,
        PRIVATE_REASONS: lessonReasonOfAbsence.PRIVATE_REASONS,
        TRANSPORT_ISSUES: lessonReasonOfAbsence.TRANSPORT_ISSUES,
        EXAM: lessonReasonOfAbsence.EXAM,
        OTHER_INTEGRATION_ACTIVITY: lessonReasonOfAbsence.OTHER_INTEGRATION_ACTIVITY,
        OTHER: () => 'Andere reden',
        NONE: () => 'Geen reden',
    },
    projectInvoiceTiming: {
        Periodical: () => 'Periodiek',
        Variable: () => 'Variabel',
    },
    projectChargeForLessonsType: {
        AllLessons: () =>
            'Factuurmomenten zijn voor alle kandidaten hetzelfde. Bij latere inschrijving worden eerdere factuurmomenten alsnog in rekening gebracht.',
        OnlyEnrolledLessons: () =>
            'Factuurmomenten worden procentueel verdeeld over de ingeschreven periode van de kandidaat. Bij latere inschrijving wordt de verdeling berekend.',
    },
    projectPeriodCondition: {
        ReceivedConfirmationOfMunicipality: () => `Eindbevestiging ontvangen van gemeente`,
        AttendancePercentage: () => `Percentage present`,
        ParticipationInExam: () => `Deelname examen`,
        PassedExam: () => `Examen behaald`,
        DateOfDisenrollment: () => `Moment van uitschrijven`,
        Other: () => `Anders, namelijk...`,
    },
    paymentBy: {
        Organization: () => 'Organisatie',
        Private: () => 'Particulier',
        SelfReporter: () => 'Zelfmelder',
        FamilyMigrant: () => 'Gezinsmigrant',
    },
    integrationLawType: {
        law2013: () => '2013',
        law2021: () => '2021',
    },
    typeOfLearner: {
        Integration2021: () => 'Inburgering WI2021',
        Integration2013: () => 'Inburgering WI2013',
        WEBNT1: () => 'WEB NT1',
        WEBNT2: () => 'WEB NT2',
        Other: () => 'Overig',
    },
    learningRoute: {
        Z: () => 'Z-route',
        B1: () => 'B1-route',
        B1Intensive: () => 'B1-route intensief',
        Education: () => 'Onderwijs-route (MBO-entree)',
    },
    targetLevelPIP: {
        A2: () => 'A2',
        B1: () => 'B1',
        B2: () => 'B2',
    },
    sharePanteia: {
        Ja: () => 'Ja',
        Nee: () => 'Nee',
        Onbekend: () => 'Onbekend',
        Unset: () => '',
    },
    exportType: {
        EnrollmentCitizenship: () => 'Examenkandidaten inburgering voor TVO',
        OutflowCitizenship: () => 'Uitstroom inburgering voor Panteia',
        TeachersCitizenship: () => 'Docenten inburgering voor Blik op Werk',
        FinalexamAttempt: () => 'Examenpogingen inburgering voor Blik op Werk',
        DUOLearnerReport: () => 'Inschrijvingen inburgering voor DUO/Blik op Werk',
        DUOBOWContractEnrollment: () => 'Aanleverbestand inschrijvingsgegevens voor DUO',
        DUOParticipatedHours: () => 'Aanleverbestand DUO: Deelgenomen uren',
        ConceptGroupCandidates: () => 'Kandidaten in conceptgroepen',
    },
}
