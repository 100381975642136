// tslint:disable:max-line-length
import * as React from 'react'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, Field, TextArea } from '~/components'
import gql from 'graphql-tag'
import { Mutator } from '~/utils'

const MUTATION = gql`
    mutation DUOParticipatedHours_generate($bsnInput: String) {
        exports_participated_hours_duo(bsnInput: $bsnInput) {
            _id
            file {
                _id
                fileName
            }
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: (fileId: string, filename: string) => void
}

export class DUOParticipatedHoursExportForm extends React.Component<Props> {
    private mutator: Mutator

    constructor(props: Props) {
        super(props)

        this.mutator = new Mutator({
            mutation: MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { loading, errors } = this.mutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style="modal">
                    <FieldGroup isForm={true}>
                        <Field title="BSN's" errors={errors}>
                            <TextArea name="bsnInput" placeholder="(komma- of entergescheiden)" />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type="button" onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button styleOverride="edit" type="submit" isLoading={loading}>
                                    Genereer export
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess } = this.props

        const result = await this.mutator.mutate({ bsnInput: fields.bsnInput })

        if (result && result.exports_participated_hours_duo) {
            const { _id, fileName } = result.exports_participated_hours_duo.file
            onSubmitSuccess(_id, fileName)
        }
    }
}
