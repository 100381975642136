import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, CheckBox, Field, FieldCollection, Form, Input, MultiInput } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import apolloClient from '~/services/apolloClient'
import { Mutator, parseGraphQLError, toast } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class EditView extends Component {
    static propTypes = {
        instructionVideo: PropTypes.object,
        params: PropTypes.object,
        refetch: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.instructionVideoMutator = new Mutator({
            mutation: EDIT_INSTRUCTION_VIDEO_MUTATION,
            reactComponentToUpdate: this,
        })

        this.state = {
            targetRoles: get(this.props, 'instructionVideo.targetRoles') || [],
        }
    }

    checkRoles = event => {
        const {
            target: { value, checked },
        } = event
        const { targetRoles } = this.state

        // Add to array of targetRoles
        if (checked && !targetRoles.includes(value)) {
            this.setState({
                targetRoles: [...targetRoles, value],
            })
        }

        // Remove from array of targetRoles
        if (!checked && targetRoles.includes(value)) {
            this.setState({
                targetRoles: [...targetRoles.splice(targetRoles.indexOf(value), 1)],
            })
        }
    }

    onSubmit = async (event, fields) => {
        const { targetRoles } = this.state
        const { params, refetch } = this.props

        const data = await this.instructionVideoMutator.mutate({
            instructionVideo: {
                _id: params.id,
                ...transformFormFields(fields.instructionVideo, {}),
                targetRoles,
            },
        })

        if (data) {
            refetch()
            browserHistory.push(`/properties/instructionvideos/${params.id}`)
        }
    }

    onDelete = async () => {
        const { params } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_INSTRUCTION_VIDEO_MUTATION,
                variables: {
                    _id: params.id,
                },
            })

            browserHistory.push(`/properties/instructionvideos`)
        } catch (err) {
            const { message } = parseGraphQLError(err, { namespace: 'instructionVideos_delete' })
            toast.error(`${message}`)
        }
    }

    isChecked(role) {
        const { targetRoles } = this.state

        return targetRoles.includes(role)
    }

    render() {
        const { instructionVideo } = this.props
        const { errors, loading } = this.instructionVideoMutator

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup>
                                <Field isLabel title={'Naam'} errors={errors}>
                                    <Input
                                        name={`instructionVideo.name`}
                                        type={'text'}
                                        placeholder={`Titel van de instructievideo`}
                                        defaultValue={get(instructionVideo, 'name')}
                                    />
                                </Field>
                                <Field isLabel title={`Doelgroep`} errors={errors}>
                                    <MultiInput type={`checkbox`}>
                                        <CheckBox
                                            name={`targetRoles`}
                                            value={`EMPLOYEE`}
                                            onChange={this.checkRoles}
                                            checked={this.isChecked('EMPLOYEE')}
                                        >
                                            Medewerker
                                        </CheckBox>
                                        <CheckBox
                                            name={`targetRoles`}
                                            value={`TEACHER`}
                                            onChange={this.checkRoles}
                                            checked={this.isChecked('TEACHER')}
                                        >
                                            Externe docent
                                        </CheckBox>
                                        <CheckBox
                                            name={`targetRoles`}
                                            value={`ORGANIZATION_CONTACT`}
                                            onChange={this.checkRoles}
                                            checked={this.isChecked('ORGANIZATION_CONTACT')}
                                        >
                                            Organisatie
                                        </CheckBox>
                                    </MultiInput>
                                </Field>
                                <Field isLabel title={`YouTube URL`} errors={errors}>
                                    <Input
                                        name={'instructionVideo.url'}
                                        type={`text`}
                                        placeholder={`Link naar de instructievideo`}
                                        defaultValue={get(instructionVideo, 'url')}
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(
                                                    `/properties/instructionvideos/${instructionVideo._id}`
                                                )
                                            }
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }
}

const EDIT_INSTRUCTION_VIDEO_MUTATION = gql`
    mutation _($instructionVideo: InstructionVideoInputType!) {
        instructionVideos_edit(instructionVideo: $instructionVideo) {
            _id
        }
    }
`

const DELETE_INSTRUCTION_VIDEO_MUTATION = gql`
    mutation _($_id: MongoID!) {
        instructionVideos_delete(instructionVideoId: $_id) {
            _id
        }
    }
`
