import ApolloClient from 'apollo-client'
import url from 'url'

import env from '~/services/env'

import createNetworkInterface from './createNetworkInterface'
import { getSession, renewIfExpirationDateIsSoon } from './session'

const networkInterface = createNetworkInterface({
    uri: url.resolve(env.serverBase, '/api/graphql'),
})

networkInterface.use([
    {
        async applyMiddleware(req, next) {
            if (!req.options.headers) {
                req.options.headers = {}
            }

            const session = await getSession()
            if (session) {
                const headersInit: HeadersInit = {}
                req.options.headers = headersInit
                req.options.headers.Authorization = `Bearer ${session.token}`
                await renewIfExpirationDateIsSoon()
            }

            next()
        },
    },
])

export default new ApolloClient({
    addTypename: true,
    networkInterface,
})
