import gql from 'graphql-tag'

export const AddressFieldsFragment = gql`
    fragment AddressFieldsFragment on AddressType {
        _id
        nl {
            number
            numberAddition
            zipcode
            extraInfo {
                street
                city
                municipality
            }
            manualExtraInfo {
                street
                city
                municipality
            }
            validatedExtraInfo {
                street
                city
                municipality
            }
        }
        other {
            addressLine1
            addressLine2
        }
        country
        phoneNumbers {
            _id
            country
            phoneNumber
            info
            internationalPhoneNumber
        }
    }
`
