import React, { Component } from 'react'
import gql from 'graphql-tag'
import { get } from 'lodash/object'

import { Fetcher } from '~/utils'
import { translateType } from '~/shared/utils'
import {
    ActionBar,
    Button,
    Field,
    FieldCollection,
    Header,
    Icon,
    ReadablePhoneNumbers,
    Spinner,
    Subtle,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Wrap } from '~/components/Wrap'
import { OrganizationContactAgreementModal } from '~/modals'

export default class ExternalOrganizationContactProfile extends Component {
    constructor(props) {
        super(props)

        this.currentUserFetcher = new Fetcher({
            query: GET_CURRENT_USER_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    render() {
        const { ...routeProps } = this.props
        const { data, loading } = this.currentUserFetcher
        const { user } = data

        return (
            <RouteView
                crumbLabel={user && `Welkom, ${get(user.profile, 'name')}`}
                routeProps={routeProps}
                isLoading={loading}
            >
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap>
                        {loading && <Spinner />}
                        {!loading && !user && <Subtle>Gebruiker niet gevonden</Subtle>}
                        {!loading && user && this.renderPage(user)}
                    </Wrap>
                </ContentView>
            </RouteView>
        )
    }

    renderPage(user) {
        const phoneNumbers = get(user, `profile.phoneNumbers`) || []

        return (
            <Wrap>
                <FieldCollection>
                    <FieldGroup title={`Persoonlijke gegevens`}>
                        <Field title={`Naam`}>{user.profile.name}</Field>
                        <Field title={`Geslacht`}>{translateType('userGender', user.profile.gender)}</Field>
                    </FieldGroup>
                    <FieldGroup title={`Contactgegevens`}>
                        <Field title={`Telefoonnummer`}>
                            <ReadablePhoneNumbers phoneNumbers={phoneNumbers} />
                        </Field>
                        <Field title={`Primair e-mailadres`}>{user.email}</Field>
                        <Field title={`Secundair e-mailadres`}>{user.profile.alternativeEmail}</Field>
                    </FieldGroup>
                    <FieldGroup title={`Organisatie`}>
                        <Field title="Naam">{get(user, 'organizationContact.organization.name')}</Field>
                    </FieldGroup>
                </FieldCollection>
                <ActionBar
                    fixed
                    getButtons={() => {
                        return (
                            <List horizontal>
                                <ListItem left>
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal} leftIcon={<Icon name={`download`} />}>
                                                Algemene voorwaarden
                                            </Button>
                                        )}
                                        getModal={closeModal => {
                                            return <OrganizationContactAgreementModal onClose={closeModal} />
                                        }}
                                    />
                                </ListItem>
                            </List>
                        )
                    }}
                />
            </Wrap>
        )
    }
}

const GET_CURRENT_USER_QUERY = gql`
    query _ {
        user {
            _id
            email
            roles(filterMutable: true)
            invitedAt
            lastActivityAt
            profile {
                firstName
                initials
                name
                surNamePrefix
                surName
                gender
                phoneNumbers {
                    _id
                    country
                    phoneNumber
                    internationalPhoneNumber
                    info
                }
                alternativeEmail
                notes
            }
            organizationContact {
                organization {
                    _id
                    name
                }
            }
        }
    }
`
