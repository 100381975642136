/**
 * From PR: https://github.com/HriBB/apollo-upload-network-interface/pull/15
 */

import { printAST, HTTPFetchNetworkInterface } from 'apollo-client'
import objectPath from 'object-path'
import * as uuid from 'uuid'

const RecursiveIterator = require('recursive-iterator')

export default function createNetworkInterface(opts: any) {
    const { uri } = opts
    return new UploadNetworkInterface(uri, opts)
}

export class UploadNetworkInterface extends HTTPFetchNetworkInterface {
    constructor(...args: [string, any]) {
        super(...args)
        /**
         * Save the original fetchFromRemoteEndpoint method
         */
        const originalFetchFromRemoteEndpoint = this.fetchFromRemoteEndpoint.bind(this)

        /**
         * Patch the fetchFromRemoteEndpoint method
         */
        this.fetchFromRemoteEndpoint = ({ request, options }) => {
            const formData = new FormData()

            /**
             * Recursively search for File objects in
             * he request and set it as formData
             */
            let hasFile = false
            for (const { node, path } of new RecursiveIterator(request.variables)) {
                if (node instanceof File) {
                    hasFile = true

                    const id = uuid.v4()
                    formData.append(id, node)
                    objectPath.set(request.variables as any, path.join('.'), id)
                }
            }

            if (hasFile) {
                /**
                 * One or more Files are found, use the special fetcher
                 */
                return this.uploadFetchFromRemoteEndpoint({ request, options }, formData)
            } else {
                /**
                 * No File is found, use the normal way
                 */
                return originalFetchFromRemoteEndpoint({ request, options })
            }
        }
    }

    /**
     * Alternative to `uploadFetchFromRemoteEndpoint`
     * to support FormData
     *
     * @param {Object} requestParams
     * @param {Object} requestParams.request The request
     * @param {Object} requestParams.options The request-options
     * @param {FormData} formData Containing the file(s)
     */
    uploadFetchFromRemoteEndpoint(args: any, formData: any) {
        formData.append('operationName', args.request.operationName)
        formData.append('query', printAST(args.request.query))
        formData.append('variables', JSON.stringify(args.request.variables || {}))

        return fetch(this._uri, {
            ...args.options,
            body: formData,
            method: 'POST',
            headers: {
                Accept: '*/*',
                ...args.options.headers,
            },
        })
    }
}
