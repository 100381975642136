import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    Bold,
    Button,
    DrawerHeader,
    Form,
    Link,
    MultiInput,
    RadioButton,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { ContentView } from '~/components/ContentView'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { Fetcher } from '~/utils'
import { GroupUserRemovedReason } from '~/types/User'
// import { Fetcher, Mutator, transformFormFields } from '~/utils'

export default class EndGroupForm extends Component {
    static propTypes = {
        groupId: PropTypes.string.isRequired,
        onSubmitSuccess: PropTypes.func,
        onFormCancel: PropTypes.func,
    }

    static defaultProps = {
        onSubmitSuccess: () => {},
        onFormCancel: () => {},
    }

    constructor(props) {
        super(props)

        bindAll(this, ['endGroup'])

        this.state = {
            disabledFieldsByUserId: [],
        }

        this.groupFetcher = new Fetcher({
            query: GROUP_QUERY,
            variables: {
                filters: {
                    filterById: props.groupId,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.getModulesFetcher = new Fetcher({
            query: GET_MODULES_QUERY,

            onChange: () => this.forceUpdate(),
        })

        // this.endGroupMutator = new Mutator({
        //     mutation: END_GROUP_MUTATION,
        //     reactComponentToUpdate: this,
        // })
    }

    // async endGroup(fields) {
    //     const { groupId } = this.props

    //     const userIds = get(fields, 'removedGroupUsers') || []

    //     const removedGroupUsers = Object.keys(userIds).map(userId => transformFormFields({
    //         userId: userId,
    //         role: 'LEARNER',
    //         status: userIds[userId].status,
    //         adviceModuleId: userIds[userId].adviceModuleId,
    //         removedReason: userIds[userId].removedReason,
    //         hasFinishedGroup: true,
    //     }))

    //     const result = await this.endGroupMutator.mutate({
    //         groupId,
    //         removedGroupUsers,
    //     })

    //     if (result) {
    //         this.props.onSubmitSuccess()
    //     }
    // }

    render() {
        const { data, loading } = this.groupFetcher
        const { onFormCancel } = this.props
        const group = get(data, 'groups[0]')
        const learnerUsers = get(group, 'learnerUsers') || []
        const { isSaving } = this.endGroupMutator

        // Store the form reference so we can pass the fields
        // to the endGroup method.
        let formRef

        return (
            <View>
                <Form onSubmit={(event, fields) => this.endGroup(fields)} ref={ref => (formRef = ref)}>
                    <DrawerHeader hasCloseButton={false} title="Groep afronden (ONDER CONSTRUCTIE)">
                        {' '}
                        {/* TODO_GU_FRONTEND */}
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onFormCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    isLoading={isSaving}
                                    shouldPreventSubmit
                                    onClick={() => this.endGroup(formRef.getFormValues())}
                                    confirm={{
                                        title: 'Afronden',
                                        message: (
                                            <span>
                                                Je staat op het punt om de groep
                                                <Bold>{get(group, 'name')}</Bold>
                                                af te ronden. De groep zal niet meer kunnen worden aangepast.
                                            </span>
                                        ),
                                        execute: {
                                            buttonType: 'submit',
                                            title: 'Afronden',
                                        },
                                    }}
                                >
                                    Afronden
                                </Button>
                            </ListItem>
                        </List>
                    </DrawerHeader>
                    <ContentView>
                        <TableWrap containsDropdowns>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem>Name</TableHeaderItem>
                                    <TableHeaderItem>Afgerond</TableHeaderItem>
                                    <TableHeaderItem>Status</TableHeaderItem>
                                    <TableHeaderItem>Advies-lesmodule</TableHeaderItem>
                                </TableHeader>
                                {loading && this.renderLoadingStateRow()}
                                {!loading && learnerUsers.length === 0 && this.renderEmptyStateRow()}
                                {learnerUsers.length > 0 && this.renderRows(learnerUsers)}
                            </Table>
                        </TableWrap>
                    </ContentView>
                </Form>
            </View>
        )
    }

    renderLoadingStateRow() {
        return (
            <TableRow>
                <TableCell colSpan={3} isLoading />
            </TableRow>
        )
    }

    renderEmptyStateRow() {
        return (
            <TableRow>
                <TableCell colSpan={3}>
                    <Subtle>Er zijn geen kandidaten gevonden.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    disableAdviceModuleFieldForUser(learnerUser) {
        this.setState({
            disabledFieldsByUserId: [...this.state.disabledFieldsByUserId, learnerUser._id],
        })
    }

    enableAdviceModuleFieldForUser(learnerUser) {
        this.setState({
            disabledFieldsByUserId: [...this.state.disabledFieldsByUserId.filter(_id => _id !== learnerUser._id)],
        })
    }

    renderRows(learnerUsers) {
        const { data } = this.getModulesFetcher
        const modules = get(data, 'modules') || []

        return learnerUsers.map(learnerUser => {
            const isAdviceModuleDisabled = this.state.disabledFieldsByUserId.includes(learnerUser._id)

            return (
                <TableRow key={learnerUser._id}>
                    <TableCell>
                        <Link route={`users/learners/${get(learnerUser, 'user._id')}`} target="_blank">
                            {get(learnerUser, 'user.profile.name')}
                        </Link>
                    </TableCell>
                    <TableCell hasMultiInput>
                        <MultiInput type="radio">
                            <RadioButton
                                name={`removedGroupUsers[${learnerUser.user._id}].removedReason`}
                                defaultChecked={true}
                                value={GroupUserRemovedReason.FinishedSuccesful}
                            >
                                Succesvol
                            </RadioButton>
                            <RadioButton
                                name={`removedGroupUsers[${learnerUser.user._id}].removedReason`}
                                value={GroupUserRemovedReason.FinishedUnsuccesful}
                            >
                                Niet succesvol
                            </RadioButton>
                        </MultiInput>
                    </TableCell>
                    <TableCell hasMultiInput>
                        <MultiInput type="radio">
                            <RadioButton
                                name={`removedGroupUsers[${learnerUser.user._id}].status`}
                                onClick={() => this.disableAdviceModuleFieldForUser(learnerUser)}
                                value={'INACTIVE'}
                            >
                                Non-actief
                            </RadioButton>
                            <RadioButton
                                name={`removedGroupUsers[${learnerUser.user._id}].status`}
                                defaultChecked={true}
                                onClick={() => this.enableAdviceModuleFieldForUser(learnerUser)}
                                value={'ADVANCE'}
                            >
                                Actief
                            </RadioButton>
                        </MultiInput>
                    </TableCell>
                    <TableCell hasOverflow>
                        <TagPicker
                            multi={false}
                            options={modules.map(m => ({ value: m._id, label: m.name }))}
                            placeholder={`Selecteer een lesmodule`}
                            isReadonly={isAdviceModuleDisabled}
                            name={`removedGroupUsers[${learnerUser.user._id}].adviceModuleId`}
                            placeholder={`Advies-lesmodule`}
                        />
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const GET_MODULES_QUERY = gql`
    query _ {
        modules(sortBy: "name", filterActive: true) {
            _id
            name
        }
    }
`

// const END_GROUP_MUTATION = gql`
//     mutation _(
//         $groupId: MongoID!
//         $removedGroupUsers: [RemovedGroupUserInputType!]
//     ) {
//         groups_end (
//             groupId: $groupId
//             removedGroupUsers: $removedGroupUsers
//         ) {
//             _id
//         }
//     }
// `

const GROUP_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            name
            learnerUsers(filterRemoved: false) {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`
