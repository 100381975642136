import * as React from 'react'
import { ExpansionContentActions } from '~/components/Table/TableRow/ExpansionContentActions/ExpansionContentActions'
import { ModalManager } from '~/components/ModalManager'
import { Button } from '~/components/buttons/Button/Button'
import CenterModal from '~/components/CenterModal'
import { Contract } from '~/types/Contracts'
import { LearnerEndContractForm } from '~/components/users/Learner/LearnerEndContractForm'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import Link from '~/components/Link'
import { PdfModal } from '~/components/PdfModal'
import { LearnerContractsLogList } from '~/components/users/Learner/LearnerContractsLogList'
import { ContractSignatureModal } from '~/components/Contracts/ContractSignatureModal'
import gql from 'graphql-tag'
import { Mutator } from '~/utils'
const moment = require('moment')
import { MakeFinalIcon, SignatureIcon } from '~/components/Contracts/ContractStatus/icons'

interface Props {
    userContract: Contract
    refetch?: () => void
}

const CONTRACT_MAKE_FINAL_MUTATION = gql`
    mutation _($contractId: MongoID!) {
        contracts_makeFinal(contractId: $contractId) {
            _id
        }
    }
`

export class LearnerContractsExpansionContent extends React.Component<Props> {
    private contractMakeFinalMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.contractMakeFinalMutator = new Mutator({
            mutation: CONTRACT_MAKE_FINAL_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { userContract, refetch } = this.props
        const learnerContractFileName = userContract.contract.fileName

        const hasContractEnded = !!userContract.terminatedAt || moment().isAfter(moment(userContract.dateTo))
        const { logs, isSigned } = userContract
        const { loading: isMakingFinal } = this.contractMakeFinalMutator

        return (
            <div>
                <SimpleTable>
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Statushistorie</SimpleTableCell>
                        <SimpleTableCell>
                            {logs && logs.length > 0 && <LearnerContractsLogList contract={userContract} logs={logs} />}
                        </SimpleTableCell>
                    </SimpleTableRow>
                    {userContract.terminatedReason && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Reden beëindiging</SimpleTableCell>
                            <SimpleTableCell>{userContract.terminatedReason}</SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Handtekening</SimpleTableCell>
                        <SimpleTableCell>
                            {userContract.isSigned ? (
                                <ModalManager
                                    render={openModal => <Link onClick={openModal}>{learnerContractFileName}</Link>}
                                    getModal={closeModal => (
                                        <PdfModal
                                            title={userContract.signedContract.fileName}
                                            fileName={userContract.signedContract.fileName}
                                            getFileId={() => userContract.signedContract.fileId}
                                            onClose={closeModal}
                                        />
                                    )}
                                />
                            ) : (
                                '-'
                            )}
                        </SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>

                <ExpansionContentActions>
                    {!hasContractEnded && isSigned && (
                        <ModalManager
                            render={openModal => (
                                <Button onClick={openModal} small={true}>
                                    Contract beëindigen
                                </Button>
                            )}
                            getModal={closeModal => (
                                <CenterModal onClose={closeModal} title={`Contract beëindigen`}>
                                    <LearnerEndContractForm
                                        onClose={() => this.OnSubmitSuccess(closeModal)}
                                        userContract={userContract}
                                    />
                                </CenterModal>
                            )}
                        />
                    )}
                    {!hasContractEnded && !userContract.isFinal && (
                        <Button
                            type={`button`}
                            isLoading={isMakingFinal}
                            shouldPreventSubmit
                            small={true}
                            leftIcon={<MakeFinalIcon />}
                            onClick={() => {
                                this.makeFinal()
                            }}
                            confirm={{
                                title: 'Contract definitief maken',
                                message: 'Weet je zeker dat je dit contract definitief wilt maken?',
                                execute: {
                                    buttonType: 'submit',
                                    title: 'Contract definitief maken',
                                },
                            }}
                        >
                            Definitief maken
                        </Button>
                    )}
                    {!hasContractEnded && userContract.isFinal && (
                        <ContractSignatureModal
                            render={openModal => {
                                return (
                                    <Button onClick={openModal} small={true} leftIcon={<SignatureIcon />}>
                                        Handtekening {userContract.isSigned ? 'wijzigen' : 'uploaden'}
                                    </Button>
                                )
                            }}
                            contract={userContract}
                            onSubmitSuccess={refetch}
                        />
                    )}
                </ExpansionContentActions>
            </div>
        )
    }

    private OnSubmitSuccess = (closeModal: () => void) => {
        closeModal()
        const { refetch } = this.props

        if (refetch) {
            refetch()
        }
    }

    private makeFinal = async () => {
        const { refetch, userContract } = this.props

        const res = await this.contractMakeFinalMutator.mutate({
            contractId: userContract._id,
        })

        if (res.data && refetch) {
            refetch()
        }
    }
}
