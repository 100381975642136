import gql from 'graphql-tag'

export const INVOICES_QUERY = gql`
    query _($skip: Int, $take: Int, $sortBy: String, $sortDir: String, $filters: InvoicesFilterInputType) {
        paginatedInvoices(skip: $skip, take: $take, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            totalCount
            hasNextPage
            nodes {
                ...invoice
                notes
                isGroupParticipationInvoice
                isFinalExamInvoice
                isCredit
                installments
                debitInvoice {
                    _id
                    invoiceNumber
                    amount
                    isGroupParticipationInvoice
                    isFinalExamInvoice
                    group {
                        _id
                        name
                    }
                }
                descendantInstallmentInvoices {
                    ...invoice
                    descendantInstallmentInvoiceHasFailedToSend
                }
                user {
                    _id
                    profile {
                        name
                    }
                }
                group {
                    _id
                    name
                    module {
                        _id
                    }
                }
            }
        }
    }

    fragment invoice on InvoiceType {
        _id
        invoiceNumber
        isForDUO
        amount
        hasMultipleInstallments
        hasSignatureForDUO
        installmentIndex
        invoiceDate
        expirationDate
        status
        openStatus
        paymentStatus
        isDescendantInstallment
        isFullyCredited
        isDownloadable
        invoiceSentAt
        isExpired
        daysExpired
    }
`
