import { find, get } from 'lodash'
import * as React from 'react'
import { browserHistory } from 'react-router'
import { Brand, Button, CenterModal, Field, FieldCollection, Form, Input, Link, Stretch } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { View } from '~/components/View'
import historyStore from '~/services/historyStore'
import { getTranslateComponent, Translator } from '~/services/i18n'
import { fetchCurrentUser, logIn, isLoggedIn, getCurrentUser } from '~/services/session'
import { Mutator, parseGraphQLError, toast } from '~/utils'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { User } from '~/types/User'
import { Amplify } from 'aws-amplify'
import { AmplifyAuthenticator, AmplifyAuthContainer, AmplifySignIn } from '@aws-amplify/ui-react'
import awsconfig from '../../../aws-exports'
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components'
import { I18n } from 'aws-amplify'
import gql from 'graphql-tag'
import { ModalManager } from '~/components/ModalManager'
import TotpOnboardingModal from '~/modals/TotpOnboardingModal'

const authScreenLabels = {
    en: {
        'Reset password': 'Wachtwoord vergeten?',
        // This is empty because we want to hide the button
        'Forgot your password?': ' ',
        'Incorrect username or password.': 'Foute gebruikersnaam of wachtwoord.',
        'Reset your password': 'Wachtwoord vergeten',
        'Username *': 'Email *',
        'Enter your username': 'voorbeeld@domein.nl',
        'Back to Sign In': 'Terug naar inloggen',
        'Send Code': 'Stuur e-mail',
        'Enter code': 'Voer code in',
        'Confirm TOTP Code': 'Verificatiecode van Google Authenticator invoeren',
        'TOTP needs to be configured': 'TOTP moet worden geconfigureerd',
        'Enter Security Code:': 'Voer beveiligingscode in:',
        'Scan then enter verification code': 'Scan en voer de verificatiecode in',
        'TOTP Setup has failed': 'TOTP Setup is mislukt',
        'Verify Security Token': 'Beveiligingstoken verifiëren',
        'Setup TOTP successfully!': 'TOTP succesvol instellen!',
        'Failed! Unable to configure MFA at this time': 'Mislukt! Kan MFA op dit moment niet configureren',
        'Verification code': 'Verificatiecode',
        'User is disabled.': 'Gebruiker is gedeactiveerd',
        // this is the name showing inside Google authenticator app
        AWSCognito: 'Toptaal',
        Confirm: 'Bevestigen',
        'Confirm SMS Code': 'Bevestig sms-code',
        'New password': 'Nieuw wachtwoord',
        'Enter your new password': 'Typ uw nieuwe wachtwoord',
    },
}

I18n.setLanguage('en')
I18n.putVocabularies(authScreenLabels)

Amplify.configure(awsconfig)

const T = getTranslateComponent({ namespace: 'Cover.Login' })

interface Props {}

interface State {
    user?: User
    errors?: GraphQLErrors
    loading: boolean
    authState: AuthState
    authData: any
    cognitoMobile: string
    cognitoUserId: string
}

const EDIT_USER_MFA_MUTATION = gql`
    mutation _($userId: MongoID!, $cognitoUserId: String!, $cognitoMobileNumber: String!) {
        users_setMfaFields(userId: $userId, cognitoUserId: $cognitoUserId, cognitoMobileNumber: $cognitoMobileNumber) {
            _id
        }
    }
`

export default class LoginView extends React.Component<Props, State> {
    public state: State = {
        user: undefined,
        errors: new GraphQLErrors(),
        loading: false,
        authState: AuthState.SignIn,
        authData: {},
        cognitoMobile: '',
        cognitoUserId: '',
    }

    private translator: Translator
    private genericErrorFields: any
    private editUserMfaMutator: Mutator
    private inputRef = React.createRef<ModalManager>()

    constructor(props: Props) {
        super(props)

        this.translator = new Translator({
            namespace: 'Cover.Login',
            subscribe: this,
        })

        this.editUserMfaMutator = new Mutator({
            mutation: EDIT_USER_MFA_MUTATION,
            reactComponentToUpdate: this,
        })

        fetchCurrentUser({
            preventRedirectWhenUnauthenticated: true,
        }).then(user => {
            this.setState({ user })
        })
    }

    public componentDidMount() {
        this.redirectWhenLoggedIn()
        onAuthUIStateChange((nextAuthState, authData) => {
            if (authData && nextAuthState == 'signedin') {
                Amplify.Auth.currentSession().then((data: { accessToken: any }) => {
                    const accessToken = data.accessToken.jwtToken
                    Amplify.Auth.currentAuthenticatedUser().then(async (data: any) => {
                        const userEmail = data.attributes.email
                        const phone_number = data.attributes.phone_number ? data.attributes.phone_number : ''
                        this.setState({ cognitoMobile: phone_number })
                        this.setState({ cognitoUserId: data.attributes.sub })
                        await this.createSessionWithCognitoToken(userEmail, accessToken)
                    })
                })
            }
            if (nextAuthState === 'TOTPSetup') {
                if (this.inputRef.current !== null) {
                    this.inputRef.current.openModal()
                }
            }
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { errors, loading, authState } = this.state
        const { t } = this.translator
        return (
            <View>
                <Brand>
                    <T i18n={`brand`} />
                </Brand>
                <div
                    style={{
                        height: 500,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                    }}
                >
                    <AmplifyAuthContainer>
                        <AmplifyAuthenticator>
                            <AmplifySignIn
                                submitButtonText="Naar code invoeren"
                                usernameAlias="email"
                                formFields={[
                                    {
                                        type: 'email',
                                        label: 'E-mailadres',
                                        placeholder: 'voorbeeld@domein.nl',
                                        required: true,
                                    },
                                    {
                                        type: 'password',
                                        label: 'Wachtwoord',
                                        placeholder: 'Minimaal 6 karakters',
                                        required: true,
                                    },
                                ]}
                                headerText=""
                                slot="sign-in"
                                hideSignUp
                            >
                                <div slot="federated-buttons"></div>
                            </AmplifySignIn>
                        </AmplifyAuthenticator>
                    </AmplifyAuthContainer>
                </div>
                <ModalManager
                    ref={this.inputRef}
                    render={openModal => (
                        <Stretch>
                            <Link onClick={openModal}>
                                <T i18n="links.totp-help" />
                            </Link>
                        </Stretch>
                    )}
                    getModal={closeModal => {
                        return (
                            <CenterModal onClose={closeModal} title={`Two-Factor Authentication `}>
                                <TotpOnboardingModal></TotpOnboardingModal>
                            </CenterModal>
                        )
                    }}
                />
                {
                    // todo: find a better way to handle hiding the form
                    !authState && (
                        <Form onSubmit={this.onSubmit}>
                            <FieldCollection style={`cover`}>
                                <FieldGroup isForm={true}>
                                    <Field
                                        errors={errors}
                                        isLabel={true}
                                        title={<T i18n="fields.email.label" />}
                                        style={`compact`}
                                        isBold={true}
                                    >
                                        <Input
                                            placeholder={t('fields.email.placeholder')}
                                            name={`email`}
                                            type={`email`}
                                        />
                                    </Field>
                                    <Field
                                        errors={errors}
                                        isLabel={true}
                                        title={<T i18n="fields.password.label" />}
                                        style={`compact`}
                                        isBold={true}
                                    >
                                        <Input
                                            placeholder={t('fields.password.placeholder')}
                                            name={`password`}
                                            type={`password`}
                                        />
                                    </Field>
                                    <Field>
                                        <Stretch>
                                            <Button type={`submit`} styleOverride={`edit`} isLoading={loading}>
                                                <T i18n="buttons.submit" />
                                            </Button>
                                        </Stretch>
                                    </Field>
                                    <Field>
                                        <Stretch>
                                            <Link route={`/forgot`}>
                                                <T i18n="links.forgot" />
                                            </Link>
                                        </Stretch>
                                    </Field>
                                </FieldGroup>
                            </FieldCollection>
                        </Form>
                    )
                }
            </View>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        this.setState({ loading: true })

        try {
            await logIn(fields.email, fields.password)

            this.setState({ loading: false })
            this.redirectWhenLoggedIn()
        } catch (error) {
            this.setState({ loading: false })

            const graphQLError = get(error, 'graphQLErrors[0]')

            if (graphQLError) {
                const namespace = graphQLError.path.join('.')
                const { field, message } = parseGraphQLError(error, { namespace })

                const isGenericError =
                    !field ||
                    find(this.genericErrorFields, f => {
                        return field.indexOf(f) === 0
                    })

                if (field && !isGenericError) {
                    this.setState({
                        errors: new GraphQLErrors([{ field, message }]),
                    })
                } else {
                    toast.error(message)
                }
            } else {
                toast.error(error.message)
            }
        }
    }

    private async createSessionWithCognitoToken(email: string, accessToken: string) {
        this.setState({ loading: true })
        try {
            const loginResult = await logIn(email, accessToken)
            if (loginResult === true) {
                this.setState({ loading: false })
                await this.redirectWhenLoggedIn()
            }
        } catch (error) {
            const graphQLError = get(error, 'graphQLErrors[0]')

            if (graphQLError) {
                const namespace = graphQLError.path.join('.')
                const { field, message } = parseGraphQLError(error, { namespace })

                const isGenericError =
                    !field ||
                    find(this.genericErrorFields, f => {
                        return field.indexOf(f) === 0
                    })

                if (field && !isGenericError) {
                    this.setState({
                        errors: new GraphQLErrors([{ field, message }]),
                    })
                } else {
                    toast.error(message)
                }
            } else {
                toast.error(error.message)
            }
        }
    }

    private async redirectWhenLoggedIn() {
        if ((await isLoggedIn()) === true) {
            const { cognitoUserId, cognitoMobile } = this.state
            await fetchCurrentUser()
            const currentUser = getCurrentUser()
            if (
                currentUser &&
                (currentUser.profile.isMfaConfigured === undefined ||
                    currentUser.profile.isMfaConfigured === false ||
                    currentUser.profile.isMfaConfigured === null)
            ) {
                this.editUserMfaMutator
                    .mutate({
                        userId: currentUser._id,
                        cognitoUserId: cognitoUserId,
                        cognitoMobileNumber: cognitoMobile,
                    })
                    .then(async data => {
                        if (data) {
                            await fetchCurrentUser()
                        }
                    })
            }
            const intent = historyStore.get('intent')

            if (intent) {
                browserHistory.replace(intent)
            } else {
                browserHistory.replace('/')
            }
        }
    }
}
