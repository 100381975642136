import gql from 'graphql-tag'

export const ADDITIONAL_MODULE_EDIT_DATA_QUERY = gql`
    query _ {
        allCourseMaterials: courseMaterials(sortBy: "name") {
            _id
            name
        }

        allLocationProperties: locationProperties(sortBy: "name") {
            _id
            name
        }
    }
`

export const EDIT_MODULE_MUTATION = gql`
    mutation _($module: ModuleInputType!) {
        modules_edit(module: $module) {
            _id
        }
    }
`

export const DELETE_MODULE_MUTATION = gql`
    mutation _($_id: MongoID!) {
        modules_delete(moduleId: $_id) {
            _id
        }
    }
`
