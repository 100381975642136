import { browserHistory } from 'react-router'
import { get } from 'lodash'
import { WatchQueryOptions } from 'apollo-client'

import apolloClient from '~/services/apolloClient'
import { removeSession } from '~/services/session'
import { parseGraphQLError, toast } from '~/utils'

interface FetcherOptions<Data> {
    onChange?: () => void
    onRefetch?: () => void
    transformData?: (data: Data) => any
    preventInitialFetch?: boolean
    preventRedirectWhenUnauthenticated?: boolean
    preventErrorToast?: boolean
    swallowAuthorizationError?: boolean
}

interface FetchOptions<Data> {
    silent?: boolean
    getMergedData?: (moreData?: Data) => Data
    onError?: (error: any) => any
}

interface FetchMoreOptions<Data> {
    getMergedData: (prevData: Data, moreData?: Data) => Data
    onError?: (error: any) => any
}

/**
 * Fetching utility with the ability to re-fetch and fetch more for pagination purposes
 */
export default class Fetcher<TData = any> {
    public hasFetched = false
    public loading = false
    public data: TData = {} as TData
    public error?: any

    private loadingPromises = 0
    private options: FetcherOptions<TData>
    private queryOptions: WatchQueryOptions

    constructor(options: FetcherOptions<TData> & WatchQueryOptions) {
        const {
            onChange,
            onRefetch,
            transformData,
            preventInitialFetch,
            preventRedirectWhenUnauthenticated,
            preventErrorToast,
            swallowAuthorizationError,
            ..._queryOptions
        } = options

        this.options = {
            onChange,
            onRefetch,
            transformData,
            preventInitialFetch,
            preventRedirectWhenUnauthenticated,
            preventErrorToast,
            swallowAuthorizationError,
        }

        this.queryOptions = _queryOptions

        if (!options.preventInitialFetch) {
            this.refetch({ _isInitialCall: false })
        }
    }

    /**
     * Expose fetch for initial fetch (just an alias for refetch)
     *
     * @returns {Promise}
     */
    public fetch = ({ ...args }): Promise<TData | void> => {
        return this.refetch({ ...args })
    }

    /**
     * Performes a refetch of the initial query options (passed at construction-time)
     *
     * @param {Object}  options
     * @param {any}     options.*       - Options overwriting the initial query variables
     * @param {Boolean} options.silent  - When true, onChange will not be fired (default=false)
     *
     * @returns {Promise}
     */
    public refetch = ({ silent = false, _isInitialCall = false, ...overrideVariables } = {}): Promise<TData | void> => {
        if (!silent) {
            this.data = {} as TData
        }

        this.queryOptions.variables = {
            ...(this.queryOptions.variables || {}),
            ...overrideVariables,
        }

        const promise = this._fetch({
            silent: silent || _isInitialCall,
            ...this.queryOptions,
        })

        if (this.options.onRefetch) {
            this.options.onRefetch()
        }

        return promise
    }

    /**
     * Silent refetch with the ability to merge old with new data
     */
    public fetchMore = async (fetchMoreOptions: FetchMoreOptions<TData> & WatchQueryOptions): Promise<TData | void> => {
        const { getMergedData, onError, ...overrideQueryOptions } = fetchMoreOptions

        const queryOptions = {
            ...this.queryOptions,
            ...overrideQueryOptions,
            variables: {
                ...this.queryOptions.variables,
                ...overrideQueryOptions.variables,
            },
        }

        return this._fetch({
            getMergedData: fetchMoreData => {
                return getMergedData(this.data, fetchMoreData)
            },
            silent: true,
            onError,
            ...queryOptions,
        })
    }

    /**
     * Clear the data set
     */
    public clear = () => {
        this.data = {} as TData
        this.error = undefined

        if (this.options.onChange) {
            this.options.onChange()
        }
    }

    private increaseLoaders() {
        this.loadingPromises++
        this.loading = this.loadingPromises > 0
    }

    private decreaseLoaders() {
        this.loadingPromises--
        this.loading = this.loadingPromises > 0
    }

    private async _fetch(options: FetchOptions<TData> & WatchQueryOptions): Promise<TData | void> {
        const { getMergedData, onError, silent, ...queryOptions } = options

        if (!silent) {
            this.increaseLoaders()

            if (this.options.onChange) {
                this.options.onChange()
            }
        }

        const done = () => {
            if (!silent) {
                this.decreaseLoaders()
            }

            if (this.options.onChange) {
                this.options.onChange()
            }
        }

        queryOptions.fetchPolicy = queryOptions.fetchPolicy || 'network-only'

        this.hasFetched = true

        try {
            const res = await apolloClient.query(queryOptions)

            const transformedData = this.options.transformData ? this.options.transformData(res.data as any) : res.data

            this.data = getMergedData ? getMergedData(transformedData) : transformedData

            this.error = undefined
            done()

            return this.data
        } catch (error) {
            this.data = {} as TData

            const graphQLError = get(error, 'graphQLErrors[0]')
            let shouldSwallowError = false

            if (graphQLError) {
                const namespace = (get(graphQLError, 'path') || []).join('.')
                const { message, name } = parseGraphQLError(error, { namespace })

                const isUnauthorizedError = name === 'unauthorized'
                shouldSwallowError = !!this.options.swallowAuthorizationError && isUnauthorizedError

                const isAuthenticatedError = name === 'unauthenticated'
                if (isAuthenticatedError && !this.options.preventRedirectWhenUnauthenticated) {
                    this._onUnAuthenticated()
                } else if (message && !this.options.preventErrorToast && !shouldSwallowError) {
                    toast.error(message)
                }

                if (isAuthenticatedError) {
                    removeSession()
                }
            } else {
                // tslint:disable-next-line:no-console
                console.error(error)
            }

            this.error = error
            done()

            if (onError) {
                onError(error)
            } else if (!shouldSwallowError) {
                throw error
            }
        }
    }

    private _onUnAuthenticated() {
        toast.warning('Je bent automatisch uitgelogd')

        const { pathname } = browserHistory.getCurrentLocation()
        const redirect = '/login'
        if (pathname !== redirect) {
            browserHistory.replace(redirect)
        }
    }
}
