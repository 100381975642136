import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, MultiInput, RadioButton } from '~/components'
import { ContentView } from '~/components/ContentView'
import { ValidationError } from '~/components/ValidationError'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker, TagPickerChangeHandler } from '~/components/TagPicker'
import { Wrap } from '~/components/Wrap'
import { LearnerLevelInput } from '~/components/LearnerLevelInput'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { get } from 'lodash'
import { ADDITIONAL_MODULE_EDIT_DATA_QUERY, EDIT_MODULE_MUTATION, DELETE_MODULE_MUTATION } from './queries'
import { Module, ModuleProjectType, ModuleIntegrationType, CourseMaterial } from '~/types/Module'
import { ModuleExamsField } from '~/components/Module/ModuleExamsField'
import { LocationProperty } from '~/types/Location'
import translateType from '~/shared/utils/translateType'

interface Props {
    module: Module
    params: any
    refetch: () => void
}

interface State {
    isLiteracyCourse: boolean | null
    isIntegrationCourse: boolean | null
    isFinalExamTraining: boolean | null
    isONA: boolean | null
    checkedModuleIntegrationType: ModuleIntegrationType | null
}

export class ModuleEditView extends React.Component<Props, State> {
    public state: State = {
        isLiteracyCourse: null,
        isIntegrationCourse: null,
        isFinalExamTraining: null,
        isONA: null,
        checkedModuleIntegrationType: ModuleIntegrationType.Alfa,
    }

    private editModuleMutator: Mutator
    private deleteModuleMutator: Mutator
    private dataFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.editModuleMutator = new Mutator({
            mutation: EDIT_MODULE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteModuleMutator = new Mutator({
            mutation: DELETE_MODULE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.dataFetcher = new Fetcher({
            query: ADDITIONAL_MODULE_EDIT_DATA_QUERY,

            onChange: () => this.forceUpdate(),
        })

        this.state = {
            isLiteracyCourse: !!this.props.module.isLiteracyCourse,
            isIntegrationCourse: !!this.props.module.isIntegrationCourse,
            isFinalExamTraining: !!this.props.module.isFinalExamTraining,
            isONA: this.props.module.integrationType === ModuleIntegrationType.ONA,
            checkedModuleIntegrationType: this.props.module.integrationType || null,
        }
    }

    public onSubmit = async (event: any, fields: any) => {
        const { params, refetch } = this.props

        const modifiedFields = fields.module

        modifiedFields.isFinalExamTraining = this.state.isFinalExamTraining

        if (modifiedFields.projectType && modifiedFields.projectType === ModuleProjectType.Other) {
            modifiedFields.integrationType = null
            modifiedFields.isFinalExamTraining = null
            modifiedFields.hasFinalReview = null
        }

        if (this.state.isLiteracyCourse) {
            modifiedFields.isFinalExamTraining = null
        }

        const response = await this.editModuleMutator.mutate({
            module: {
                _id: params.id,
                ...transformFormFields(modifiedFields, {
                    courseMaterialIds: transformLib.split(),
                    locationPropertyIds: transformLib.split(),
                    exams: {
                        value: (v: any) =>
                            v &&
                            v
                                .filter((moduleExamInput: any) => !!moduleExamInput)
                                .filter((moduleExamInput: any) => !!moduleExamInput.examId)
                                .map((moduleExamInput: any) => {
                                    moduleExamInput.abilities = moduleExamInput.abilities || {}
                                    return moduleExamInput
                                }),
                    },
                }),
            },
        })

        if (response) {
            refetch()
            browserHistory.push(`/properties/modules/${params.id}`)
        }
    }

    public onDelete = async () => {
        const { params } = this.props

        await this.deleteModuleMutator.mutate({
            _id: params.id,
        })

        browserHistory.push(`/properties/modules`)
    }

    public checkIsFinalExamTraining = () => {
        const isFinalExamTraining = !this.state.isFinalExamTraining
        this.setState({ isFinalExamTraining })
    }

    public render() {
        const { module } = this.props
        const { errors, loading } = this.editModuleMutator
        const { data, loading: isAdditionalDataLoading } = this.dataFetcher
        const { isONA, isFinalExamTraining, isIntegrationCourse, isLiteracyCourse, checkedModuleIntegrationType } =
            this.state

        const usedByProgram = !!get(module, 'usedByProgram')

        const courseMaterials: CourseMaterial[] = data.allCourseMaterials || []
        const courseMaterialsOptions = courseMaterials.map(courseMaterial => ({
            value: courseMaterial._id,
            label: courseMaterial.name,
        }))

        const locationProperties: LocationProperty[] = data.allLocationProperties || []
        const locationPropertiesOptions = locationProperties.map(locationProperty => ({
            value: locationProperty._id,
            label: locationProperty.name,
        }))

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={`Algemeen`} isForm={true}>
                                <Field isLabel title={'Naam'} errors={errors}>
                                    <Input
                                        name={`module.name`}
                                        isReadonly={module.isUsedInNonConceptGroups && !!module.name}
                                        type={`text`}
                                        placeholder={`Naam`}
                                        defaultValue={module.name}
                                    />
                                    {module.isUsedInNonConceptGroups && (
                                        <ValidationError
                                            isWarning
                                            // tslint:disable-next-line:max-line-length
                                            text={`Deze module wordt momenteel gebruikt in lopende groepen. Sommige velden kunnen daarom niet meer aangepast worden.`}
                                        />
                                    )}
                                    {usedByProgram && (
                                        <ValidationError
                                            isWarning
                                            // tslint:disable-next-line:max-line-length
                                            text={`Deze module wordt momenteel gebruikt in een of meerdere opleidingen. Sommige velden kunnen daarom niet meer aangepast worden.`}
                                        />
                                    )}
                                </Field>
                                <Field title={'Type project'} errors={errors}>
                                    <TagPicker
                                        name={'module.projectType'}
                                        placeholder={`Selecteer een type`}
                                        options={[
                                            ModuleProjectType.Integration,
                                            ModuleProjectType.Organization,
                                            ModuleProjectType.Other,
                                        ].map(value => ({
                                            label: translateType('projectType', value),
                                            value: value,
                                        }))}
                                        defaultValue={get(module, 'projectType')}
                                        multi={false}
                                        onChange={this.onIsIntegrationCourseChange}
                                        isDisabled={usedByProgram}
                                    />
                                </Field>
                                <Field title={'Alfabetisering'} errors={errors}>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name={`module.isLiteracyCourse`}
                                            value={true}
                                            onClick={this.onIsLiteracyCourseChange}
                                            defaultChecked={!!module.isLiteracyCourse}
                                            isDisabled={module.isUsedInNonConceptGroups}
                                        >
                                            Ja
                                        </RadioButton>
                                        <RadioButton
                                            name={`module.isLiteracyCourse`}
                                            value={false}
                                            onClick={this.onIsLiteracyCourseChange}
                                            defaultChecked={!module.isLiteracyCourse}
                                            isDisabled={module.isUsedInNonConceptGroups}
                                        >
                                            Nee
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                                {!isLiteracyCourse && (
                                    <FieldGroup isInsetGroup={true}>
                                        <Field isLabel title={'Beginniveau'} errors={errors}>
                                            <LearnerLevelInput
                                                name={`module.startLevel`}
                                                placeholder={`Selecteer een niveau`}
                                                defaultValue={get(module, `startLevel`)}
                                            />
                                        </Field>
                                        <Field isLabel title={'Eindniveau'} errors={errors}>
                                            <LearnerLevelInput
                                                name={`module.endLevel`}
                                                placeholder={`Selecteer een niveau`}
                                                defaultValue={get(module, `endLevel`)}
                                            />
                                        </Field>
                                    </FieldGroup>
                                )}
                                {isIntegrationCourse && (
                                    <FieldGroup isInsetGroup={true}>
                                        <Field title={'Type inburgering'} errors={errors}>
                                            <TagPicker
                                                name={'module.integrationType'}
                                                placeholder={`Selecteer een type`}
                                                isDisabled={usedByProgram || isLiteracyCourse || false}
                                                onChange={this.onIsIntegrationTypeChange}
                                                options={Object.values(ModuleIntegrationType).map(value => ({
                                                    label: translateType('lessonModuleIntegrationType', value),
                                                    value: value,
                                                    isDisabled: value === ModuleIntegrationType.Alfa, // Alfa is always disabled since it's in fact controlled by 'isLiteracyCourse'
                                                }))}
                                                value={checkedModuleIntegrationType}
                                                multi={false}
                                            />
                                        </Field>
                                        {!this.state.isLiteracyCourse && (
                                            <Field title={'Examentraining'} errors={errors}>
                                                <MultiInput type="radio">
                                                    <RadioButton
                                                        name={`module.isFinalExamTraining`}
                                                        value={true}
                                                        checked={isFinalExamTraining}
                                                        isDisabled={isONA}
                                                        onClick={() => this.setFinalExamValue(true)}
                                                    >
                                                        Ja
                                                    </RadioButton>
                                                    <RadioButton
                                                        name={`module.isFinalExamTraining`}
                                                        value={false}
                                                        checked={!isFinalExamTraining}
                                                        isDisabled={isONA}
                                                        onClick={() => this.setFinalExamValue(false)}
                                                    >
                                                        Nee
                                                    </RadioButton>
                                                </MultiInput>
                                            </Field>
                                        )}
                                        <Field title={'Bevat eindgesprek'} errors={errors}>
                                            <MultiInput type="radio">
                                                <RadioButton
                                                    name={`module.hasFinalReview`}
                                                    value={true}
                                                    defaultChecked={!!get(module, 'hasFinalReview')}
                                                >
                                                    Ja
                                                </RadioButton>
                                                <RadioButton
                                                    name={`module.hasFinalReview`}
                                                    value={false}
                                                    defaultChecked={!get(module, 'hasFinalReview')}
                                                >
                                                    Nee
                                                </RadioButton>
                                            </MultiInput>
                                        </Field>
                                    </FieldGroup>
                                )}
                            </FieldGroup>
                            <FieldGroup title={`Inhoudelijk`} isForm={true}>
                                <Field isLabel title={'Aantal minuten per les'} errors={errors}>
                                    <Input
                                        name={`module.lessonDuration`}
                                        isReadonly={module.isUsedInNonConceptGroups && !!get(module, `lessonDuration`)}
                                        type={`number`}
                                        defaultValue={get(module, `lessonDuration`)}
                                    />
                                </Field>
                                <Field isLabel title={'Aantal lessen per week'} errors={errors}>
                                    <Input
                                        name={`module.amountOfWeeklyLessons`}
                                        isReadonly={
                                            module.isUsedInNonConceptGroups && !!get(module, `amountOfWeeklyLessons`)
                                        }
                                        type={`number`}
                                        defaultValue={get(module, `amountOfWeeklyLessons`)}
                                    />
                                </Field>
                                <Field isLabel title={'Totaal aantal lessen'} errors={errors}>
                                    <Input
                                        name={`module.amountOfTotalLessons`}
                                        isReadonly={
                                            module.isUsedInNonConceptGroups && !!get(module, `amountOfTotalLessons`)
                                        }
                                        type={`number`}
                                        defaultValue={get(module, `amountOfTotalLessons`)}
                                    />
                                </Field>
                                <Field isLabel title={'Maximale groepsgrootte'} errors={errors}>
                                    <Input
                                        name={`module.capacity`}
                                        type={`number`}
                                        defaultValue={get(module, `capacity`)}
                                    />
                                </Field>
                                <Field isLabel title={`Lesmaterialen`}>
                                    <TagPicker
                                        name={`module.courseMaterialIds`}
                                        options={courseMaterialsOptions}
                                        placeholder="Selecteer lesmaterialen"
                                        defaultValue={this.getDefaultCourseMaterialIds()}
                                        isLoading={isAdditionalDataLoading}
                                    />
                                </Field>
                                <ModuleExamsField
                                    title={'Toetsen'}
                                    name={`module.exams`}
                                    errors={errors}
                                    defaultModuleExams={get(module, 'exams') || []}
                                />
                                <Field isLabel title={`Locatie-eisen`} errors={errors}>
                                    <TagPicker
                                        name={`module.locationPropertyIds`}
                                        options={locationPropertiesOptions}
                                        placeholder="Selecteer locatie-eisen"
                                        defaultValue={this.getLocationPropertyIds()}
                                        isLoading={isAdditionalDataLoading}
                                    />
                                </Field>
                                <Field title={'Status'} errors={errors}>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name={`module.isActive`}
                                            value={true}
                                            defaultChecked={!!get(module, 'isActive')}
                                        >
                                            Actief
                                        </RadioButton>
                                        <RadioButton
                                            name={`module.isActive`}
                                            value={false}
                                            defaultChecked={!get(module, 'isActive')}
                                        >
                                            Non-actief
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() => browserHistory.push(`/properties/modules/${module._id}`)}
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onIsIntegrationCourseChange = (option: any) => {
        this.setState({
            isIntegrationCourse: option.value === ModuleProjectType.Integration,
        })
    }

    private onIsLiteracyCourseChange = (event: React.SyntheticEvent<HTMLInputElement>) => {
        this.setState({
            isLiteracyCourse: event.currentTarget.value === 'true',
            checkedModuleIntegrationType: event.currentTarget.value === 'true' ? ModuleIntegrationType.Alfa : null,
        })
    }

    private onIsIntegrationTypeChange: TagPickerChangeHandler<void, ModuleIntegrationType> = option => {
        this.setState(
            {
                checkedModuleIntegrationType: option.value,
                isONA: option.value === ModuleIntegrationType.ONA,
            },
            () => this.setFinalExamValue(option.value === ModuleIntegrationType.ONA)
        )
    }

    private setFinalExamValue = (value: boolean) => {
        this.setState({
            isFinalExamTraining: value,
        })
    }

    private getDefaultCourseMaterialIds = (): string[] => {
        const { module } = this.props
        const courseMaterials = module.courseMaterials || []

        return courseMaterials.map(courseMaterial => {
            return courseMaterial._id
        })
    }

    private getLocationPropertyIds = (): string[] => {
        const { module } = this.props
        const locationProperties = module.locationProperties || []

        return locationProperties.map(locationProperty => {
            return locationProperty._id
        })
    }
}
