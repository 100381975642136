import gql from 'graphql-tag'
import { get } from 'lodash'
import * as React from 'react'
import { browserHistory } from 'react-router'

import {
    ActionBar,
    Button,
    CenterModal,
    Field,
    FieldCollection,
    Form,
    Input,
    MultiInput,
    PhoneInput,
    RadioButton,
    TextArea,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { Wrap } from '~/components/Wrap'
import { getCurrentUser } from '~/services/session'
import { ASSIGNABLE_ROLES_BY_ROLE } from '~/shared/entities/user'
import { translateType, types } from '~/shared/utils'
import { Mutator, toast } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import { NT2CertificateFieldGroup } from '~/components/users/Teacher/NT2CertificateFieldGroup'
import { LessonCompetenceCertificateFieldGroup } from '~/components/users/Teacher/LessonCompetenceCertificateFieldGroup'
import { User, UserRole } from '~/types/User'
import { GraphQLErrors } from '~/utils/GraphQLErrors'
import { ChangePasswordForm } from '~/forms/ChangePasswordForm'
import { ChangeMobileMfaForm } from '~/forms/ChangeMobileMfaForm'
import { ChangeEmailForm } from '~/forms/ChangeEmail'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'
import { ChangeUserStatusForm } from '~/forms/ChangeUserStatus'

interface Props {
    user?: any
    params?: any
    refetch?: (options?: any) => void
}

interface State {
    currentUser?: User
    editingCurrentUser?: boolean
    isNT2Certified?: boolean
    isLessonCompetent?: boolean
    selectedMutableRoles?: UserRole[]
}

// const DELETE_USER_MUTATION = gql`
//     mutation _($userId: MongoID!) {
//         users_delete(userId: $userId){
//             _id
//         }
//     }
// `

const RESET_COGNITO_TOTP_MFA_MUTATION = gql`
    mutation _($userId: MongoID!) {
        users_resetCognitoTotpMfa(userId: $userId) {
            _id
        }
    }
`

const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`

export default class EditView extends React.Component<Props, State> {
    public state: State = {
        currentUser: undefined,
        editingCurrentUser: false,
        isNT2Certified: undefined,
        isLessonCompetent: undefined,
        selectedMutableRoles: undefined,
    }

    private userMutator: Mutator
    private resetCognitoTotpMfaMutator: Mutator

    // private userDeleteMutator: Mutator
    private returnUrl: string

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: EDIT_USER_MUTATION,
            reactComponentToUpdate: this,
        })

        this.resetCognitoTotpMfaMutator = new Mutator({
            mutation: RESET_COGNITO_TOTP_MFA_MUTATION,
            reactComponentToUpdate: this,
        })

        // this.userDeleteMutator = new Mutator({
        //     mutation: DELETE_USER_MUTATION,
        //     reactComponentToUpdate: this,
        // })

        const user = getCurrentUser()

        this.state = {
            currentUser: user,
            editingCurrentUser: props.user._id === user._id,
            isNT2Certified: props.user && props.user.teacher && props.user.teacher.isNT2Certified,
            isLessonCompetent: props.user && props.user.teacher && props.user.teacher.lessonCompetence,
            selectedMutableRoles: (props.user && props.user.roles) || [],
        }
    }

    // TODO
    // onDelete(event) {
    //     const { params, refetch } = this.props;
    //     this.userDeleteMutator.mutate({
    //         'userId': params.id,
    //     }).then(() => {
    //         refetch();
    //         browserHistory.push(`/users/employees/`);
    //     });
    // }

    public render() {
        const { user, refetch } = this.props
        const { editingCurrentUser, currentUser, selectedMutableRoles } = this.state
        const { errors, loading: loadingMutate } = this.userMutator
        const roleOptions = Object.keys(types.mutableRole)
            .filter(role => ASSIGNABLE_ROLES_BY_ROLE.EMPLOYEE.includes(role))
            .map(this.mapRoleToOption)
        let userMfaMobileNumber = ''
        let isMfaConfigured = 'Nee'
        let userStatus = ''
        if (user) {
            isMfaConfigured = user.profile.isMfaConfigured === true ? 'Ja' : 'Nee'
            userMfaMobileNumber = user.profile.mfaMobileNumber !== undefined ? user.profile.mfaMobileNumber : ''
            userStatus = user.employee.isActive === true ? 'Actief' : 'Non-actief'
        }
        return (
            <ContentView hasFixedActionBar>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={`Account`} isForm>
                                <Field isLabel title={`Primair e-mailadres`} errors={errors}>
                                    {user.email}
                                </Field>
                                <Field isLabel title={` `}>
                                    <ModalManager
                                        render={openModal => <Button onClick={openModal}>E-mailadres wijzigen</Button>}
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`E-mailadres wijzigen`}>
                                                <ChangeEmailForm
                                                    onSubmitSuccess={() => {
                                                        closeModal()
                                                        if (refetch) {
                                                            refetch()
                                                        }
                                                    }}
                                                    onCancel={closeModal}
                                                    userId={user._id}
                                                    emailProps={user.email}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </Field>
                                {editingCurrentUser && (
                                    <Field isLabel title={`Wachtwoord`}>
                                        <ModalManager
                                            render={openModal => (
                                                <Button onClick={openModal}>Wachtwoord wijzigen</Button>
                                            )}
                                            getModal={closeModal => (
                                                <CenterModal onClose={closeModal} title={`Wachtwoord wijzigen`}>
                                                    <ChangePasswordForm
                                                        onSubmitSuccess={() => {
                                                            closeModal()
                                                            if (refetch) {
                                                                refetch()
                                                            }
                                                        }}
                                                        onCancel={closeModal}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    </Field>
                                )}{' '}
                            </FieldGroup>
                            {currentUser && currentUser.isAdmin && user && (
                                <FieldGroup title={`MFA`}>
                                    <Field isLabel title={`Geconfigureerd`}>
                                        {isMfaConfigured}
                                    </Field>
                                    <div>
                                        {user && user.profile.isMfaConfigured && user.profile.isMfaConfigured === true && (
                                            <Field isLabel title={`Reset MFA`}>
                                                <Button onClick={this.resetCognitoTotpMfa}>Reset</Button>
                                            </Field>
                                        )}
                                        <Field isLabel title={`Mobiel nummer voor MFA met SMS`}>
                                            <div>{userMfaMobileNumber}</div>
                                            <ModalManager
                                                render={openModal => <Button onClick={openModal}>Updaten</Button>}
                                                getModal={closeModal => (
                                                    <CenterModal
                                                        onClose={closeModal}
                                                        title={`Mobiel nummer voor MFA invoeren`}
                                                    >
                                                        <ChangeMobileMfaForm
                                                            onSubmitSuccess={() => {
                                                                closeModal()
                                                                if (refetch) {
                                                                    refetch()
                                                                }
                                                            }}
                                                            onCancel={closeModal}
                                                            userId={user._id}
                                                            mfaMobileNumberProps={userMfaMobileNumber}
                                                        />
                                                    </CenterModal>
                                                )}
                                            />
                                        </Field>
                                    </div>
                                </FieldGroup>
                            )}
                            <FieldGroup title={`Persoonlijke gegevens`} isForm>
                                <Field title={`Naam`} errors={errors}>
                                    <MultiInput type={`name`}>
                                        <Input
                                            name={`user.profile.firstName`}
                                            type={`text`}
                                            placeholder={`Voornaam`}
                                            defaultValue={get(user, 'profile.firstName')}
                                        />
                                        <Input
                                            name={`user.profile.initials`}
                                            type={`text`}
                                            placeholder={`Voorletters`}
                                            defaultValue={get(user, 'profile.initials')}
                                        />
                                        <Input
                                            name={`user.profile.surNamePrefix`}
                                            type={`text`}
                                            placeholder={`Tussenvoegsel`}
                                            defaultValue={get(user, 'profile.surNamePrefix')}
                                        />
                                        <Input
                                            name={`user.profile.surName`}
                                            type={`text`}
                                            placeholder={`Achternaam`}
                                            defaultValue={get(user, 'profile.surName')}
                                        />
                                    </MultiInput>
                                </Field>
                                <Field title={`Geslacht`} errors={errors}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`MALE`}
                                            defaultChecked={get(user, 'profile.gender') === 'MALE'}
                                        >
                                            Man
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`FEMALE`}
                                            defaultChecked={get(user, 'profile.gender') === 'FEMALE'}
                                        >
                                            Vrouw
                                        </RadioButton>
                                        <RadioButton
                                            name={`user.profile.gender`}
                                            value={`OTHER`}
                                            defaultChecked={get(user, 'profile.gender') === 'OTHER'}
                                        >
                                            Anders
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            </FieldGroup>
                            <FieldGroup title={`Contactgegevens`} isForm>
                                <Field title={`Telefoonnummer`} errors={errors}>
                                    <VariableMultiInput
                                        defaultAmount={get(user, 'profile.phoneNumbers.length')}
                                        getNewInput={i => {
                                            return (
                                                <PhoneInput
                                                    key={i}
                                                    name={`user.profile.phoneNumbers[${i}]`}
                                                    defaultValue={get(user, `profile.phoneNumbers[${i}]`)}
                                                />
                                            )
                                        }}
                                        addButtonLabel={`Extra veld`}
                                    />
                                </Field>
                                <Field isLabel title={`Secundair e-mailadres`} errors={errors}>
                                    <Input
                                        type={`email`}
                                        name={`user.profile.alternativeEmail`}
                                        defaultValue={get(user, `profile.alternativeEmail`)}
                                    />
                                </Field>
                                <LocationFieldsInputGroup
                                    title={'Adres'}
                                    errors={errors}
                                    isLocked={false}
                                    location={user && user.location && user.location.address}
                                    withoutCountry={false}
                                    name={'user.profile.address'}
                                />
                            </FieldGroup>
                            <FieldGroup title={`Overige`} isForm>
                                {currentUser && currentUser.isAdmin && (
                                    <Field title="Functie(s)" errors={errors}>
                                        <TagPicker
                                            name={`user.mutableRoles`}
                                            options={roleOptions}
                                            placeholder={'Selecteer functies'}
                                            value={
                                                selectedMutableRoles
                                                    ? selectedMutableRoles.map(this.mapRoleToOption)
                                                    : []
                                            }
                                            onChange={this.onChangeMutableRoles}
                                        />
                                    </Field>
                                )}
                                {selectedMutableRoles && selectedMutableRoles.includes(UserRole.InternalTeacher) && (
                                    <>
                                        {this.renderIsNT2CertifiedField(user, errors)}
                                        {this.renderLessonCompetenceField(user, errors)}
                                    </>
                                )}
                                <Field isLabel title={`Notities`} errors={errors}>
                                    <TextArea name={`user.profile.notes`} defaultValue={get(user, `profile.notes`)} />
                                </Field>
                                {currentUser && currentUser.isAdmin && (
                                    <Field title={`Status`} errors={errors}>
                                        <div>{userStatus}</div>
                                        <ModalManager
                                            render={openModal => <Button onClick={openModal}>Status wijzigen</Button>}
                                            getModal={closeModal => (
                                                <CenterModal onClose={closeModal} title={`Status wijzigen`}>
                                                    <ChangeUserStatusForm
                                                        onSubmitSuccess={() => {
                                                            closeModal()
                                                            if (refetch) {
                                                                refetch()
                                                            }
                                                        }}
                                                        onCancel={closeModal}
                                                        userId={user._id}
                                                        userStatus={user.employee.isActive}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    </Field>
                                )}
                                {/* TODO !currentUserLoading && editingCurrentUser &&
                                    <Field title={`De taal van Toptaal`}>
                                        <Select
                                            defaultValue={getCurrentLanguage()}
                                            onChange={({nativeEvent: {target: {value}}}) => changeLanguage(value)}
                                        >
                                            <Option value={'nl'}>Nederlands</Option>
                                            <Option value={'en'}>English</Option>
                                        </Select>
                                    </Field>
                                */}
                            </FieldGroup>
                        </FieldCollection>
                        <ActionBar
                            fixed={true}
                            getButtons={() => {
                                return (
                                    <List horizontal={true}>
                                        <ListItem right={true}>
                                            <Button type={`submit`} isLoading={loadingMutate}>
                                                Opslaan
                                            </Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button onClick={() => browserHistory.push(`/users/employees/${user._id}`)}>
                                                Annuleren
                                            </Button>
                                        </ListItem>
                                    </List>
                                )
                            }}
                        />
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private renderIsNT2CertifiedField = (user: User, errors: GraphQLErrors) => {
        const { isNT2Certified } = this.state

        return (
            <React.Fragment>
                <Field title={`Gecertificeerd NT2 docent?`} errors={errors}>
                    <MultiInput type={`radio`}>
                        <RadioButton
                            name={`user.teacher.isNT2Certified`}
                            value={true}
                            defaultChecked={isNT2Certified}
                            onChange={this.onIsNT2CertifiedChange}
                        >
                            Ja
                        </RadioButton>
                        <RadioButton
                            name={`user.teacher.isNT2Certified`}
                            value={false}
                            defaultChecked={!isNT2Certified}
                            onChange={this.onIsNT2CertifiedChange}
                        >
                            Nee
                        </RadioButton>
                    </MultiInput>
                </Field>
                {isNT2Certified && (
                    <FieldGroup isInsetGroup={true}>
                        <NT2CertificateFieldGroup
                            defaultCertificateFiles={get(this.props, 'user.teacher.NT2Certificates')}
                            defaultDateValue={get(this.props, 'user.teacher.NT2CertifiedDate')}
                            errors={errors}
                        />
                    </FieldGroup>
                )}
            </React.Fragment>
        )
    }

    private renderLessonCompetenceField = (user: User, errors: any[]) => {
        const { isLessonCompetent } = this.state

        return (
            <React.Fragment>
                <Field title={`Lesbevoegdheid`}>
                    <MultiInput type={`radio`}>
                        <RadioButton
                            name={`user.teacher.lessonCompetence`}
                            value={true}
                            defaultChecked={isLessonCompetent}
                            onChange={this.onLessonCompetenceChange}
                        >
                            Ja
                        </RadioButton>
                        <RadioButton
                            name={`user.teacher.lessonCompetence`}
                            value={false}
                            defaultChecked={!isLessonCompetent}
                            onChange={this.onLessonCompetenceChange}
                        >
                            Nee
                        </RadioButton>
                    </MultiInput>
                </Field>

                {isLessonCompetent && (
                    <FieldGroup isInsetGroup={true}>
                        <LessonCompetenceCertificateFieldGroup
                            defaultCertificateFiles={get(this.props, 'user.teacher.lessonCompetenceCertificates')}
                            defaultDateValue={get(this.props, 'user.teacher.lessonCompetenceDate')}
                            errors={errors}
                        />
                    </FieldGroup>
                )}
            </React.Fragment>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { params, refetch } = this.props

        this.userMutator
            .mutate({
                user: {
                    _id: params.id,
                    ...transformFormFields(fields.user, {
                        mutableRoles: transformLib.split(),
                        profile: {
                            fields: (v: any) =>
                                transformFormFields(v, {
                                    address: transformLib.address(),
                                    phoneNumbers: transformLib.phoneNumbers(),
                                }),
                        },
                        teacher: {
                            fields: (v: any) =>
                                transformFormFields(v, {
                                    NT2CertifiedDate: transformLib.date(),
                                    lessonCompetenceDate: transformLib.date(),
                                }),
                        },
                        employee: {
                            fields: (v: any) => transformFormFields(v),
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    if (refetch) {
                        refetch()
                    }

                    toast.success('Medewerker succesvol opgeslagen')

                    if (this.returnUrl) {
                        browserHistory.push(this.returnUrl)
                    }

                    browserHistory.push(`/users/employees/${params.id}`)
                }
            })
    }

    private onIsNT2CertifiedChange = ({ target }: any) => {
        this.setState({
            isNT2Certified: target.value === 'true' ? true : false,
        })
    }

    private resetCognitoTotpMfa = () => {
        const { params, refetch, user } = this.props
        this.resetCognitoTotpMfaMutator
            .mutate({
                userId: user._id,
            })
            .then(data => {
                if (data) {
                    if (refetch) {
                        refetch()
                    }
                    toast.success('MFA reset is successful')
                    if (this.returnUrl) {
                        browserHistory.push(this.returnUrl)
                    }
                    browserHistory.push(`/users/employees/${params.id}`)
                }
            })
    }

    private onLessonCompetenceChange = ({ target }: any) => {
        this.setState({
            isLessonCompetent: target.value === 'true' ? true : false,
        })
    }

    private onChangeMutableRoles = (options: TagPickerOption[]) => {
        const newRoles = options.map(option => option.value as UserRole)

        this.setState({
            selectedMutableRoles: newRoles,
        })
    }

    private mapRoleToOption = (role: any) => {
        return {
            value: role,
            label: translateType('mutableRole', role),
        }
    }
}
