import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { Spinner } from '~/components'
import { Wrap } from '~/components/Wrap'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { View } from '~/components/View'
import { getCurrentUser } from '~/services/session'
import { Fetcher } from '~/utils'

export default class ExternalTeacherGroupLearnersDetailView extends Component {
    static propTypes = {
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
            learnerUserId: PropTypes.string.isRequired,
        }).isRequired,
        children: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.groupFetcher = new Fetcher({
            query: GET_GROUP_QUERY,
            variables: {
                filters: {
                    filterById: props.params.id,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.learnerFetcher = new Fetcher({
            query: GET_LEARNER_QUERY,
            variables: {
                filters: {
                    byId: props.params.learnerUserId,
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.learnerFetcher.refetch({
                filters: {
                    byId: params.id,
                },
            })
        }
    }

    render() {
        const currentUser = getCurrentUser()
        const { children, ...routeProps } = this.props
        const { learnerUserId: userId, groupId } = routeProps.params
        const { data: groupData, loading: groupLoading } = this.groupFetcher
        const { data, loading, refetch } = this.learnerFetcher
        const user = get(data, 'users[0]')
        const group = get(groupData, 'groups[0]')
        const baseUrl = `/groups/${groupId}/learners/${userId}`

        if (groupLoading || loading) {
            return (
                <View>
                    <Wrap full={true}>
                        <Spinner />
                    </Wrap>
                </View>
            )
        }

        return (
            <RouteView crumbLabel={get(group, 'name')} path={`/groups/${groupId}`}>
                <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps}>
                    <Header>
                        <BreadCrumbs />
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`${baseUrl}/timeline`}>
                                    Correspondentie
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`${baseUrl}/documents`}>
                                    Documenten
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`${baseUrl}/progress`}>
                                    Resultaten
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`${baseUrl}`}>
                                    Gegevens
                                </Link>
                            </PageTab>
                        </PageTabs>
                    </Header>
                    <View>
                        {currentUser &&
                            cloneElement(children, {
                                userId,
                                groupId,
                                refetch,
                                isViewedByTeacherOfUser: true,
                            })}
                    </View>
                </RouteView>
            </RouteView>
        )
    }
}

const GET_GROUP_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            name
        }
    }
`

const GET_LEARNER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            profile {
                name
            }
        }
    }
`
