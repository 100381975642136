import * as React from 'react'
import Form from '~/components/Form'
import { FinalExam, FinalExamSubmitters } from '~/types/FinalExam'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components'
import MultiInput from '~/components/MultiInput'
import RadioButton from '~/components/RadioButton'
import DatePicker from '~/components/DatePicker'
import { transformFormFields, toast } from '~/utils'
import Mutator from '~/utils/Mutator'
import gql from 'graphql-tag'
import FileInput from '~/components/FileInput'
import { browserHistory } from 'react-router'
import { TagPicker } from '~/components/TagPicker'

const FIX_FINAL_EXAM_SUBMISSION_MISTAKE_MUTATION = gql`
    mutation finalExams_update(
        $type: FinalExamEditTypesEnum
        $finalExamId: MongoID
        $date: Date
        $partAndLevel: FinalExamCombinedPartsAndLevelsEnum
        $file: UploadedFile
    ) {
        finalExams_update(type: $type, finalExamId: $finalExamId, date: $date, partAndLevel: $partAndLevel, file: $file)
    }
`

interface Props {
    finalExam: FinalExam
    onClose: () => void
    onSubmitSuccess: () => void
}

interface State {
    submissionTypeSelected: string
}

export class FinalExamFixSubmissionMistakeForm extends React.Component<Props, State> {
    public state: State = {
        submissionTypeSelected: 'dateCorrected',
    }

    private fixFinalExamSubmissionMistakeMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.fixFinalExamSubmissionMistakeMutator = new Mutator({
            mutation: FIX_FINAL_EXAM_SUBMISSION_MISTAKE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { finalExam, onClose } = this.props
        const { submissionTypeSelected } = this.state

        const isDateCorrectedSelected = submissionTypeSelected === 'dateCorrected'
        const isExamPartCorrectedSelected = submissionTypeSelected === 'examPartCorrected'
        const isSubmitterChangedToToptaalSelected = submissionTypeSelected === 'submitterChangedToToptaal'

        const isSubmittedByTopTaal = finalExam.submitter === FinalExamSubmitters.TopTaal

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field>
                            <MultiInput type={`radio`} isVertical={true} fullWidth={true}>
                                <>
                                    <RadioButton
                                        name={`type`}
                                        value={`dateCorrected`}
                                        defaultChecked={true}
                                        onClick={this.onChangeSubmissionType}
                                    >
                                        Ik heb de datum verkeerd ingevoerd
                                    </RadioButton>
                                    {isDateCorrectedSelected && (
                                        <FieldCollection>
                                            <FieldGroup isInsetGroup={true}>
                                                <Field title={`Nieuwe datum`}>
                                                    <DatePicker name={`date`} />
                                                </Field>
                                            </FieldGroup>
                                        </FieldCollection>
                                    )}
                                </>
                                <>
                                    <RadioButton
                                        name={`type`}
                                        value={`examPartCorrected`}
                                        onClick={this.onChangeSubmissionType}
                                    >
                                        Ik heb het verkeerde examenonderdeel aangegeven
                                    </RadioButton>
                                    {isExamPartCorrectedSelected && (
                                        <FieldCollection>
                                            <FieldGroup isInsetGroup={true}>
                                                <Field title={`Examenonderdeel`}>
                                                    <TagPicker
                                                        multi={false}
                                                        options={this.getAbilityOptions()}
                                                        placeholder={`Onderdeel`}
                                                        name={`partAndLevel`}
                                                    />
                                                </Field>
                                            </FieldGroup>
                                        </FieldCollection>
                                    )}
                                </>
                                {isSubmittedByTopTaal && (
                                    <RadioButton
                                        name={`type`}
                                        value={`submitterChangedToExternal`}
                                        onClick={this.onChangeSubmissionType}
                                    >
                                        De aanvraag bleek achteraf een externe aanvrager te zijn
                                    </RadioButton>
                                )}
                                {!isSubmittedByTopTaal && (
                                    <>
                                        <RadioButton
                                            name={`type`}
                                            value={`submitterChangedToToptaal`}
                                            onClick={this.onChangeSubmissionType}
                                        >
                                            De aanvraag bleek achteraf een TopTaal aanvrager te zijn
                                        </RadioButton>
                                        {isSubmitterChangedToToptaalSelected && (
                                            <FieldCollection>
                                                <FieldGroup isInsetGroup={true}>
                                                    <Field title={`Upload PDF examenafspraak`}>
                                                        <FileInput
                                                            name={`file`}
                                                            multiple={false}
                                                            accept={`application/pdf`}
                                                        >
                                                            Kies bestand
                                                        </FileInput>
                                                    </Field>
                                                </FieldGroup>
                                            </FieldCollection>
                                        )}
                                    </>
                                )}
                                <RadioButton
                                    name={`type`}
                                    value={`removeFinalExam`}
                                    onClick={this.onChangeSubmissionType}
                                >
                                    Ik heb de verkeerde kandidaat aangemeld in MijnTopTaal
                                </RadioButton>
                            </MultiInput>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`}>Opslaan</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onChangeSubmissionType: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        const { value } = target

        this.setState({ submissionTypeSelected: value })
    }

    private getAbilityOptions = () => {
        return [
            {
                value: 'knm',
                label: 'KNM',
            },
            {
                value: 'ona',
                label: 'ONA',
            },
            {
                value: 'talkA2',
                label: 'Spreekvaardigheid A2',
            },
            {
                value: 'talkB1',
                label: 'Spreekvaardigheid B1',
            },
            {
                value: 'talkB2',
                label: 'Spreekvaardigheid B2',
            },
            {
                value: 'listenA2',
                label: 'Luistervaardigheid A2',
            },
            {
                value: 'listenB1',
                label: 'Luistervaardigheid B1',
            },
            {
                value: 'listenB2',
                label: 'Luistervaardigheid B2',
            },
            {
                value: 'readA2',
                label: 'Leesvaardigheid A2',
            },
            {
                value: 'readB1',
                label: 'Leesvaardigheid B1',
            },
            {
                value: 'readB2',
                label: 'Leesvaardigheid B2',
            },
            {
                value: 'writeA2',
                label: 'Schrijfvaardigheid A2',
            },
            {
                value: 'writeB1',
                label: 'Schrijfvaardigheid B1',
            },
            {
                value: 'writeB2',
                label: 'Schrijfvaardigheid B2',
            },
        ]
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { finalExam, onSubmitSuccess } = this.props

        const data = await this.fixFinalExamSubmissionMistakeMutator.mutate({
            finalExamId: finalExam._id,
            ...transformFormFields(fields),
        })

        if (data && data.finalExams_update === true) {
            if (fields.type === 'removeFinalExam') {
                toast.success('Het examen is verwijderd')
                browserHistory.push('/final-exams/planned/')
            }
            toast.success(`Het examen is geüpdatet`)
            onSubmitSuccess()
        }
    }
}
