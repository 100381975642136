import { get } from 'lodash'
import * as React from 'react'

import { Header, PageTab, PageTabs, Paragraph, Link } from '~/components'
import { Fetcher } from '~/utils'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import gql from 'graphql-tag'
import { ContentView } from '~/components/ContentView'
import { RouteComponentProps } from 'react-router'
import { BreadCrumbs } from '~/components/BreadCrumbs'

const GET_INFLOW_MODULE_QUERY = gql`
    query _($_id: MongoID) {
        inflowModules(byId: $_id) {
            _id
            name
            type
            exams {
                examId
                abilities {
                    converse
                    listen
                    read
                    talk
                    write
                }
                exam {
                    _id
                    name
                }
            }
            examMaxTimeMinutes
            examStartIntervalMinutes
            maxStudentsPerStart
            isLockedForEdit
            isLockedForDelete
            checkAdviceReportNeeded
        }
    }
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: any
}

export default class DetailView extends React.Component<Props, {}> {
    private inflowModuleFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.inflowModuleFetcher = new Fetcher({
            query: GET_INFLOW_MODULE_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    public componentDidUpdate(preIterationVariables: any) {
        const { params } = this.props

        if (preIterationVariables.params.id !== params.id) {
            this.inflowModuleFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.inflowModuleFetcher

        const inflowModule = get(data, 'inflowModules[0]')

        return (
            <RouteView crumbLabel={inflowModule && inflowModule.name} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {inflowModule && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex={true} route={`/properties/inflow/${inflowModule._id}`}>
                                    Gegevens
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link
                                    onlyActiveOnIndex={true}
                                    route={`/properties/inflow/${inflowModule._id}/invites/`}
                                >
                                    Uitnodigingsbrieven
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                {inflowModule && React.cloneElement(children, { inflowModule, refetch })}
                {!loading && !inflowModule && (
                    <ContentView>
                        <Paragraph>Instroommodule niet gevonden</Paragraph>
                    </ContentView>
                )}
            </RouteView>
        )
    }
}
