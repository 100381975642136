import { get } from 'lodash'
import * as React from 'react'

import { Paragraph } from '~/components'
import { Fetcher } from '~/utils'
import gql from 'graphql-tag'
import { RouteComponentProps } from 'react-router'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import Header from '~/components/Header'
import { ContentView } from '~/components/ContentView'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ConceptInflowMoment } from '~/types/InflowMoments'

const GET_INFLOW_MOMENTS_QUERY = gql`
    query _($_id: MongoID) {
        inflowMoments(byId: $_id) {
            _id
            createdAt
            createdByUser {
                _id
                profile {
                    name
                }
            }
            inflowModule {
                _id
                name
                type
                exams {
                    exam {
                        _id
                        name
                    }
                    examId
                    abilities {
                        converse
                        listen
                        read
                        talk
                        write
                    }
                }
                examMaxTimeMinutes
                examStartIntervalMinutes
                maxStudentsPerStart
            }
            isForOrganization
            organization {
                _id
                name
            }
            location {
                _id
                name
                rooms {
                    _id
                    capacity
                    name
                    locationProperties {
                        _id
                        name
                    }
                }
            }
            locationRoomsOrganized
            locationRoomsConversation {
                _id
                name
                capacity
            }
            locationRoomsGeneral {
                _id
                name
                capacity
            }
            dateRange {
                from
                to
            }
            intakerUsers {
                _id
                profile {
                    name
                    address {
                        _id
                        nl {
                            extraInfo {
                                city
                            }
                        }
                    }
                }
                roles
            }
            employeeUsers {
                _id
                profile {
                    name
                    address {
                        _id
                        nl {
                            extraInfo {
                                city
                            }
                        }
                    }
                }
                roles
            }
            timeslotsProjected {
                totalPlaces
                timeslots {
                    number
                    start
                    end
                    duration
                }
            }
        }
    }
`

const GET_INFLOW_MOMENT_VALIDITY_QUERY = gql`
    query _($_id: MongoID!) {
        inflowMoments_validate(inflowMomentId: $_id) {
            success
            errors {
                key
                stringifiedInfo
            }
        }
    }
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    children: any
}

export interface InflowMomentError {
    key: string
    stringifiedInfo: string
}

interface State {
    inflowMomentIsValid: boolean
    inflowMomentErrors: InflowMomentError[]
}

export class AppInflowMomentsConceptsDetail extends React.Component<Props, State> {
    public state: State = {
        inflowMomentIsValid: false,
        inflowMomentErrors: [],
    }

    private inflowMomentFetcher: Fetcher
    private inflowMomentValidityFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.inflowMomentFetcher = new Fetcher({
            query: GET_INFLOW_MOMENTS_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
            onRefetch: () => this.setInflowMomentValidity(),
        })

        this.inflowMomentValidityFetcher = new Fetcher({
            query: GET_INFLOW_MOMENT_VALIDITY_QUERY,
            variables: { _id: props.params.id },
            preventInitialFetch: true,
        })

        this.setInflowMomentValidity()
    }

    public componentDidUpdate(preIterationVariables: any) {
        const { params } = this.props

        if (preIterationVariables.params.id !== params.id) {
            this.inflowMomentFetcher.refetch({
                _id: params.id,
            } as any)
        }
    }

    public render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.inflowMomentFetcher
        const { inflowMomentIsValid, inflowMomentErrors } = this.state

        const inflowMoment: ConceptInflowMoment = get(data, 'inflowMoments[0]')

        return (
            <RouteView
                crumbLabel={inflowMoment && inflowMoment.inflowModule ? inflowMoment.inflowModule.name : 'Naamloos'}
                routeProps={routeProps}
                isLoading={loading}
            >
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    {inflowMoment &&
                        React.cloneElement(children, {
                            inflowMoment,
                            refetch,
                            inflowMomentIsValid,
                            inflowMomentErrors,
                        })}
                    {!loading && !inflowMoment && <Paragraph>Toetsmoment niet gevonden</Paragraph>}
                </ContentView>
            </RouteView>
        )
    }

    private setInflowMomentValidity = async () => {
        if (!this.inflowMomentValidityFetcher) {
            return
        }

        const data = await this.inflowMomentValidityFetcher.fetch({})
        const { success, errors } = data.inflowMoments_validate

        this.setState({
            inflowMomentIsValid: success,
            inflowMomentErrors: errors,
        })
    }
}
