import gql from 'graphql-tag'
import * as React from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Header,
    Link,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    Currency,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { CreateCourseMaterialForm } from '~/forms/CreateCourseMaterialForm'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { CourseMaterial } from '~/types/Module'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { BreadCrumbs } from '~/components/BreadCrumbs'

const START = 50
const INCREASE = 50
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

const GET_COURSEMATERIALSS_QUERY = gql`
    query _($searchText: String, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        courseMaterials(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            name
            amount
        }
    }
`

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export default class MasterView extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private filter: Filter
    private courseMaterialsFetcher: Fetcher
    private paginator: Paginator

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: (filters: any) => {
                this.courseMaterialsFetcher.refetch({
                    searchText: filters.searchText,
                } as any)
            },
        } as any)

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.courseMaterialsFetcher = new Fetcher({
            query: GET_COURSEMATERIALSS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                searchText: this.filter.getFilters().searchText,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public render() {
        const { loading, data } = this.courseMaterialsFetcher
        const { courseMaterials = [] } = data
        const { paginator, sorter } = this

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full={true}>{this.renderActionBar()}</Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`name`}>
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={sorter} sortBy={`amount`}>
                                        Bedrag
                                    </TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={2} isLoading={true} />
                                    </TableRow>
                                ) : courseMaterials.length > 0 ? (
                                    this.renderCourseMaterialRows(courseMaterials)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={2}>
                                            <Subtle>Er is geen lesmateriaal gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    public renderCourseMaterialRows = (courseMaterials: CourseMaterial[]) => {
        return courseMaterials.map(courseMaterial => (
            <TableRow key={courseMaterial._id}>
                <TableCell>
                    <Link route={`/properties/courseMaterials/${courseMaterial._id}`}>{courseMaterial.name}</Link>
                </TableCell>
                <TableCell>
                    {typeof courseMaterial.amount === 'number' && <Currency amount={courseMaterial.amount} />}
                </TableCell>
            </TableRow>
        ))
    }

    private renderActionBar = () => {
        const { loading } = this.courseMaterialsFetcher
        const filters = this.filter.getFilters()

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem>
                            <Search isLoading={loading} onSearch={this.onSearch} defaultValue={filters.searchText} />
                        </ListItem>
                        <ListItem right={true}>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} type={`edit`}>
                                        Lesmateriaal toevoegen
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <CenterModal onClose={closeModal} title={`Lesmateriaal toevoegen`}>
                                        <CreateCourseMaterialForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                                    </CenterModal>
                                )}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private loadMore = (skip: number, limit: number, callback: (options?: any) => void) => {
        this.courseMaterialsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.courseMaterials.length === 0,
                })

                return {
                    courseMaterials: removeDuplicateDocuments([
                        ...(prevData.courseMaterials || []),
                        ...moreData.courseMaterials,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.courseMaterialsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }
}
