import gql from 'graphql-tag'
import $ from 'jquery'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Calendar, CenterModal, Paragraph } from '~/components'
import { Wrap } from '~/components/Wrap'
import { CreateLearnerAvailabilityEventForm, EditLearnerAvailabilityEventForm } from '~/forms'
import { Fetcher, Mutator, transformFormFields } from '~/utils'

export default class LearnerAvailability extends Component {
    static propTypes = {
        userId: PropTypes.string.isRequired,
    }

    state = {
        createEventModalActive: false,
        createEventModalData: null,
        editEventModalActive: false,
        editEventModalData: null,
    }

    constructor(props) {
        super(props)

        this.createEventMutator = new Mutator({
            mutation: CREATE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.editEventMutator = new Mutator({
            mutation: EDIT_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteEventMutator = new Mutator({
            mutation: DELETE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.calendarEventsFetcher = new Fetcher({
            query: AVAILABILITY_EVENTS_QUERY,
            variables: {
                filters: {
                    byId: props.userId,
                },
            },

            onChange: () => this.forceUpdate(),
        })
    }

    onSelectSlot = (start, end) => {
        this.setState({
            createEventModalActive: true,
            createEventModalData: { start, end },
        })
    }

    onClickEvent = event => {
        this.setState({
            editEventModalActive: true,
            editEventModalData: event,
        })
    }

    onAddEventButtonClick = event => {
        this.setState({
            createEventModalActive: true,
            createEventModalData: { start: event.start },
        })
    }

    onMoveEvent = event => {
        const { _id, start, end } = event

        this.editEventMutator
            .mutate({
                calendarEvent: {
                    _id: _id,
                    startDate: start.toDate(),
                    endDate: end.toDate(),
                },
            })
            .then(data => {
                if (data) {
                    this.calendarEventsFetcher.refetch({ silent: true })
                }
            })
    }

    onRequestCloseCreateEventModal = () => {
        $(this.refs.Calendar.refs.calendar).fullCalendar('unselect')

        this.setState({
            createEventModalActive: false,
            createEventModalData: null,
        })
    }

    onRequestCloseEditEventModal = () => {
        this.setState({
            editEventModalActive: false,
            editEventModalData: null,
        })
    }

    createEvent = fields => {
        const { userId } = this.props

        this.createEventMutator
            .mutate({
                calendarEvent: {
                    type: 'LEARNER_UNAVAILABILITY',
                    ...transformFormFields(fields, {
                        typeData: {
                            fields: v =>
                                transformFormFields(v, {
                                    learnerUserId: { value: userId },
                                }),
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    this.onRequestCloseCreateEventModal()
                    this.calendarEventsFetcher.refetch()
                }
            })
    }

    editEvent(fields) {
        const { editEventModalData: event } = this.state

        this.editEventMutator
            .mutate({
                calendarEvent: {
                    _id: event._id,
                    ...transformFormFields(fields, {}),
                },
            })
            .then(data => {
                if (data) {
                    this.onRequestCloseEditEventModal()
                    this.calendarEventsFetcher.refetch()
                }
            })
    }

    removeEvent = () => {
        const { editEventModalData: event } = this.state

        this.deleteEventMutator
            .mutate({
                calendarEventId: event._id,
            })
            .then(data => {
                if (data) {
                    this.onRequestCloseEditEventModal()
                    this.calendarEventsFetcher.refetch()
                }
            })
    }

    getCalendarEvents = () => {
        const { data } = this.calendarEventsFetcher
        const calendarEvents = get(data, 'users[0].learner.availabilityCalendarEvents') || []
        return calendarEvents.map(e => ({
            ...e,
            start: e.startDate,
            end: e.endDate,
        }))
    }

    render() {
        const { createEventModalActive, editEventModalActive } = this.state
        const { loading } = this.calendarEventsFetcher

        return (
            <Wrap full>
                <Paragraph>Let op: enkel aangeven wanneer de kandidaat écht niet beschikbaar is!</Paragraph>
                {createEventModalActive && this.renderCreateEventModal()}
                {editEventModalActive && this.renderEditEventModal()}

                <Calendar
                    // TODO: Fix this issue and re-enable the no-string-refs rule
                    // eslint-disable-next-line react/no-string-refs
                    ref="Calendar"
                    isLoading={loading}
                    events={this.getCalendarEvents()}
                    defaultView="agendaWeek"
                    disableViewChange
                    isGeneral
                    height="auto"
                    minHours={8}
                    maxHours={22}
                    allowCreateEvents
                    allowEditEvents
                    selectSlotType="LEARNER_UNAVAILABILITY"
                    onSelectSlot={this.onSelectSlot}
                    onMoveEvent={this.onMoveEvent}
                    onResizeEvent={this.onMoveEvent}
                    onClickEvent={this.onClickEvent}
                    onAddEventButtonClick={this.onAddEventButtonClick}
                />
            </Wrap>
        )
    }

    renderCreateEventModal = () => {
        const { errors, loading } = this.createEventMutator
        const { createEventModalData: { start, end } = {} } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseCreateEventModal} title={`Afwezigheid toevoegen`}>
                <CreateLearnerAvailabilityEventForm
                    defaultValues={{
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.createEvent}
                    onCancel={this.onRequestCloseCreateEventModal}
                />
            </CenterModal>
        )
    }

    renderEditEventModal = () => {
        const { editEventModalData: { title, start, end } = {} } = this.state
        const { errors, loading } = this.editEventMutator

        return (
            <CenterModal onClose={this.onRequestCloseEditEventModal} title={`Afwezigheid aanpassen`}>
                <EditLearnerAvailabilityEventForm
                    defaultValues={{
                        title: title,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.editEvent}
                    onRemove={this.removeEvent}
                    onCancel={this.onRequestCloseEditEventModal}
                />
            </CenterModal>
        )
    }
}

const AVAILABILITY_EVENTS_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            learner {
                availabilityCalendarEvents {
                    _id
                    title
                    type
                    startDate
                    endDate
                }
            }
        }
    }
`

const CREATE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_create(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const EDIT_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_edit(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const DELETE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEventId: MongoID!) {
        calendarEvents_delete(calendarEventId: $calendarEventId) {
            _id
        }
    }
`
