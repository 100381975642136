import * as React from 'react'
import gql from 'graphql-tag'

import { TableRow, TableHeaderItem, Link, ReadableDate, Subtle, Icon, TableWrap, PdfModal } from '~/components'
import { ProgressTableAbilityItem } from '~/components/tables/ProgressTable/ProgressTableAbilityItem'
import { Group } from '~/types/Group'
import { Lesson } from '~/types/Lesson'
import { ExamAbilityResult, ExamAbility, Exam, ResultStatus } from '~/types/Exam'
import { translateType } from '~/shared/utils'
import {
    User,
    UserRole,
    IntakeGrades,
    LegacyIntakeGrades,
    LatestAbilityLevels,
    LearnerAbilityLevel,
} from '~/types/User'
import { TableView } from '~/components/TableView'
import { TableCell } from '~/components/TableCell'
import { ResultCell } from '~/components/tables/ProgressTable/ResultCell'
import TableHeader from '~/components/TableHeader'
import { AbilityExamResultCell } from '~/components/tables/ProgressTable/AbilityExamResultCell'
import { Module } from '~/types/Module'
import { viewerHasRole } from '~/services/session'
import { InflowMoment } from '~/types/InflowMoments'
import { LearnerLevelWithAlpha, LearnerLevel } from '~/types/LearnerLevel'
import { AdviceReport } from '~/types/AdviceReports'
import { ModalManager } from '../ModalManager'
import Mutator from '~/utils/Mutator'
import { SimpleTable } from '../SimpleTable'
import { SimpleTableRow } from '../SimpleTableRow'
import { SimpleTableCell } from '../SimpleTableCell'
import { DividerDot } from '../Core/DividerDot/DividerDot'
import { Button } from '../buttons/Button/Button'
import { BrickTableCell } from '../Table/BrickTableCell/BrickTableCell'
import { Table } from '../Table'
import { uniqWith } from 'lodash'
import { CandidateProgressStatusCell } from '../tables/ProgressTable/CandidateProgressStatusCell'
import { Translator } from '~/services/i18n'

interface Props {
    isLoading: boolean
    user?: User
    refetch?: () => void
}

interface ProgressResult {
    type: ProgressResultType
    date?: Date
    results: ProgressResultResult[]
}

enum ProgressResultType {
    LegacyIntake = 'LegacyIntake',
    InflowMoment = 'InflowMoment',
    ExamResult = 'ExamResult',
}

type ProgressResultResult = IntakeResult | ExamAbilityResult

interface IntakeResult {
    examAbility: ExamAbility
    resultLevel: LearnerLevel | LearnerLevelWithAlpha | null
}

interface LegacyIntakeResult extends ProgressResult {
    type: ProgressResultType.LegacyIntake
    date?: Date
    results: IntakeResult[]
}

interface InflowMomentResult extends ProgressResult {
    type: ProgressResultType.InflowMoment
    date: Date
    inflowMoment: InflowMoment
    results: IntakeResult[]
    adviceReport?: AdviceReport
}

interface LessonExamResult extends ProgressResult {
    type: ProgressResultType.ExamResult
    date: Date
    group: Group
    module: Module
    lesson: Lesson
    exam: Exam
    results: ExamAbilityResult[]
}

const CREATE_ADVICE_REPORT_FILE_MUTATION = gql`
    mutation _($adviceReportId: MongoID!) {
        adviceReports_generateFile(adviceReportId: $adviceReportId) {
            fileId
        }
    }
`

export class UserLearnerProgressTable extends React.Component<Props> {
    private translator = new Translator({
        namespace: 'App',
        subscribe: this,
    })

    private abilityColumns = [
        ExamAbility.Converse,
        ExamAbility.Talk,
        ExamAbility.Write,
        ExamAbility.Listen,
        ExamAbility.Read,
    ]

    private progressRowDateFormat = 'DD-MM-YYYY'

    private createAdviceReportFileMutator = new Mutator({
        mutation: CREATE_ADVICE_REPORT_FILE_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const { isLoading, user } = this.props
        const { t } = this.translator

        const progressResults = this.getProgressResults()

        return (
            <TableWrap>
                <TableView title={`Kandidaatniveau`}>
                    <Table>
                        {this.renderTableHeader()}
                        {isLoading ? (
                            <TableRow>
                                <TableCell colSpan={10} isLoading={true} />
                            </TableRow>
                        ) : progressResults.length > 0 ? (
                            this.renderRowsWithProgressResults(progressResults)
                        ) : (
                            this.renderEmptyRow()
                        )}
                        <TableRow isStatisticRow={true} isHighlighted={true}>
                            <TableCell colSpan={10}>
                                <SimpleTable>
                                    <SimpleTableRow>
                                        <SimpleTableCell>
                                            <Subtle regularSize={true}>Leerbaarheid score</Subtle>
                                        </SimpleTableCell>
                                        <SimpleTableCell>
                                            {user && user.learner && user.learner.intakeGradesLearnability
                                                ? `
                                            ${user.learner.intakeGradesLearnability}${t(
                                                      'Users.Common.Leerbaarheid.suffix'
                                                  )}
                                            `
                                                : ''}
                                        </SimpleTableCell>
                                    </SimpleTableRow>
                                </SimpleTable>
                            </TableCell>
                        </TableRow>
                    </Table>
                </TableView>
            </TableWrap>
        )
    }

    private renderTableHeader = () => {
        return (
            <TableHeader>
                <TableHeaderItem>Traject</TableHeaderItem>
                <TableHeaderItem>Lesmodule</TableHeaderItem>
                <TableHeaderItem>Toets</TableHeaderItem>
                <TableHeaderItem>Niveau</TableHeaderItem>
                <TableHeaderItem>Datum afname</TableHeaderItem>
                {this.abilityColumns.map((examAbility, i) => (
                    <ProgressTableAbilityItem
                        title={translateType('examAbilityColumnTitle', examAbility)}
                        key={i}
                        biggestTitleLength={this.getBiggestTitleLength()}
                    />
                ))}
            </TableHeader>
        )
    }

    private renderRowsWithProgressResults = (progressResults: ProgressResult[]) => {
        const { user } = this.props
        const latestAbilityLevels = user && user.learner && user.learner.latestAbilityLevels
        const hasLatestAbilityLevels = latestAbilityLevels && Object.keys(latestAbilityLevels).length > 0

        return (
            <React.Fragment>
                {latestAbilityLevels &&
                    hasLatestAbilityLevels &&
                    this.renderLatestAbilityLevelsRow(latestAbilityLevels)}
                {progressResults.map((progressResult, index) => {
                    const previousRow = progressResults[index - 1]
                    const isSameGroupAsPreviousRow =
                        !!previousRow &&
                        this.isLessonExamResult(previousRow) &&
                        this.isLessonExamResult(progressResult) &&
                        previousRow.group._id === progressResult.group._id

                    return (
                        <React.Fragment key={index}>
                            {this.renderProgressResultRow(progressResult, isSameGroupAsPreviousRow)}
                        </React.Fragment>
                    )
                })}
            </React.Fragment>
        )
    }

    private renderLatestAbilityLevelsRow = (latestAbilityLevels: LatestAbilityLevels) => {
        return (
            <TableRow>
                <TableCell colSpan={5} />
                {Object.values(latestAbilityLevels)
                    .filter(latestAbilityLevel => typeof latestAbilityLevel !== 'string')
                    .map((latestAbilityLevel: LearnerAbilityLevel, i) => {
                        if (latestAbilityLevel.level) {
                            return (
                                <BrickTableCell key={i}>
                                    {translateType('level', latestAbilityLevel.level)}
                                </BrickTableCell>
                            )
                        }

                        return <ResultCell key={i} />
                    })}
            </TableRow>
        )
    }

    private renderProgressResultRow = (progressResult: ProgressResult, isSameGroupAsPreviousRow: boolean) => {
        if (this.isLessonExamResult(progressResult)) {
            return this.renderExamResultRow(progressResult, isSameGroupAsPreviousRow)
        }

        if (this.isInflowMomentResult(progressResult)) {
            return this.renderInflowMomentRow(progressResult)
        }

        if (this.isLegacyIntakeResult(progressResult)) {
            return this.renderLegacyIntakeRow(progressResult)
        }

        return null
    }

    private renderExamResultRow = (progressResult: LessonExamResult, isSameGroupAsPreviousRow: boolean) => {
        return (
            <TableRow>
                <TableCell>{this.renderLessonExamName(progressResult.group, isSameGroupAsPreviousRow)}</TableCell>
                <TableCell>{module && this.renderModule(progressResult.module)}</TableCell>
                <TableCell>{this.renderExamName(progressResult.exam)}</TableCell>
                <TableCell>
                    {progressResult.exam.level ? translateType('level', progressResult.exam.level) : ''}
                </TableCell>
                <TableCell>
                    <ReadableDate date={progressResult.date} format={this.progressRowDateFormat} />{' '}
                    {progressResult.lesson &&
                        progressResult.lesson.teacherUser &&
                        `(${progressResult.lesson.teacherUser.profile.name})`}
                </TableCell>
                {this.mapExamAbilityWithResult<ExamAbilityResult>(progressResult.results, (examAbility, result) => (
                    <React.Fragment key={examAbility}>
                        {this.renderAbilityExamResultCell(examAbility, result)}
                    </React.Fragment>
                ))}
            </TableRow>
        )
    }

    private renderInflowMomentRow = (progressResult: InflowMomentResult) => {
        return (
            <>
                <TableRow>
                    <TableCell colSpan={4}>
                        {this.renderInflowMomentName(progressResult.inflowMoment)}
                        <DividerDot />
                        {progressResult.adviceReport && this.renderAdviceReportModal(progressResult.adviceReport)}
                    </TableCell>
                    <TableCell>
                        <ReadableDate date={progressResult.date} format={this.progressRowDateFormat} />
                        {progressResult.adviceReport && progressResult.adviceReport.intakerUser && (
                            <React.Fragment> ({progressResult.adviceReport.intakerUser.profile.name})</React.Fragment>
                        )}
                    </TableCell>
                    {this.mapExamAbilityWithResult<IntakeResult>(progressResult.results, (examAbility, result, i) => (
                        <React.Fragment key={i}>
                            <ResultCell title={result && translateType('level', result.resultLevel)} />
                        </React.Fragment>
                    ))}
                </TableRow>
            </>
        )
    }

    private renderAdviceReportModal = (adviceReport: AdviceReport) => {
        return (
            <ModalManager
                render={openModal => (
                    <Button linkStyle={'default'} small={true} onClick={openModal}>
                        {this.getAdviceReportFileName()}
                    </Button>
                )}
                getModal={closeModal => (
                    <PdfModal
                        title={this.getAdviceReportFileName()}
                        fileName={this.getAdviceReportFileName()}
                        getFileId={async () => await this.getAdviceReportFileId(adviceReport)}
                        onClose={closeModal}
                    />
                )}
            />
        )
    }

    private getAdviceReportFileName = (): string => {
        const { user } = this.props

        return `${user!.profile.name}-advies-rapport.pdf`
    }

    private getAdviceReportFileId = async (adviceReport: AdviceReport) => {
        if (adviceReport.file) {
            return adviceReport.file.fileId
        }

        const res = await this.createAdviceReportFileMutator.mutate({
            adviceReportId: adviceReport._id,
        })

        if (res && res.adviceReports_generateFile) {
            return res.adviceReports_generateFile.fileId
        }
    }

    private renderLegacyIntakeRow = (progressResult: LegacyIntakeResult) => {
        const user = this.props.user!

        return (
            <TableRow>
                <TableCell>{this.renderLegacyIntakeName(user)}</TableCell>
                <TableCell colSpan={3} />
                <TableCell>
                    <ReadableDate date={progressResult.date} format={this.progressRowDateFormat} />
                </TableCell>
                {this.mapExamAbilityWithResult<IntakeResult>(progressResult.results, (examAbility, result, i) => (
                    <React.Fragment key={i}>
                        <ResultCell title={result && translateType('level', result.resultLevel)} />
                    </React.Fragment>
                ))}
            </TableRow>
        )
    }

    private mapExamAbilityWithResult = <TResult extends ProgressResultResult, TMapReturn = any>(
        results: ProgressResultResult[],
        iteratee: (examAbility: ExamAbility, result: TResult | undefined, index: number) => TMapReturn
    ) => {
        return this.abilityColumns.map((examAbility, index) => {
            const result = this.findResult(results, examAbility) as TResult | undefined
            return iteratee(examAbility, result, index)
        })
    }

    private renderAbilityExamResultCell = (examAbility: ExamAbility, result?: ExamAbilityResult) => {
        if (result) {
            if (result.result) {
                if ([ResultStatus.Exempt, ResultStatus.NoShow].includes(result.result.status)) {
                    return <CandidateProgressStatusCell status={result.result.status} />
                } else {
                    return (
                        <AbilityExamResultCell
                            examAbility={result.exam![examAbility]}
                            examType={result.exam!.type}
                            examLevel={result.exam!.level}
                            result={result.result!}
                            files={result.files || []}
                        />
                    )
                }
            } else {
                return <ResultCell />
            }
        } else {
            return <ResultCell isNotScheduled={true} />
        }
    }

    private renderEmptyRow = () => {
        return (
            <TableRow>
                <TableCell colSpan={10}>
                    <Subtle>Er zijn geen resultaten gevonden.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    private findResult = (
        results: ProgressResultResult[],
        examAbility: ExamAbility
    ): ProgressResultResult | undefined => {
        return results.find(result => {
            return result.examAbility === examAbility
        })
    }

    private getBiggestTitleLength = () => {
        const biggestTitleLength = Math.max(
            ...this.abilityColumns.map(columnTitle => translateType('examAbilityColumnTitle', columnTitle).length)
        )

        return biggestTitleLength
    }

    private renderModule = (module: Module) => {
        if (viewerHasRole(UserRole.Employee)) {
            return <Link route={`/properties/modules/${module._id}`}>{module.name}</Link>
        }

        return module.name
    }

    private renderExamName = (exam: Exam) => {
        if (viewerHasRole(UserRole.Employee)) {
            return <Link route={`/properties/exams/${exam._id}`}>{exam.name}</Link>
        }

        return exam.name
    }

    private renderLessonExamName = (group: Group, isSameGroupAsPreviousRow: boolean) => {
        if (isSameGroupAsPreviousRow) {
            return <Icon name={`nesting`} />
        }

        if (viewerHasRole(UserRole.Employee)) {
            return <Link route={`/groups/${group._id}`}>{group.name}</Link>
        }

        return group.name
    }

    private renderLegacyIntakeName = (user: User) => {
        const label = 'Intake'
        const route = `/users/learners/${user._id}`

        if (viewerHasRole(UserRole.Employee)) {
            return <Link route={route}>{label}</Link>
        }

        return label
    }

    private renderInflowMomentName = (inflowMoment: InflowMoment) => {
        const label = 'Instroom'
        const route = `/inflow-moments/definitive/${inflowMoment._id}`

        if (viewerHasRole(UserRole.Employee)) {
            return <Link route={route}>{label}</Link>
        }

        return label
    }

    private getLegacyIntakeGradeByExamAbility(
        legacyIntakeGrades: LegacyIntakeGrades,
        examAbility: ExamAbility
    ): LearnerLevel | LearnerLevelWithAlpha | undefined {
        return this.getLegacyIntakeGrade(examAbility, legacyIntakeGrades)
    }

    private getExamAbility(examAbility: ExamAbility, grades: AdviceReport | IntakeGrades) {
        switch (examAbility) {
            case ExamAbility.Converse:
                return grades.levelConversations
            case ExamAbility.Talk:
                return grades.levelTalking
            case ExamAbility.Write:
                return grades.levelWriting
            case ExamAbility.Listen:
                return grades.levelListening
            case ExamAbility.Read:
                return grades.levelReading
            default:
                return undefined
        }
    }

    private getLegacyIntakeGrade(examAbility: ExamAbility, grades: LegacyIntakeGrades) {
        switch (examAbility) {
            case ExamAbility.Converse:
                return grades.conversations
            case ExamAbility.Talk:
                return grades.talking
            case ExamAbility.Write:
                return grades.writing
            case ExamAbility.Listen:
                return grades.listening
            case ExamAbility.Read:
                return grades.reading
            default:
                return undefined
        }
    }

    private getProgressResults(): ProgressResult[] {
        const legacyIntakeGrades = this.getLegacyIntakeGradesFromUser()

        const results = [
            ...this.getExamResults(),
            ...this.getInflowMomentResults(),
            ...(legacyIntakeGrades ? [this.getLegacyIntakeResult(legacyIntakeGrades)] : []),
        ]

        return results
            .sort((a, b) => {
                if (!a.date && !b.date) {
                    return 0
                }

                if (!a.date) {
                    return 1
                }

                if (!b.date) {
                    return -1
                }

                return new Date(b.date).getTime() - new Date(a.date).getTime()
            })
            .map(result => {
                return {
                    ...result,
                    results: result.results,
                }
            })
    }

    private getLegacyIntakeGradesFromUser(): LegacyIntakeGrades | null {
        const { user } = this.props

        return (user && user.learner && user.learner.legacyIntakeGrades) || null
    }

    private getLegacyIntakeResult(legacyIntakeGrades: LegacyIntakeGrades): LegacyIntakeResult {
        const { user } = this.props

        return {
            type: ProgressResultType.LegacyIntake,
            date: (user && user.learner && user.learner.intakeDate) || undefined,
            results: this.abilityColumns.map(examAbility => {
                return {
                    examAbility,
                    resultLevel: this.getLegacyIntakeGradeByExamAbility(legacyIntakeGrades, examAbility) || null,
                }
            }),
        }
    }

    private getInflowMomentResults(): InflowMomentResult[] {
        const { user } = this.props

        if (user && user.learner && user.learner.inflowMoments) {
            return user.learner.inflowMoments.map<InflowMomentResult>(inflowMoment => {
                const adviceReports = (user.learner && user.learner.adviceReports) || []
                const adviceReport = adviceReports.find(report => {
                    return report.inflowMoment._id === inflowMoment._id
                })

                return {
                    type: ProgressResultType.InflowMoment,
                    date: inflowMoment.dateRange.from,
                    inflowMoment,
                    adviceReport,
                    results: this.abilityColumns.map(examAbility => {
                        return {
                            examAbility,
                            resultLevel: adviceReport ? this.getExamAbility(examAbility, adviceReport) || null : null,
                        }
                    }),
                }
            })
        }

        return []
    }

    private getExamResults(): LessonExamResult[] {
        const { user } = this.props
        const results = (user && user.results) || []

        const filteredResults = results.filter(result => {
            return (
                result.plannedAbilityExam &&
                result.plannedAbilityExam.group &&
                result.plannedAbilityExam.group.module &&
                result.plannedAbilityExam.lesson &&
                result.exam
            )
        })

        const uniqueLessonExamResults = uniqWith(filteredResults, (resultA, resultB) => {
            return (
                resultA.examId === resultB.examId &&
                resultA.plannedAbilityExam!.lesson!._id === resultB.plannedAbilityExam!.lesson!._id
            )
        })

        return uniqueLessonExamResults.map(uniqueLessonExamResult => {
            const { exam, plannedAbilityExam } = uniqueLessonExamResult

            const lessonExamResult: LessonExamResult = {
                type: ProgressResultType.ExamResult,
                group: plannedAbilityExam!.group!,
                module: plannedAbilityExam!.group!.module!,
                lesson: plannedAbilityExam!.lesson!,
                date: plannedAbilityExam!.lesson!.date,
                exam: exam!,
                results: plannedAbilityExam!.lesson!.plannedAbilityExams!.map(plannedAbilityExam => {
                    const result = filteredResults.find(r => {
                        return r.plannedAbilityExam!._id === plannedAbilityExam._id
                    })

                    if (!result) {
                        // Return fake ExamAbilityResult with empty result
                        return {
                            _id: 'no-result',
                            exam,
                            examAbility: plannedAbilityExam.examAbility,
                            files: [],
                            plannedAbilityExam,
                            result: undefined,
                        }
                    }

                    return result
                }),
            }

            return lessonExamResult
        })
    }

    private isLessonExamResult = (progressResult: ProgressResult): progressResult is LessonExamResult => {
        return progressResult.type === ProgressResultType.ExamResult
    }

    private isInflowMomentResult = (progressResult: ProgressResult): progressResult is InflowMomentResult => {
        return progressResult.type === ProgressResultType.InflowMoment
    }

    private isLegacyIntakeResult = (progressResult: ProgressResult): progressResult is LegacyIntakeResult => {
        return progressResult.type === ProgressResultType.LegacyIntake
    }
}
