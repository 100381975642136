import React from 'react'
import Link from '~/components/Link'
import { Wrap } from '~/components/Wrap'
import { ProcessList } from '~/components/User/Learner/Process/ProcessList'
import { Spinner, Subtle, Paragraph } from '~/components'
import gql from 'graphql-tag'
import Fetcher from '~/utils/Fetcher'
import { get, isNumber } from 'lodash'
import { StatisticWrap } from '~/components/Core/StatisticWrap/StatisticWrap'
import { StatisticTable } from '~/components/Table/StatisticTable/StatisticTable'
import { StatisticTableRow } from '~/components/Table/StatisticTable/StatisticTableRow'
import { StatisticTableCell } from '~/components/Table/StatisticTable/StatisticTableCell'
import { translateType } from '~/shared/utils'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import { UserLearnerHoursSummary, User } from '~/types/User'
import { View } from '~/components/View'

interface Props {
    userId: string
}

export default class ProcessOverviewView extends React.Component<Props> {
    private userFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.userFetcher = new Fetcher({
            query: GET_USER_DATA_QUERY,
            variables: {
                filters: {
                    byId: props.userId,
                },
                filterByActiveAndNeeded: true,
            },

            onChange: () => this.forceUpdate(),
            transformData: data => ({
                user: get(data, 'users[0]'),
            }),
        })
    }

    public render() {
        const { userId } = this.props
        const hoursSummary = this.getHoursSummary()

        return (
            <View>
                <Wrap full={true}>
                    {hoursSummary && !!hoursSummary.hoursTotal && hoursSummary.hoursTotal > 0 && (
                        <StatisticWrap
                            title={`Urenverantwoording inburgering`}
                            renderAction={() => (
                                <Link small={true} route={`/learners/${userId}/process/hours`}>
                                    Bekijk alle uren
                                </Link>
                            )}
                        >
                            {this.renderUserLearnerHours()}
                        </StatisticWrap>
                    )}
                </Wrap>
                <Wrap full={true}>
                    <ProcessList userId={userId} hideFieldsForOrganizationContact />
                </Wrap>
            </View>
        )
    }

    private renderUserLearnerHours() {
        const { loading } = this.userFetcher
        const hoursSummary = this.getHoursSummary()

        if (loading) {
            return <Spinner />
        }

        if (!hoursSummary) {
            return
        }

        return (
            <StatisticTable>
                <StatisticTableRow>
                    {isNumber(hoursSummary.hoursCitizenship) && (
                        <StatisticTableCell
                            statistic={String(hoursSummary.hoursCitizenship)}
                            subStatistic={translateType(
                                'userLearnerHourDescription',
                                'CITIZENSHIP_OF_WHICH_ONA_SHORT',
                                hoursSummary.hoursCitizenshipOfWhichONA
                            )}
                            description={translateType('userLearnerHourDescription', 'CITIZENSHIP')}
                        />
                    )}
                    {isNumber(hoursSummary.hoursAlfa) && (
                        <StatisticTableCell
                            statistic={String(hoursSummary.hoursAlfa)}
                            description={translateType('userLearnerHourDescription', 'ALFA')}
                        />
                    )}
                    {isNumber(hoursSummary.hoursOther) && (
                        <StatisticTableCell
                            statistic={String(hoursSummary.hoursOther)}
                            subStatistic={translateType(
                                'userLearnerHourDescription',
                                'OTHER_OF_WHICH_ONA_SHORT',
                                hoursSummary.hoursOtherOfWhichONA
                            )}
                            description={translateType('userLearnerHourDescription', 'OTHER')}
                        />
                    )}
                </StatisticTableRow>
                <TableRow isStatisticRow={true}>
                    <TableCell colSpan={3}>
                        <Paragraph>
                            <Subtle>Totaal aantal uren inburgering</Subtle> {hoursSummary.hoursTotal || 0}
                        </Paragraph>
                    </TableCell>
                </TableRow>
            </StatisticTable>
        )
    }

    private getHoursSummary(): UserLearnerHoursSummary | undefined {
        const { data } = this.userFetcher
        const user = data && (data.user as User)
        return user && user.learner && user.learner.hoursSummary
    }
}

const GET_USER_DATA_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            learner {
                enrollmentType
                enrollmentDate
                isAvailableForInflow
                hoursSummary {
                    hoursCitizenship
                    hoursCitizenshipOfWhichONA
                    hoursAlfa
                    hoursOther
                    hoursOtherOfWhichONA
                    hoursTotal
                }
            }
        }
    }
`
