import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, CheckBox, Field, FieldCollection, Form, Input, MultiInput } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class CreateImportVideoForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
    }
    instructionVideoMutator: Mutator

    constructor(props: any) {
        super(props)

        bindAll(this, ['checkRoles', 'onSubmit'])

        this.state = {
            targetRoles: [],
            forEmployee: false,
            forTeacher: false,
            forOrganizationContact: false,
        }

        this.instructionVideoMutator = new Mutator({
            mutation: CREATE_IMPORT_VIDEO_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event: any, fields: { instructionVideo: {} | undefined }) => {
        const { targetRoles } = this.state as any
        const { onSubmitSuccess } = this.props as any

        this.instructionVideoMutator
            .mutate({
                instructionVideo: {
                    ...transformFormFields(fields.instructionVideo, {}),
                    targetRoles,
                },
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/instructionvideos/${get(data, 'instructionVideos_create._id')}`)
                }
            })
    }

    checkRoles(event: any) {
        const {
            target: { value, checked },
        } = event
        const { targetRoles } = this.state as any

        // Add to array of targetRoles
        if (checked && !targetRoles.includes(value)) {
            this.setState({
                targetRoles: [...targetRoles, value],
            })
        }

        // Remove from array of targetRoles
        if (!checked && targetRoles.includes(value)) {
            this.setState({
                targetRoles: [...targetRoles.splice(targetRoles.indexOf(value), 1)],
            })
        }
    }

    isChecked(role: any) {
        const { targetRoles } = this.state as any

        return targetRoles.includes(role)
    }

    render() {
        const { errors, loading } = this.instructionVideoMutator
        const { onCancel } = this.props as any

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field isLabel title={'Naam'} errors={errors}>
                            <Input
                                name="instructionVideo.name"
                                type={'text'}
                                placeholder={`Titel van de instructievideo`}
                            />
                        </Field>
                        <Field isLabel title={`Doelgroep`} errors={errors}>
                            <MultiInput type={`checkbox`}>
                                <CheckBox
                                    name={`targetRoles`}
                                    value={`EMPLOYEE`}
                                    onChange={this.checkRoles}
                                    checked={this.isChecked('EMPLOYEE')}
                                >
                                    Medewerker
                                </CheckBox>
                                <CheckBox
                                    name={`targetRoles`}
                                    value={`TEACHER`}
                                    onChange={this.checkRoles}
                                    checked={this.isChecked('TEACHER')}
                                >
                                    Externe docent
                                </CheckBox>
                                <CheckBox
                                    name={`targetRoles`}
                                    value={`ORGANIZATION_CONTACT`}
                                    onChange={this.checkRoles}
                                    checked={this.isChecked('ORGANIZATION_CONTACT')}
                                >
                                    Organisatie
                                </CheckBox>
                            </MultiInput>
                        </Field>
                        <Field isLabel title={`YouTube url`} errors={errors}>
                            <Input
                                name={'instructionVideo.url'}
                                type={`text`}
                                placeholder={`Link naar de instructievideo`}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const CREATE_IMPORT_VIDEO_MUTATION = gql`
    mutation _($instructionVideo: InstructionVideoInputType!) {
        instructionVideos_create(instructionVideo: $instructionVideo) {
            _id
        }
    }
`
