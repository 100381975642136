import gql from 'graphql-tag'
import * as React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Brand, Button, Field, FieldCollection, Form, Input, Link, CoverPageTitle, Stretch } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { View } from '~/components/View'
import { Translator } from '~/services/i18n'
import { logIn } from '~/services/session'
import { Mutator, toast } from '~/utils'
import { PasswordInput } from '~/components/PasswordInput/PasswordInput'
import { ErrorLabel } from '~/components/ErrorLabel/ErrorLabel'

interface Params {
    token: string
}

interface Props extends RouteComponentProps<Params, {}> {}

interface State {
    password?: string | null
    passwordConfirm?: string | null
    passwordScore: number
}

const RESET_MUTATION = gql`
    mutation _($token: String, $password: String, $passwordConfirmation: String) {
        users_change_password(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
            _id
            email
        }
    }
`

export default class ResetView extends React.Component<Props, State> {
    public state: State = {
        password: undefined,
        passwordConfirm: undefined,
        passwordScore: 0,
    }

    private resetMutator: Mutator
    private translator: Translator

    constructor(props: Props) {
        super(props)

        this.resetMutator = new Mutator({
            mutation: RESET_MUTATION,
            reactComponentToUpdate: this,
        })

        this.translator = new Translator({
            namespace: 'Cover.Reset',
            subscribe: this,
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { errors, loading } = this.resetMutator
        const { t } = this.translator

        return (
            <View>
                <Brand>{t('brand')}</Brand>
                <Form onSubmit={this.onSubmit}>
                    <CoverPageTitle>{t('title')}</CoverPageTitle>
                    <FieldCollection style={`cover`}>
                        <FieldGroup isForm={true}>
                            <Field isLabel={true} title={t('fields.password.label')} style={`compact`} errors={errors}>
                                <PasswordInput
                                    name={`password`}
                                    placeholder={t('fields.password.placeholder')}
                                    onChangePassword={password => this.setState({ password })}
                                    onStrengthChange={(passwordScore: number) => this.setState({ passwordScore })}
                                />
                                <ErrorLabel>{this.getErrorLabelForPassword()}</ErrorLabel>
                            </Field>
                            <Field
                                isLabel={true}
                                title={t('fields.passwordConfirmation.label')}
                                style={`compact`}
                                errors={errors}
                            >
                                <Input
                                    name={`passwordConfirmation`}
                                    type={`password`}
                                    placeholder={t('fields.passwordConfirmation.placeholder')}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                                        this.setState({ passwordConfirm: event.target.value })
                                    }
                                />
                                <ErrorLabel>{this.getErrorLabelForPasswordConfirm()}</ErrorLabel>
                            </Field>
                            <Field>
                                <List>
                                    <ListItem>
                                        <Stretch>
                                            <Button
                                                type={`submit`}
                                                styleOverride={`edit`}
                                                isLoading={loading}
                                                isDisabled={!this.canSubmitForm()}
                                            >
                                                {t('buttons.submit')}
                                            </Button>
                                        </Stretch>
                                    </ListItem>
                                    <ListItem>
                                        <Stretch>
                                            <Link route={`/login`}>{t('links.login')}</Link>
                                        </Stretch>
                                    </ListItem>
                                </List>
                            </Field>
                        </FieldGroup>
                    </FieldCollection>
                </Form>
            </View>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { params } = this.props

        if (!this.canSubmitForm()) {
            return
        }

        const data = await this.resetMutator.mutate({
            token: params.token,
            password: fields.password,
            passwordConfirmation: fields.passwordConfirmation,
        })

        if (data) {
            const { email } = data.users_change_password

            toast.success('Je wachtwoord is gewijzigd')

            try {
                await logIn(email, fields.password)
                browserHistory.push('/')
            } catch (error) {
                toast.warning('Automatisch inloggen mislukt')
            }
        }
    }

    private getErrorLabelForPassword() {
        const { password, passwordConfirm, passwordScore } = this.state
        const { t } = this.translator

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 4) {
            return t(`errorLabels.passwordNotStrongEnough`)
        }

        return null
    }

    private getErrorLabelForPasswordConfirm() {
        const { password, passwordConfirm, passwordScore } = this.state
        const { t } = this.translator

        if (!password || !passwordConfirm) {
            return null
        }

        if (passwordScore < 4) {
            return null
        }

        if (password !== passwordConfirm) {
            return t(`errorLabels.passwordDoesNotMatch`)
        }

        return null
    }

    private canSubmitForm() {
        const { password, passwordConfirm, passwordScore } = this.state

        if (!password || !passwordConfirm) {
            return false
        }

        if (password !== passwordConfirm) {
            return false
        }

        if (passwordScore < 4) {
            return false
        }

        return true
    }
}
