import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class CreateLocationPropertyForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.locationPropertyMutator = new Mutator({
            mutation: CREATE_LOCATION_PROPERTY_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.locationPropertyMutator
            .mutate({
                locationProperty: transformFormFields(fields.locationProperty, {}),
            })
            .then(data => {
                if (data) {
                }
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/locationProperties/${get(data, 'locationProperties_create._id')}`)
                } else {
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    render() {
        const { errors, loading } = this.locationPropertyMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input
                                name={`locationProperty.name`}
                                type={`text`}
                                placeholder={`Naam locatiekenmerk`}
                                autoFocus={true}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const CREATE_LOCATION_PROPERTY_MUTATION = gql`
    mutation _($locationProperty: LocationPropertyInputType!) {
        locationProperties_create(locationProperty: $locationProperty) {
            _id
            name
        }
    }
`
