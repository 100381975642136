import gql from 'graphql-tag'
import React from 'react'
import { browserHistory } from 'react-router'
import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Mutator, transformFormFields } from '~/utils'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
    inflowModuleId: string
}

interface CreateInflowModuleInviteFields {
    inflowInviteTemplate: {
        name: string
    }
}

export class CreateInflowInviteTemplateForm extends React.Component<Props> {
    private inflowInviteTemplateMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.inflowInviteTemplateMutator = new Mutator({
            mutation: CREATE_INFLOW_INVITE_TEMPLATE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.inflowInviteTemplateMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Naam`} errors={errors}>
                            <Input
                                name={`inflowInviteTemplate.name`}
                                type={`text`}
                                placeholder={`Naam van uitnodigingsbrief`}
                                autoFocus={true}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: CreateInflowModuleInviteFields) => {
        const { onSubmitSuccess, onSubmitFailed, inflowModuleId } = this.props

        const data = await this.inflowInviteTemplateMutator.mutate({
            data: transformFormFields(
                {
                    ...fields.inflowInviteTemplate,
                    inflowModuleId,
                },
                {}
            ),
        })

        if (data && data.inflowInviteTemplates_create && data.inflowInviteTemplates_create._id) {
            onSubmitSuccess()
            browserHistory.push(
                `/properties/inflow/${inflowModuleId}/invites/${data.inflowInviteTemplates_create._id}/edit`
            )
        } else {
            if (onSubmitFailed) {
                onSubmitFailed()
            }
        }
    }
}

const CREATE_INFLOW_INVITE_TEMPLATE_MUTATION = gql`
    mutation ($data: InflowInviteTemnplateInputType!) {
        inflowInviteTemplates_create(inflowInviteTemplate: $data) {
            _id
            inflowModule {
                _id
                name
            }
            name
            updatedAt
            nl {
                content
            }
            en {
                content
            }
            attachments {
                _id
                fileName
            }
        }
    }
`
