import * as React from 'react'

import { DefaultAmountMultiInput } from '~/components/MultiInput/DefaultAmountMultiInput'
import gql from 'graphql-tag'
import transformFormFields from '~/utils/transformFormFields'
import Mutator from '~/utils/Mutator'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import { FieldText } from '~/components/FieldText'
import { Currency } from '~/components/Currency'
import { UnitInput, unitType } from '~/components/UnitInput'
import Input from '~/components/Input'
import Bold from '~/components/Bold'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { Button } from '~/components/buttons/Button/Button'
import { ListItem } from '~/components/ListItem'
import { isNumber } from 'util'
import { FinalExam } from '~/types/FinalExam'
import { translateType } from '~/shared/utils'
import Subtle from '~/components/Subtle'

interface Props {
    onSubmitSuccess: () => void
    onCancel?: () => void
    onRequestSend: (id: any) => void
    finalExam: FinalExam
    isForDUO: boolean
}

interface FormFields {
    invoice: {
        alternativeAmountDescription: string | null
        amount: number | null
    }
}

type FormSubmit<Fields> = (event: React.SyntheticEvent<HTMLFormElement>, fields: Fields) => void

interface State {
    alternativeAmount: number | null
    isCreatingInvoice: boolean
}

const CREATE_DEBIT_INVOICE_MUTATION = gql`
    mutation _($invoice: InvoiceInputType!) {
        invoices_createDebit(invoice: $invoice) {
            _id
        }
    }
`

export class ActivateFinalExamInvoiceForm extends React.Component<Props, State> {
    private sendInvoiceOnSubmit: boolean
    private invoiceMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.invoiceMutator = new Mutator({
            mutation: CREATE_DEBIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.state = {
            alternativeAmount: null,
            isCreatingInvoice: false,
        }
    }

    public render() {
        const { errors } = this.invoiceMutator
        const { onCancel, finalExam, isForDUO } = this.props
        const { alternativeAmount, isCreatingInvoice } = this.state
        const amount = this.getAmount()

        const readableFinalExamPart = translateType('finalExamPart', finalExam.part)

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title={`Examen`}>
                            <FieldText>
                                {`${readableFinalExamPart}${finalExam.level ? ` ${finalExam.level}` : ''}`}{' '}
                                <Subtle>Poging {finalExam.attempt}</Subtle>
                            </FieldText>
                        </Field>
                        <Field title={`Examenprijs`}>
                            <DefaultAmountMultiInput>
                                <FieldText>
                                    <Currency amount={finalExam.cost} />
                                </FieldText>
                                <UnitInput
                                    name={`invoice.amount`}
                                    value={alternativeAmount || ''}
                                    placeholder={`Alternatief factuurbedrag`}
                                    onChange={this.handleAlternativeAmountChange}
                                    unitType={unitType.euro}
                                />
                            </DefaultAmountMultiInput>
                        </Field>
                        {this.hasAlternativeAmount() && (
                            <Field title={`Toelichting`} errors={errors} isRequired={true}>
                                <Input
                                    type={`text`}
                                    placeholder={`Toelichting op alternatief factuurbedrag`}
                                    name={`invoice.alternativeAmountDescription`}
                                />
                            </Field>
                        )}
                    </FieldGroup>
                    <FieldGroup highlight>
                        <React.Fragment>
                            <Field title={`Factuurbedrag`}>
                                <Bold>
                                    <Currency amount={amount} />
                                </Bold>
                            </Field>
                            {isForDUO && (
                                <Field>
                                    Het bedrag wordt, indien nodig, automatisch opgesplitst in termijnen voor DUO.
                                </Field>
                            )}
                        </React.Fragment>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button
                                    type={`submit`}
                                    styleOverride={`secondary-submit`}
                                    isLoading={isCreatingInvoice && !this.sendInvoiceOnSubmit}
                                >
                                    Activeren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button
                                    type={`submit`}
                                    shouldNotPreventDefaultOnClick={true}
                                    isLoading={isCreatingInvoice && this.sendInvoiceOnSubmit}
                                    onClick={() => (this.sendInvoiceOnSubmit = true)}
                                >
                                    Activeren en versturen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    public hasAlternativeAmount() {
        const { alternativeAmount } = this.state

        return isNumber(alternativeAmount)
    }

    public handleAlternativeAmountChange = (event: any) => {
        const amount = parseFloat(event.target.value)

        this.setState({
            alternativeAmount: isNaN(amount) ? null : amount,
        })
    }

    private onSubmit: FormSubmit<FormFields> = async (event, fields) => {
        const { sendInvoiceOnSubmit } = this

        const { onSubmitSuccess, finalExam, onRequestSend } = this.props

        this.setState({ isCreatingInvoice: true }, () => {
            this.sendInvoiceOnSubmit = false
        })

        const data = await this.invoiceMutator.mutate({
            invoice: {
                ...transformFormFields(fields.invoice, {
                    amount: {
                        value: () => this.getAmount(),
                    },
                    alternativeAmountDescription: (v: any) => v && v.trim(),
                }),

                isFinalExamInvoice: true,
                finalExamId: finalExam._id,
            },
        })

        if (data && data.invoices_createDebit) {
            if (sendInvoiceOnSubmit) {
                await onRequestSend(data.invoices_createDebit._id)
            }

            onSubmitSuccess()
        }

        this.setState({ isCreatingInvoice: false })
    }

    private getAmount() {
        return this.hasAlternativeAmount() ? this.state.alternativeAmount : this.props.finalExam.cost
    }
}
