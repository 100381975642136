import { get, isArray, isEmpty, noop, has } from 'lodash'
import historyStore from '~/services/historyStore'
import { TagPicker } from '~/components/TagPicker'

export interface FilterTransformerType<TFilters> {
    transformTriggerKeys: string[] // Keys to trigger the transformer on.
    transform: (filters: TFilters) => Partial<TFilters> // Transform html input into filters.
    historyTriggerKeys: string[] // Keys to trigger the historyParser on. Use with `options.useHistory = true`.
    // tslint:disable-next-line: max-line-length
    parseFromHistory: (filters: TFilters) => Partial<TFilters> // Transform filters (always coming from the navigation history) back into html input. Use with `options.useHistory = true`.
}

export interface FilterOptionsType<TFilters> {
    allowedKeys?: string[] // Keys to allow in the filter (to prevent bad requests).
    onChange?: (filters: TFilters) => void // Fired when filters change, meant to fire request. First arg is the filters object.
    useHistory?: boolean
    // tslint:disable-next-line: max-line-length
    customTransformers?: FilterTransformerType<TFilters>[] // Specification of custom filter trasnformers. Useful when html input names don't exactly represent filter signature for request.
    defaultFilterState?: TFilters
}

export interface ExcludeFiltersOptions {
    excludeKeys?: string[]
    excludeKeyValuePairs?: { key: string; value: any }[]
}

interface FiltersAnyType {
    [key: string]: any
}

export default class Filter<TFilters = FiltersAnyType> {
    private allowedKeys: string[]
    private onChange: (filters: TFilters) => void
    private useHistory = false
    private customTransformers: FilterTransformerType<TFilters>[] = []

    private filters: TFilters
    private customFilters: TFilters
    private defaultFilterState: TFilters | undefined

    public constructor({
        allowedKeys,
        onChange,
        useHistory,
        customTransformers,
        defaultFilterState,
    }: FilterOptionsType<TFilters> = {}) {
        this.allowedKeys = allowedKeys || []
        this.onChange = onChange || noop
        this.useHistory = useHistory || false
        this.customTransformers = customTransformers || []
        this.defaultFilterState = defaultFilterState

        this.filters = {} as TFilters
        this.customFilters = {} as TFilters
        this._initialize()
    }

    public getFilters(): TFilters {
        return this.filters
    }

    public getCustomFilters(): TFilters {
        return this.customFilters
    }

    public hasFilters({ excludeKeys = [], excludeKeyValuePairs = [] }: ExcludeFiltersOptions = {}): boolean {
        return (
            Object.keys(this.filters).filter(key => {
                return (
                    excludeKeys.indexOf(key) === -1 &&
                    !excludeKeyValuePairs.find(kvp => kvp.key === key && kvp.value === this.filters[key])
                )
            }).length > 0
        )
    }

    public apply(key: string, value: any, preventOnChange?: boolean) {
        if (this.allowedKeys.indexOf(key) > -1) {
            this._setKeyValue(key, value)
        }

        const customTransformers = this._findCustomTransformerForTransformTriggerKey(key)
        if (customTransformers.length > 0) {
            this.customFilters[key] = value

            customTransformers.forEach(({ transform }) => {
                const filters = transform(this.customFilters) || {}

                for (const _key in filters) {
                    if (!filters.hasOwnProperty(_key)) {
                        continue
                    }
                    this._setKeyValue(_key, filters[_key])
                }
            })
        }

        this._updateHistory()

        if (preventOnChange !== true) {
            this.onChange(this.filters)
        }
    }

    public applyFromInputEvent(event: React.ChangeEvent<HTMLInputElement>) {
        if (!event) {
            throw new Error('No event provided')
        }

        const key = get(event, 'target.name')
        const value = get(event, 'target.type') === 'checkbox' ? event.target.checked : event.target.value || null

        if (key) {
            this.apply(key, value)
        }
    }

    public applyFromTagPicker(tagPicker: TagPicker) {
        const key = get(tagPicker, 'props.name')
        const value = isArray(tagPicker.state.value)
            ? tagPicker.state.value.map(option => option.value)
            : tagPicker.state.value && tagPicker.state.value.value

        if (key) {
            this.apply(key, value)
        }
    }

    private _setKeyValue(key: string, value: any) {
        if (typeof value !== 'undefined' && value !== null && (!isArray(value) || value.length !== 0)) {
            if (isArray(value) && isEmpty(value)) {
                if (key in this.filters) {
                    delete this.filters[key]
                }
            } else {
                this.filters[key] = value
            }
        } else {
            delete this.filters[key]
        }
    }

    private _findCustomTransformerForTransformTriggerKey(key: string) {
        return this.customTransformers.filter(({ transformTriggerKeys }) => {
            return transformTriggerKeys.indexOf(key) > -1
        })
    }

    private _findCustomTransformerForHistoryTriggerKey(key: string) {
        return this.customTransformers.filter(({ historyTriggerKeys }) => {
            return historyTriggerKeys.indexOf(key) > -1
        })
    }

    private _updateHistory() {
        if (this.useHistory) {
            try {
                historyStore.set('filters', this.filters)
                historyStore.set('customFilters', this.customFilters)
            } catch (err) {
                // tslint:disable-next-line: no-console
                console.warn('Error while updating history:', err)
            }
        }
    }

    private _initialize() {
        if (this.useHistory) {
            try {
                this._applyHistory()
            } catch (err) {
                // tslint:disable-next-line: no-console
                console.warn('Error while applying history:', err)
            }
        }

        if (!this.hasFilters()) {
            this._applyDefaultFilters()
        }
    }

    private _applyHistory() {
        const hist = (historyStore.get('filters') as TFilters) || ({} as TFilters)
        this.customFilters = (historyStore.get('customFilters') as TFilters) || ({} as TFilters)

        for (const key in hist) {
            if (!has(hist, key)) {
                continue
            }
            const value = hist[key]

            if (this.allowedKeys.indexOf(key) === -1) {
                // tslint:disable-next-line: no-console
                console.warn(
                    `History filter key "${key}" not allowed. You may be facing a newer version of the application.`
                )
                this.filters = {} as TFilters
                historyStore.set('filters', this.filters)
                break
            }

            this.filters[key] = value

            this._findCustomTransformerForHistoryTriggerKey(key).forEach(({ parseFromHistory }) => {
                const customFilters = (parseFromHistory(hist) as TFilters) || ({} as TFilters)

                for (const _key in customFilters) {
                    if (!has(customFilters, _key)) {
                        continue
                    }
                    this.customFilters[_key] = customFilters[_key]
                }
            })
        }
    }

    private _applyDefaultFilters() {
        if (!this.defaultFilterState || typeof this.defaultFilterState !== 'object') {
            return
        }

        for (const key in this.defaultFilterState) {
            if (!has(this.defaultFilterState, key)) {
                continue
            }

            /**
             * 3rd argument "true" is for preventing the onChange to be called
             * this is because this function is called in the initialize phase
             * and calling onChange before any render cycle causes errors
             */
            this.apply(key, this.defaultFilterState[key], true)
        }
    }
}
