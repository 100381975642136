import gql from 'graphql-tag'
import React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import { Button, CenterModal, Field, FieldCollection } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { ModalManager } from '~/components/ModalManager'
import { Wrap } from '~/components/Wrap'
import { RoomsForm } from '~/forms'
import { Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { Room } from '~/types/Location'
import { FormFields } from '~/components/Form'

const EDIT_ROOM_MUTATION = gql`
    mutation _($room: RoomInputType!) {
        rooms_edit(room: $room) {
            _id
        }
    }
`

const DELETE_ROOM_MUTATION = gql`
    mutation _($_id: MongoID!) {
        rooms_delete(roomId: $_id) {
            _id
        }
    }
`

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    refetch?: (options?: any) => void
    room?: Room
}

export default class RoomsDetailDataView extends React.Component<Props> {
    private roomEditMutator: Mutator
    private roomDeleteMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.roomEditMutator = new Mutator({
            mutation: EDIT_ROOM_MUTATION,
            reactComponentToUpdate: this,
        })

        this.roomDeleteMutator = new Mutator({
            mutation: DELETE_ROOM_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { room } = this.props
        const { errors: editErrors } = this.roomEditMutator

        return (
            <ContentView>
                <Wrap>
                    <FieldCollection>
                        <FieldGroup>
                            <Field title={`Naam`}>{room && room.name}</Field>
                            <Field title={`Capaciteit`}>{room && room.capacity}</Field>
                            <Field title={`Kenmerken`}>
                                {room && room.locationProperties && room.locationProperties.map(p => p.name).join(', ')}
                            </Field>
                        </FieldGroup>
                        <FieldCollectionFooter>
                            <List horizontal={true}>
                                <ListItem right={true}>
                                    <ModalManager
                                        render={openModal => (
                                            <Button onClick={openModal} type={`edit`}>
                                                Bewerken
                                            </Button>
                                        )}
                                        getModal={closeModal => (
                                            <CenterModal onClose={closeModal} title={`Lesruimte aanpassen`}>
                                                <RoomsForm
                                                    onSubmit={(e: React.FormEvent<HTMLFormElement>, f: FormFields) => {
                                                        this.onEdit(e, f, room && room._id)
                                                    }}
                                                    closeModal={closeModal}
                                                    errors={editErrors}
                                                    isEdit={true}
                                                    onDelete={() => this.onDelete(room && room._id)}
                                                    room={room}
                                                />
                                            </CenterModal>
                                        )}
                                    />
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Wrap>
            </ContentView>
        )
    }

    private onEdit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields, id?: string) => {
        const { params, refetch } = this.props

        const data = await this.roomEditMutator.mutate({
            room: {
                _id: id,
                locationId: params.id,
                ...transformFormFields(fields.room, {
                    locationPropertyIds: transformLib.split(),
                }),
            },
        })

        if (data && refetch) {
            refetch()
        }
    }

    private onDelete = async (id?: string) => {
        const { refetch, params } = this.props

        const data = await this.roomDeleteMutator.mutate({
            _id: id,
        })

        if (data && refetch) {
            refetch()
            browserHistory.push(`/properties/locations/${params.id}/rooms`)
        }
    }
}
