import gql from 'graphql-tag'
import { get } from 'lodash'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { Wrap } from '~/components/Wrap'
import apolloClient from '~/services/apolloClient'
import { Fetcher, Mutator, parseGraphQLError, toast, Filter } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { Program } from '~/types/Program'
import { ContractProjectType, ContractType } from '~/types/Contracts'
import { ValidationError } from '~/components/ValidationError'
import { Module, ModuleIntegrationType } from '~/types/Module'
import { translateType } from '~/shared/utils'

interface State {
    projectType: ContractProjectType | null
    integrationType: ContractType | null
    selectedModuleIds: string[]
}

interface Props {
    program: Program
    params: any
    refetch: () => void
}

export default class EditView extends Component<Props, State> {
    public state: State = {
        projectType: null,
        integrationType: null,
        selectedModuleIds: [],
    }

    private programMutator: Mutator
    private modulesFetcher: Fetcher
    private modulesFilter: Filter

    constructor(props: Props) {
        super(props)

        const { program } = this.props

        this.state = {
            projectType: get(program, 'projectType'),
            integrationType: get(program, 'integrationType'),
            selectedModuleIds: (get(program, 'modules') || []).map(p => p._id),
        }

        this.programMutator = new Mutator({
            mutation: EDIT_PROGRAM_MUTATION,
            reactComponentToUpdate: this,
        })

        this.modulesFilter = new Filter({
            allowedKeys: ['filterByProjectType', 'filterByIntegrationType'],
            onChange: (filters: any) => {
                this.modulesFetcher.refetch({
                    siltent: true,
                    filters: {
                        ...filters,
                    },
                } as any)
            },
        } as any)

        this.modulesFetcher = new Fetcher({
            query: GET_MODULES_QUERY,
            variables: {
                filters: {
                    filterByProjectType: get(program, 'projectType'),
                    filterByIntegrationType: get(program, 'integrationType'),
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { data } = this.modulesFetcher
        const { modules, loading: modulesLoading } = data
        const { program } = this.props
        const { errors, loading } = this.programMutator
        const { projectType, selectedModuleIds } = this.state

        const usedByProject = !!get(program, 'usedByProject')

        const modulesOptions = (modules || []).map((module: Module) => ({
            value: module._id,
            label: module.name,
        }))

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup>
                                <Field isLabel title={'Functienaam'} errors={errors}>
                                    {usedByProject && (
                                        <ValidationError
                                            isWarning
                                            // tslint:disable-next-line:max-line-length
                                            text={`Deze opleiding wordt momenteel gebruikt in een of meerdere projecten. Sommige velden kunnen daarom niet meer aangepast worden.`}
                                        />
                                    )}
                                    <Input
                                        name={`program.name`}
                                        type={`text`}
                                        placeholder={`Functienaam`}
                                        defaultValue={get(program, `name`)}
                                    />
                                </Field>
                                <Field title={'Type project'} errors={errors}>
                                    <TagPicker
                                        name={'program.projectType'}
                                        placeholder={`Selecteer een type`}
                                        options={[
                                            { label: 'Inburgering', value: ContractProjectType.Integration },
                                            { label: 'Organisatie', value: ContractProjectType.Organization },
                                            { label: 'Overig', value: ContractProjectType.Other },
                                        ]}
                                        multi={false}
                                        onChange={this.onProjectTypeChange}
                                        defaultValue={get(program, 'projectType')}
                                        isDisabled={usedByProject}
                                    />
                                </Field>
                                {projectType && (
                                    <FieldGroup isInsetGroup={true}>
                                        {projectType === ContractProjectType.Integration && (
                                            <Field title={'Type inburgering'} errors={errors}>
                                                <TagPicker
                                                    name={'program.integrationType'}
                                                    placeholder={`Selecteer een type`}
                                                    options={Object.values(ModuleIntegrationType).map(value => ({
                                                        label: translateType('lessonModuleIntegrationType', value),
                                                        value: value,
                                                    }))}
                                                    multi={false}
                                                    onChange={this.onIntegrationTypeChange}
                                                    defaultValue={get(program, 'integrationType')}
                                                />
                                            </Field>
                                        )}
                                        <Field isLabel title={`Lesmodules`}>
                                            <TagPicker
                                                name={`program.moduleIds`}
                                                options={modulesOptions}
                                                placeholder="Selecteer lesmodules"
                                                value={selectedModuleIds}
                                                isLoading={modulesLoading}
                                                onChange={this.onModulesChange}
                                            />
                                        </Field>
                                    </FieldGroup>
                                )}
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() => browserHistory.push(`/properties/programs/${program._id}`)}
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { params, refetch } = this.props

        this.programMutator
            .mutate({
                program: {
                    _id: params.id,
                    ...transformFormFields(fields.program, {
                        moduleIds: transformLib.split(),
                    }),
                },
            })
            .then(data => {
                if (data) {
                    refetch()
                    browserHistory.push(`/properties/programs/${params.id}`)
                }
            })
    }

    private onDelete = () => {
        const { params } = this.props

        apolloClient
            .mutate({
                mutation: DELETE_PROGRAM_MUTATION,
                variables: {
                    _id: params.id,
                },
            })
            .then(() => browserHistory.push(`/properties/programs`))
            .catch((err: any) => {
                const { message } = parseGraphQLError(err, { namespace: 'programs_delete' })
                toast.error(`${message}`)
            })
    }

    private onProjectTypeChange = (option: any) => {
        this.setState(
            {
                projectType: option.value,
                integrationType: null,
                selectedModuleIds: [],
            },
            () => {
                this.modulesFilter.apply('filterByProjectType', option.value)
                this.modulesFilter.apply('filterByIntegrationType', this.state.integrationType)
            }
        )
    }

    private onIntegrationTypeChange = (option: any) => {
        this.setState(
            {
                integrationType: option.value || null,
                selectedModuleIds: [],
            },
            () => this.modulesFilter.apply('filterByIntegrationType', this.state.integrationType)
        )
    }

    private onModulesChange = (options: TagPickerOption[]) => {
        this.setState({
            selectedModuleIds: options.map(option => option.value),
        })
    }
}

const GET_MODULES_QUERY = gql`
    query _($filters: ModulesFiltersInputType) {
        modules(filters: $filters) {
            _id
            name
        }
    }
`

const EDIT_PROGRAM_MUTATION = gql`
    mutation _($program: ProgramInputType!) {
        programs_edit(program: $program) {
            _id
            name
        }
    }
`

const DELETE_PROGRAM_MUTATION = gql`
    mutation _($_id: MongoID!) {
        programs_delete(programId: $_id) {
            _id
        }
    }
`
