import * as React from 'react'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import Link from '~/components/Link'
import { Currency, ActionBar, Button, Icon, Search, FilterFieldCollection, Field } from '~/components'
const moment = require('moment')
import { FinalExamsExpansionContent } from '~/components/FinalExams/FinalExamsExpansionContent'
import gql from 'graphql-tag'
import { Fetcher, removeDuplicateDocuments, Filter } from '~/utils'
import { get, times } from 'lodash'
import { User } from '~/types/User'
import { FinalExamNeeded } from '~/types/FinalExam'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { TagPicker } from '~/components/TagPicker'
import { Organization } from '~/types/Organization'
import { Module } from '~/types/Module'
import { Paginator, PaginatorLoadMoreFn } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

interface Props {}

const START = 40
const INCREASE = 40

const GET_FINAL_EXAMS_NEEDED_QUERY = gql`
    query finalExamsNeeded($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: UsersFilterInputType) {
        users(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            profile {
                name
            }
            learner {
                finalExamNeeded {
                    budgetRemaining
                    endOfIntegrationTerm
                    hoursPresent
                    module {
                        _id
                        name
                    }
                    explainScore {
                        budget
                        hours
                        module
                        months
                    }
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
            contactUsers {
                _id
                profile {
                    name
                }
            }
        }

        users(filters: { filterByRoleInGroup: "EMPLOYEE" }) {
            _id
            profile {
                name
            }
        }

        modules(filterByFinalExamTraining: true, filterActive: true) {
            _id
            name
        }
    }
`

interface State {
    limit: number
    skip: number
    filters: object
    selectedOrganizationId?: string
}

export class FinalExamsConceptTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        filters: {
            filterByFinalExamNeeded: true,
        },
        selectedOrganizationId: undefined,
    }

    private finalExamsNeededFetcher: Fetcher
    private paginator: Paginator
    private finalExamsNeededFilter: Filter
    private filterSuggestionsFetcher: Fetcher

    constructor(props: Props) {
        super(props)
        const { limit, skip, filters } = this.state

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.finalExamsNeededFilter = new Filter({
            useHistory: true,
            allowedKeys: [
                'filterByFinalExamNeeded',
                'filterByGroupEmployeeId',
                'filterByModuleId',
                'searchByName',
                'filterByEndTerm',
                'filterByOrganizationId',
                'filterByOrganizationContactId',
            ],
            onChange: (finalExamsNeededFilters: any) => {
                this.finalExamsNeededFetcher.refetch({
                    filters: {
                        ...filters,
                        finalExamNeededFilters: {
                            ...finalExamsNeededFilters,
                            ...this.finalExamsNeededFilter.getFilters(),
                        },
                    },
                } as any)
            },
        } as any)

        this.finalExamsNeededFetcher = new Fetcher({
            query: GET_FINAL_EXAMS_NEEDED_QUERY,
            variables: {
                limit,
                skip,
                sortBy: 'learner.finalExamScores.total',
                sortDir: 'DESC',
                filters: {
                    ...filters,
                    finalExamNeededFilters: {
                        ...this.finalExamsNeededFilter.getFilters(),
                    },
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { paginator } = this
        const { loading } = this.finalExamsNeededFetcher
        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Wrap full={true}>{this.renderActionBar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    private renderActionBar = () => {
        const { loading } = this.finalExamsNeededFetcher
        const finalExamsNeededFilters = this.finalExamsNeededFilter.getFilters()
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { users = [], organizations = [], modules = [] } = filterParams
        const { selectedOrganizationId } = this.state

        const endTermOptions = [
            ...times(12).map(n => ({ value: n + 1, label: `${n + 1} ${n + 1 === 1 ? 'maand' : 'maanden'}` })),
        ]
        const groupEmployeeOptions = users.map((user: User) => ({ value: user._id, label: user.profile.name }))
        const organizationOptions = organizations.map((organization: Organization) => ({
            value: organization._id,
            label: organization.name,
        }))
        const moduleOptions = modules.map((module: Module) => ({ value: module._id, label: module.name }))
        const hideOrganizationContactFilter = !selectedOrganizationId
        const organizationContactOptions = this.getOrganizationContactOptions(organizations)
        const hasFilters = this.finalExamsNeededFilter.hasFilters({ excludeKeys: ['searchText'] })

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                leftIcon={<Icon name={`filter`} />}
                                onClick={() => toggleDrawer()}
                                isActive={drawerActive}
                            >
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Search
                                isLoading={loading}
                                onSearch={this.onSearch}
                                defaultValue={finalExamsNeededFilters.searchText}
                            />
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        <Field isLabel={true} title={`Groep-medewerker`} style={`compact`}>
                            <TagPicker
                                defaultValue={finalExamsNeededFilters.filterByGroupEmployeeId}
                                multi={false}
                                name={`filterByGroupEmployeeId`}
                                onChange={this.onFilterTagPicker}
                                options={groupEmployeeOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={`Lesmodule voor examentraining`} style={`compact`}>
                            <TagPicker
                                defaultValue={finalExamsNeededFilters.filterByModuleId}
                                multi={false}
                                name={`filterByModuleId`}
                                onChange={this.onFilterTagPicker}
                                options={moduleOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={`Einde inburgeringstermijn`} style={`compact`}>
                            <TagPicker
                                defaultValue={finalExamsNeededFilters.filterByEndTerm}
                                multi={false}
                                name={`filterByEndTerm`}
                                onChange={this.onFilterTagPicker}
                                options={endTermOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={`Organisatie`} style={`compact`}>
                            <TagPicker
                                defaultValue={finalExamsNeededFilters.filterByOrganizationId}
                                multi={false}
                                name={`filterByOrganizationId`}
                                onChange={(selectedValue, tagpicker) => {
                                    this.setState({
                                        selectedOrganizationId: selectedValue ? selectedValue.value : null,
                                    })
                                    this.onFilterTagPicker(selectedValue, tagpicker)
                                }}
                                options={organizationOptions}
                            />
                        </Field>
                        <Field
                            isLabel={true}
                            title={`Organisatie contactpersoon`}
                            style={`compact`}
                            isDisabled={!selectedOrganizationId}
                        >
                            <TagPicker
                                multi={false}
                                name={`filterByOrganizationContactId`}
                                onChange={this.onFilterTagPicker}
                                isDisabled={hideOrganizationContactFilter}
                                options={organizationContactOptions}
                            />
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    private renderTable = () => {
        const { data, loading } = this.finalExamsNeededFetcher
        const users = data.users || []

        const totalColSpan = 6

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem>Kandidaat</TableHeaderItem>
                        <TableHeaderItem>Resterend budget</TableHeaderItem>
                        <TableHeaderItem>Uren aanwezigheid</TableHeaderItem>
                        <TableHeaderItem>Einde inburgeringstermijn</TableHeaderItem>
                        <TableHeaderItem>Examenmodule</TableHeaderItem>
                        <TableHeaderItem width={`5%`} />
                        {/* Expansion button */}
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={totalColSpan} isLoading={true} />
                        </TableRow>
                    ) : users.length > 0 ? (
                        this.renderFinalExamsNeededRows(users)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={totalColSpan}>
                                <Subtle>Er zijn geen examenkandidaten gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderFinalExamsNeededRows = (users: User[]) => {
        return users.map(user => {
            const finalExamNeeded = get(user, 'learner.finalExamNeeded')
            return (
                <React.Fragment key={user._id}>
                    {finalExamNeeded && (
                        <TableRow key={user._id} getExpansion={() => this.renderFinalExamsExpansionCells(user)}>
                            <TableCell>
                                <Link route={`/users/learners/${user._id}`}>{user.profile.name}</Link>
                            </TableCell>
                            <TableCell isHighlighted={this.isCellTextHighlighted('budget', finalExamNeeded)}>
                                {finalExamNeeded.budgetRemaining || finalExamNeeded.budgetRemaining === 0 ? (
                                    <Currency amount={finalExamNeeded.budgetRemaining} />
                                ) : (
                                    '-'
                                )}
                            </TableCell>
                            <TableCell isHighlighted={this.isCellTextHighlighted('hours', finalExamNeeded)}>
                                {finalExamNeeded.hoursPresent || '-'}
                            </TableCell>
                            <TableCell isHighlighted={this.isCellTextHighlighted('months', finalExamNeeded)}>
                                {finalExamNeeded.endOfIntegrationTerm
                                    ? this.getDateFromNow(finalExamNeeded.endOfIntegrationTerm)
                                    : '-'}
                            </TableCell>
                            <TableCell isHighlighted={this.isCellTextHighlighted('module', finalExamNeeded)}>
                                {get(finalExamNeeded, 'module.name')}
                            </TableCell>
                        </TableRow>
                    )}
                </React.Fragment>
            )
        })
    }

    private renderFinalExamsExpansionCells = (user: User) => {
        return (
            <TableCell colSpan={6}>
                <FinalExamsExpansionContent onSubmitSuccess={this.onSubmitSuccess} user={user} />
            </TableCell>
        )
    }

    private getOrganizationContactOptions = (organizations: Organization[]) => {
        const { selectedOrganizationId } = this.state

        const selectedOrganization = selectedOrganizationId
            ? organizations.find((organization: Organization) => organization._id === selectedOrganizationId)
            : null

        if (!selectedOrganization || !selectedOrganization.contactUsers) {
            return undefined
        }

        return selectedOrganization.contactUsers.map((contactUser: User) => ({
            value: contactUser._id,
            label: contactUser.profile.name,
        }))
    }

    private onFilterTagPicker = (values: any[], tagPicker: any) => {
        this.finalExamsNeededFilter.applyFromTagPicker(tagPicker)
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.finalExamsNeededFilter.apply('searchByName', searchText)
    }

    private onSubmitSuccess = () => {
        this.finalExamsNeededFetcher.refetch()
    }

    private getDateFromNow = (date?: string) => {
        if (!date) {
            return null
        }

        const dateFromNow = moment(date).fromNow()
        let dateToReturn = null

        if (dateFromNow) {
            if (dateFromNow.includes('over')) {
                dateToReturn = dateFromNow.replace(/over /, '')

                if (dateToReturn.includes('één')) {
                    dateToReturn = dateToReturn.replace(/één/, '1')
                }
            }
        }

        return dateToReturn || dateFromNow
    }

    private isCellTextHighlighted = (cellKey: string, finalExamNeeded: FinalExamNeeded) => {
        return !!get(finalExamNeeded, `explainScore.${cellKey}`)
    }

    private loadMore: PaginatorLoadMoreFn = (skip, limit, callback) => {
        const { filters } = this.state

        this.finalExamsNeededFetcher.fetchMore({
            variables: {
                limit,
                skip,
                filters: {
                    ...filters,
                    finalExamNeededFilters: {
                        ...this.finalExamsNeededFilter.getFilters(),
                    },
                },
            },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }
}
