import gql from 'graphql-tag'
import { get } from 'lodash'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    Header,
    Link,
    PageTabs,
    PageTab,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    Search,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Mutator, Filter, removeDuplicateDocuments, Sorter, downloadFile } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'createdAt'
const DEFAULT_SORT_DIR = 'DESC'

export default class DUOInvoicesView extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['byTextSearch'],
            onChange: filters => {
                this.DUOinvoicesFetcher.refetch({
                    filters: {
                        ...this.baseFilters,
                        ...filters,
                    },
                })
            },
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.generateDUOInvoicesMutator = new Mutator({
            mutation: DUO_GENERATE_MUTATION,
            onChange: () => this.DUOinvoicesFetcher.refetch(),
        })

        this.DUOinvoicesFetcher = new Fetcher({
            query: DUO_INVOICES_QUERY,
            variables: {
                skip,
                limit,
                sortDir,
                sortBy,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.DUOinvoicesFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.DUOInvoices.length === 0,
                })

                return {
                    DUOInvoices: removeDuplicateDocuments([...(prevData.DUOInvoices || []), ...moreData.DUOInvoices]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('byTextSearch', searchText)
    }

    sort = ({ sortBy, sortDir }) => {
        this.DUOinvoicesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    render() {
        const { loading, data } = this.DUOinvoicesFetcher
        const filters = this.filter.getFilters()
        const { DUOInvoices = [], DUOInvoicesAvailable = 0 } = data
        const { paginator, sorter } = this

        return (
            <InfiniteScroll
                paginator={paginator}
                preventLoad={loading}
                component={View}
                className={`tt-LearnerInvoicingView`}
            >
                <Header>
                    <BreadCrumbs />
                    <PageTabs>
                        <PageTab>
                            <Link route={`/financial/duo-batches/invoices`}>Exports genereren</Link>
                        </PageTab>
                        <PageTab>
                            <Link route={`/financial/duo-batches/responses`}>Respons upload</Link>
                        </PageTab>
                    </PageTabs>
                </Header>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            getButtons={() => (
                                <List horizontal>
                                    <ListItem>
                                        <Search
                                            isLoading={loading}
                                            onSearch={this.onSearch}
                                            defaultValue={filters.byTextSearch}
                                        />
                                    </ListItem>
                                    <ListItem right>
                                        <Button
                                            isDisabled={DUOInvoicesAvailable === 0}
                                            type={`edit`}
                                            onClick={() => this.generateDUOInvoices()}
                                        >
                                            DUO batch genereren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Subtle isBlockElement>
                                            {DUOInvoicesAvailable === 1
                                                ? `Er staat ${DUOInvoicesAvailable} factuur klaar`
                                                : `Er staan ${DUOInvoicesAvailable} facturen klaar`}
                                        </Subtle>
                                    </ListItem>
                                </List>
                            )}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table hasAutoLayout={true}>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`file.filename`} width="35%">
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Laatst gedownload op</TableHeaderItem>
                                    <TableHeaderItem>Genereerdatum</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={3} isLoading />
                                    </TableRow>
                                ) : DUOInvoices.length > 0 ? (
                                    this.renderDUOInvoiceRows(DUOInvoices)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={3}>
                                            <Subtle>Er zijn geen DUO facturen gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderDUOInvoiceRows(DUOinvoices) {
        return DUOinvoices.map(DUOinvoice => {
            return (
                <TableRow key={DUOinvoice._id}>
                    <TableCell>
                        <Button linkStyle={`default`} onClick={() => this.handleDownload(DUOinvoice)}>
                            {get(DUOinvoice, 'file.fileName')}
                        </Button>
                    </TableCell>
                    <TableCell>
                        {DUOinvoice.lastDownloadedAt ? (
                            <ReadableDate date={DUOinvoice.lastDownloadedAt} />
                        ) : (
                            <span>Niet gedownload</span>
                        )}
                    </TableCell>
                    <TableCell>
                        <ReadableDate date={DUOinvoice.createdAt} />
                    </TableCell>
                </TableRow>
            )
        })
    }

    generateDUOInvoices() {
        this.generateDUOInvoicesMutator.mutate().then(() => {
            this.DUOinvoicesFetcher.refetch()
        })
    }

    async handleDownload(DUOinvoice) {
        await downloadFile(get(DUOinvoice, 'file._id'), get(DUOinvoice, 'file.fileName'))
        await this.DUOinvoicesFetcher.refetch({ silent: true })
        setTimeout(async () => {
            await this.DUOinvoicesFetcher.refetch({ silent: true })
        }, 500)
    }
}

const DUO_INVOICES_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        DUOInvoicesAvailable
        DUOInvoices(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            file {
                _id
                fileName
            }
            createdAt
            createdByUserId
            lastDownloadedAt
        }
    }
`

const DUO_GENERATE_MUTATION = gql`
    mutation _ {
        DUOInvoices_generate {
            _id
        }
    }
`
