import gql from 'graphql-tag'
import { get } from 'lodash'
import React from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    Header,
    Link,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { CreateLocationForm } from '~/forms'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { RouteComponentProps } from 'react-router'
import { Location } from '~/types/Location'
import { AddressFieldsFragment } from '~/types/Address'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

const GET_LOCATIONS_QUERY = gql`
    query _($searchText: String, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        locations(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            name
            address {
                ...AddressFieldsFragment
            }
            rooms {
                _id
            }
        }
    }
    ${AddressFieldsFragment}
`

interface Props extends RouteComponentProps<{}, {}> {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export default class MasterView extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private filter: Filter
    private paginator: Paginator
    private locationsFetcher: Fetcher

    constructor(props: Props) {
        super(props)
        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: (filters: any) => {
                this.locationsFetcher.refetch({
                    searchText: filters.searchText,
                } as any)
            },
        } as any)

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.locationsFetcher = new Fetcher({
            query: GET_LOCATIONS_QUERY,
            variables: { limit, skip, sortDir, sortBy, searchText: this.filter.getFilters().searchText },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public render() {
        const { loading, data } = this.locationsFetcher
        const filters = this.filter.getFilters()
        const { locations = [] } = data
        const { paginator, sorter } = this

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <Wrap full={true}>
                        <ActionBar
                            getButtons={() => (
                                <List horizontal={true}>
                                    <ListItem>
                                        <Search
                                            isLoading={loading}
                                            onSearch={this.onSearch}
                                            defaultValue={filters.searchText}
                                        />
                                    </ListItem>
                                    <ListItem right={true}>
                                        <ModalManager
                                            render={openModal => (
                                                <Button onClick={openModal} type={`edit`}>
                                                    Locatie toevoegen
                                                </Button>
                                            )}
                                            getModal={closeModal => (
                                                <CenterModal onClose={closeModal} title={`Locatie toevoegen`}>
                                                    <CreateLocationForm
                                                        onSubmitSuccess={closeModal}
                                                        onCancel={closeModal}
                                                    />
                                                </CenterModal>
                                            )}
                                        />
                                    </ListItem>
                                </List>
                            )}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={sorter} sortBy={`name`} width="35%">
                                        Naam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Adres</TableHeaderItem>
                                    <TableHeaderItem>Postcode</TableHeaderItem>
                                    <TableHeaderItem>Plaats</TableHeaderItem>
                                    <TableHeaderItem>Lesruimtes</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={`loading`}>
                                        <TableCell colSpan={5} isLoading={true} />
                                    </TableRow>
                                ) : locations.length > 0 ? (
                                    this.renderLocationRows(locations)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={5}>
                                            <Subtle>Er zijn geen locaties gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    private renderLocationRows = (locations: Location[]) => {
        return locations.map(location => {
            const extraInfo = get(location, 'address.nl.extraInfo')

            return (
                <TableRow key={location._id}>
                    <TableCell>
                        <Link route={`/properties/locations/${location._id}`}>{location.name}</Link>
                    </TableCell>
                    <TableCell>
                        {get(extraInfo, 'street')} {get(location, 'address.nl.number')}
                        {get(location, 'address.nl.numberAddition')}
                    </TableCell>
                    <TableCell>{get(location, 'address.nl.zipcode')}</TableCell>
                    <TableCell>{get(extraInfo, 'city')}</TableCell>
                    <TableCell>{!!location.rooms && location.rooms.length}</TableCell>
                </TableRow>
            )
        })
    }

    private loadMore = (skip: number, limit: number, callback: (options?: { finished: boolean }) => void) => {
        this.locationsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.locations.length === 0,
                })

                return {
                    locations: removeDuplicateDocuments([...(prevData.locations || []), ...moreData.locations]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.locationsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }
}
