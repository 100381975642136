import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class CreateLocationForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
    }

    constructor(props) {
        super(props)

        this.locationMutator = new Mutator({
            mutation: CREATE_LOCATION_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.locationMutator
            .mutate({
                location: transformFormFields(fields.location, {}),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/locations/${get(data, 'locations_create._id')}`)
                } else {
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    render() {
        const { errors, loading } = this.locationMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input name={`location.name`} type={`text`} placeholder={`Locatie naam`} autoFocus={true} />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const CREATE_LOCATION_MUTATION = gql`
    mutation _($location: LocationInputType!) {
        locations_create(location: $location) {
            _id
            name
        }
    }
`
