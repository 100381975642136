import React from 'react'

import { ModalManager } from '../ModalManager'
import { Button, DrawerModal } from '..'
import { AdviceReportForm } from './Forms/AdviceReportForm'
import { User } from '~/types/User'
import gql from 'graphql-tag'
import { AdviceReport } from '~/types/AdviceReports'

const CREATE_ADVICE_REPORT_MUTATION = gql`
    mutation _($adviceReport: AdviceReportInputType!) {
        adviceReports_create(adviceReport: $adviceReport) {
            _id
        }
    }
`
const EDIT_ADVICE_REPORT_MUTATION = gql`
    mutation _($adviceReport: AdviceReportInputType!) {
        adviceReports_edit(adviceReport: $adviceReport) {
            _id
        }
    }
`

interface Props {
    candidate: User
    inflowMomentId: string
    refetch: (options?: any) => void
    isDisabled?: boolean
    editButtonClassName?: string
    existingAdviceReport?: AdviceReport
}

export class AdviceReportModalButton extends React.Component<Props> {
    public render() {
        const { candidate, inflowMomentId, refetch, isDisabled, editButtonClassName, existingAdviceReport } = this.props

        return (
            <ModalManager
                render={openModal => (
                    <Button
                        small={true}
                        onClick={openModal}
                        isDisabled={isDisabled}
                        className={(existingAdviceReport && editButtonClassName) || ''}
                    >
                        {existingAdviceReport ? 'Adviesrapport bewerken' : 'Adviesrapport maken'}
                    </Button>
                )}
                getModal={closeModal => (
                    <DrawerModal onClose={closeModal}>
                        <AdviceReportForm
                            title={`Adviesrapport: ${candidate.profile.name}`}
                            closeModal={closeModal}
                            candidateId={candidate._id}
                            refetch={refetch}
                            inflowMomentId={inflowMomentId}
                            mutation={
                                existingAdviceReport ? EDIT_ADVICE_REPORT_MUTATION : CREATE_ADVICE_REPORT_MUTATION
                            }
                            adviceReportId={existingAdviceReport ? existingAdviceReport._id : undefined}
                        />
                    </DrawerModal>
                )}
            />
        )
    }
}
