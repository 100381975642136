import gql from 'graphql-tag'
import { compact, get } from 'lodash'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    CheckBox,
    DatePicker,
    Field,
    FilterFieldCollection,
    Form,
    Icon,
    Link,
    MultiInput,
    ReadableDate,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { ExportGroupsForm } from '~/forms/ExportGroupsForm'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'dateTo'
const DEFAULT_SORT_DIR = 'DESC'

const STATIC_GROUPS_FILTER = {
    filterByConcepts: false,
}

export default class EmployeeGroupsTable extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'filterByTextSearch',
                'filterByProjectIds',
                'filterByLocationIds',
                'filterByTeacherUserIds',
                'filterByModuleIds',
                'filterByProgramIds',
                'filterByEmployeeUserIds',
                'filterByLearnerOrganizationIds',
                'filterByStartDateTo',
                'filterByStartDateFrom',
                'filterByEndDateTo',
                'filterByEndDateFrom',
                'filterByIsEnded',
            ],
            onChange: filters => {
                this.groupsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_GROUPS_FILTER,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsEnded', 'filterIsNotEnded'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterIsEnded) activeStates.push(true)
                        if (filters.filterIsNotEnded) activeStates.push(false)

                        return {
                            filterByIsEnded: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterByIsEnded'],
                    parseFromHistory: historyFilters => {
                        if ('filterByIsEnded' in historyFilters) {
                            if (historyFilters.filterByIsEnded) {
                                return { filterIsEnded: true }
                            } else {
                                return { filterIsNotEnded: true }
                            }
                        }
                    },
                },
            ],
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.groupsFetcher = new Fetcher({
            query: GET_GROUPS_QUERY,

            variables: {
                filters: this.getAllFilters(),
                limit,
                skip,
                sortDir,
                sortBy,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            variables: {
                teacherUsersFilters: {
                    roles: ['TEACHER', 'INTERNAL_TEACHER'],
                    filterTeacherActive: true,
                },
                employeeUsersFilters: {
                    roles: ['EMPLOYEE'],
                },
            },
            preventInitialFetch: true,
            onChange: () => this.forceUpdate(),
        })
    }

    onFilterTagPicker = (values, tagPicker) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    onFilter = event => {
        this.filter.applyFromInputEvent(event)
    }

    loadMore = (skip, limit, callback) => {
        this.groupsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.groups.length === 0,
                })

                return {
                    groups: removeDuplicateDocuments([...(prevData.groups || []), ...moreData.groups]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.groupsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('filterByTextSearch', searchText)
    }

    render() {
        const { paginator } = this
        const { loading } = this.groupsFetcher

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Wrap full>{this.renderActionbar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    renderActionbar() {
        const { loading } = this.groupsFetcher
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['filterByTextSearch'] })
        const filters = this.filter.getFilters()

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={(toggleDrawer, drawerActive) => (
                    <List horizontal>
                        <ListItem>
                            <Button leftIcon={<Icon name={`filter`} />} onClick={toggleDrawer} isActive={drawerActive}>
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem>
                            <Search
                                isLoading={loading}
                                onSearch={this.onSearch}
                                defaultValue={filters.filterByTextSearch}
                            />
                        </ListItem>
                        <ListItem right>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} leftIcon={<Icon name={`download`} />}>
                                        Export van huidige selectie
                                    </Button>
                                )}
                                getModal={closeModal => {
                                    return (
                                        <CenterModal onClose={closeModal} title={`Groepen exporteren`}>
                                            <ExportGroupsForm
                                                groupsFilter={this.getAllFilters()}
                                                onSubmitSuccess={closeModal}
                                                onCancel={closeModal}
                                            />
                                        </CenterModal>
                                    )
                                }}
                            />
                        </ListItem>
                    </List>
                )}
                getDrawer={this.renderFilters}
            />
        )
    }

    getAllFilters() {
        return {
            ...STATIC_GROUPS_FILTER,
            ...this.filter.getFilters(),
        }
    }

    renderTable() {
        const { data, loading } = this.groupsFetcher
        const { groups = [] } = data
        const { sorter } = this

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem sorter={sorter} sortBy={`name`} width="35%">
                            Naam
                        </TableHeaderItem>
                        <TableHeaderItem>Docent(en)</TableHeaderItem>
                        <TableHeaderItem>Locatie</TableHeaderItem>
                        <TableHeaderItem>Lesmodule</TableHeaderItem>
                        <TableHeaderItem sorter={sorter} sortBy={`dateFrom`}>
                            Startdatum
                        </TableHeaderItem>
                        <TableHeaderItem sorter={sorter} sortBy={`dateTo`}>
                            Einddatum
                        </TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={6} isLoading />
                        </TableRow>
                    ) : groups.length > 0 ? (
                        this.renderGroupRows(groups)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={6}>
                                <Subtle>Er zijn geen groepen gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    renderGroupRows(groups) {
        return groups.map(group => {
            return (
                <TableRow key={group._id}>
                    <TableCell>
                        <Link route={`/groups/${group._id}/lessons`}>
                            {group.name && group.name.trim() ? group.name : 'Geen naam'}
                        </Link>
                    </TableCell>
                    <TableCell>
                        {compact((group.teacherUsers || []).map(tu => get(tu, 'user.profile.name'))).join(', ')}
                    </TableCell>
                    <TableCell>{(group.location && group.location.name) || ''}</TableCell>
                    <TableCell>
                        {group.module && (
                            <Link route={`/properties/modules/${group.module._id}`}>{group.module.name}</Link>
                        )}
                    </TableCell>
                    <TableCell>{group.dateFrom && <ReadableDate date={new Date(group.dateFrom)} />}</TableCell>
                    <TableCell>{group.dateTo && <ReadableDate date={new Date(group.dateTo)} />}</TableCell>
                </TableRow>
            )
        })
    }

    renderFilters = () => {
        if (!this.filterSuggestionsFetcher.hasFetched) {
            this.filterSuggestionsFetcher.fetch()
        }

        const customFilters = this.filter.getCustomFilters()
        const filters = this.filter.getFilters()

        const { loading, data: filterParams } = this.filterSuggestionsFetcher
        const { teachers, projects, locations, programs, modules, employees, organizations } = filterParams

        const teacherOptions = teachers && teachers.map(user => ({ value: user._id, label: user.profile.name }))
        const employeeOptions = employees && employees.map(user => ({ value: user._id, label: user.profile.name }))
        const locationOptions = locations && locations.map(location => ({ value: location._id, label: location.name }))
        const projectOptions = projects && projects.map(project => ({ value: project._id, label: project.name }))
        const programOptions = programs && programs.map(program => ({ value: program._id, label: program.name }))
        const moduleOptions = modules && modules.map(module => ({ value: module._id, label: module.name }))
        const organizationOptions =
            organizations && organizations.map(organization => ({ value: organization._id, label: organization.name }))

        return (
            <Form ref="filterForm">
                <FilterFieldCollection>
                    <Field isLabel title={`Docent`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByTeacherUserIds`}
                            defaultValue={filters.filterByTeacherUserIds}
                            options={teacherOptions}
                            placeholder={`Selecteer docenten`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Locatie`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByLocationIds`}
                            defaultValue={filters.filterByLocationIds}
                            options={locationOptions}
                            placeholder={`Selecteer locaties`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Project`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByProjectIds`}
                            defaultValue={filters.filterByProjectIds}
                            options={projectOptions}
                            placeholder={`Selecteer projecten`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Opleiding`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByProgramIds`}
                            defaultValue={filters.filterByProgramIds}
                            options={programOptions}
                            placeholder={`Selecteer opleidingen`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Lesmodule`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByModuleIds`}
                            defaultValue={filters.filterByModuleIds}
                            options={moduleOptions}
                            placeholder={`Selecteer lesmodules`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Toptaal medewerker`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByEmployeeUserIds`}
                            defaultValue={filters.filterByEmployeeUserIds}
                            options={employeeOptions}
                            placeholder={`Selecteer medewerkers`}
                            isLoading={loading}
                        />
                    </Field>
                    <Field isLabel title={`Organisatie`} style={`compact`}>
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByLearnerOrganizationIds"
                            defaultValue={filters.filterByLearnerOrganizationIds}
                            options={organizationOptions}
                            placeholder="Selecteer organisatie"
                            isLoading={loading}
                        />
                    </Field>
                    <Field title={`Startdatum`} style={`compact`}>
                        <MultiInput type={`select-range`}>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByStartDateFrom'}
                                defaultValue={filters.filterByStartDateFrom}
                            />
                            <label>t/m</label>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByStartDateTo'}
                                defaultValue={filters.filterByStartDateTo}
                            />
                        </MultiInput>
                    </Field>
                    <Field title={`Einddatum`} style={`compact`}>
                        <MultiInput type={`select-range`}>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByEndDateFrom'}
                                defaultValue={filters.filterByEndDateFrom}
                            />
                            <label>t/m</label>
                            <DatePicker
                                onChange={this.onFilter}
                                name={'filterByEndDateTo'}
                                defaultValue={filters.filterByEndDateTo}
                            />
                        </MultiInput>
                    </Field>
                    <Field title={`Groepstatus`} style={`compact`}>
                        <MultiInput type="checkbox">
                            <CheckBox
                                name="filterIsEnded"
                                onChange={this.onFilter}
                                defaultChecked={customFilters.filterIsEnded}
                            >
                                Afgerond
                            </CheckBox>
                            <CheckBox
                                name="filterIsNotEnded"
                                onChange={this.onFilter}
                                defaultChecked={customFilters.filterIsNotEnded}
                            >
                                Niet afgerond
                            </CheckBox>
                        </MultiInput>
                    </Field>
                </FilterFieldCollection>
            </Form>
        )
    }
}

const GET_GROUPS_QUERY = gql`
    query _($skip: Int, $limit: Int, $sortBy: String, $sortDir: String, $filters: GroupsFilterInputType) {
        groups(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            name
            dateFrom
            dateTo
            module {
                _id
                name
            }
            location {
                _id
                name
            }
            teacherUsers(filterRemoved: false) {
                _id
                user {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`
const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _($teacherUsersFilters: UsersFilterInputType, $employeeUsersFilters: UsersFilterInputType) {
        teachers: users(sortBy: "profile.firstName", filters: $teacherUsersFilters) {
            _id
            profile {
                name
            }
        }

        employees: users(sortBy: "profile.firstName", filters: $employeeUsersFilters) {
            _id
            profile {
                name
            }
        }

        locations(sortBy: "name") {
            _id
            name
        }

        programs(sortBy: "name") {
            _id
            name
        }

        projects(sortBy: "name") {
            _id
            name
        }

        modules(sortBy: "name") {
            _id
            name
        }

        organizations(sortBy: "name") {
            _id
            name
        }
    }
`
