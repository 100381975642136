import * as React from 'react'
import { browserHistory, RouteComponentProps } from 'react-router'

import {
    Button,
    Field,
    FieldCollection,
    Form,
    Input,
    ActionBar,
    Select,
    Option,
    MultiInput,
    RadioButton,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import { Mutator } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import gql from 'graphql-tag'
import { InflowModule, InflowModuleType } from '~/types/InflowModule'
import { getMinutesWithInterval } from '~/utils/getMinutesWithInterval'
import { getTimeFromMinutes } from '~/utils/getTimeFromMinutes'
import { ModuleExamsField } from '~/components/Module/ModuleExamsField'
import { ValidationError } from '~/components/ValidationError'
import { translateType } from '~/shared/utils'

interface RouteParams {
    id: string
}

interface Props extends RouteComponentProps<RouteParams, {}> {
    inflowModule: InflowModule
    refetch: () => void
}

interface State {
    inflowTypeIsIntake: boolean | null
}

export class InflowModuleEditView extends React.Component<Props, State> {
    public state: State = {
        inflowTypeIsIntake: this.props.inflowModule.type === InflowModuleType.intake,
    }

    private editModuleMutator: Mutator
    private deleteModuleMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.editModuleMutator = new Mutator({
            mutation: gql`
                mutation _($inflowModule: InflowModuleInputType!) {
                    inflowModules_edit(inflowModule: $inflowModule) {
                        _id
                    }
                }
            `,
            reactComponentToUpdate: this,
        })

        this.deleteModuleMutator = new Mutator({
            mutation: gql`
                mutation _($_id: MongoID!) {
                    inflowModules_delete(inflowModuleId: $_id) {
                        _id
                    }
                }
            `,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { inflowModule } = this.props
        const { errors, loading } = this.editModuleMutator

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={`Gegevens`} isForm={true}>
                                <Field isLabel title={'Naam'} errors={errors}>
                                    <Input
                                        name={`inflowModule.name`}
                                        type={`text`}
                                        placeholder={`Naam`}
                                        defaultValue={inflowModule.name}
                                    />
                                    {inflowModule.isLockedForEdit && (
                                        <ValidationError
                                            isWarning
                                            // tslint:disable-next-line:max-line-length
                                            text={`Deze instroommodule wordt momenteel gebruikt in lopende instroommomenten. Sommige velden kunnen daarom niet meer aangepast worden.`}
                                        />
                                    )}
                                </Field>
                                <Field title={`Type instroom`}>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name={`inflowModule.type`}
                                            value={InflowModuleType.intake}
                                            onClick={() => this.setState({ inflowTypeIsIntake: true })}
                                            defaultChecked={inflowModule.type === InflowModuleType.intake}
                                            isDisabled={inflowModule.isLockedForEdit}
                                        >
                                            {translateType('inflowModuleType', InflowModuleType.intake)}
                                        </RadioButton>
                                        <RadioButton
                                            name={`inflowModule.type`}
                                            value={InflowModuleType.levelDetermination}
                                            onClick={() => this.setState({ inflowTypeIsIntake: false })}
                                            defaultChecked={inflowModule.type === InflowModuleType.levelDetermination}
                                            isDisabled={inflowModule.isLockedForEdit}
                                        >
                                            {translateType('inflowModuleType', InflowModuleType.levelDetermination)}
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            </FieldGroup>
                            <FieldGroup title={'Toets(en)'} isForm={true}>
                                <ModuleExamsField
                                    defaultModuleExams={inflowModule.exams || []}
                                    name="inflowModule.exams"
                                    title={`Af te nemen toets(en)`}
                                    errors={errors}
                                />
                                <Field title={`Maximale toetstijd per kandidaat in minuten`}>
                                    {/* <Select
                                        name={`inflowModule.examMaxTimeMinutes`}
                                        placeholder={`Selecteer tijd`}
                                        defaultValue={inflowModule.examMaxTimeMinutes}
                                        isDisabled={inflowModule.isLockedForEdit}
                                    >
                                        {getMinutesWithInterval(5, 180).map(amountOfMinutes => (
                                            <Option
                                                key={`examMaxTimeMinutes-${amountOfMinutes}`}
                                                value={amountOfMinutes}
                                            >
                                                {getTimeFromMinutes(amountOfMinutes)}
                                            </Option>
                                        ))}
                                    </Select> */}
                                    <Input
                                        min={1}
                                        name={`inflowModule.examMaxTimeMinutes`}
                                        placeholder={`Toetstijd in minuten`}
                                        defaultValue={inflowModule.examMaxTimeMinutes}
                                        step={1}
                                        type={`number`}
                                        isDisabled={inflowModule.isLockedForEdit}
                                    />
                                </Field>
                                <Field title={`Interval tussen startmomenten`}>
                                    <Select
                                        name={`inflowModule.examStartIntervalMinutes`}
                                        placeholder={`Selecteer interval`}
                                        defaultValue={inflowModule.examStartIntervalMinutes}
                                        isDisabled={inflowModule.isLockedForEdit}
                                    >
                                        <Option key={`examStartIntervalMinutes-0`} value={0}>
                                            Geen
                                        </Option>
                                        {getMinutesWithInterval(5, 180).map(amountOfMinutes => (
                                            <Option
                                                key={`examStartIntervalMinutes-${amountOfMinutes}`}
                                                value={amountOfMinutes}
                                            >
                                                {getTimeFromMinutes(amountOfMinutes)}
                                            </Option>
                                        ))}
                                        {getMinutesWithInterval(15, 180, 180).map(amountOfMinutes => (
                                            <Option
                                                key={`examStartIntervalMinutes-${amountOfMinutes}`}
                                                value={amountOfMinutes}
                                            >
                                                {getTimeFromMinutes(amountOfMinutes)}
                                            </Option>
                                        ))}
                                    </Select>
                                </Field>
                                <Field title={`Maximaal aantal kandidaten per startmoment per toetsdocent`}>
                                    <Input
                                        min={0}
                                        name={`inflowModule.maxStudentsPerStart`}
                                        placeholder={`Aantal kandidaten`}
                                        defaultValue={inflowModule.maxStudentsPerStart}
                                        step={1}
                                        type={`number`}
                                        isDisabled={inflowModule.isLockedForEdit}
                                    />
                                </Field>
                                <Field title={`Controle op adviesrapport nodig`}>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name={`inflowModule.checkAdviceReportNeeded`}
                                            value={true}
                                            defaultChecked={!!inflowModule.checkAdviceReportNeeded}
                                            isDisabled={inflowModule.isLockedForEdit}
                                        >
                                            Ja
                                        </RadioButton>
                                        <RadioButton
                                            name={`inflowModule.checkAdviceReportNeeded`}
                                            value={false}
                                            defaultChecked={!inflowModule.checkAdviceReportNeeded}
                                            isDisabled={inflowModule.isLockedForEdit}
                                        >
                                            Nee
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            </FieldGroup>
                            <ActionBar
                                getButtons={() => (
                                    <List horizontal>
                                        <ListItem right>
                                            <Button type={`submit`} isLoading={loading}>
                                                Opslaan
                                            </Button>
                                        </ListItem>
                                        <ListItem right>
                                            <Button
                                                onClick={() =>
                                                    browserHistory.push(`/properties/inflow/${inflowModule._id}`)
                                                }
                                            >
                                                Annuleren
                                            </Button>
                                        </ListItem>
                                        <ListItem>
                                            {!inflowModule.isLockedForDelete && (
                                                <Button
                                                    onClick={this.onDelete}
                                                    linkStyle={['default', 'danger']}
                                                    confirm={{
                                                        title: 'Verwijderen',
                                                        message:
                                                            'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                        execute: {
                                                            buttonType: 'danger',
                                                            title: 'Verwijderen',
                                                        },
                                                    }}
                                                >
                                                    Verwijderen
                                                </Button>
                                            )}
                                        </ListItem>
                                    </List>
                                )}
                                fixed={true}
                                isWide={true}
                            />
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onSubmit = async (event: any, fields: any) => {
        const { params, refetch } = this.props

        const response = await this.editModuleMutator.mutate({
            inflowModule: {
                _id: params.id,
                ...transformFormFields(fields.inflowModule, {
                    examMaxTimeMinutes: transformLib.int(),
                    examStartIntervalMinutes: transformLib.int(),
                    exams: {
                        value: (v: any) =>
                            v &&
                            v
                                .filter((inflowModuleExamInput: any) => !!inflowModuleExamInput)
                                .filter((inflowModuleExamInput: any) => !!inflowModuleExamInput.examId)
                                .map((moduleExamInput: any) => {
                                    moduleExamInput.abilities = moduleExamInput.abilities || {}
                                    return moduleExamInput
                                }),
                    },
                }),
            },
        })

        if (response) {
            refetch()
            browserHistory.push(`/properties/inflow/${params.id}`)
        }
    }

    private onDelete = async () => {
        const { params } = this.props

        const response = await this.deleteModuleMutator.mutate({
            _id: params.id,
        })

        if (response) {
            browserHistory.push(`/properties/inflow`)
        }
    }
}
