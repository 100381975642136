import gql from 'graphql-tag'
import { get } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    ActionBar,
    Icon,
    Link,
    ReadableDate,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'learner.intakeDate'
const DEFAULT_SORT_DIR = 'DESC'

export default class IntakesView extends Component {
    static propTypes = {
        teacherUserId: PropTypes.string,
    }

    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { teacherUserId } = props

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: filters => {
                this.intakesFetcher.refetch({
                    searchText: filters.searchText,
                })
            },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.intakesFetcher = new Fetcher({
            query: GET_INTAKES_QUERY,

            variables: {
                teacherFilters: {
                    byId: teacherUserId,
                },
                limit,
                skip,
                sortDir,
                sortBy,
                searchText: this.filter.getFilters().searchText,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    loadMore = (skip, limit, callback) => {
        this.intakesFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.intakesFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    render() {
        const { data, loading } = this.intakesFetcher
        const filters = this.filter.getFilters()
        const user = get(data, 'users[0]')
        const learnerUsers = get(user, 'teacher.intakes') || []

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <Wrap full>
                    <ActionBar
                        getButtons={() => (
                            <List horizontal>
                                <ListItem>
                                    <Search
                                        isLoading={loading}
                                        onSearch={this.onSearch}
                                        defaultValue={filters.searchText}
                                    />
                                </ListItem>
                            </List>
                        )}
                    />
                </Wrap>
                <TableWrap>
                    <TableView>
                        <Table>
                            <TableHeader>
                                <TableHeaderItem sorter={this.sorter} sortBy={`profile.firstName`}>
                                    Voornaam
                                </TableHeaderItem>
                                <TableHeaderItem sorter={this.sorter} sortBy={`profile.surName`}>
                                    Achternaam
                                </TableHeaderItem>
                                <TableHeaderItem sorter={this.sorter} sortBy={`profile.givenName`}>
                                    Roepnaam
                                </TableHeaderItem>
                                <TableHeaderItem sorter={this.sorter} sortBy={`learner.intakeDate`}>
                                    Intakedatum
                                </TableHeaderItem>
                            </TableHeader>
                            {loading ? (
                                <TableRow key={`loading`}>
                                    <TableCell colSpan={3} isLoading />
                                </TableRow>
                            ) : learnerUsers.length > 0 ? (
                                this.renderIntakeRows(learnerUsers)
                            ) : (
                                <TableRow key={`emptyresult`}>
                                    <TableCell colSpan={3}>
                                        <Subtle>Er zijn geen intakes gevonden.</Subtle>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableView>
                </TableWrap>
            </InfiniteScroll>
        )
    }

    renderIntakeRows(learnerUsers) {
        return learnerUsers.map(learnerUser => {
            const intakeDate = get(learnerUser, 'learner.intakeDate')
            const intakeIsPast = intakeDate && moment(intakeDate).isBefore(moment().subtract(1, 'days'))
            const url = `/users/learners/${learnerUser._id}`

            return (
                <TableRow key={learnerUser._id}>
                    <TableCell>
                        <Link route={url}>{learnerUser.profile.firstName}</Link>
                    </TableCell>
                    <TableCell>
                        <Link route={url}>{learnerUser.profile.fullSurName}</Link>
                    </TableCell>
                    <TableCell>
                        <Link route={url}>{learnerUser.profile.givenName}</Link>
                    </TableCell>
                    <TableCell>
                        {intakeIsPast && <Icon name="check" />}
                        {intakeDate && <ReadableDate date={new Date(intakeDate)} />}
                    </TableCell>
                </TableRow>
            )
        })
    }
}

const GET_INTAKES_QUERY = gql`
    query _(
        $teacherFilters: UsersFilterInputType
        $skip: Int
        $limit: Int
        $sortBy: String
        $sortDir: String
        $searchText: String
    ) {
        users(filters: $teacherFilters) {
            _id
            teacher {
                intakes(searchText: $searchText, skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
                    _id
                    profile {
                        firstName
                        fullSurName
                        givenName
                    }
                    learner {
                        intakeDate
                    }
                }
            }
        }
    }
`
