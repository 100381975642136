import React from 'react'
import gql from 'graphql-tag'
import { RouteComponentProps, browserHistory } from 'react-router'

import { Wrap } from '~/components/Wrap'
import { ActionBar, Button, TableHeaderItem, TableWrap, TableHeader, TableRow, Subtle } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { ListItem } from '~/components/ListItem'
import { List } from '~/components/List'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'

import { RouteParams } from '~/views/App/Properties/Projects/Detail'
import Fetcher from '~/utils/Fetcher'
import Sorter from '~/utils/Sorter'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import { View } from '~/components/View'
import Link from '~/components/Link'
import { AddInvoicingSettingModal } from '~/components/InvoicingSetting/AddInvoicingSettingModal'
import { ProjectInvoicingSetting } from '~/types/ProjectInvoicingSetting'
import { Project } from '~/types/Project'
import { getCurrentUser } from '~/services/session'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'name'
const DEFAULT_SORT_DIR = 'ASC'

interface Props extends RouteComponentProps<RouteParams, {}> {
    project: Project
    refetchProject: () => void
}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export default class AppPropertiesProjectsDetailInvoicingSettingsMaster extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private sorter: Sorter
    private paginator: Paginator
    private invoicingSettingsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.invoicingSettingsFetcher = new Fetcher({
            query: GET_INVOICE_SETTINGS_QUERY,
            variables: {
                limit: this.state.limit,
                skip: this.state.skip,
                sortDir: this.state.sortDir,
                sortBy: this.state.sortBy,
                projectId: props.project._id,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public render() {
        const { loading } = this.invoicingSettingsFetcher
        const user = getCurrentUser()

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                {user.isAdmin && <Wrap full={true}>{this.renderActionBar()}</Wrap>}
                <TableWrap>
                    <TableView>{this.renderTable()}</TableView>
                </TableWrap>
            </InfiniteScroll>
        )
    }

    private renderActionBar() {
        const { params, refetchProject } = this.props

        return (
            <ActionBar
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem right={true}>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} type={`edit`}>
                                        Facturatie-instelling toevoegen
                                    </Button>
                                )}
                                getModal={closeModal => (
                                    <AddInvoicingSettingModal
                                        onRequestClose={closeModal}
                                        onSubmitSuccess={newInvoicingSettingId => {
                                            closeModal()
                                            refetchProject()
                                            browserHistory.push(
                                                `/properties/projects/${params.id}/invoicing-settings/${newInvoicingSettingId}`
                                            )
                                        }}
                                        projectId={params.id}
                                    />
                                )}
                            />
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private renderTable() {
        const { loading, data } = this.invoicingSettingsFetcher
        const project = data && data.projects && data.projects[0]
        const projectInvoicingSettings = (project && project.projectInvoicingSettings) || []

        return (
            <Table>
                <TableHeader>
                    <TableHeaderItem sorter={this.sorter} sortBy={`name`} width="35%">
                        Naam
                    </TableHeaderItem>
                    <TableHeaderItem>Opleiding(en)</TableHeaderItem>
                </TableHeader>
                {loading ? (
                    <TableRow key={`loading`}>
                        <TableCell colSpan={2} isLoading />
                    </TableRow>
                ) : projectInvoicingSettings.length > 0 ? (
                    this.renderProjectInvoicingSettingsRows(projectInvoicingSettings)
                ) : (
                    <TableRow key={`emptyresult`}>
                        <TableCell colSpan={2}>
                            <Subtle>Er zijn geen facturatie-instellingen gevonden.</Subtle>
                        </TableCell>
                    </TableRow>
                )}
            </Table>
        )
    }

    private renderProjectInvoicingSettingsRows(projectInvoicingSettings: ProjectInvoicingSetting[]) {
        const { params } = this.props

        return projectInvoicingSettings.map(projectInvoicingSetting => (
            <TableRow key={projectInvoicingSetting._id}>
                <TableCell>
                    <Link route={`/properties/projects/${params.id}/invoicing-settings/${projectInvoicingSetting._id}`}>
                        {projectInvoicingSetting.name}
                    </Link>
                </TableCell>
                <TableCell>
                    {projectInvoicingSetting.programs &&
                        projectInvoicingSetting.programs.map(program => program.name).join(', ')}
                </TableCell>
            </TableRow>
        ))
    }

    private sort = ({ sortBy, sortDir }: any) => {
        this.invoicingSettingsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private loadMore = (skip: number, limit: number, callback: (result?: { finished: boolean }) => void) => {
        this.invoicingSettingsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                const prevProject = (prevData.projects && prevData.projects[0]) || {}
                const nextProject = (moreData.projects && moreData.projects[0]) || {}

                callback({
                    finished: nextProject.projectInvoicingSettings.length === 0,
                })

                return {
                    groups: removeDuplicateDocuments([
                        ...(prevProject.projectInvoicingSettings || []),
                        ...nextProject.projectInvoicingSettings,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }
}

const GET_INVOICE_SETTINGS_QUERY = gql`
    query _($projectId: MongoID, $skip: Int, $limit: Int, $sortBy: String, $sortDir: String) {
        projects(byId: $projectId) {
            _id
            projectInvoicingSettings(skip: $skip, limit: $limit, sortBy: $sortBy, sortDir: $sortDir) {
                _id
                name
                programs {
                    _id
                    name
                }
            }
        }
    }
`
