import gql from 'graphql-tag'
import { get } from 'lodash'
import React from 'react'

import {
    Button,
    CheckBox,
    Field,
    FieldCollection,
    Form,
    Link,
    Spinner,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { Fetcher } from '~/utils'
import { TableView } from '~/components/TableView'
import { Group, GroupInvite, GroupInviteType } from '~/types/Group'
import { GroupUser } from '~/types/User'
import { FieldGroup } from '~/components/FieldGroup'
import { InviteTextVariableLabel } from '~/components/InviteText/InviteTextVariableLabel/InviteTextVariableLabel'
import { GroupInviteTemplate } from '~/types/groupInviteTemplate'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { InflowMomentPreviousInviteLabel } from '~/components/InflowMoment/InflowMomentPreviousInviteLabel'

interface Props {
    group: Group
    onPrevious: () => void
    onNext: (manualIds: string[], emailIds: string[]) => void
    loading?: boolean
    groupInviteTemplate?: GroupInviteTemplate
    manualInviteIds?: string[]
    emailInviteIds?: string[]
}

interface State {
    manualInviteIds: string[]
    emailInviteIds: string[]
}

export class LessonInvitesForm extends React.Component<Props, State> {
    public static sortGroupUsers = (a: any, b: any) => {
        if (!a.user || !b.user) {
            return 0
        }

        if (a.user.profile.name < b.user.profile.name) {
            return -1
        }

        if (a.user.profile.name > b.user.profile.name) {
            return 1
        }

        return 0
    }

    public state: State = {
        manualInviteIds: [],
        emailInviteIds: [],
    }

    private learnersFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.learnersFetcher = new Fetcher({
            query: GET_GROUP_LEARNERS_QUERY,
            variables: {
                filters: {
                    filterById: this.props.group._id,
                },
            },

            onChange: () => this.forceUpdate(),
            transformData: data => ({
                ...data,
                groups: [
                    {
                        ...data.groups[0],
                        learnerUsers: [...(data.groups[0].learnerUsers || [])].sort(LessonInvitesForm.sortGroupUsers),
                    },
                ],
            }),
        })
    }

    public render() {
        const { onPrevious, loading: mutationLoading, groupInviteTemplate } = this.props
        const { data, loading } = this.learnersFetcher
        const group = get(data, 'groups[0]') || {}
        const learners = group.learnerUsers || []

        const { manualInviteIds, emailInviteIds } = this.state

        const isSendButtonDisabled = loading || !(manualInviteIds.length || emailInviteIds.length)
        const hasLearners = learners.length > 0

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field isDark={true} title={'Uitnodigingsbrief'}>
                            {groupInviteTemplate && (
                                <InviteTextVariableLabel>{groupInviteTemplate.name}</InviteTextVariableLabel>
                            )}
                        </Field>
                    </FieldGroup>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem width="35%">Naam</TableHeaderItem>
                                    <TableHeaderItem
                                        renderCheckBox={() => (
                                            <CheckBox
                                                name={`manualInviteIds`}
                                                onChange={event => this.onToggleAllCheckBoxes(event, learners)}
                                            />
                                        )}
                                    >
                                        Brief
                                    </TableHeaderItem>
                                    <TableHeaderItem
                                        renderCheckBox={() => (
                                            <CheckBox
                                                name={`emailInviteIds`}
                                                onChange={event => this.onToggleAllCheckBoxes(event, learners)}
                                            />
                                        )}
                                    >
                                        E-mail
                                    </TableHeaderItem>
                                </TableHeader>
                                {loading && (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Spinner />
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!loading && !hasLearners && (
                                    <TableRow>
                                        <TableCell colSpan={3}>
                                            <Subtle>Er zijn geen kandidaten toegevoegd.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                                {!loading &&
                                    hasLearners &&
                                    learners.map((learner: any) => {
                                        const hasEmail = !!get(learner, 'user.emailForCommunication')
                                        const invites = group.latestInvites as GroupInvite[]
                                        const userInvites =
                                            invites && invites.filter(invite => invite.user._id === learner.user._id)
                                        const manualInvite =
                                            userInvites &&
                                            userInvites.find(invite => invite.type === GroupInviteType.Manual)
                                        const emailInvite =
                                            userInvites &&
                                            userInvites.find(invite => invite.type === GroupInviteType.Email)

                                        return (
                                            <TableRow key={learner._id}>
                                                <TableCell>
                                                    <Link target="_blank" route={`/users/learners/${learner.user._id}`}>
                                                        {learner.user.profile.name}
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    <CheckBox
                                                        name={`manual`}
                                                        value={learner._id}
                                                        checked={manualInviteIds.includes(learner._id)}
                                                        onClick={this.onCheckBoxToggle}
                                                    >
                                                        {manualInvite && (
                                                            <InflowMomentPreviousInviteLabel
                                                                templateName={
                                                                    manualInvite.groupInviteTemplate &&
                                                                    manualInvite.groupInviteTemplate.name
                                                                }
                                                                invitedAt={manualInvite.date}
                                                                invitedBy={
                                                                    manualInvite.invitedByUser &&
                                                                    manualInvite.invitedByUser.profile.name
                                                                }
                                                            />
                                                        )}
                                                    </CheckBox>
                                                </TableCell>
                                                <TableCell>
                                                    {!hasEmail ? (
                                                        <Subtle>Gebruiker heeft geen e-mailadres</Subtle>
                                                    ) : (
                                                        <CheckBox
                                                            name={`email`}
                                                            value={learner._id}
                                                            onClick={this.onCheckBoxToggle}
                                                            checked={emailInviteIds.includes(learner._id)}
                                                            isReadonly={!hasEmail}
                                                        >
                                                            {emailInvite && (
                                                                <InflowMomentPreviousInviteLabel
                                                                    templateName={
                                                                        emailInvite.groupInviteTemplate &&
                                                                        emailInvite.groupInviteTemplate.name
                                                                    }
                                                                    invitedAt={emailInvite.date}
                                                                    invitedBy={
                                                                        emailInvite.invitedByUser &&
                                                                        emailInvite.invitedByUser.profile.name
                                                                    }
                                                                    forEmail={true}
                                                                />
                                                            )}
                                                        </CheckBox>
                                                    )}
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })}
                            </Table>
                        </TableView>
                    </TableWrap>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem>
                                <Button onClick={onPrevious}>Vorige</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type="submit" isLoading={mutationLoading} isDisabled={isSendButtonDisabled}>
                                    {this.getButtonTitle()}
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async () => {
        const { onNext } = this.props
        const { manualInviteIds, emailInviteIds } = this.state

        onNext(manualInviteIds, emailInviteIds)
    }

    private onToggleAllCheckBoxes = (event: React.ChangeEvent<HTMLInputElement>, learners?: GroupUser[]) => {
        if (!learners) {
            return
        }

        const property = event.target.name

        const nextStateUsers =
            property === 'emailInviteIds'
                ? learners.filter(learner => learner.user && !!learner.user.emailForCommunication)
                : learners

        const nextStateUserIds = nextStateUsers.map(learner => learner._id)

        this.setState({ [property]: event.target.checked ? nextStateUserIds : [] } as any)
    }

    private onCheckBoxToggle = (event: React.MouseEvent<HTMLInputElement>) => {
        const prop = `${(event.target as any).name}InviteIds`
        if ((event.target as any).checked) {
            this.setState({
                [prop]: [...this.state[prop], (event.target as any).value],
            } as any)
        } else {
            this.setState({
                [prop]: this.state[prop].filter((_id: string) => _id !== (event.target as any).value),
            } as any)
        }
    }

    private getButtonTitle() {
        const { manualInviteIds, emailInviteIds } = this.state

        if (!manualInviteIds.length && emailInviteIds.length) {
            return 'Lesuitnodiging versturen'
        }

        if (manualInviteIds.length && !emailInviteIds.length) {
            return 'Lesuitnodiging downloaden'
        }

        if (manualInviteIds.length && emailInviteIds.length) {
            return 'Lesuitnodiging downloaden en versturen'
        }

        return 'Lesuitnodiging versturen'
    }
}

const GET_GROUP_LEARNERS_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            latestInvites {
                _id
                user {
                    _id
                }
                date
                type
                groupInviteTemplate {
                    _id
                    name
                }
                invitedByUser {
                    profile {
                        name
                    }
                }
            }
            learnerUsers(filterRemoved: false) {
                _id
                user {
                    emailForCommunication
                    _id
                    profile {
                        name
                    }
                }
                lessonInvitePdfDownloadedAt {
                    _id
                    user {
                        _id
                    }
                    date
                    type
                    groupInviteTemplate {
                        _id
                        name
                    }
                    invitedByUser {
                        profile {
                            name
                        }
                    }
                }
                lessonInviteEmailSentAt {
                    _id
                    user {
                        _id
                    }
                    date
                    type
                    groupInviteTemplate {
                        _id
                        name
                    }
                    invitedByUser {
                        profile {
                            name
                        }
                    }
                }
            }
        }
    }
`
