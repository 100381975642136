import * as React from 'react'
import gql from 'graphql-tag'

import CenterModal from '~/components/CenterModal'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import Input from '~/components/Input'
import Fetcher from '~/utils/Fetcher'
import { Program } from '~/types/Program'
import { TagPickerOption, TagPicker } from '~/components/TagPicker'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import { ProjectInvoiceGranularity } from '~/types/ProjectInvoicingSetting'
import translateType from '~/shared/utils/translateType'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import Mutator from '~/utils/Mutator'

interface Props {
    onSubmitSuccess: (newInvoicingSettingId: string) => void
    onRequestClose: () => void
    projectId: string
}

export class AddInvoicingSettingModal extends React.Component<Props> {
    private addProjectInvoicingSettingMutator = new Mutator({
        mutation: CREATE_PROJECT_INVOICING_SETTING_MUTATION,
        reactComponentToUpdate: this,
    })

    private programsFetcher = new Fetcher({
        query: GET_PROGRAMS_QUERY,
        variables: { projectId: this.props.projectId },
        onChange: () => this.forceUpdate(),
    })

    public render() {
        const { onRequestClose } = this.props
        const { errors } = this.addProjectInvoicingSettingMutator

        return (
            <CenterModal onClose={onRequestClose} title={`Facturatie-instelling toevoegen`}>
                <Form onSubmit={this.onSubmitInvoicingSetting}>
                    <FieldCollection style="modal">
                        <FieldGroup isForm>
                            <Field title={'Naam'} errors={errors}>
                                <Input type={'text'} placeholder={'Naam'} name={'projectInvoicingSetting.name'} />
                            </Field>

                            <Field title={'Opleiding(en)'} errors={errors}>
                                <TagPicker
                                    name={'projectInvoicingSetting.programIds'}
                                    placeholder={'Selecteer opleiding(en)'}
                                    isClearable={false}
                                    multi={true}
                                    options={this.getProgramOptions()}
                                />
                            </Field>

                            <Field title={'Factureren op'} errors={errors}>
                                <TagPicker
                                    name={`projectInvoicingSetting.invoiceGranularity`}
                                    placeholder={`Kandidaatsniveau of groepsniveau`}
                                    multi={false}
                                    options={[ProjectInvoiceGranularity.Learner, ProjectInvoiceGranularity.Group].map(
                                        l => ({
                                            label: translateType('projectInvoiceGranularity', l),
                                            value: l,
                                        })
                                    )}
                                />
                            </Field>
                        </FieldGroup>

                        <FieldCollectionFooter>
                            <List horizontal={true} forButtons={true}>
                                <ListItem right={true}>
                                    <Button onClick={onRequestClose}>Annuleren</Button>
                                </ListItem>
                                <ListItem right={true}>
                                    <Button type={'submit'}>Toevoegen</Button>
                                </ListItem>
                            </List>
                        </FieldCollectionFooter>
                    </FieldCollection>
                </Form>
            </CenterModal>
        )
    }

    private getProgramOptions(): TagPickerOption[] {
        const { data } = this.programsFetcher
        const project = data && data.projects && data.projects[0]
        const programs: Program[] = (project && project.programs) || []

        return programs.map(program => ({
            value: program._id,
            label: program.name,
        }))
    }

    private onSubmitInvoicingSetting = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess, projectId } = this.props

        const result = await this.addProjectInvoicingSettingMutator.mutate({
            projectInvoicingSetting: {
                projectId: projectId,
                ...transformFormFields(fields.projectInvoicingSetting, {
                    programIds: transformLib.split(),
                }),
            },
        })

        if (result && result.projectInvoicingSettings_create) {
            const { _id } = result.projectInvoicingSettings_create
            onSubmitSuccess(_id)
        }
    }
}

const GET_PROGRAMS_QUERY = gql`
    query projects($projectId: MongoID) {
        projects(byId: $projectId) {
            _id
            programs(filterByAvailableForNewInvoicingSetting: true) {
                _id
                name
            }
        }
    }
`

const CREATE_PROJECT_INVOICING_SETTING_MUTATION = gql`
    mutation _($projectInvoicingSetting: ProjectInvoicingSettingInputType!) {
        projectInvoicingSettings_create(projectInvoicingSetting: $projectInvoicingSetting) {
            _id
        }
    }
`
