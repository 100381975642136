import * as React from 'react'
const moment = require('moment')
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import MultiInput from '~/components/MultiInput'
import RadioButton from '~/components/RadioButton'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { Button, Spinner } from '~/components'
import { ListItem } from '~/components/ListItem'
import FileInput from '~/components/FileInput'
import { TagPicker } from '~/components/TagPicker'
import DatePicker from '~/components/DatePicker'
import { User } from '~/types/User'
import gql from 'graphql-tag'
import { transformFormFields, Mutator, toast, Fetcher } from '~/utils'
import { orderBy, groupBy } from 'lodash'
import { FinalExamResult, FinalExamSubmitters } from '~/types/FinalExam'
import { VariableMultiInput } from '~/components/VariableMultiInput'

interface Props {
    onClose: () => void
    user: User
    finalExams?: any
    onSubmitSuccess: () => void
    submitterIsToptaal?: boolean
    setPartAndLevel?: string
}

interface State {
    requestedByTopTaal: boolean
}

const INSERT_FINAL_EXAMS_MUTATION = gql`
    mutation finalExams_insertMultiple($finalExams: FinalExamInputType) {
        finalExams_insertMultiple(finalExams: $finalExams) {
            success
            noEmailKnown
        }
    }
`

const GET_FINAL_EXAMS_QUERY = gql`
    query finalExams($byUserId: MongoID) {
        finalExams(byUserId: $byUserId) {
            _id
            date
            part
            level
            attempt
            result
        }
    }
`

interface FinalExamType {
    part: string
    result: string
    date: Date
    attempt: number
}

// interface FinalExamParts {
//     knm: FinalExamType[]
//     ona: FinalExamType[]
//     talk: FinalExamType[]
//     listen: FinalExamType[]
//     read: FinalExamType[]
//     write: FinalExamType[]
// }

export class RegisterFinalExamForm extends React.Component<Props, State> {
    public state: State = {
        requestedByTopTaal: !!this.props.submitterIsToptaal,
    }

    private insertFinalExamsMutator: Mutator
    private finalExamsFetcher: Fetcher
    private finalExams: any

    private attempts = {
        knm: 1,
        ona: 1,
        talk: 1,
        listen: 1,
        read: 1,
        write: 1,
    }

    constructor(props: Props) {
        super(props)

        if (!this.props.finalExams) {
            this.finalExamsFetcher = new Fetcher({
                query: GET_FINAL_EXAMS_QUERY,
                onChange: () => this.forceUpdate(),
                variables: { byUserId: this.props.user._id },
            })
        } else {
            this.finalExams = this.props.finalExams
        }
        this.insertFinalExamsMutator = new Mutator({
            mutation: INSERT_FINAL_EXAMS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { requestedByTopTaal } = this.state
        const { loading } = this.insertFinalExamsMutator
        if (this.finalExamsFetcher) {
            const { data, loading } = this.finalExamsFetcher
            if (data && !loading) {
                this.finalExams = groupBy(data.finalExams, 'part')
            }
        }

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field title={`Aangevraagd door`}>
                            <MultiInput type={`radio`}>
                                <RadioButton
                                    checked={requestedByTopTaal}
                                    onChange={this.onChangeRequestedBy}
                                    value={`toptaal`}
                                >
                                    TopTaal
                                </RadioButton>
                                <RadioButton
                                    checked={!requestedByTopTaal}
                                    onChange={this.onChangeRequestedBy}
                                    value={`external`}
                                >
                                    Extern
                                </RadioButton>
                            </MultiInput>
                        </Field>
                    </FieldGroup>
                    {this.finalExams ? this.renderSubFieldGroup() : <Spinner />}
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading}>
                                    Registreren
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private renderSubFieldGroup = () => {
        const { requestedByTopTaal } = this.state
        const { setPartAndLevel } = this.props
        const increase = setPartAndLevel ? 1 : 0

        return (
            <FieldGroup isInsetGroup={true} isForm={true} noNextField={true}>
                {requestedByTopTaal && (
                    <Field title={`Upload PDF examenafspraak`}>
                        <FileInput name={`file`} accept={`application/pdf`} multiple={false}>
                            Kies bestand
                        </FileInput>
                    </Field>
                )}
                <Field title={`Examen`}>
                    {setPartAndLevel && (
                        <MultiInput type={`two-third`} key={0}>
                            <TagPicker
                                multi={false}
                                options={this.getAbilityOptions()}
                                value={setPartAndLevel}
                                // defaultValue={}
                                isReadonly={true}
                                placeholder={`Onderdeel`}
                                name={`finalExamParts[0].partAndLevel`}
                            />
                            <DatePicker min={new Date().toISOString()} name={`finalExamParts[0].date`} />
                        </MultiInput>
                    )}
                    <VariableMultiInput
                        baseAmount={1 - increase}
                        amountOfPrefilledInputs={increase}
                        getNewInput={(i: number) => {
                            return (
                                <MultiInput type={`two-third`} key={i + increase}>
                                    <TagPicker
                                        multi={false}
                                        options={this.getAbilityOptions()}
                                        placeholder={`Onderdeel`}
                                        name={`finalExamParts[${i + increase}].partAndLevel`}
                                    />
                                    <DatePicker
                                        min={new Date().toISOString()}
                                        name={`finalExamParts[${i + increase}].date`}
                                    />
                                </MultiInput>
                            )
                        }}
                        addButtonLabel={`Examen toevoegen`}
                    />
                </Field>
            </FieldGroup>
        )
    }

    private isExamPartEligibleForRetake = (exam: FinalExamType): boolean => {
        return exam.result !== FinalExamResult.Passed && moment(exam.date).isBefore(moment())
    }

    private onChangeRequestedBy: React.ChangeEventHandler<HTMLInputElement> = ({ target }) => {
        if (target) {
            this.setState({ requestedByTopTaal: target.value === FinalExamSubmitters.TopTaal })
        }
    }

    private getAbilityOptions = () => {
        const { finalExams } = this

        let abilityOptions = [
            {
                value: 'knm',
                label: 'KNM',
            },
            {
                value: 'ona',
                label: 'ONA',
            },
            {
                value: 'talkA2',
                label: 'Spreekvaardigheid A2',
            },
            {
                value: 'talkB1',
                label: 'Spreekvaardigheid B1',
            },
            {
                value: 'talkB2',
                label: 'Spreekvaardigheid B2',
            },
            {
                value: 'listenA2',
                label: 'Luistervaardigheid A2',
            },
            {
                value: 'listenB1',
                label: 'Luistervaardigheid B1',
            },
            {
                value: 'listenB2',
                label: 'Luistervaardigheid B2',
            },
            {
                value: 'readA2',
                label: 'Leesvaardigheid A2',
            },
            {
                value: 'readB1',
                label: 'Leesvaardigheid B1',
            },
            {
                value: 'readB2',
                label: 'Leesvaardigheid B2',
            },
            {
                value: 'writeA2',
                label: 'Schrijfvaardigheid A2',
            },
            {
                value: 'writeB1',
                label: 'Schrijfvaardigheid B1',
            },
            {
                value: 'writeB2',
                label: 'Schrijfvaardigheid B2',
            },
        ]

        for (const groupedExamName of Object.keys(finalExams)) {
            const examsForPart = orderBy(finalExams[groupedExamName], 'attempt', 'desc')
            const lastExamForPart = examsForPart[0]

            switch (groupedExamName) {
                case 'knm': {
                    if (this.isExamPartEligibleForRetake(lastExamForPart)) {
                        this.attempts = { ...this.attempts, knm: lastExamForPart.attempt + 1 }
                        break
                    }
                    abilityOptions = abilityOptions.filter(abilityOptions => abilityOptions.value !== 'knm')
                    break
                }
                case 'ona': {
                    if (this.isExamPartEligibleForRetake(lastExamForPart)) {
                        this.attempts = { ...this.attempts, ona: lastExamForPart.attempt + 1 }
                        break
                    }

                    abilityOptions = abilityOptions.filter(abilityOptions => abilityOptions.value !== 'ona')
                    break
                }
                case 'talk': {
                    if (this.isExamPartEligibleForRetake(lastExamForPart)) {
                        this.attempts = { ...this.attempts, talk: lastExamForPart.attempt + 1 }
                        break
                    }

                    abilityOptions = abilityOptions.filter(abilityOptions => !abilityOptions.value.startsWith('talk'))
                    break
                }
                case 'listen': {
                    if (this.isExamPartEligibleForRetake(lastExamForPart)) {
                        this.attempts = { ...this.attempts, listen: lastExamForPart.attempt + 1 }
                        break
                    }

                    abilityOptions = abilityOptions.filter(abilityOptions => !abilityOptions.value.startsWith('listen'))
                    break
                }
                case 'read': {
                    if (this.isExamPartEligibleForRetake(lastExamForPart)) {
                        this.attempts = { ...this.attempts, read: lastExamForPart.attempt + 1 }
                        break
                    }

                    abilityOptions = abilityOptions.filter(abilityOptions => !abilityOptions.value.startsWith('read'))
                    break
                }
                case 'write': {
                    if (this.isExamPartEligibleForRetake(lastExamForPart)) {
                        this.attempts = { ...this.attempts, write: lastExamForPart.attempt + 1 }
                        break
                    }

                    abilityOptions = abilityOptions.filter(abilityOptions => !abilityOptions.value.startsWith('write'))
                    break
                }
                default:
            }
        }

        return abilityOptions
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { onSubmitSuccess, user, setPartAndLevel } = this.props
        const { requestedByTopTaal } = this.state

        if (!fields.finalExamParts[0].partAndLevel && setPartAndLevel) {
            fields.finalExamParts[0].partAndLevel = setPartAndLevel
        }

        const response = await this.insertFinalExamsMutator.mutate({
            finalExams: {
                ...transformFormFields(fields),
                submitter: requestedByTopTaal ? FinalExamSubmitters.TopTaal : FinalExamSubmitters.External,
                userId: user._id,
                attempts: this.attempts,
            },
        })

        if (response && response.finalExams_insertMultiple && response.finalExams_insertMultiple.success) {
            const responseBody = response.finalExams_insertMultiple
            toast.success(`Examen(s) geboekt.`)

            if (responseBody.noEmailKnown) {
                toast.error(`Geen emailadres bekend bij deze gebruiker.`)
            }

            onSubmitSuccess()
        }
    }
}
