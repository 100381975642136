import gql from 'graphql-tag'
import * as React from 'react'
import { browserHistory } from 'react-router'

import { Brand, Button, Field, FieldCollection, Form, Input, Link, CoverPageTitle, Stretch } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { View } from '~/components/View'
import { Translator } from '~/services/i18n'
import { Mutator, parseGraphQLError, toast } from '~/utils'

interface Props {}

const FORGOT_MUTATION = gql`
    mutation _($email: String) {
        users_forgot_password(email: $email)
    }
`

export default class ForgotView extends React.Component<Props> {
    private forgetMutator: Mutator
    private translator: Translator

    constructor(props: Props) {
        super(props)

        this.forgetMutator = new Mutator({
            mutation: FORGOT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.translator = new Translator({
            namespace: 'Cover.Forgot',
            subscribe: this,
        })
    }

    public componentWillUnmount() {
        this.translator.unsubscribe(this)
    }

    public render() {
        const { errors, loading } = this.forgetMutator
        const { t } = this.translator

        return (
            <View>
                <Brand>{t('brand')}</Brand>
                <Form onSubmit={this.onSubmit}>
                    <CoverPageTitle>{t('title')}</CoverPageTitle>
                    <FieldCollection style={`cover`}>
                        <FieldGroup isForm={true}>
                            <Field
                                isLabel={true}
                                title={t('fields.email.label')}
                                style={`compact`}
                                errors={errors}
                                isBold={true}
                            >
                                <Input placeholder={t('fields.email.placeholder')} name={`email`} type={`email`} />
                            </Field>
                            <Field>
                                <Stretch>
                                    <Button type={`submit`} styleOverride={`edit`} isLoading={loading}>
                                        {t('buttons.submit')}
                                    </Button>
                                </Stretch>
                            </Field>
                            <Field>
                                <Stretch>
                                    <Link route={`/login`}>{t('links.login')}</Link>
                                </Stretch>
                            </Field>
                        </FieldGroup>
                    </FieldCollection>
                </Form>
            </View>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        try {
            const data = await this.forgetMutator.mutate({
                email: fields.email,
            })

            if (data && data.users_forgot_password) {
                browserHistory.push(`/forgotmessage`)
            }
        } catch (error) {
            const { message } = parseGraphQLError(error, { namespace: 'users_forgot_password' })
            toast.error(message)
        }
    }
}
