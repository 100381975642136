import gql from 'graphql-tag'

export const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _($projectManagerUsersFilters: UsersFilterInputType!) {
        projects(sortBy: "name") {
            _id
            name
        }
        projectManagerUsers: users(filters: $projectManagerUsersFilters) {
            _id
            profile {
                name
            }
        }
        groups(sortBy: "name", filters: { filterByHasInvoices: true }) {
            _id
            name
        }
        locations(sortBy: "name") {
            _id
            name
        }
    }
`
