import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, Field, MultiInput, DatePicker } from '~/components'
import gql from 'graphql-tag'
import { Mutator } from '~/utils'

const EXPORT_GENERATE_CITIZENSHIP_REPORT = gql`
    mutation _($startDate: Date, $endDate: Date) {
        exports_generate_citizenship(startDate: $startDate, endDate: $endDate) {
            _id
            file {
                _id
                fileName
            }
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: (fileId: string, filename: string) => void
}

export class ExamCandidateIntegrationExportForm extends React.Component<Props> {
    private generateExportMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.generateExportMutator = new Mutator({
            mutation: EXPORT_GENERATE_CITIZENSHIP_REPORT,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { loading } = this.generateExportMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field title={`Datum vanaf`}>
                            <MultiInput type={`select-range`}>
                                <DatePicker name={'exportStartDateForm'} />
                                <label>t/m</label>
                                <DatePicker name={'exportStartDateTo'} />
                            </MultiInput>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button styleOverride={`edit`} type={`submit`} isLoading={loading}>
                                    Genereer export
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (
        event: React.FormEvent<HTMLFormElement>,
        { exportStartDateForm, exportStartDateTo }: { exportStartDateForm: Date; exportStartDateTo: Date }
    ) => {
        const { onSubmitSuccess } = this.props

        const result = (await this.generateExportMutator.mutate({
            startDate: exportStartDateForm,
            endDate: exportStartDateTo,
        })) as any

        if (result && result.exports_generate_citizenship) {
            const { _id, fileName } = result.exports_generate_citizenship.file
            onSubmitSuccess(_id, fileName)
        }
    }
}
