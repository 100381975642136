import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import apolloClient from '~/services/apolloClient'
import { Mutator, parseGraphQLError, toast } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class EditView extends Component {
    static propTypes = {
        locationProperty: PropTypes.object,
        refetch: PropTypes.func.isRequired,
        params: PropTypes.shape({
            id: PropTypes.string.isRequired,
        }).isRequired,
    }

    constructor(props) {
        super(props)

        this.locationPropertyMutator = new Mutator({
            mutation: EDIT_LOCATIONPROPERTY_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = async (event, fields) => {
        const { params, refetch } = this.props

        const data = await this.locationPropertyMutator.mutate({
            locationProperty: {
                _id: params.id,
                ...transformFormFields(fields.locationProperty),
            },
        })

        if (data) {
            refetch()
            browserHistory.push(`/properties/locationproperties/${params.id}`)
        }
    }

    onDelete = async () => {
        const { params } = this.props

        try {
            await apolloClient.mutate({
                mutation: DELETE_LOCATIONPROPERTY_MUTATION,
                variables: {
                    _id: params.id,
                },
            })

            browserHistory.push(`/properties/locationProperties`)
        } catch (err) {
            const { message } = parseGraphQLError(err, { namespace: 'locationProperties_delete' })
            toast.error(`${message}`)
        }
    }

    render() {
        const { locationProperty } = this.props
        const { errors, loading } = this.locationPropertyMutator

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup>
                                <Field isLabel title={'Naam locatiekenmerk'} errors={errors}>
                                    <Input
                                        name={`locationProperty.name`}
                                        type={`text`}
                                        placeholder={`Naam locatiekenmerk`}
                                        defaultValue={get(locationProperty, `name`)}
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(
                                                    `/properties/locationproperties/${locationProperty._id}`
                                                )
                                            }
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }
}

const EDIT_LOCATIONPROPERTY_MUTATION = gql`
    mutation _($locationProperty: LocationPropertyInputType!) {
        locationProperties_edit(locationProperty: $locationProperty) {
            _id
            name
        }
    }
`

const DELETE_LOCATIONPROPERTY_MUTATION = gql`
    mutation _($_id: MongoID!) {
        locationProperties_delete(locationPropertyId: $_id) {
            _id
        }
    }
`
