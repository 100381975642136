import gql from 'graphql-tag'
import $ from 'jquery'
import { get } from 'lodash'
const moment = require('moment')
import { Moment } from 'moment'
import React from 'react'

import { Calendar, CenterModal } from '~/components'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { CreateTeacherAvailabilityEventForm, EditTeacherAvailabilityEventForm } from '~/forms'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { FormFields } from '~/components/Form'
import { Group } from '~/types/Group'
import { InflowMoment } from '~/types/InflowMoments'

const AVAILABILITY_EVENTS_QUERY = gql`
    query _($userFilters: UsersFilterInputType, $groupFilters: GroupsFilterInputType, $filterByTeacherId: MongoID) {
        users(filters: $userFilters) {
            _id
            teacher {
                availabilityCalendarEvents {
                    _id
                    title
                    type
                    startDate
                    endDate
                }
            }
            intaker {
                inflowMoments {
                    _id
                    isConcept
                    inflowModule {
                        _id
                        name
                    }
                    dateRange {
                        from
                        to
                    }
                }
            }
        }
        groups(filters: $groupFilters) {
            _id
            name
            module {
                _id
                lessonDuration
            }
            lessons(filterByTeacherId: $filterByTeacherId) {
                _id
                date
                order
                duration
            }
        }
    }
`

const HOLIDAY_EVENTS_QUERY = gql`
    query _ {
        calendarEvents(type: "HOLIDAY") {
            _id
            title
            type
            startDate
            endDate
        }
    }
`

const CREATE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_create(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const EDIT_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEvent: CalendarEventInputType!) {
        calendarEvents_edit(calendarEvent: $calendarEvent) {
            _id
        }
    }
`

const DELETE_CALENDAR_EVENT_MUTATION = gql`
    mutation _($calendarEventId: MongoID!) {
        calendarEvents_delete(calendarEventId: $calendarEventId) {
            _id
        }
    }
`

interface Props {
    teacherUserId: string
}

interface State {
    createEventModalActive: boolean
    createEventModalData: any
    editEventModalActive: boolean
    editEventModalData: any
}

export default class AvailabilityView extends React.Component<Props, State> {
    public state: State = {
        createEventModalActive: false,
        createEventModalData: null,
        editEventModalActive: false,
        editEventModalData: null,
    }

    private createEventMutator: Mutator
    private editEventMutator: Mutator
    private deleteEventMutator: Mutator
    private availabilityEventsFetcher: Fetcher
    private holidayEventsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.createEventMutator = new Mutator({
            mutation: CREATE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.editEventMutator = new Mutator({
            mutation: EDIT_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.deleteEventMutator = new Mutator({
            mutation: DELETE_CALENDAR_EVENT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.availabilityEventsFetcher = new Fetcher({
            query: AVAILABILITY_EVENTS_QUERY,
            variables: {
                userFilters: {
                    byId: props.teacherUserId,
                },
                groupFilters: {
                    filterByTeacherUserIds: [props.teacherUserId],
                },
                filterByTeacherId: props.teacherUserId,
            },

            onChange: () => this.forceUpdate(),
        })

        this.holidayEventsFetcher = new Fetcher({
            query: HOLIDAY_EVENTS_QUERY,

            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { createEventModalActive, editEventModalActive } = this.state
        const { loading } = this.availabilityEventsFetcher

        return (
            <ContentView>
                <Wrap full>
                    {createEventModalActive && this.renderCreateEventModal()}
                    {editEventModalActive && this.renderEditEventModal()}

                    <Calendar
                        // TODO: Fix this issue and re-enable the no-string-refs rule
                        // eslint-disable-next-line react/no-string-refs
                        ref="Calendar"
                        isLoading={loading}
                        height="auto"
                        events={this.getCalendarEvents()}
                        defaultView="agendaWeek"
                        allowCreateEvents
                        allowEditEvents
                        selectSlotType="TEACHER_AVAILABILITY"
                        onSelectSlot={this.onSelectSlot}
                        onMoveEvent={this.onMoveEvent}
                        onResizeEvent={this.onMoveEvent}
                        onClickEvent={this.onClickEvent}
                        onAddEventButtonClick={this.onAddEventButtonClick}
                    />
                </Wrap>
            </ContentView>
        )
    }

    private renderCreateEventModal = () => {
        const { errors, loading } = this.createEventMutator
        const {
            createEventModalData: { start, end, allDay },
        } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseCreateEventModal} title={`Beschikbaarheid invoeren`}>
                <CreateTeacherAvailabilityEventForm
                    defaultValues={{
                        allDay: allDay,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.createEvent}
                    onCancel={this.onRequestCloseCreateEventModal}
                />
            </CenterModal>
        )
    }

    private renderEditEventModal = () => {
        const { errors, loading } = this.editEventMutator
        const {
            editEventModalData: { title, start, end, allDay },
        } = this.state

        return (
            <CenterModal onClose={this.onRequestCloseEditEventModal} title={`Aanwezigheid aanpassen`}>
                <EditTeacherAvailabilityEventForm
                    defaultValues={{
                        allDay,
                        startDate: start && start.toDate(),
                        endDate: end && end.toDate(),
                        title,
                    }}
                    errors={errors.getErrorsFromNamespace('calendarEvent')}
                    loading={loading}
                    onSubmit={this.editEvent}
                    onRemove={this.removeEvent}
                    onCancel={this.onRequestCloseEditEventModal}
                />
            </CenterModal>
        )
    }

    private isTimelessMoment = (m: Moment) => {
        return Boolean(m.hours() === 0 && m.minutes() === 0 && m.seconds() === 0 && m.milliseconds() === 0)
    }

    private onAddEventButtonClick = (event: any) => {
        this.setState({
            createEventModalActive: true,
            createEventModalData: { start: event.start },
        })
    }

    private onSelectSlot = (start: Moment, end: Moment) => {
        const allDay = this.isTimelessMoment(start) && this.isTimelessMoment(end)

        this.setState({
            createEventModalActive: true,
            createEventModalData: { start, end, allDay },
        })
    }

    private onClickEvent = (event: any) => {
        this.setState({
            editEventModalActive: true,
            editEventModalData: event,
        })
    }

    private onMoveEvent = async (event: any) => {
        const { _id, start, end } = event

        const data = await this.editEventMutator.mutate({
            calendarEvent: {
                _id: _id,
                startDate: start.toDate(),
                endDate: end.toDate(),
            },
        })

        if (data) {
            this.availabilityEventsFetcher.refetch({ silent: true })
        }
    }

    private onRequestCloseCreateEventModal = () => {
        // TODO: Fix this issue and re-enable the no-string-refs rule
        // eslint-disable-next-line react/no-string-refs
        ;($((this.refs.Calendar as any).refs.calendar) as any).fullCalendar('unselect')

        this.setState({
            createEventModalActive: false,
            createEventModalData: null,
        })
    }

    private onRequestCloseEditEventModal = () => {
        this.setState({
            editEventModalActive: false,
            editEventModalData: null,
        })
    }

    private createEvent = async (fields: FormFields) => {
        const data = await this.createEventMutator.mutate({
            calendarEvent: {
                type: 'TEACHER_AVAILABILITY',
                typeData: {
                    teacherUserId: this.props.teacherUserId,
                },
                ...transformFormFields(fields, {
                    repetition: {
                        fields: (v: FormFields) => transformFormFields(v),
                    },
                }),
            },
        })

        if (data) {
            this.onRequestCloseCreateEventModal()
            this.availabilityEventsFetcher.refetch()
        }
    }

    private editEvent = async (fields: FormFields) => {
        const { editEventModalData: event } = this.state

        const data = await this.editEventMutator.mutate({
            calendarEvent: {
                _id: event._id,
                ...transformFormFields(fields),
            },
        })

        if (data) {
            this.onRequestCloseEditEventModal()
            this.availabilityEventsFetcher.refetch()
        }
    }

    private removeEvent = async () => {
        const { editEventModalData: event } = this.state

        const data = await this.deleteEventMutator.mutate({
            calendarEventId: event._id,
        })

        if (data) {
            this.onRequestCloseEditEventModal()
            this.availabilityEventsFetcher.refetch()
        }
    }

    private getCalendarEvents = () => {
        const { data } = this.availabilityEventsFetcher
        const { data: holidayData } = this.holidayEventsFetcher
        const availabilityEvents = get(data, 'users[0].teacher.availabilityCalendarEvents') || []
        const holidayEvents = get(holidayData, 'calendarEvents') || []

        // Get lesson events
        const groups = get(data, 'groups') || []
        const lessons = groups.reduce((prev: any[], group: Group) => {
            ;(group.lessons || []).forEach(lesson => {
                prev.push({
                    type: 'BACKGROUND_EVENT',
                    allDay: false,
                    startDate: lesson.date,
                    endDate: moment(lesson.date).add(lesson.duration, 'minutes').toJSON(),
                    title: `Les ${lesson.order + 1}: ${group.name}${group.isConcept ? ' (conceptgroep)' : ''}`,
                })
            })

            return prev
        }, [])

        // Get inflow events
        const inflowMoments = get(data, 'users[0].intaker.inflowMoments') || []
        const inflowMomentsWithDate = inflowMoments.filter((im: InflowMoment) => {
            return im.dateRange ? !!im.dateRange.from && !!im.dateRange.to : false
        })
        const inflowMomentEvents = inflowMomentsWithDate.map((inflowMoment: InflowMoment) => {
            return {
                type: 'BACKGROUND_EVENT',
                allDay: false,
                startDate: inflowMoment.dateRange.from,
                endDate: inflowMoment.dateRange.to,
                title: `Instroom: ${inflowMoment.inflowModule.name}${inflowMoment.isConcept ? ` (concept)` : ''}`,
            }
        })

        return [
            ...availabilityEvents,
            ...lessons,
            ...inflowMomentEvents,
            ...holidayEvents.map((h: any) => ({ ...h, type: 'BACKGROUND_EVENT' })),
        ].map(e => ({
            ...e,
            start: e.startDate,
            end: e.endDate,
            allDay: e.allDay,
        }))
    }
}
