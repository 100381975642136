import gql from 'graphql-tag'
import { debounce, omit } from 'lodash'
import * as React from 'react'
import { browserHistory } from 'react-router'

import {
    Button,
    Field,
    FieldCollection,
    Form,
    Input,
    Link,
    MultiInput,
    PhoneInput,
    RadioButton,
    TextArea,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Wrap } from '~/components/Wrap'
import apolloClient from '~/services/apolloClient'
import { Fetcher, Mutator, parseGraphQLError, toast } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { PaymentOrganizationDetailsFields } from '~/components/Organization/PaymentOrganizationDetailsFields'
import { Organization } from '~/types/Organization'
import { VariableMultiInput } from '~/components/VariableMultiInput'
import { LocationFieldsInputGroup } from '~/components/Core/Form/LocationFieldsGroup/LocationFieldsInputGroup'

interface Props {
    organization: Organization
    refetch: () => void
}

interface State {
    showBillingAddress: boolean
    requestNewTwinfieldCustomerCode: boolean
    lookupTwinfieldCustomerLoading: boolean
    lookupTwinfieldCustomerExists: boolean
    lookupTwinfieldCustomerName?: string
    twinfieldCustomerCode?: string
}

export class OrganizationEditView extends React.Component<Props> {
    public state: State = {
        showBillingAddress: !!this.props.organization.billingAddress,
        requestNewTwinfieldCustomerCode: false,
        lookupTwinfieldCustomerLoading: false,
        lookupTwinfieldCustomerExists: !!this.props.organization.twinfieldCustomer,
        lookupTwinfieldCustomerName: this.props.organization.twinfieldCustomer
            ? this.props.organization.twinfieldCustomer.name
            : undefined,
        twinfieldCustomerCode: this.props.organization.twinfieldCustomer
            ? this.props.organization.twinfieldCustomer.code
            : undefined,
    }

    private organizationMutator: Mutator
    private lookupTwinfieldCustomerByCodeFetcher: Fetcher
    private debouncedLookupTwinfieldCustomer: (customerCode: string) => void

    constructor(props: Props) {
        super(props)

        this.organizationMutator = new Mutator({
            mutation: EDIT_ORGANIZATION_MUTATION,
            reactComponentToUpdate: this,
        })

        this.lookupTwinfieldCustomerByCodeFetcher = new Fetcher({
            query: LOOKUP_TWINFIELD_CUSTOMER_BY_CODE_QUERY,
            preventInitialFetch: true,
        })

        this.debouncedLookupTwinfieldCustomer = debounce(this.lookupTwinfieldCustomer, 1000)
    }

    public render() {
        const {
            showBillingAddress,
            lookupTwinfieldCustomerLoading,
            lookupTwinfieldCustomerExists,
            lookupTwinfieldCustomerName,
            requestNewTwinfieldCustomerCode,
            twinfieldCustomerCode,
        } = this.state
        const { organization } = this.props
        const { errors, loading } = this.organizationMutator

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title="Organisatie" isForm={true}>
                                <Field isLabel title={'Naam organisatie'} errors={errors}>
                                    <Input
                                        name={`organization.name`}
                                        type={`text`}
                                        placeholder={`Voer naam in`}
                                        defaultValue={organization.name}
                                    />
                                </Field>
                                <LocationFieldsInputGroup
                                    errors={errors}
                                    location={organization && organization.address}
                                    title={'Adres'}
                                    withoutCountry={true}
                                    name={'organization.address'}
                                />
                                <Field title={`Adresgegevens als factuuradres gebruiken`} errors={errors}>
                                    <MultiInput type={`radio`}>
                                        <RadioButton
                                            name={`organization.addressAsBillingAdress`}
                                            onClick={() => this.setState({ showBillingAddress: false })}
                                            defaultChecked={!showBillingAddress}
                                        >
                                            Ja
                                        </RadioButton>
                                        <RadioButton
                                            name={`organization.addressAsBillingAdress`}
                                            onClick={() => this.setState({ showBillingAddress: true })}
                                            defaultChecked={showBillingAddress}
                                        >
                                            Nee
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                                {showBillingAddress && (
                                    <LocationFieldsInputGroup
                                        errors={errors}
                                        location={organization && organization.billingAddress}
                                        title={'Factuuradres'}
                                        withoutCountry={true}
                                        name={'organization.billingAddress'}
                                    />
                                )}
                                {!showBillingAddress && <input type="hidden" name="organization.billingAddress" />}
                                <Field isLabel title={`Telefoonnummer`} errors={errors}>
                                    <PhoneInput
                                        name={`organization.phoneNumber`}
                                        defaultValue={organization.phoneNumber}
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldGroup title="Betalingsgegevens" isForm={true}>
                                <PaymentOrganizationDetailsFields
                                    organization={organization}
                                    errors={errors}
                                    twinfieldCustomerCode={twinfieldCustomerCode}
                                    requestNewTwinfieldCustomerCode={requestNewTwinfieldCustomerCode}
                                    lookupTwinfieldCustomerLoading={lookupTwinfieldCustomerLoading}
                                    lookupTwinfieldCustomerExists={lookupTwinfieldCustomerExists}
                                    lookupTwinfieldCustomerName={lookupTwinfieldCustomerName}
                                    onChangeRequestNewTwinfieldCustomerCode={
                                        this.onChangeRequestNewTwinfieldCustomerCode
                                    }
                                    onChangeTwinfieldCustomerCode={this.onChangeTwinfieldCustomerCode}
                                />
                            </FieldGroup>
                            <FieldGroup title="Factuurgegevens" isForm={true}>
                                <Field title="Extra factuurgegevens" errors={errors}>
                                    <VariableMultiInput
                                        defaultAmount={organization.invoiceTexts ? organization.invoiceTexts.length : 1}
                                        getNewInput={i => (
                                            <Input
                                                key={i}
                                                name={`organization.invoiceTexts[${i}]`}
                                                type={`name`}
                                                placeholder={`Factuurgegevens`}
                                                defaultValue={
                                                    organization.invoiceTexts ? organization.invoiceTexts[i] : ''
                                                }
                                            />
                                        )}
                                        addButtonLabel="Extra factuurgegevens toevoegen"
                                    />
                                </Field>
                            </FieldGroup>
                            <FieldGroup title="Overige" isForm={true}>
                                <Field title="Contactpersonen">
                                    {this.renderOrganizationContacts(organization.contactUsers)}
                                </Field>
                                <Field isLabel title="Notities" errors={errors}>
                                    <TextArea name="organization.notes" defaultValue={organization.notes} />
                                </Field>
                            </FieldGroup>
                            <FieldCollectionFooter>
                                <List horizontal>
                                    <ListItem right>
                                        <Button
                                            onClick={() =>
                                                browserHistory.push(`/properties/organizations/${organization._id}`)
                                            }
                                        >
                                            Annuleren
                                        </Button>
                                    </ListItem>
                                    <ListItem right>
                                        <Button type={`submit`} isLoading={loading}>
                                            Opslaan
                                        </Button>
                                    </ListItem>
                                    <ListItem>
                                        <Button
                                            onClick={this.onDelete}
                                            linkStyle={['default', 'danger']}
                                            confirm={{
                                                title: 'Verwijderen',
                                                message:
                                                    'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                                execute: {
                                                    buttonType: 'danger',
                                                    title: 'Verwijderen',
                                                },
                                            }}
                                        >
                                            Verwijderen
                                        </Button>
                                    </ListItem>
                                </List>
                            </FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private onChangeTwinfieldCustomerCode = (customerCode: string) => {
        this.setState({
            twinfieldCustomerCode: customerCode,
            lookupTwinfieldCustomerLoading: true,
        })

        if (customerCode) {
            this.debouncedLookupTwinfieldCustomer(customerCode)
        }
    }

    private onChangeRequestNewTwinfieldCustomerCode = (checked: boolean) => {
        this.setState({
            requestNewTwinfieldCustomerCode: checked,
        })
    }

    private async lookupTwinfieldCustomer(code: string) {
        this.setState({
            lookupTwinfieldCustomerLoading: true,
            lookupTwinfieldCustomerExists: false,
            lookupTwinfieldCustomerName: undefined,
        })

        try {
            const result = await this.lookupTwinfieldCustomerByCodeFetcher.fetch({
                twinfieldCustomerCode: code,
            })

            this.setState({ lookupTwinfieldCustomerLoading: false })

            if (result) {
                const { twinfield_customer } = result

                if (twinfield_customer) {
                    this.setState({
                        lookupTwinfieldCustomerExists: true,
                        lookupTwinfieldCustomerName: twinfield_customer.name,
                    })
                }
            }
        } catch (err) {
            this.setState({ lookupTwinfieldCustomerLoading: false })
            throw err
        }
    }

    private renderOrganizationContacts(contactUsers: Organization['contactUsers']) {
        if (!contactUsers) {
            return null
        }

        return (
            <ul>
                {contactUsers.map((contactUser, i) => (
                    <li key={i}>
                        <Link key={contactUser._id} route={`/users/organization-contacts/${contactUser._id}`}>
                            {contactUser.profile.name}
                        </Link>
                    </li>
                ))}
            </ul>
        )
    }

    private onSubmit = (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { organization, refetch } = this.props
        const { requestNewTwinfieldCustomerCode } = this.state
        fields = omit(fields, ['organization.addressAsBillingAdress'])

        if (requestNewTwinfieldCustomerCode) {
            fields = omit(fields, ['organization.twinfieldCustomerCode'])
        }

        this.organizationMutator
            .mutate({
                organization: {
                    _id: organization._id,
                    requestNewTwinfieldCustomerCode,
                    ...transformFormFields(fields.organization, {
                        address: transformLib.addressWithCountryNL(),
                        billingAddress: transformLib.addressWithCountryNL(),
                        phoneNumber: transformLib.phoneNumber(),
                        invoiceEmailRecipients: {
                            value: (values: any[]) => {
                                return (
                                    values &&
                                    values
                                        .filter(value => value.name || value.email)
                                        .map(value => transformFormFields(value))
                                )
                            },
                            isNull: (values: any[]) => !values || values.length === 0,
                        },
                    }),
                },
            })
            .then(data => {
                if (data) {
                    refetch()
                    browserHistory.push(`/properties/organizations/${organization._id}`)
                }
            })
    }

    private onDelete = () => {
        const { organization } = this.props

        apolloClient
            .mutate({
                mutation: DELETE_ORGANIZATION_MUTATION,
                variables: {
                    _id: organization._id,
                },
            })
            .then(() => browserHistory.push(`/properties/organizations`))
            .catch((err: any) => {
                const { message } = parseGraphQLError(err, { namespace: 'organizations_delete' })
                toast.error(`${message}`)
            })
    }
}

const LOOKUP_TWINFIELD_CUSTOMER_BY_CODE_QUERY = gql`
    query _($twinfieldCustomerCode: String!) {
        twinfield_customer(twinfieldCustomerCode: $twinfieldCustomerCode) {
            name
        }
    }
`

const EDIT_ORGANIZATION_MUTATION = gql`
    mutation _($organization: OrganizationInputType!) {
        organizations_edit(organization: $organization) {
            _id
        }
    }
`

const DELETE_ORGANIZATION_MUTATION = gql`
    mutation _($_id: MongoID!) {
        organizations_delete(organizationId: $_id) {
            _id
        }
    }
`
