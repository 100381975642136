import * as React from 'react'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import Icon from '~/components/Icon'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import gql from '../../../node_modules/graphql-tag'
import { Fetcher, Filter, Mutator, downloadFile, toast } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { InflowMoment } from '~/types/InflowMoments'
import Link from '~/components/Link'
import Sorter from '~/utils/Sorter'
import { InflowModule } from '~/types/InflowModule'
import { Organization } from '~/types/Organization'
import { User } from '~/types/User'
import { Location } from '~/types/Location'
import FilterFieldCollection from '~/components/FilterFieldCollection'
import Field from '~/components/Field'
import { TagPicker, TagPickerChangeHandler } from '~/components/TagPicker'
import DatePicker from '~/components/DatePicker'
import MultiInput from '~/components/MultiInput'
import { capitalize, get } from 'lodash'
const moment = require('moment')

const START = 40
const INCREASE = 40
const DEFAULT_SORT_DIR = 'DESC'
const DEFAULT_SORT_BY = 'dateRange.from'

const STATIC_INFLOW_MODULES_FILTER = {
    filterByConcepts: false,
}

const GET_INFLOW_MOMENTS_QUERY = gql`
    query inflowMoments(
        $skip: Int
        $limit: Int
        $filters: InflowMomentFilterInputType
        $sortDir: String
        $sortBy: String
    ) {
        inflowMoments(skip: $skip, limit: $limit, filters: $filters, sortDir: $sortDir, sortBy: $sortBy) {
            _id
            organization {
                _id
                name
            }
            location {
                _id
                name
            }
            dateRange {
                from
                to
            }
            inflowModule {
                _id
                name
            }
            candidateCountUsed
            candidateCountTotal
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }

        users(filters: { roles: ["EMPLOYEE"] }, sortBy: "profile.firstName") {
            _id
            profile {
                name
            }
        }

        inflowModules(sortBy: "name") {
            _id
            name
        }

        locations(sortBy: "name") {
            _id
            name
        }
    }
`

const CREATE_INFLOWMOMENT_TIMESLOT_USER_EXPORT_FILE_MUTATION = gql`
    mutation _($filters: InflowMomentFilterInputType, $sortDir: String, $sortBy: String) {
        inflowMomentTimeslotUsers_createExportFile(sortDir: $sortDir, sortBy: $sortBy, filters: $filters) {
            fileId
        }
    }
`

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

export class DefinitiveInflowMomentsTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private inflowMomentsFetcher: Fetcher
    private paginator: Paginator
    private filter: Filter
    private sorter: Sorter
    private filterSuggestionsFetcher: Fetcher
    private createTimeslotUserExportMutator: Mutator

    constructor(props: Props) {
        super(props)
        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'filterByInflowModule',
                'filterByLocation',
                'filterByOrganization',
                'filterByDateFrom',
                'filterByDateTo',
                'filterByCreatedBy',
                'filterByConcepts',
            ],
            onChange: (filters: any) => {
                this.inflowMomentsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_INFLOW_MODULES_FILTER,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [],
        })

        this.inflowMomentsFetcher = new Fetcher({
            query: GET_INFLOW_MOMENTS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_INFLOW_MODULES_FILTER,
                    ...this.filter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => this.paginator.reset(),
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.createTimeslotUserExportMutator = new Mutator({
            mutation: CREATE_INFLOWMOMENT_TIMESLOT_USER_EXPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { loading } = this.inflowMomentsFetcher

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <Wrap full={true}>{this.renderActionBar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    private renderActionBar = () => {
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['filterByConcepts'] })
        const inflowMomentsFilters = this.filter.getFilters()
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { loading: loadingExport } = this.createTimeslotUserExportMutator
        const { users = [], organizations = [], inflowModules = [], locations = [] } = filterParams

        const employeeOptions = users.map((user: User) => ({ value: user._id, label: user.profile.name }))
        const organizationOptions = organizations.map((organization: Organization) => ({
            value: organization._id,
            label: organization.name,
        }))
        const inflowModuleOptions = inflowModules.map((inflowModule: InflowModule) => ({
            value: inflowModule._id,
            label: inflowModule.name,
        }))
        const locationOptions = locations.map((location: Location) => ({ value: location._id, label: location.name }))

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                leftIcon={<Icon name={`filter`} />}
                                onClick={() => toggleDrawer()}
                                isActive={drawerActive}
                            >
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem right>
                            <Button
                                leftIcon={<Icon name={`download`} />}
                                onClick={this.onClickExportInflowMomentTimeslotUsers}
                                isLoading={loadingExport}
                                confirm={{
                                    title: 'Exporteren',
                                    message: 'Je staat op het punt om een XLS export te maken. Weet je het zeker?',
                                    execute: {
                                        buttonType: 'submit',
                                        title: 'Export maken',
                                    },
                                }}
                            >
                                Export van huidige selectie
                            </Button>
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        <Field isLabel={true} title={'Instroommodule'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByInflowModule}
                                multi={false}
                                name={`filterByInflowModule`}
                                onChange={this.onFilterTagPicker}
                                options={inflowModuleOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={'Location'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByLocation}
                                multi={false}
                                name={`filterByLocation`}
                                onChange={this.onFilterTagPicker}
                                options={locationOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={'Organisatie'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByOrganization}
                                multi={false}
                                name={`filterByOrganization`}
                                onChange={this.onFilterTagPicker}
                                options={organizationOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={'Datum'} style={'compact'}>
                            <MultiInput type={`select-range`}>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'filterByDateFrom'}
                                    defaultValue={inflowMomentsFilters.filterByEndDateFrom}
                                />
                                <label>t/m</label>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'filterByDateTo'}
                                    defaultValue={inflowMomentsFilters.filterByEndDateTo}
                                />
                            </MultiInput>
                        </Field>
                        <Field isLabel={true} title={'Aangemaakt door'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByDateFrom}
                                multi={false}
                                name={`filterByCreatedBy`}
                                onChange={this.onFilterTagPicker}
                                options={employeeOptions}
                            />
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    private renderTable = () => {
        const { loading, data } = this.inflowMomentsFetcher
        const { inflowMoments } = data || []
        const totalColSpan = 6

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem>Instroommodule</TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`dateRange.from`} width={`280px`}>
                            Datum
                        </TableHeaderItem>
                        <TableHeaderItem>Locatie</TableHeaderItem>
                        <TableHeaderItem>Kandidaten</TableHeaderItem>
                        <TableHeaderItem>Organisatie</TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={totalColSpan} isLoading={true} />
                        </TableRow>
                    ) : inflowMoments && inflowMoments.length > 0 ? (
                        this.renderInflowMomentsRows(inflowMoments)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={totalColSpan}>
                                <Subtle>Er zijn geen instroommodules gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderInflowMomentsRows = (inflowMoments: InflowMoment[]) => {
        return inflowMoments.map(inflowMoment => {
            const { _id, inflowModule, location, organization, candidateCountTotal, candidateCountUsed } = inflowMoment

            return (
                <TableRow key={_id}>
                    <TableCell>
                        <Link route={`/inflow-moments/definitive/${_id}`}>{inflowModule.name}</Link>
                    </TableCell>
                    <TableCell>{this.renderDate(inflowMoment)}</TableCell>
                    <TableCell>{location ? location.name : '-'}</TableCell>
                    <TableCell>
                        {candidateCountTotal ? `${candidateCountUsed || 0} / ${candidateCountTotal}` : '-'}
                    </TableCell>
                    <TableCell>{organization ? organization.name : '-'}</TableCell>
                </TableRow>
            )
        })
    }

    private onFilter = (event: any) => {
        this.filter.applyFromInputEvent(event)
    }

    private onFilterTagPicker: TagPickerChangeHandler = (values, tagPicker) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    private sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.inflowMomentsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    private renderDate(inflowMoment: InflowMoment) {
        if (!inflowMoment.dateRange || !inflowMoment.dateRange.from || !inflowMoment.dateRange.to) {
            return ''
        }

        const startDate = moment(inflowMoment.dateRange.from)
        const endDate = moment(inflowMoment.dateRange.to)

        return `${capitalize(startDate.format('ddd DD MMMM YYYY HH:mm'))} - ${endDate.format('H:mm')}`
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.inflowMomentsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.inflowMoments.length === 0,
                })

                return {
                    inflowMoments: removeDuplicateDocuments([
                        ...(prevData.inflowMoments || []),
                        ...moreData.inflowMoments,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onClickExportInflowMomentTimeslotUsers = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault()

        const { sortDir, sortBy } = this.state

        this.createTimeslotUserExportMutator
            .mutate({
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_INFLOW_MODULES_FILTER,
                    ...this.filter.getFilters(),
                },
            })
            .then(data => {
                if (data) {
                    const res = get(data, 'inflowMomentTimeslotUsers_createExportFile')

                    if (res) {
                        if (res.fileId) {
                            downloadFile(res.fileId, 'instroom-export.xlsx')
                        } else {
                            toast.error('Geen inschrijvingen gevonden om te exporteren')
                        }
                    } else {
                        toast.error('XLS downloaden mislukt')
                    }
                }
            })
    }
}
