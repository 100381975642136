import React from 'react'
import { throttle } from 'lodash'

import { ClassValue } from '~/services/BEMService'

import gql from 'graphql-tag'
import { Fetcher, removeDuplicateDocuments } from '~/utils'
import { User } from '~/types/User'
import { TagPicker } from '~/components/TagPicker'
import { Row } from '~/components/Core/Layout/Row'

const GET_LEARNERS_QUERY = gql`
    query _($filters: UsersFilterInputType, $limit: Int, $skip: Int, $sortBy: String, $sortDir: String) {
        users(filters: $filters, limit: $limit, skip: $skip, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            learner {
                enrollmentDate
                hasInflowMomentsAfterEnrollmentDate
                inflowMomentsNoShowStreak
                isAvailableForInflow
                inflowMoments {
                    inflowModule {
                        name
                    }
                    dateRange {
                        from
                        to
                    }
                    location {
                        name
                    }
                    inflowModule {
                        name
                    }
                }
                organizations {
                    organizationFileNumber
                }
            }
            profile {
                name
            }
        }
    }
`

interface Props {
    className?: ClassValue
    name: string
    onChangeHandler?: any
    creatableHandler?: any
}

interface State {}

export class LearnersTagPicker extends React.Component<Props, State> {
    private skip = 0
    private limit = 20

    private onThrottledSearch: (value: string) => void
    private learnersFetcher: Fetcher = new Fetcher({
        query: GET_LEARNERS_QUERY,
        variables: {
            filters: {
                roles: ['LEARNER'],
            },

            skip: this.skip,
            limit: this.limit,
            sortBy: 'profile.name',
            sortDir: 'ASC',
        },
        onChange: () => this.forceUpdate(),
    })

    constructor(props: Props) {
        super(props)

        this.onThrottledSearch = throttle(this.onSearch, 350, {
            leading: false,
            trailing: true,
        })
    }

    public render() {
        const { name, onChangeHandler, creatableHandler } = this.props
        const { data } = this.learnersFetcher

        const users: User[] = data && data.users ? data.users : []

        const options = users.map(learnerUser => ({
            value: learnerUser._id,
            isAvailableForInflow: learnerUser.learner && learnerUser.learner.isAvailableForInflow,
            hasInflowMomentsAfterEnrollmentDate:
                learnerUser.learner && learnerUser.learner.hasInflowMomentsAfterEnrollmentDate,
            enrollmentDate: learnerUser.learner && learnerUser.learner.enrollmentDate,
            inflowMoments: learnerUser.learner && learnerUser.learner.inflowMoments,
            NoShowStreak: learnerUser.learner && learnerUser.learner.inflowMomentsNoShowStreak,
            label: this.renderLabel(learnerUser),
        }))

        return (
            <TagPicker
                multi={false}
                creatable={true}
                name={name}
                isClearable={true}
                createValue={'Kandidaat aanmaken'}
                creatableHandler={creatableHandler}
                onChange={onChangeHandler}
                onSearch={this.onThrottledSearch}
                options={options}
                disableBuiltInFiltering={true}
                onEndReached={() => {
                    this.skip = this.skip + this.limit

                    this.learnersFetcher.fetchMore({
                        variables: { limit: this.limit, skip: this.skip },
                        getMergedData: (prevData: any, moreData: any) => {
                            return {
                                users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                            }
                        },
                    } as any)
                }}
            />
        )
    }

    private renderLabel = (learnerUser: User) => {
        const { name } = learnerUser.profile
        const organizations = (learnerUser.learner && learnerUser.learner.organizations) || []
        const fileNumbers = organizations.map(o => o.organizationFileNumber)

        return (
            <Row>
                {name}
                {fileNumbers.length > 0 && (
                    <span
                        style={{
                            marginLeft: '10px',
                            color: '#b2b2b2',
                        }}
                    >
                        {fileNumbers.join(', ')}
                    </span>
                )}
            </Row>
        )
    }

    private onSearch = (searchText: string) => {
        this.skip = 0
        this.learnersFetcher.fetch({ filters: { searchText, roles: ['LEARNER'] }, skip: this.skip })
    }
}
