import * as React from 'react'
import Form, { FormFields } from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button, Field, Select, Option } from '~/components'
import { range } from 'lodash'
import gql from 'graphql-tag'
import { Mutator } from '~/utils'

const EXPORT_GENERATE_OUTFLOW_REPORT = gql`
    mutation _($year: Int) {
        exports_generate_outflow(year: $year) {
            _id
            file {
                _id
                fileName
            }
        }
    }
`

interface Props {
    onClose: () => void
    onSubmitSuccess: (fileId: string, filename: string) => void
}

export class OutflowIntegrationExportForm extends React.Component<Props> {
    // TODO: Get the from and to years dynamically
    private YEAR_OPTIONS = range(2010, new Date().getFullYear() + 1)

    private generateExportMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.generateExportMutator = new Mutator({
            mutation: EXPORT_GENERATE_OUTFLOW_REPORT,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { onClose } = this.props
        const { loading } = this.generateExportMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field title={`Kalenderjaar`}>
                            <Select name={`year`}>
                                <Option value={''}>Kalenderjaar</Option>
                                {this.YEAR_OPTIONS.map((year, index) => (
                                    <Option key={index} value={year}>
                                        {year}
                                    </Option>
                                ))}
                            </Select>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button styleOverride={`edit`} type={`submit`} isLoading={loading}>
                                    Genereer export
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onSubmitSuccess } = this.props

        const result = (await this.generateExportMutator.mutate({
            year: +fields.year,
        })) as any

        if (result && result.exports_generate_outflow) {
            const { _id, fileName } = result.exports_generate_outflow.file
            onSubmitSuccess(_id, fileName)
        }
    }
}
