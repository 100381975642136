import React from 'react'
import gql from 'graphql-tag'

import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import { Fetcher, Mutator, toast } from '~/utils'
import { FieldCollection, Field, Form, Input, MultiInput, RadioButton, Button, CheckBox, ActionBar } from '~/components'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { VariableMultiFieldBlock } from '~/components/VariableMultiFieldBlock/VariableMultiFieldBlock'
import { FieldBlock } from '~/components/FieldBlock'
import { UnitInput, unitType } from '~/components/UnitInput'
import CheckboxWithOptionalInput from '~/components/CheckboxWithOptionalInput'
import { ListItem } from '~/components/ListItem'
import { browserHistory, RouteComponentProps } from 'react-router'
import { RouteParams } from '~/views/App/Properties/Projects/Detail/InvoicingSettings/Detail'
import { Project } from '~/types/Project'
import {
    ProjectInvoicingSetting,
    ProjectInvoiceGranularity,
    ProjectVariablePriceVariableBy,
    ProjectChargeForLessonAttendanceType,
    ProjectPriceType,
    ProjectFixedPriceSettings,
    ProjectInvoiceTiming,
    ProjectPeriodicalTimingPeriod,
    ProjectChargeForLessonReasonOfAbsence,
    ProjectChargeForLessonsType,
    ProjectPeriodCondition,
    ProjectPeriodConditionSetting,
} from '~/types/ProjectInvoicingSetting'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { FormFields } from '~/components/Form'
import translateType from '~/shared/utils/translateType'
import { Program } from '~/types/Program'
import { ValidationError } from '~/components/ValidationError'

interface Props extends RouteComponentProps<RouteParams, {}> {
    project: Project
    projectInvoicingSetting: ProjectInvoicingSetting
    refetchProject: () => void
    refetchInvoicingSetting: () => void
}

interface AmountRange {
    to: number
    amount?: number | null
}

interface State {
    variablePriceAmountRanges: AmountRange[]
    priceType?: ProjectPriceType | null
    invoiceTiming?: ProjectInvoiceTiming | null
    projectVariablePriceVariableBy?: ProjectVariablePriceVariableBy | null
    projectInvoiceGranularity?: ProjectInvoiceGranularity | null
    periodicalTimingLessonAttendanceType?: ProjectChargeForLessonAttendanceType | null
}

export default class InvoicingSettingsView extends React.Component<Props, State> {
    public state: State = {
        variablePriceAmountRanges: this.getDefaultVariablePriceAmountRangesValue(),
        priceType: this.props.projectInvoicingSetting.priceType,
        projectVariablePriceVariableBy:
            (this.props.projectInvoicingSetting.variablePriceSettings &&
                this.props.projectInvoicingSetting.variablePriceSettings.variableBy) ||
            ProjectVariablePriceVariableBy.Learner,
        projectInvoiceGranularity: this.props.projectInvoicingSetting.invoiceGranularity,
        invoiceTiming: this.props.projectInvoicingSetting.invoiceTiming,
        periodicalTimingLessonAttendanceType: this.props.projectInvoicingSetting.periodicalTimingSettings
            ? this.props.projectInvoicingSetting.periodicalTimingSettings.chargeForLessonAttendanceType || undefined
            : undefined,
    }

    private editProjectInvoicingSettingMutator = new Mutator({
        mutation: EDIT_PROJECT_INVOICING_SETTING_MUTATION,
        reactComponentToUpdate: this,
    })

    private deleteProjectInvoicingSettingMutator = new Mutator({
        mutation: DELETE_PROJECT_INVOICING_SETTING_MUTATION,
    })

    private programsFetcher = new Fetcher({
        query: GET_PROGRAMS_QUERY,
        variables: { projectId: this.props.project._id },
        onChange: () => this.forceUpdate(),
    })

    public render() {
        const { projectInvoicingSetting } = this.props
        const { loading: programsLoading } = this.programsFetcher

        const defaultPrograms = (projectInvoicingSetting.programs && projectInvoicingSetting.programs) || []
        const defaultProgramIds = defaultPrograms.map(program => program._id)

        return (
            <ContentView>
                <Wrap>
                    <Form onSubmit={this.onSubmit}>
                        <FieldCollection>
                            <FieldGroup title={'Gegevens'} isForm={true}>
                                <Field title={'Naam'}>
                                    <Input
                                        name="projectInvoicingSetting.name"
                                        type="text"
                                        defaultValue={projectInvoicingSetting.name}
                                    />
                                </Field>

                                <Field title={'Opleiding(en)'}>
                                    <TagPicker
                                        name={'projectInvoicingSetting.programIds'}
                                        placeholder={'Selecteer opleiding(en)'}
                                        isClearable={false}
                                        multi={true}
                                        defaultValue={defaultProgramIds}
                                        options={this.getProgramOptions()}
                                        isLoading={programsLoading}
                                    />
                                </Field>

                                <Field title={'Factureren op'}>
                                    <TagPicker
                                        name={`projectInvoicingSetting.invoiceGranularity`}
                                        placeholder={`Kandidaatsniveau of groepsniveau`}
                                        multi={false}
                                        defaultValue={projectInvoicingSetting.invoiceGranularity}
                                        options={[
                                            ProjectInvoiceGranularity.Learner,
                                            ProjectInvoiceGranularity.Group,
                                        ].map(l => ({
                                            label: translateType('projectInvoiceGranularity', l),
                                            value: l,
                                        }))}
                                        onChange={option => {
                                            this.setState({
                                                projectInvoiceGranularity: option ? option.value : undefined,
                                            })
                                        }}
                                    />
                                </Field>
                            </FieldGroup>

                            {this.renderPriceFieldGroup()}

                            {this.renderTimingFieldGroup()}

                            <FieldCollectionFooter>{this.renderFooter()}</FieldCollectionFooter>
                        </FieldCollection>
                    </Form>
                </Wrap>
            </ContentView>
        )
    }

    private renderPriceFieldGroup() {
        const { errors } = this.editProjectInvoicingSettingMutator
        const { priceType, projectInvoiceGranularity } = this.state

        return (
            <FieldGroup title={'Groepskosten'} isForm={true}>
                <Field title="Bedrag" errors={errors}>
                    <MultiInput type="radio">
                        <RadioButton
                            name="projectInvoicingSetting.priceType"
                            defaultChecked={priceType === ProjectPriceType.Fixed}
                            onClick={() => this.setState({ priceType: ProjectPriceType.Fixed })}
                            value={ProjectPriceType.Fixed}
                        >
                            Vast
                        </RadioButton>
                        <RadioButton
                            name="projectInvoicingSetting.priceType"
                            defaultChecked={priceType === ProjectPriceType.Variable}
                            onClick={() => this.setState({ priceType: ProjectPriceType.Variable })}
                            value={ProjectPriceType.Variable}
                        >
                            Variabel
                        </RadioButton>
                    </MultiInput>
                </Field>

                {priceType === ProjectPriceType.Fixed && this.renderFixedPriceFieldGroup()}

                {priceType === ProjectPriceType.Variable &&
                    (projectInvoiceGranularity
                        ? this.renderVariablePriceFieldGroup()
                        : this.renderMissingGranularityWarning())}
            </FieldGroup>
        )
    }

    private renderFixedPriceFieldGroup() {
        const { errors } = this.editProjectInvoicingSettingMutator
        const { projectInvoicingSetting } = this.props
        const { priceType } = this.state

        const fixedPriceSettings: ProjectFixedPriceSettings = projectInvoicingSetting.fixedPriceSettings
            ? projectInvoicingSetting.fixedPriceSettings
            : {}

        if (priceType === ProjectPriceType.Fixed) {
            return (
                <FieldGroup highlight={true} isInsetGroup={true} separateFields={false} isForm={true}>
                    <Field title={'Vast bedrag'} errors={errors}>
                        <UnitInput
                            name={'projectInvoicingSetting.fixedPriceSettings.amount'}
                            type={'number'}
                            unitType={unitType.euro}
                            defaultValue={fixedPriceSettings.amount}
                        />
                    </Field>
                </FieldGroup>
            )
        }

        return null
    }

    private renderVariablePriceFieldGroup() {
        const { errors } = this.editProjectInvoicingSettingMutator
        const { variablePriceAmountRanges, projectVariablePriceVariableBy, projectInvoiceGranularity } = this.state

        return (
            <FieldGroup highlight={true} isInsetGroup={true} separateFields={false} isForm={true}>
                <Field isIndented={true} title={'Variabel op'} errors={errors}>
                    <MultiInput type="radio">
                        <RadioButton
                            name="projectInvoicingSetting.variablePriceSettings.variableBy"
                            defaultChecked={projectVariablePriceVariableBy === ProjectVariablePriceVariableBy.Hour}
                            onClick={() =>
                                this.setState({ projectVariablePriceVariableBy: ProjectVariablePriceVariableBy.Hour })
                            }
                            value={ProjectVariablePriceVariableBy.Hour}
                        >
                            Lesuren
                        </RadioButton>
                        <RadioButton
                            name="projectInvoicingSetting.variablePriceSettings.variableBy"
                            defaultChecked={projectVariablePriceVariableBy === ProjectVariablePriceVariableBy.Learner}
                            onClick={() =>
                                this.setState({
                                    projectVariablePriceVariableBy: ProjectVariablePriceVariableBy.Learner,
                                })
                            }
                            value={ProjectVariablePriceVariableBy.Learner}
                        >
                            Groepsgrootte
                        </RadioButton>
                    </MultiInput>
                </Field>
                {projectVariablePriceVariableBy && (
                    <VariableMultiFieldBlock
                        isInset={true}
                        baseAmount={variablePriceAmountRanges.length || 1}
                        onAddAction={() => this.setNewVariableGroupInput()}
                        addButtonLabel={'Extra bereik toevoegen'}
                        onRemoveAction={index => this.removeVariableGroupInput(index)}
                        renderFieldGroup={(index, remove) => {
                            const { to, amount } = variablePriceAmountRanges[index]
                            const previousRnage = variablePriceAmountRanges[index - 1]
                            const from = previousRnage ? previousRnage.to + 1 : 1

                            const baseName = `projectInvoicingSetting.variablePriceSettings.amountRanges[${index}]`
                            const variableByCopy = this.getVariableByCopy(projectVariablePriceVariableBy)
                            const title = `Bereik vanaf ${from} ${
                                from === 1 ? variableByCopy.singular : variableByCopy.plural
                            }`

                            return (
                                <FieldBlock title={title} onRemove={remove} isForm={true}>
                                    <Field title={`Tot en met`} errors={errors}>
                                        <Input
                                            type={'number'}
                                            placeholder={'0'}
                                            value={to}
                                            onChange={event => this.setUntillValue(event, index)}
                                            name={`${baseName}.to`}
                                        />
                                    </Field>
                                    <Field
                                        title={translateType('amountPerInvoiceGranularity', projectInvoiceGranularity)}
                                        errors={errors}
                                    >
                                        <UnitInput
                                            onChange={event => this.setPriceValue(event, index)}
                                            name={`${baseName}.amount`}
                                            placeholder={'0'}
                                            unitType={unitType.euro}
                                            defaultValue={amount}
                                        />
                                    </Field>
                                </FieldBlock>
                            )
                        }}
                    />
                )}
            </FieldGroup>
        )
    }

    private renderMissingGranularityWarning = () => {
        return (
            <Field isLabel title={' '}>
                <ValidationError text={`Vul 'Factureren op' in om verder te gaan`} />
            </Field>
        )
    }

    private renderTimingFieldGroup = () => {
        const { errors } = this.editProjectInvoicingSettingMutator
        const { invoiceTiming } = this.state

        return (
            <FieldGroup title={'Factuurmomenten'} isForm={true}>
                <Field title="Frequentie" errors={errors}>
                    <MultiInput type="radio">
                        {[ProjectInvoiceTiming.Periodical, ProjectInvoiceTiming.Variable].map((value, i) => (
                            <RadioButton
                                key={i}
                                name={'projectInvoicingSetting.invoiceTiming'}
                                onClick={() => this.setState({ invoiceTiming: value })}
                                defaultChecked={invoiceTiming === value}
                                value={value}
                            >
                                {translateType('projectInvoiceTiming', value)}
                            </RadioButton>
                        ))}
                    </MultiInput>
                </Field>
                <FieldGroup isInsetGroup={true} isForm={true}>
                    {invoiceTiming === ProjectInvoiceTiming.Periodical && this.renderTimingPeriodicalFields()}
                    {invoiceTiming === ProjectInvoiceTiming.Variable && this.renderTimingVariableFields()}
                </FieldGroup>
            </FieldGroup>
        )
    }

    private renderTimingPeriodicalFields() {
        const { projectInvoicingSetting } = this.props
        const { periodicalTimingLessonAttendanceType } = this.state
        const { errors } = this.editProjectInvoicingSettingMutator

        const chargeForLessonReasonOfAbsences = projectInvoicingSetting.periodicalTimingSettings
            ? projectInvoicingSetting.periodicalTimingSettings.chargeForLessonReasonOfAbsences || []
            : []

        return (
            <>
                <Field title={'Periode'} errors={errors}>
                    <TagPicker
                        name={`projectInvoicingSetting.periodicalTimingSettings.period`}
                        placeholder={`Selecteer periode`}
                        multi={false}
                        defaultValue={
                            projectInvoicingSetting.periodicalTimingSettings
                                ? projectInvoicingSetting.periodicalTimingSettings.period
                                : null
                        }
                        options={[
                            ProjectPeriodicalTimingPeriod.Weekly,
                            ProjectPeriodicalTimingPeriod.Monthly,
                            ProjectPeriodicalTimingPeriod.Quarterly,
                        ].map(value => ({
                            value: value,
                            label: translateType('projectPeriodicalTimingPeriod', value),
                        }))}
                    />
                </Field>
                <Field title={'Facturabele presenties'} errors={errors}>
                    <TagPicker<{}, ProjectChargeForLessonAttendanceType>
                        name={`projectInvoicingSetting.periodicalTimingSettings.chargeForLessonAttendanceType`}
                        placeholder={`Selecteer presentiesoort`}
                        multi={false}
                        defaultValue={periodicalTimingLessonAttendanceType}
                        onChange={this.setInvoicedLessonsAttendanceType}
                        options={[
                            ProjectChargeForLessonAttendanceType.Any,
                            ProjectChargeForLessonAttendanceType.Present,
                            ProjectChargeForLessonAttendanceType.PresentAndAbsent,
                        ].map(value => ({
                            value,
                            label: translateType('projectInvoicingSettingsLessonAttendanceType', value),
                        }))}
                    />
                </Field>
                {periodicalTimingLessonAttendanceType &&
                    periodicalTimingLessonAttendanceType !== ProjectChargeForLessonAttendanceType.Present && (
                        <Field title={'Facturabele redenen'} errors={errors}>
                            <MultiInput type={`checkbox`} isVertical>
                                {[
                                    ProjectChargeForLessonReasonOfAbsence.Other_Integrtion_Activity,
                                    ProjectChargeForLessonReasonOfAbsence.Sick,
                                    ProjectChargeForLessonReasonOfAbsence.Work_Study,
                                    ProjectChargeForLessonReasonOfAbsence.Vacation,
                                    ProjectChargeForLessonReasonOfAbsence.Private,
                                    ProjectChargeForLessonReasonOfAbsence.Transport_Issues,
                                    ProjectChargeForLessonReasonOfAbsence.Exam,
                                    ProjectChargeForLessonReasonOfAbsence.Other,
                                    ProjectChargeForLessonReasonOfAbsence.None,
                                ].map((value, i) => (
                                    <CheckBox
                                        key={i}
                                        name={
                                            'projectInvoicingSetting.periodicalTimingSettings.chargeForLessonReasonOfAbsences'
                                        }
                                        value={value}
                                        defaultChecked={chargeForLessonReasonOfAbsences.includes(value)}
                                    >
                                        {translateType('projectChargeForLessonReasonOfAbsence', value)}
                                    </CheckBox>
                                ))}
                            </MultiInput>
                        </Field>
                    )}
            </>
        )
    }

    private renderTimingVariableFields() {
        const { errors } = this.editProjectInvoicingSettingMutator
        const { projectInvoicingSetting } = this.props

        const chargeForLessonsTypeValue =
            projectInvoicingSetting.variableTimingSettings &&
            projectInvoicingSetting.variableTimingSettings.chargeForLessonsType

        const periodsValue =
            (projectInvoicingSetting.variableTimingSettings &&
                projectInvoicingSetting.variableTimingSettings.periods) ||
            []

        return (
            <>
                <Field title="Omgaan met latere inschrijving kandidaat" errors={errors}>
                    <MultiInput type="radio" isVertical={true}>
                        {[ProjectChargeForLessonsType.AllLessons, ProjectChargeForLessonsType.OnlyEnrolledLessons].map(
                            (value, i) => (
                                <RadioButton
                                    key={i}
                                    name={'projectInvoicingSetting.variableTimingSettings.chargeForLessonsType'}
                                    value={value}
                                    defaultChecked={chargeForLessonsTypeValue === value}
                                >
                                    {translateType('projectChargeForLessonsType', value)}
                                </RadioButton>
                            )
                        )}
                    </MultiInput>
                </Field>
                <VariableMultiFieldBlock
                    isInset={true}
                    baseAmount={periodsValue.length || 1}
                    addButtonLabel={'Periode toevoegen'}
                    renderFieldGroup={(index, remove, initialIndex) => {
                        const periodValue = periodsValue[initialIndex]
                        const baseName = `projectInvoicingSetting.variableTimingSettings.periods[${index}]`

                        return (
                            <FieldBlock title={`Periode ${index + 1}`} onRemove={remove} isForm={true}>
                                <Field title={`Aantal afgeronde lessen`} errors={errors}>
                                    <UnitInput
                                        unitType={unitType.percent}
                                        alignUnitRight={true}
                                        type={'number'}
                                        defaultValue={periodValue && periodValue.percentageOfPassedLessons}
                                        name={`${baseName}.percentageOfPassedLessons`}
                                    />
                                </Field>
                                <Field title={`Percentage van bedrag`} errors={errors}>
                                    <UnitInput
                                        unitType={unitType.percent}
                                        alignUnitRight={true}
                                        type={'number'}
                                        defaultValue={periodValue && periodValue.percentageOfPrice}
                                        name={`${baseName}.percentageOfPrice`}
                                    />
                                </Field>
                                <Field title={`Condities`} errors={errors}>
                                    <MultiInput type={`checkbox`} isVertical>
                                        {[
                                            ProjectPeriodCondition.ReceivedConfirmationOfMunicipality,
                                            ProjectPeriodCondition.AttendancePercentage,
                                            ProjectPeriodCondition.ParticipationInExam,
                                            ProjectPeriodCondition.PassedExam,
                                            ProjectPeriodCondition.DateOfDisenrollment,
                                            ProjectPeriodCondition.Other,
                                        ].map((condition, i) => {
                                            const conditionSettingValue =
                                                periodValue &&
                                                periodValue.conditionSettings &&
                                                periodValue.conditionSettings.find(conditionSetting => {
                                                    return conditionSetting.condition === condition
                                                })

                                            const name = `${baseName}.conditionSettings[${i}]`

                                            return (
                                                <React.Fragment key={i}>
                                                    {this.renderPeriodConditionSettingInput(
                                                        condition,
                                                        name,
                                                        conditionSettingValue
                                                    )}
                                                </React.Fragment>
                                            )
                                        })}
                                    </MultiInput>
                                </Field>
                            </FieldBlock>
                        )
                    }}
                />
            </>
        )
    }

    private renderPeriodConditionSettingInput = (
        condition: ProjectPeriodCondition,
        name: string,
        conditionSettingValue?: ProjectPeriodConditionSetting
    ) => {
        return (
            <>
                <input type="hidden" name={`${name}.condition`} value={condition} />

                <CheckboxWithOptionalInput
                    name={`${name}.isChecked`}
                    alwaysShowInput={condition === ProjectPeriodCondition.Other}
                    defaultChecked={conditionSettingValue && conditionSettingValue.isChecked}
                    renderInput={() => (
                        <Input
                            type={`text`}
                            name={`${name}.description`}
                            defaultValue={conditionSettingValue && conditionSettingValue.description}
                            placeholder={`Toelichting${
                                condition === ProjectPeriodCondition.Other ? '' : ' (optioneel)'
                            }`}
                        />
                    )}
                >
                    {translateType('projectPeriodCondition', condition)}
                </CheckboxWithOptionalInput>
            </>
        )
    }

    private renderFooter() {
        // const {  } = this.props
        const { loading: mutationLoading } = this.editProjectInvoicingSettingMutator

        return (
            <ActionBar
                fixed={true}
                getButtons={() => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                type={'danger'}
                                linkStyle={['default', 'danger']}
                                onClick={this.onDelete}
                                confirm={{
                                    title: 'Verwijderen',
                                    message: 'Weet je het zeker? Deze actie kan niet ongedaan gemaakt worden.',
                                    execute: {
                                        buttonType: 'danger',
                                        title: 'Verwijderen',
                                    },
                                }}
                            >
                                Verwijderen
                            </Button>
                        </ListItem>
                        <ListItem right={true}>
                            <Button type="submit" isLoading={mutationLoading}>
                                Opslaan
                            </Button>
                        </ListItem>
                        <ListItem right={true}>
                            <Button onClick={this.redirectToData}>Annuleren</Button>
                        </ListItem>
                    </List>
                )}
            />
        )
    }

    private getDefaultVariablePriceAmountRangesValue() {
        const { projectInvoicingSetting } = this.props

        return (
            (projectInvoicingSetting.variablePriceSettings &&
                projectInvoicingSetting.variablePriceSettings.amountRanges) || [{ to: 2, amount: null }]
        )
    }

    private setUntillValue(event: any, index: number) {
        const {
            currentTarget: { value },
        } = event
        const parsedValue = parseInt(value as string, 10)

        if (isNaN(parsedValue)) {
            return
        }

        this.setState(prevState => {
            const prevRange = prevState.variablePriceAmountRanges[index - 1]
            const nextRange = prevState.variablePriceAmountRanges[index + 1]

            const minValue = prevRange ? prevRange.to + 2 : 2
            const maxValue = nextRange ? nextRange.to - 2 : Infinity

            const limitedValue = Math.max(minValue, Math.min(maxValue, parsedValue))

            return {
                variablePriceAmountRanges: prevState.variablePriceAmountRanges.map((range, rangeIndex) => {
                    if (rangeIndex === index) {
                        return {
                            ...range,
                            to: limitedValue,
                        }
                    }

                    return range
                }),
            }
        })
    }

    private setInvoicedLessonsAttendanceType = (
        option: TagPickerOption<{}, ProjectChargeForLessonAttendanceType> | null
    ) => {
        this.setState({
            periodicalTimingLessonAttendanceType: option ? option.value : undefined,
        })
    }

    private setPriceValue(event: any, index: number) {
        const {
            currentTarget: { value },
        } = event

        this.setState(prevState => ({
            variablePriceAmountRanges: prevState.variablePriceAmountRanges.map((range, rangeIndex) => {
                if (rangeIndex === index) {
                    range.amount = parseInt(value as string, 10)
                }

                return range
            }),
        }))
    }

    private removeVariableGroupInput(indexToRemove: number) {
        this.setState(prevState => {
            return {
                variablePriceAmountRanges: prevState.variablePriceAmountRanges.filter(
                    (input, index) => index !== indexToRemove
                ),
            }
        })
    }

    private setNewVariableGroupInput() {
        this.setState(prevState => {
            const prevRange = prevState.variablePriceAmountRanges[prevState.variablePriceAmountRanges.length - 1]

            return {
                variablePriceAmountRanges: [
                    ...prevState.variablePriceAmountRanges,
                    {
                        to: prevRange.to + 2,
                        amount: undefined,
                    },
                ],
            }
        })
    }

    private getVariableByCopy(projectVariablePriceVariableBy: ProjectVariablePriceVariableBy): {
        singular: string
        plural: string
    } {
        switch (projectVariablePriceVariableBy) {
            case ProjectVariablePriceVariableBy.Hour:
                return {
                    singular: 'lesuur',
                    plural: 'lesuren',
                }
            case ProjectVariablePriceVariableBy.Learner:
                return {
                    singular: 'kandidaat',
                    plural: 'kandidaten',
                }
            default:
                throw new Error('Could not determine variable by copy')
        }
    }

    private getProgramOptions(): TagPickerOption[] {
        const { data } = this.programsFetcher
        const project = data && data.projects && data.projects[0]
        const programs: Program[] = (project && project.programs) || []

        return programs.map(program => ({
            value: program._id,
            label: program.name,
        }))
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { projectInvoicingSetting, refetchProject } = this.props

        const result = await this.editProjectInvoicingSettingMutator.mutate({
            projectInvoicingSetting: {
                _id: projectInvoicingSetting._id,
                ...transformFormFields(fields.projectInvoicingSetting, {
                    programIds: transformLib.split(),
                    fixedPriceSettings: (v: any) => transformFormFields(v),
                    variablePriceSettings: (v: any) => transformFormFields(v),
                    periodicalTimingSettings: (v: any) => transformFormFields(v),
                    variableTimingSettings: (v: any) => transformFormFields(v),
                }),
            },
        })

        if (result && result.projectInvoicingSettings_edit) {
            refetchProject()
            this.redirectToData()
        }
    }

    private onDelete = async () => {
        const { params, refetchProject } = this.props

        const result = await this.deleteProjectInvoicingSettingMutator.mutate({
            projectInvoicingSettingId: params.projectInvoicingSettingId,
        })

        if (result && result.projectInvoicingSettings_delete) {
            toast.success('Facturatie-instelling verwijderd')
            refetchProject()
            this.redirectToOverview()
        }
    }

    private redirectToOverview = () => {
        const { project } = this.props

        browserHistory.push(`/properties/projects/${project._id}/invoicing-settings`)
    }

    private redirectToData = () => {
        const { projectInvoicingSetting, project } = this.props

        browserHistory.push(`/properties/projects/${project._id}/invoicing-settings/${projectInvoicingSetting._id}`)
    }
}

const GET_PROGRAMS_QUERY = gql`
    query projects($projectId: MongoID) {
        projects(byId: $projectId) {
            _id
            programs {
                _id
                name
            }
        }
    }
`

const EDIT_PROJECT_INVOICING_SETTING_MUTATION = gql`
    mutation _($projectInvoicingSetting: ProjectInvoicingSettingInputType!) {
        projectInvoicingSettings_edit(projectInvoicingSetting: $projectInvoicingSetting) {
            _id
            name
        }
    }
`

const DELETE_PROJECT_INVOICING_SETTING_MUTATION = gql`
    mutation _($projectInvoicingSettingId: MongoID!) {
        projectInvoicingSettings_delete(projectInvoicingSettingId: $projectInvoicingSettingId)
    }
`
