import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher } from '~/utils'
import { GraphQLErrors } from '~/utils/GraphQLErrors'

export default class RoomsForm extends Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        isEdit: PropTypes.bool,
        closeModal: PropTypes.func,
        onDelete: PropTypes.func,
        errors: PropTypes.instanceOf(GraphQLErrors),
        room: PropTypes.object,
        loading: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.formFetcher = new Fetcher({
            query: GET_FORM_QUERY,

            onChange: () => this.forceUpdate(),
        })
    }

    render() {
        const { onSubmit, isEdit, closeModal, onDelete, errors, room, loading: mutateLoading } = this.props
        const { loading, data } = this.formFetcher
        const { locationProperties } = data

        if (loading) return <Form></Form>

        const locationPropertiesOptions = (locationProperties || []).map(locationProperty => ({
            value: locationProperty._id,
            label: locationProperty.name,
        }))

        return (
            <Form onSubmit={onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input
                                name={`room.name`}
                                type={`text`}
                                placeholder={`Locatie naam`}
                                autoFocus={true}
                                defaultValue={isEdit ? room.name : undefined}
                            />
                        </Field>
                        <Field isLabel title={`Capaciteit`} errors={errors}>
                            <Input
                                name={`room.capacity`}
                                type={'number'}
                                defaultValue={isEdit ? room.capacity : undefined}
                            />
                        </Field>
                        <Field isLabel title={`Kenmerken`}>
                            {locationProperties && (
                                <TagPicker
                                    name={`room.locationPropertyIds`}
                                    placeholder={`Selecteer kenmerken`}
                                    options={locationPropertiesOptions}
                                    defaultValue={
                                        isEdit
                                            ? room.locationProperties.map(locationProperty => locationProperty._id)
                                            : undefined
                                    }
                                />
                            )}
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={closeModal}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={mutateLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                            {isEdit && (
                                <ListItem>
                                    <Button type={`button`} linkStyle={['default', 'danger']} onClick={onDelete}>
                                        Verwijderen
                                    </Button>
                                </ListItem>
                            )}
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const GET_FORM_QUERY = gql`
    query _ {
        locationProperties {
            _id
            name
        }
    }
`
