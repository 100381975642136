import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { View } from '~/components/View'
import { Fetcher } from '~/utils'

export default class DetailView extends Component {
    static propTypes = {
        params: PropTypes.object,
        children: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.learnerFetcher = new Fetcher({
            query: GET_LEARNER_QUERY,
            variables: {
                filters: {
                    byId: props.params.id,
                },
            },

            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.learnerFetcher.refetch({
                filters: {
                    byId: params.id,
                },
            })
        }
    }

    render() {
        const { children, ...routeProps } = this.props
        const { id: userId } = routeProps.params
        const { data, refetch, loading } = this.learnerFetcher
        const user = get(data, 'users[0]')

        return (
            <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    {user && (
                        <PageTabs>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`/learners/${userId}/timeline`}>
                                    Correspondentie
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`/learners/${userId}/process`}>
                                    Traject
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`/learners/${userId}/documents`}>
                                    Documenten
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`/learners/${userId}/progress`}>
                                    Resultaten
                                </Link>
                            </PageTab>
                            <PageTab>
                                <Link onlyActiveOnIndex route={`/learners/${userId}`}>
                                    Gegevens
                                </Link>
                            </PageTab>
                        </PageTabs>
                    )}
                </Header>
                <View>{user && cloneElement(children, { userId, refetch })}</View>
            </RouteView>
        )
    }
}

const GET_LEARNER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            profile {
                name
            }
        }
    }
`
