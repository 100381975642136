import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CheckBox,
    Field,
    FilterFieldCollection,
    Header,
    Icon,
    Link,
    MultiInput,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    DrawerModal,
} from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { getCurrentUser } from '~/services/session'
import { translateType } from '~/shared/utils'
import { reactJoin } from '~/shared/utils'
import { Fetcher, Filter, removeDuplicateDocuments, Sorter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import { ModalManager } from '~/components/ModalManager'
import { NewLearnerForm } from '~/components/users/Learner/NewLearnerForm'
import { User } from '~/types/User'
import { RouteComponentProps } from 'react-router'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'profile.surName'
const DEFAULT_SORT_DIR = 'ASC'

const STATIC_LEARNERS_FILTERS = {
    roles: ['LEARNER'],
}
interface State {
    currentUser: User
}

interface Props extends RouteComponentProps<{}, {}> {
    user?: User
}

export default class MasterView extends Component {
    public state: State
    private sorter: Sorter
    private filter: Filter
    private usersFetcher: Fetcher
    private paginator: Paginator
    private filterSuggestionsFetcher: Fetcher
    private organizationFetcher: Fetcher

    private async getLocalCurrentUser() {
        const myCurrentUser = await getCurrentUser()
        this.state = {
            currentUser: myCurrentUser,
        }
    }

    constructor(props: Props) {
        super(props)
        bindAll(this, ['onFilterTagPicker', 'onFilter'])

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'searchText',
                'filterByOrganizationContactUserIds',
                'filterLearnerActive',
                'filterLearnerStatuses',
                'filterByCityNames',
                'filterByGroupIds',
            ],
            onChange: (filters: any[]) => {
                this.usersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_LEARNERS_FILTERS,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsActive', 'filterIsInactive'],
                    transform: (filters: any) => {
                        const activeStates = []

                        if (filters.filterIsActive) {
                            activeStates.push(true)
                        }

                        if (filters.filterIsInactive) {
                            activeStates.push(false)
                        }

                        return {
                            filterLearnerActive: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterLearnerActive'],
                    parseFromHistory: (historyFilters: any): any => {
                        if ('filterLearnerActive' in historyFilters) {
                            if (historyFilters.filterLearnerActive) {
                                return { filterIsActive: true }
                            } else {
                                return { filterIsInactive: true }
                            }
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterIsRegistered', 'filterIsUnassigned', 'filterIsAssigned'],
                    transform: (filters: any) => {
                        const statuses = []

                        if (filters.filterIsRegistered) {
                            statuses.push('REGISTERED')
                        }

                        if (filters.filterIsUnassigned) {
                            statuses.push('UNASSIGNED')
                        }

                        if (filters.filterIsAssigned) {
                            statuses.push('ASSIGNED')
                        }

                        return {
                            filterLearnerStatuses: statuses.length > 0 && statuses.length < 3 ? statuses : null,
                        }
                    },
                    historyTriggerKeys: ['filterLearnerStatuses'],
                    parseFromHistory: (historyFilters: any) => {
                        const statuses = historyFilters.filterLearnerStatuses || []

                        return {
                            filterIsRegistered: statuses.indexOf('REGISTERED'),
                            filterIsUnassigned: statuses.indexOf('UNASSIGNED'),
                            filterIsAssigned: statuses.indexOf('ASSIGNED'),
                        }
                    },
                },
            ],
            defaultFilterState: { filterIsActive: true },
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.onSort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.onLoadMore,
        })

        this.usersFetcher = new Fetcher({
            query: GET_LEARNERS_QUERY,
            variables: {
                limit: START,
                skip: 0,
                sortDir: DEFAULT_SORT_DIR,
                sortBy: DEFAULT_SORT_BY,
                filters: {
                    ...STATIC_LEARNERS_FILTERS,
                    ...this.filter.getFilters(),
                },
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
        let organizationId = null
        const myCurrentUser = getCurrentUser()
        this.state = {
            currentUser: myCurrentUser,
        }
        if (
            this.state &&
            this.state.currentUser &&
            this.state.currentUser.organizationContact &&
            this.state.currentUser.organizationContact.organization
        ) {
            organizationId = this.state.currentUser.organizationContact.organization
        }
        this.organizationFetcher = new Fetcher({
            query: ORGANIZATION_QUERY,
            variables: {
                filters: {
                    byId: organizationId,
                },
            },
            transformData: data => {
                return {
                    organization: get(data, 'organizations[0]'),
                }
            },
        })
    }

    onLoadMore = (skip: number, limit: number, callback: any) => {
        this.usersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    onSort = ({ sortBy, sortDir }: any) => {
        this.usersFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)
    }

    onFilterTagPicker(values: any, tagPicker: any) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    onSearch = ({ searchText }: any) => {
        this.filter.apply('searchText', searchText)
    }

    onFilter(event: any) {
        this.filter.applyFromInputEvent(event)
    }
    componentDidMount() {
        this.getLocalCurrentUser()
    }
    render() {
        const { loading, data } = this.usersFetcher
        const { users = [] } = data
        const hasFilters = this.filter.hasFilters({
            excludeKeys: ['searchText'],
            excludeKeyValuePairs: [{ key: 'filterLearnerActive', value: true }],
        })

        return (
            <View>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <InfiniteScroll paginator={this.paginator} component={View}>
                        <Wrap full>
                            <ActionBar
                                isActiveByDefault={hasFilters}
                                getButtons={this.renderActionBarButtons.bind(this)}
                                getDrawer={this.renderActionBarDrawer.bind(this)}
                            />
                        </Wrap>
                        <TableWrap>
                            <TableView>
                                <Table>
                                    <TableHeader>
                                        <TableHeaderItem sorter={this.sorter} sortBy={`profile.firstName`}>
                                            Voornaam
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={this.sorter} sortBy={`profile.surName`}>
                                            Achternaam
                                        </TableHeaderItem>
                                        <TableHeaderItem>Status</TableHeaderItem>
                                        <TableHeaderItem>Actieve groep(en)</TableHeaderItem>
                                        <TableHeaderItem>Contactpersoon</TableHeaderItem>
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={3} isLoading />
                                        </TableRow>
                                    ) : users.length > 0 ? (
                                        this.renderContactRows(users)
                                    ) : (
                                        <TableRow key={`emptyresult`}>
                                            <TableCell colSpan={3}>
                                                <Subtle>Er zijn geen kandidaten gevonden.</Subtle>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
            </View>
        )
    }

    renderActionBarButtons(toggleDrawer: any, isDrawerActive: any) {
        const { loading } = this.usersFetcher
        const filters = this.filter.getFilters()
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['searchText'] })

        return (
            <List horizontal>
                <ListItem>
                    <Button
                        hasNotification={hasFilters}
                        leftIcon={<Icon name={`filter`} />}
                        onClick={toggleDrawer}
                        isActive={isDrawerActive}
                    >
                        Filteropties
                    </Button>
                </ListItem>
                <ListItem>
                    <Search
                        placeholder={'Zoeken'}
                        isLoading={loading}
                        onSearch={this.onSearch}
                        defaultValue={filters.searchText}
                    />
                </ListItem>
                <ListItem right={true}>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type={'edit'}>
                                Kandidaat aanmaken
                            </Button>
                        )}
                        getModal={closeModal => (
                            <DrawerModal onClose={closeModal}>
                                <NewLearnerForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                            </DrawerModal>
                        )}
                    />
                </ListItem>
            </List>
        )
    }

    renderActionBarDrawer() {
        const filters = this.filter.getFilters()
        const customFilters = this.filter.getCustomFilters()
        const { data: { organization = null } = {} } = this.organizationFetcher || {}
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { addresses_usedCities } = filterParams

        const organizationContactUsers = organization && organization.contactUsers
        const organizationGroups = organization && organization.groups
        const organizationContactUsersOptions =
            organizationContactUsers &&
            organizationContactUsers.map((user: { _id: any; profile: { name: any } }) => ({
                value: user._id,
                label: user.profile.name,
            }))
        const addressOptions =
            addresses_usedCities && addresses_usedCities.map((city: any) => ({ value: city, label: city }))
        const groupsOptions =
            organizationGroups &&
            organizationGroups.map((group: { _id: any; name: any }) => ({ value: group._id, label: group.name }))

        return (
            <FilterFieldCollection>
                <Field isLabel title={`Contactpersoon`} style={`compact`}>
                    {organizationContactUsersOptions && (
                        <TagPicker
                            name={`filterByOrganizationContactUserIds`}
                            placeholder={`Selecteer contactpersoon`}
                            defaultValue={filters.filterByOrganizationContactUserIds}
                            options={organizationContactUsersOptions}
                            onChange={this.onFilterTagPicker}
                        />
                    )}
                </Field>
                <Field isLabel title={`Plaats`} style={`compact`}>
                    {addressOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByCityNames"
                            defaultValue={filters.filterByCityNames}
                            options={addressOptions}
                            placeholder="Selecteer plaats"
                        />
                    )}
                </Field>
                <Field isLabel title={`Groepen`} style={`compact`}>
                    {groupsOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name="filterByGroupIds"
                            defaultValue={filters.filterByGroupIds}
                            options={groupsOptions}
                            placeholder="Selecteer groep"
                        />
                    )}
                </Field>
                <Field title={`Status`} style={`compact`}>
                    <MultiInput type={`checkbox`}>
                        <CheckBox
                            name="filterIsRegistered"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsRegistered}
                        >
                            Aangemeld
                        </CheckBox>
                        <CheckBox
                            name="filterIsUnassigned"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsUnassigned}
                        >
                            Niet ingedeeld
                        </CheckBox>
                        <CheckBox
                            name="filterIsAssigned"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsAssigned}
                        >
                            Ingedeeld
                        </CheckBox>
                        <CheckBox
                            name="filterIsActive"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsActive}
                        >
                            Actief
                        </CheckBox>
                        <CheckBox
                            name="filterIsInactive"
                            onChange={this.onFilter}
                            defaultChecked={customFilters.filterIsInactive}
                        >
                            Non-actief
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    renderContactRows(users: any) {
        return users.map((user: { learner: { organizations: any }; _id: React.Key | null | undefined }) => {
            const learnerStatus = get(user, 'learner.status')
            const activeGroups = get(user, 'learner.activeGroups') || []
            const organizations = user && user.learner && user.learner.organizations
            let organizationId = ''
            if (
                this.state &&
                this.state.currentUser &&
                this.state.currentUser.organizationContact &&
                this.state.currentUser.organizationContact.organization &&
                this.state.currentUser.organizationContact.organization._id
            ) {
                organizationId = this.state.currentUser.organizationContact.organization._id
            }
            if (organizationId !== '') {
                const currentOrganization =
                    organizations &&
                    organizations.find((o: { organization: { _id: string } }) => o.organization._id === organizationId)

                const organizationContact =
                    currentOrganization &&
                    currentOrganization.organizationContactUser &&
                    currentOrganization.organizationContactUser.profile.name
                return (
                    <TableRow key={user._id}>
                        <TableCell>
                            <Link route={`/learners/${user._id}`}>{get(user, 'profile.firstName')}</Link>
                        </TableCell>
                        <TableCell>
                            <Link route={`/learners/${user._id}`}>{get(user, 'profile.fullSurName')}</Link>
                        </TableCell>
                        <TableCell>{learnerStatus && translateType('learnerStatus', learnerStatus)}</TableCell>
                        <TableCell>{reactJoin(activeGroups.map((group: { name: string }) => group.name))}</TableCell>
                        <TableCell>{organizationContact}</TableCell>
                    </TableRow>
                )
            }
            return ''
        })
    }
}

const GET_LEARNERS_QUERY = gql`
    query _($filters: UsersFilterInputType, $limit: Int, $skip: Int, $sortBy: String, $sortDir: String) {
        users(filters: $filters, limit: $limit, skip: $skip, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            profile {
                firstName
                fullSurName
            }
            learner {
                status
                organizations {
                    organization {
                        _id
                    }
                    organizationContactUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
                activeGroups: groups(filterByActive: true) {
                    _id
                    name
                }
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
    }
`

const ORGANIZATION_QUERY = gql`
    query _($byId: MongoID) {
        organizations(byId: $byId) {
            _id
            contactUsers {
                _id
                profile {
                    name
                }
            }
            groups {
                _id
                name
            }
        }
    }
`
