import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { Header, Link, PageTab, PageTabs } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Fetcher } from '~/utils'
import { AddressFieldsFragment } from '~/types/Address'

export default class OrganizationDetailView extends Component {
    static propTypes = {
        children: PropTypes.any,
        params: PropTypes.object,
    }

    constructor(props) {
        super(props)

        this.organizationFetcher = new Fetcher({
            query: GET_ORGANIZATION_QUERY,
            variables: { _id: props.params.id },

            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.id !== params.id) {
            this.organizationFetcher.refetch({
                _id: params.id,
            })
        }
    }

    render() {
        const { children, ...routeProps } = this.props
        const { data, loading, refetch } = this.organizationFetcher
        const organization = get(data, 'organizations[0]')
        const baseUrl = `/properties/organizations/${routeProps.params.id}`

        return (
            <RouteView crumbLabel={get(organization, 'name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                    <PageTabs>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`${baseUrl}/timeline`}>
                                Correspondentie
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`${baseUrl}/documents`}>
                                Documenten
                            </Link>
                        </PageTab>
                        <PageTab>
                            <Link onlyActiveOnIndex route={`${baseUrl}`}>
                                Gegevens
                            </Link>
                        </PageTab>
                    </PageTabs>
                </Header>
                {organization && cloneElement(children, { organization, refetch })}
            </RouteView>
        )
    }
}

const GET_ORGANIZATION_QUERY = gql`
    query _($_id: MongoID) {
        organizations(byId: $_id) {
            _id
            name
            twinfieldCustomer {
                name
                code
            }
            canTwinfieldCustomerCodeChange
            vatExempt
            invoiceEmailRecipients {
                name
                email
            }
            address {
                ...AddressFieldsFragment
            }
            billingAddress {
                ...AddressFieldsFragment
            }
            phoneNumber {
                _id
                country
                phoneNumber
                internationalPhoneNumber
                info
            }
            contactName
            organizationCode
            project {
                _id
                name
            }
            contactUsers {
                _id
                profile {
                    name
                }
            }
            notes
            invoiceTexts
        }
    }
    ${AddressFieldsFragment}
`
