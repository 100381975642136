import gql from 'graphql-tag'
import { bindAll, compact, flatMapDeep, get, uniqBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Field } from '~/components'
import { ValidationError } from '~/components/ValidationError'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher } from '~/utils'

export default class SelectModuleFormField extends Component {
    static propTypes = {
        title: PropTypes.string.isRequired,
        errors: PropTypes.object.isRequired,
        defaultModule: PropTypes.object,
        defaultFilterByProjectId: PropTypes.string,
        defaultFilterByProgramId: PropTypes.string,
        moduleIdName: PropTypes.string.isRequired,
        projectIdName: PropTypes.string,
        programIdName: PropTypes.string,
        isDisabled: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['onSelectedProjectChange', 'onSelectedProgramChange', 'onSelectedModuleChange'])

        this.state = {
            selectedProjectId: props.defaultFilterByProjectId,
            selectedProgramId: props.defaultFilterByProgramId,
            selectedModuleId: props.defaultModule && props.defaultModule._id,
        }

        this.projectsFetcher = new Fetcher({
            query: GET_PROJECTS_PROGRAMS_MODULES_QUERY,

            onChange: () => this.forceUpdate(),
        })
    }

    onSelectedProjectChange(selectedOption) {
        const id = get(selectedOption, 'value') || null

        this.setState({
            selectedProjectId: id,
        })
    }

    onSelectedProgramChange(selectedOption) {
        const id = get(selectedOption, 'value') || null

        this.setState({
            selectedProgramId: id,
        })
    }

    onSelectedModuleChange(selectedOption) {
        const id = get(selectedOption, 'value') || null

        this.setState({
            selectedModuleId: id,
        })
    }

    render() {
        const { selectedProjectId, selectedProgramId, selectedModuleId } = this.state

        const { title, errors, moduleIdName, projectIdName, programIdName, defaultModule, isDisabled } = this.props

        const { data, loading } = this.projectsFetcher

        const projects = get(data, 'projects') || []
        const selectedProject = projects.find(({ _id }) => _id === selectedProjectId)
        const programs = get(selectedProject, 'programs') || []
        const selectedProgram = selectedProject && selectedProject.programs.find(({ _id }) => _id === selectedProgramId)

        const modules = compact(
            uniqBy(
                [
                    defaultModule,

                    // If a program is selected get those modules
                    // Else: If there are programs get all modules from all programs
                    // Else: Get all modules from all projects.
                    ...(selectedProgram
                        ? selectedProgram.modules
                        : programs.length > 0
                        ? uniqBy(
                              flatMapDeep(programs, programs => programs.modules),
                              '_id'
                          )
                        : uniqBy(
                              flatMapDeep(projects, project => project.programs.map(({ modules }) => modules)),
                              '_id'
                          )),
                ],
                '_id'
            )
        )

        const selectedModule = modules.find(({ _id }) => _id === selectedModuleId)

        if (loading) {
            return <Field title={title} errors={errors} isLoading />
        }

        return (
            <Field title={title} errors={errors}>
                <TagPicker
                    name={projectIdName}
                    multi={false}
                    isReadonly={projects.length === 0}
                    options={projects.map(v => ({ label: v.name, value: v._id }))}
                    defaultValue={selectedProject && selectedProject._id}
                    placeholder={`Filter op project`}
                    isDisabled={isDisabled}
                    onChange={this.onSelectedProjectChange}
                />
                <TagPicker
                    name={programIdName}
                    multi={false}
                    isReadonly={programs.length === 0}
                    options={programs.map(v => ({ label: v.name, value: v._id }))}
                    defaultValue={selectedProgram && selectedProgram._id}
                    placeholder={`Filter op opleiding`}
                    isDisabled={isDisabled}
                    onChange={this.onSelectedProgramChange}
                />
                <TagPicker
                    name={moduleIdName}
                    multi={false}
                    isReadonly={modules.length === 0}
                    options={modules.map(v => ({ label: v.name, value: v._id }))}
                    defaultValue={selectedModule && selectedModule._id}
                    placeholder={`Selecteer advies-lesmodule`}
                    isDisabled={isDisabled}
                    onChange={this.onSelectedModuleChange}
                />
                {selectedModule && !selectedModule.isActive && (
                    <ValidationError isWarning text={`Let op! Deze lesmodule is non-actief.`} />
                )}
            </Field>
        )
    }
}

const GET_PROJECTS_PROGRAMS_MODULES_QUERY = gql`
    query _ {
        projects(sortBy: "name") {
            _id
            name
            programs {
                _id
                name
                modules {
                    _id
                    name
                    isActive
                }
            }
        }
    }
`
