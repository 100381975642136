import React from 'react'
import gql from 'graphql-tag'

import DrawerHeader from '~/components/DrawerHeader'
import { List } from '~/components/List'
import { ContentView } from '~/components/ContentView'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import TableWrap from '~/components/TableWrap'
import { TableView } from '~/components/TableView'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import { TableCell } from '~/components/TableCell'
import Icon from '~/components/Icon'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import Search from '~/components/Search'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Paginator } from '~/utils/Paginator'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import Fetcher from '~/utils/Fetcher'
import Filter from '~/utils/Filter'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import Spinner from '~/components/Spinner'
import Subtle from '~/components/Subtle'
import { User } from '~/types/User'
import Link from '~/components/Link'
import TableRow from '~/components/TableRow'
import { Table } from '~/components/Table'
import translateType from '~/shared/utils/translateType'
import Mutator from '~/utils/Mutator'
import { toast } from '~/utils'
import DrawerFooter from '~/components/DrawerFooter'

interface Props {
    groupId: string
    onCancel: () => void
    onSuccess: () => void
}

interface State {
    limit: number
    skip: number
}

const START = 40
const INCREASE = 40
const STATIC_EMPLOYEE_USERS_FILTERS = {
    roles: ['EMPLOYEE'],
}

export class AddEmployeeGroupUserDrawerForm extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
    }

    private employeeUsersPaginator: Paginator
    private employeeUsersFetcher: Fetcher
    private employeeUsersFilter: Filter

    private addEmployeeToGroupMutator = new Mutator({
        mutation: ADD_EMPLOYEE_TO_GROUP,
        reactComponentToUpdate: this,
    })

    public constructor(props: Props) {
        super(props)

        const { limit, skip } = this.state

        this.employeeUsersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.employeeUsersFilter = new Filter({
            useHistory: false,
            allowedKeys: ['searchText'],
            onChange: (filters: any) => {
                this.employeeUsersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_EMPLOYEE_USERS_FILTERS,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [],
        })

        this.employeeUsersFetcher = new Fetcher({
            query: GET_EMPLOYEE_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_EMPLOYEE_USERS_FILTERS,
                    ...this.employeeUsersFilter.getFilters(),
                },
                limit,
                skip,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.employeeUsersPaginator.reset()
            },
        })
    }

    public render() {
        const { onCancel } = this.props
        const { loading, data } = this.employeeUsersFetcher
        const users = data.users || []

        return (
            <React.Fragment>
                <DrawerHeader title={'Medewerker toevoegen'} close={onCancel} />
                <ContentView>
                    <InfiniteScroll paginator={this.employeeUsersPaginator} preventLoad={loading}>
                        <Wrap full>
                            <ActionBar
                                getButtons={() => {
                                    return (
                                        <List horizontal>
                                            <ListItem>
                                                <Search isLoading={loading} onSearch={this.onSearch} />
                                            </ListItem>
                                        </List>
                                    )
                                }}
                            />
                        </Wrap>
                        <TableWrap>
                            <TableView>
                                <Table>
                                    <TableHeader>
                                        <TableHeaderItem forIconButton={true} />
                                        <TableHeaderItem width="35%">Naam</TableHeaderItem>
                                        <TableHeaderItem>Functie</TableHeaderItem>
                                        <TableHeaderItem />
                                    </TableHeader>
                                    {loading && (
                                        <TableRow>
                                            <TableCell colSpan={4}>
                                                <Spinner instant />
                                            </TableCell>
                                        </TableRow>
                                    )}
                                    {!loading &&
                                        (users.length > 0 ? (
                                            users.map((user: any) => (
                                                <React.Fragment key={user._id}>
                                                    {this.renderEmployeeRow(user)}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <TableRow>
                                                <TableCell colSpan={4}>
                                                    <Subtle>Er zijn geen medewerkers gevonden.</Subtle>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter hasCancelSaveBtns={false}>
                    <List horizontal>
                        <ListItem right>
                            <Button onClick={onCancel}>Annuleer</Button>
                        </ListItem>
                    </List>
                </DrawerFooter>
            </React.Fragment>
        )
    }

    private renderEmployeeRow = (user: User) => {
        const canExpandRow = !!user.profile.notes

        return (
            <TableRow
                hasExpandableInfo={canExpandRow}
                getExpansion={() => (
                    <TableCell colSpan={4}>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Notities:</SimpleTableCell>
                                <SimpleTableCell>{user.profile.notes || '-'}</SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </TableCell>
                )}
            >
                <TableCell forIconButton>
                    <Button
                        type={`in-row`}
                        leftIcon={<Icon name={`plus`} />}
                        onClick={() => this.addEmployeeToGroup(user)}
                    />
                </TableCell>
                <TableCell>
                    <Link route={`/users/employees/${user._id}`} target="_blank" type="emphasized">
                        {user.profile.name}
                    </Link>
                </TableCell>
                <TableCell>{(user.roles || []).map(role => translateType('mutableRole', role)).join(', ')}</TableCell>
            </TableRow>
        )
    }

    private loadMore = (skip: number, limit: number, callback: (options?: { finished: boolean }) => void) => {
        this.employeeUsersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSearch = ({ searchText }: any) => {
        this.employeeUsersFilter.apply('searchText', searchText)
    }

    private addEmployeeToGroup = async (user: User) => {
        const { groupId, onSuccess } = this.props

        const res = await this.addEmployeeToGroupMutator.mutate({
            groupId,
            userId: user._id,
            role: 'EMPLOYEE',
            date: new Date(),
        })

        if (res && res.groups_enrollUser) {
            onSuccess()
            toast.success(`Medewerker "${user.profile.name}" succesvol toegevoegd aan groep`)
        }
    }
}

const GET_EMPLOYEE_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $filters: UsersFilterInputType) {
        users(limit: $limit, skip: $skip, sortBy: "profile.firstName", filters: $filters) {
            _id
            roles(filterMutable: true)
            profile {
                name
                notes
            }
        }
    }
`

const ADD_EMPLOYEE_TO_GROUP = gql`
    mutation _($groupId: MongoID!, $userId: MongoID!, $role: GroupUserRoleEnum!, $date: Date!) {
        groups_enrollUser(groupId: $groupId, userId: $userId, role: $role, date: $date) {
            _id
        }
    }
`
