import c from 'classnames'
import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import { Button, CenterModal, Currency, Icon, Link, PdfModal, Spinner } from '~/components'
import { ModalManager } from '~/components/ModalManager'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { CreateFinalExamCreditInvoiceForm } from '~/forms/CreateFinalExamCreditInvoiceForm'
import { CreateLessonMaterialsCreditInvoiceForm } from '~/forms/CreateLessonMaterialsCreditInvoiceForm'
import { CreateGroupParticipationCreditInvoiceForm } from '~/forms/CreateGroupParticipationCreditInvoiceForm'
import { InvoiceDescriptionText } from '~/implementations/InvoiceDescriptionText'
import { InvoiceLogsList } from '~/implementations/InvoiceLogsList'
import { SendInvoiceProvider } from '~/implementations/SendInvoiceProvider'
import { getCurrentUser } from '~/services/session'
import { Fetcher, Mutator, toast, downloadFile } from '~/utils'
import { SignatureIcon } from '~/components/InvoiceStatusIndicator/icons'
import { UploadInvoiceSignatureProvider } from '~/implementations/UploadInvoiceSignatureProvider'
import { ButtonGroup } from '~/components/ButtonGroup'
import { InvoiceStatus } from '~/types/Invoice'
import { InvoiceDescription } from '~/types/Invoice'

export class InvoiceRowExpansionContent extends Component {
    static propTypes = {
        className: PropTypes.string,
        invoiceId: PropTypes.string.isRequired,
        userId: PropTypes.string.isRequired,
        userName: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        showInvoiceNumber: PropTypes.bool,
        showGroup: PropTypes.bool,
        showCreditApprovalButtons: PropTypes.bool,
        hideActions: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.invoiceFetcher = new Fetcher({
            query: INVOICE_QUERY,
            variables: {
                filters: {
                    byIds: [props.invoiceId],
                },
            },

            onChange: () => this.forceUpdate(),
            transformData(data) {
                return {
                    invoice: get(data, 'invoices[0]'),
                }
            },
        })

        this.rejectCreditInvoiceMutator = new Mutator({
            mutation: REJECT_CREDIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.cancelInvoiceMutator = new Mutator({
            mutation: CANCEL_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.removeDebitInvoiceMutator = new Mutator({
            mutation: REMOVE_DEBIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })

        this.removeCreditInvoiceMutator = new Mutator({
            mutation: REMOVE_CREDIT_INVOICE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    rejectCreditInvoice = () => {
        const { onChange, invoiceId } = this.props
        const { refetch } = this.invoiceFetcher

        ;(async () => {
            const data = await this.rejectCreditInvoiceMutator.mutate({
                invoiceId: invoiceId,
            })

            if (data) {
                toast.success('De creditnota is afgewezen')
                refetch()
                onChange()
            }
        })()
    }

    cancelInvoice = () => {
        const { onChange, invoiceId } = this.props
        const { refetch } = this.invoiceFetcher

        ;(async () => {
            const data = await this.cancelInvoiceMutator.mutate({
                invoiceId: invoiceId,
            })

            if (data) {
                toast.success('De factuur is verscheurd')
                refetch()
                onChange()
            }
        })()
    }

    removeDebitInvoice = async invoiceId => {
        const { onChange } = this.props

        const data = await this.removeDebitInvoiceMutator.mutate({
            invoiceId: invoiceId,
        })

        if (data) {
            toast.success('De factuur is verwijderd')
            onChange()
        }
    }

    removeCreditInvoice = async invoiceId => {
        const { onChange } = this.props

        const data = await this.removeCreditInvoiceMutator.mutate({
            invoiceId: invoiceId,
        })

        if (data) {
            toast.success('De creditnota is verwijderd')
            onChange()
        }
    }

    activateGroupInvoice = () => {
        const { onChange, invoiceId } = this.props
        const { refetch } = this.invoiceFetcher

        ;(async () => {
            const data = await this.activateNewGroupInvoiceMutator.mutate({
                invoiceId: invoiceId,
            })

            if (data) {
                toast.success('De nieuwe factuur is aangemaakt')
                refetch()
                onChange()
            }
        })()
    }

    /**
     * For installment-invoices, the first installment is leading for editing and sending.
     * If this invoice is a non-leading installment invoice,
     * this function returns the leading installment invoice
     */
    getLeadingInvoice(invoice) {
        if (invoice.isDescendantInstallment) {
            return invoice.installmentLeadingInvoice
        }

        return invoice
    }

    render() {
        const { showInvoiceNumber, showGroup, hideActions } = this.props
        const {
            refetch: refetchInvoices,
            data: { invoice },
            loading,
        } = this.invoiceFetcher
        const currentUser = getCurrentUser()
        const currentUserIsFinancial = currentUser.isFinancial || currentUser.isAdmin

        if (loading || !invoice) {
            return (
                <div className={this.getClassName()}>
                    <Spinner />
                </div>
            )
        }

        return (
            <div className={this.getClassName()}>
                <SimpleTable>
                    {showInvoiceNumber && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Factuurnummer</SimpleTableCell>
                            <SimpleTableCell>{invoice.invoiceNumber || 'Concept'}</SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    <SimpleTableRow>
                        <SimpleTableCell isBold={true}>Statushistorie</SimpleTableCell>
                        <SimpleTableCell>
                            <InvoiceLogsList logs={invoice.logs} />
                        </SimpleTableCell>
                    </SimpleTableRow>
                    {showGroup && invoice.debitInvoice.group && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Groep</SimpleTableCell>
                            <SimpleTableCell>
                                <Link route={`/groups/${invoice.debitInvoice.group._id}/`}>
                                    {invoice.debitInvoice.group.name}
                                </Link>
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    {!invoice.isCredit && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Factuuromschrijving</SimpleTableCell>
                            <SimpleTableCell>
                                {invoice.isCredit ? (
                                    invoice.creditDescription
                                ) : (
                                    <InvoiceDescriptionText invoice={invoice} />
                                )}
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    {invoice.isCredit && (
                        <Fragment>
                            <SimpleTableRow>
                                <SimpleTableCell isBold={true}>Interne notitie</SimpleTableCell>
                                <SimpleTableCell>{invoice.notes}</SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold={true}>Debetfactuurnummer</SimpleTableCell>
                                <SimpleTableCell>{invoice.debitInvoice.invoiceNumber}</SimpleTableCell>
                            </SimpleTableRow>
                        </Fragment>
                    )}
                    {invoice.hasMultipleInstallments && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Totaalbedrag</SimpleTableCell>
                            <SimpleTableCell>
                                <Currency amount={invoice.installmentsTotal} />
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    {invoice.alternativeAmountDescription && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Toelichting alternatief bedrag</SimpleTableCell>
                            <SimpleTableCell>{invoice.alternativeAmountDescription}</SimpleTableCell>
                        </SimpleTableRow>
                    )}
                    {invoice.signatureForDuoFile && (
                        <SimpleTableRow>
                            <SimpleTableCell isBold={true}>Handtekening</SimpleTableCell>
                            <SimpleTableCell>
                                {invoice.signatureForDuoFile.isPDF ? (
                                    <ModalManager
                                        render={openModal => (
                                            <Button linkStyle={`default`} onClick={openModal}>
                                                {invoice.signatureForDuoFile.fileName}
                                            </Button>
                                        )}
                                        getModal={closeModal => (
                                            <PdfModal
                                                title={invoice.signatureForDuoFile.fileName}
                                                fileName={invoice.signatureForDuoFile.fileName}
                                                getFileId={() => invoice.signatureForDuoFile._id}
                                                onClose={closeModal}
                                            />
                                        )}
                                    />
                                ) : (
                                    <Button
                                        linkStyle={`default`}
                                        onClick={() =>
                                            downloadFile(
                                                invoice.signatureForDuoFile._id,
                                                invoice.signatureForDuoFile.fileName
                                            )
                                        }
                                    >
                                        {invoice.signatureForDuoFile.fileName}
                                    </Button>
                                )}

                                {currentUserIsFinancial && !hideActions && (
                                    <UploadInvoiceSignatureProvider
                                        noConfirm={true}
                                        render={(uploadSignatureByInvoiceId, isUploadingSignature) => (
                                            <Button
                                                shouldPreventSubmit={true}
                                                className="tt-InvoiceRowExpansionContent__reupload-button"
                                                isLoading={isUploadingSignature}
                                                onClick={async () => {
                                                    await uploadSignatureByInvoiceId(invoice._id)
                                                    refetchInvoices({ silent: true })
                                                }}
                                            >
                                                <SignatureIcon />
                                            </Button>
                                        )}
                                    />
                                )}
                            </SimpleTableCell>
                        </SimpleTableRow>
                    )}
                </SimpleTable>
                {!hideActions && this.renderPrimaryButtons(invoice)}
                {!hideActions && this.renderSecondaryButtons(invoice)}
            </div>
        )
    }

    getClassName() {
        const { className } = this.props

        return c('tt-InvoiceRowExpansionContent', className)
    }

    hasPaymentByDUO() {
        const {
            data: { invoice },
        } = this.invoiceFetcher

        return !!(
            invoice &&
            get(invoice, 'user.learner.privatePaymentIsDUO') &&
            get(invoice, 'user.learner.privatePaymentDUOHasApprovedFund')
        )
    }

    renderPrimaryButtons(invoice) {
        const leadingInvoice = this.getLeadingInvoice(invoice)

        return (
            <Fragment>
                {invoice.status === InvoiceStatus.Draft &&
                    (invoice.isCredit
                        ? this.renderCreditEditButton(invoice)
                        : this.renderDebitEditButton(leadingInvoice))}
                {leadingInvoice.mayBeCredited && this.renderCreditCreateButton(leadingInvoice)}
                {invoice.mayBeCanceled && this.renderCancelButton()}
            </Fragment>
        )
    }

    renderSecondaryButtons(invoice) {
        const { showCreditApprovalButtons, onChange } = this.props
        const { refetch } = this.invoiceFetcher

        if (showCreditApprovalButtons && invoice.isCredit && invoice.status === InvoiceStatus.Draft) {
            return (
                <ButtonGroup
                    className={`tt-InvoiceRowExpansionContent__side-button-group`}
                    smallButtons={true}
                    joined={true}
                >
                    <SendInvoiceProvider
                        render={(sendInvoiceById, isSendingInvoice) => (
                            <Button
                                small={true}
                                onClick={async () => {
                                    await sendInvoiceById(invoice._id)
                                    refetch()
                                    onChange()
                                }}
                                leftIcon={<Icon name={`status_done`} color={`#2cc472`} />}
                                isLoading={isSendingInvoice}
                            >
                                Creditnota goedkeuren
                            </Button>
                        )}
                    />
                    <Button
                        small
                        onClick={this.rejectCreditInvoice}
                        leftIcon={<Icon name={`remove`} color={`#a8aeb3`} />}
                    >
                        Creditnota afwijzen
                    </Button>
                </ButtonGroup>
            )
        }
    }

    renderCreditCreateButton(debitInvoice) {
        const { userId, onChange } = this.props
        const { refetch } = this.invoiceFetcher

        return (
            <ModalManager
                render={openModal => (
                    <Button small={true} onClick={openModal}>
                        Creditnota aanvragen
                    </Button>
                )}
                getModal={closeModal => (
                    <CenterModal onClose={closeModal} title={`Creditnota ${debitInvoice.invoiceNumber}`}>
                        {this.renderCreditInvoiceForm(userId, debitInvoice, closeModal, () => {
                            closeModal()
                            refetch()
                            onChange()
                        })}
                    </CenterModal>
                )}
            />
        )
    }

    renderFullyCreditedButton() {
        return <Button isDisabled={true}>Factuur geheel gecrediteerd</Button>
    }

    renderCancelButton() {
        const { loading: isCancelInvoiceLoading } = this.cancelInvoiceMutator

        return (
            <Button
                small={true}
                type="danger"
                styleOverride="secondary-danger"
                onClick={this.cancelInvoice}
                isLoading={isCancelInvoiceLoading}
            >
                Annuleren (geen handtekening)
            </Button>
        )
    }

    renderCreditEditButton(creditInvoice) {
        return (
            <Button
                small
                type="danger"
                styleOverride="secondary-danger"
                onClick={() => this.removeCreditInvoice(creditInvoice._id)}
                confirm={{
                    title: 'Verwijderen',
                    message: 'Weet je het zeker?',
                    execute: {
                        buttonType: 'danger',
                        title: 'Verwijderen',
                    },
                }}
            >
                Verwijderen
            </Button>
        )
    }

    renderDebitEditButton(debitInvoice) {
        return (
            <Button
                small
                type="danger"
                styleOverride="secondary-danger"
                onClick={() => this.removeDebitInvoice(debitInvoice._id)}
                confirm={{
                    title: 'Verwijderen',
                    message: 'Weet je het zeker? Hiermee verwijder je ook alle eventuele overige termijnen.',
                    execute: {
                        buttonType: 'danger',
                        title: 'Verwijderen',
                    },
                }}
            >
                Verwijderen
            </Button>
        )
    }

    renderCreditInvoiceForm(userId, debitInvoice, onCancel, onSubmitSuccess) {
        if (debitInvoice.isGroupParticipationInvoice) {
            return (
                <CreateGroupParticipationCreditInvoiceForm
                    userId={userId}
                    debitInvoice={debitInvoice}
                    onCancel={onCancel}
                    onSubmitSuccess={onSubmitSuccess}
                />
            )
        }

        if (debitInvoice.isFinalExamInvoice) {
            return (
                <CreateFinalExamCreditInvoiceForm
                    userId={userId}
                    debitInvoice={debitInvoice}
                    onCancel={onCancel}
                    onSubmitSuccess={onSubmitSuccess}
                />
            )
        }

        if (debitInvoice.description === InvoiceDescription.LessonMaterials) {
            return (
                <CreateLessonMaterialsCreditInvoiceForm
                    userId={userId}
                    debitInvoice={debitInvoice}
                    onCancel={onCancel}
                    onSubmitSuccess={onSubmitSuccess}
                />
            )
        }

        throw new Error('Could not determine invoice type')
    }
}

const INVOICE_QUERY = gql`
    query _($filters: InvoicesFilterInputType) {
        invoices(filters: $filters) {
            _id
            invoiceNumber
            invoiceDate
            expirationDate
            description
            creditDescription
            amount
            hasMultipleInstallments
            installments
            installmentsSubTotal
            installmentsTotal
            alternativeAmountDescription
            amount
            notes
            isGroupParticipationInvoice
            isFinalExamInvoice
            isCredit
            conceptCreditedAmount
            isDescendantInstallment
            mayBeCredited
            isFullyCredited
            mayBeCanceled
            creditedAmount
            remainingConceptCreditableAmount
            creditableAmountPerLesson
            invoicedLessonUsers {
                _id
                lastDebitInvoiceIdForCreditableLessonUser
                lessonId
                lessonDate
                lesson {
                    _id
                    date
                    order
                }
            }
            lessons {
                _id
                date
                order
            }
            signatureForDuoFile {
                _id
                fileName
                isPDF
            }
            installmentLeadingInvoice {
                _id
                status
                openStatus
                paymentStatus
                invoiceNumber
                isGroupParticipationInvoice
                isFinalExamInvoice
                conceptCreditedAmount
                amount
                hasMultipleInstallments
                installments
                installmentsSubTotal
                installmentsTotal
                mayBeCredited
                isFullyCredited
                isForDUO
                mayBeCanceled
                remainingConceptCreditableAmount
                invoicedLessonUsers {
                    _id
                    lastDebitInvoiceIdForCreditableLessonUser
                    lessonId
                    lessonDate
                    lesson {
                        _id
                        date
                        order
                    }
                }
                lessons {
                    _id
                    date
                    order
                }

                # For normal invoices
                description

                # For group invoices
                alternativeAmountDescription
                creditableAmountPerLesson
                group {
                    _id
                    module {
                        _id
                    }
                }
            }
            debitInvoice {
                _id
                invoiceNumber
                amount
                conceptCreditedAmount
                hasMultipleInstallments
                installmentsSubTotal
                installmentsTotal
                remainingConceptCreditableAmount
                group {
                    _id
                    name
                }
            }
            user {
                _id
                profile {
                    name
                }
                learner {
                    privatePaymentIsDUO
                    privatePaymentDUOHasApprovedFund
                }
            }
            group {
                _id
                name
            }
            finalExam {
                _id
                part
                date
            }
            status
            openStatus
            paymentStatus
            hasSignatureForDUO
            isForDUO
            isExpired
            daysExpired
            logs {
                _id
                type
                typeData {
                    newInvoiceStatus
                    newOpenInvoiceStatus
                    newOpenInvoiceStatusInfo {
                        responseByDUO
                    }
                    newInvoicePaymentStatus
                    reminderCount
                    newAmount
                    previousAmount
                }
                createdAt
                triggeredByUser {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`

const REJECT_CREDIT_INVOICE_MUTATION = gql`
    mutation _($invoiceId: MongoID!) {
        invoices_credit_reject(invoiceId: $invoiceId) {
            _id
        }
    }
`

const CANCEL_INVOICE_MUTATION = gql`
    mutation _($invoiceId: MongoID!) {
        invoices_cancel(invoiceId: $invoiceId) {
            _id
        }
    }
`

const REMOVE_DEBIT_INVOICE_MUTATION = gql`
    mutation _($invoiceId: MongoID!) {
        invoices_deleteDebit(invoiceId: $invoiceId) {
            _id
        }
    }
`

const REMOVE_CREDIT_INVOICE_MUTATION = gql`
    mutation _($invoiceId: MongoID!) {
        invoices_deleteCredit(invoiceId: $invoiceId) {
            _id
        }
    }
`
