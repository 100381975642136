import gql from 'graphql-tag'
import * as React from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, MultiInput, RadioButton } from '~/components'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { get } from 'lodash'
import { FieldGroup } from '~/components/FieldGroup'
import { ExamLevelInput } from '~/components/ExamLevelInput'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'

interface Props {
    onSubmitSuccess: any
    onSubmitFailed?: any
    onCancel?: any
}

interface State {
    isLanguageChecked: boolean
}

const CREATE_EXAM_MUTATION = gql`
    mutation _($exam: ExamInputType!) {
        exams_create(exam: $exam) {
            _id
            name
            type
            level
        }
    }
`

export class CreateExamForm extends React.Component<Props, State> {
    public state: State = {
        isLanguageChecked: true,
    }

    private examMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.examMutator = new Mutator({
            mutation: CREATE_EXAM_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.examMutator
        const { onCancel } = this.props
        const { isLanguageChecked } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input name={`exam.name`} type={`text`} placeholder={`Naam eindtoets`} autoFocus={true} />
                        </Field>
                        <Field title={'Soort'} errors={errors}>
                            <MultiInput type="radio">
                                <RadioButton
                                    name={`exam.type`}
                                    value={'language'}
                                    onClick={() => this.setState({ isLanguageChecked: true })}
                                    defaultChecked={true}
                                >
                                    Taaltoets
                                </RadioButton>
                                <RadioButton
                                    name={`exam.type`}
                                    value={'alpha'}
                                    onClick={() => this.setState({ isLanguageChecked: false })}
                                >
                                    Alfatoets
                                </RadioButton>
                            </MultiInput>
                        </Field>
                        {isLanguageChecked && (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel title={`Niveau`} errors={errors}>
                                    <ExamLevelInput name={`exam.level`} placeholder={`Welk niveau heeft de toets?`} />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.examMutator
            .mutate({
                exam: transformFormFields(fields.exam, {}),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/exams/${get(data, 'exams_create._id')}`)
                } else {
                    if (onSubmitFailed) {
                        onSubmitFailed()
                    }
                }
            })
    }
}
