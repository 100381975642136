import * as React from 'react'
import ActionBar from '~/components/ActionBar'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Table } from '~/components/Table'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import Link from '~/components/Link'
import gql from 'graphql-tag'
import { TableView } from '~/components/TableView'
import TableWrap from '~/components/TableWrap'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { Fetcher, removeDuplicateDocuments, Filter } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import Sorter from '~/utils/Sorter'
import { ConceptInflowMoment } from '~/types/InflowMoments'
import { Button, Icon, FilterFieldCollection, Field, MultiInput, DatePicker, Emphasis } from '~/components'
import { TagPicker } from '../TagPicker'
import { User } from '~/types/User'
import { Organization } from '~/types/Organization'
import { InflowModule } from '~/types/InflowModule'
import { Location } from '~/types/Location'
import { ModalManager } from '../ModalManager'
import { CreateInflowMomentModal } from './CreateInflowMomentModal'
import { capitalize } from 'lodash'
const moment = require('moment')

const START = 40
const INCREASE = 40
const DEFAULT_SORT_DIR = 'ASC'
const DEFAULT_SORT_BY = 'dateRange.from'

const STATIC_INFLOW_MODULES_FILTER = {
    filterByConcepts: true,
}

interface Props {}

interface State {
    limit: number
    skip: number
    sortDir: string
    sortBy: string
}

const GET_INFLOW_MOMENTS_QUERY = gql`
    query inflowMoments(
        $skip: Int
        $limit: Int
        $filters: InflowMomentFilterInputType
        $sortDir: String
        $sortBy: String
    ) {
        inflowMoments(skip: $skip, limit: $limit, filters: $filters, sortDir: $sortDir, sortBy: $sortBy) {
            _id
            createdAt
            createdByUser {
                _id
                profile {
                    name
                }
            }
            organization {
                _id
                name
            }
            location {
                _id
                name
            }
            dateRange {
                from
                to
            }
            inflowModule {
                _id
                name
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        organizations(sortBy: "name") {
            _id
            name
        }

        users(filters: { roles: ["EMPLOYEE"] }, sortBy: "profile.firstName") {
            _id
            profile {
                name
            }
        }

        inflowModules(sortBy: "name") {
            _id
            name
        }

        locations(sortBy: "name") {
            _id
            name
        }
    }
`

export class ConceptInflowMomentsTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    private inflowMomentsFetcher: Fetcher
    private paginator: Paginator
    private sorter: Sorter
    private filter: Filter
    private filterSuggestionsFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'filterByInflowModule',
                'filterByLocation',
                'filterByOrganization',
                'filterByDateFrom',
                'filterByDateTo',
                'filterByCreatedBy',
                'filterByConcepts',
            ],
            onChange: (filters: any) => {
                this.inflowMomentsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_INFLOW_MODULES_FILTER,
                        // filterByContactOrganizationIds: [organizationId],
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [],
        })

        this.inflowMomentsFetcher = new Fetcher({
            query: GET_INFLOW_MOMENTS_QUERY,
            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_INFLOW_MODULES_FILTER,
                    ...this.filter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public sort = ({ sortBy, sortDir }: { sortBy: string; sortDir: string }) => {
        this.inflowMomentsFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        } as any)

        this.setState({ sortDir, sortBy })
    }

    public render() {
        const { paginator } = this
        const { loading } = this.inflowMomentsFetcher

        return (
            <InfiniteScroll paginator={paginator} preventLoad={loading} component={View}>
                <Wrap full={true}>{this.renderActionBar()}</Wrap>
                <TableWrap>{this.renderTable()}</TableWrap>
            </InfiniteScroll>
        )
    }

    private renderActionBar = () => {
        const hasFilters = this.filter.hasFilters({ excludeKeys: ['filterByConcepts'] })
        const inflowMomentsFilters = this.filter.getFilters()
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { users = [], organizations = [], inflowModules = [], locations = [] } = filterParams

        const employeeOptions = users.map((user: User) => ({ value: user._id, label: user.profile.name }))
        const organizationOptions = organizations.map((organization: Organization) => ({
            value: organization._id,
            label: organization.name,
        }))
        const inflowModuleOptions = inflowModules.map((inflowModule: InflowModule) => ({
            value: inflowModule._id,
            label: inflowModule.name,
        }))
        const locationOptions = locations.map((location: Location) => ({ value: location._id, label: location.name }))

        return (
            <ActionBar
                isActiveByDefault={hasFilters}
                getButtons={(toggleDrawer: () => void, drawerActive: boolean) => (
                    <List horizontal={true}>
                        <ListItem>
                            <Button
                                leftIcon={<Icon name={`filter`} />}
                                onClick={() => toggleDrawer()}
                                isActive={drawerActive}
                            >
                                Filteropties
                            </Button>
                        </ListItem>
                        <ListItem right={true}>
                            <ModalManager
                                render={openModal => (
                                    <Button onClick={openModal} type={'edit'}>
                                        Toetsmoment aanmaken
                                    </Button>
                                )}
                                getModal={closeModal => <CreateInflowMomentModal onClose={closeModal} />}
                            />
                        </ListItem>
                    </List>
                )}
                getDrawer={() => (
                    <FilterFieldCollection>
                        <Field isLabel={true} title={'Instroommodule'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByInflowModule}
                                multi={false}
                                name={`filterByInflowModule`}
                                onChange={this.onFilterTagPicker}
                                options={inflowModuleOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={'Locatie'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByLocation}
                                multi={false}
                                name={`filterByLocation`}
                                onChange={this.onFilterTagPicker}
                                options={locationOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={'Organisatie'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByOrganization}
                                multi={false}
                                name={`filterByOrganization`}
                                onChange={this.onFilterTagPicker}
                                options={organizationOptions}
                            />
                        </Field>
                        <Field isLabel={true} title={'Datum'} style={'compact'}>
                            <MultiInput type={`select-range`}>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'filterByDateFrom'}
                                    defaultValue={inflowMomentsFilters.filterByEndDateFrom}
                                />
                                <label>t/m</label>
                                <DatePicker
                                    onChange={this.onFilter}
                                    name={'filterByDateTo'}
                                    defaultValue={inflowMomentsFilters.filterByEndDateTo}
                                />
                            </MultiInput>
                        </Field>
                        <Field isLabel={true} title={'Aangemaakt door'} style={'compact'}>
                            <TagPicker
                                defaultValue={inflowMomentsFilters.filterByDateFrom}
                                multi={false}
                                name={`filterByCreatedBy`}
                                onChange={this.onFilterTagPicker}
                                options={employeeOptions}
                            />
                        </Field>
                    </FilterFieldCollection>
                )}
            />
        )
    }

    private renderTable = () => {
        const { data, loading } = this.inflowMomentsFetcher
        const { inflowMoments } = data || []

        const totalColSpan = 7

        return (
            <TableView>
                <Table>
                    <TableHeader>
                        <TableHeaderItem>Naam</TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`dateRange.from`}>
                            Start over
                        </TableHeaderItem>
                        <TableHeaderItem sorter={this.sorter} sortBy={`dateRange.from`} width={`280px`}>
                            Datum
                        </TableHeaderItem>
                        <TableHeaderItem>Locatie</TableHeaderItem>
                        <TableHeaderItem>Organisatie</TableHeaderItem>
                        <TableHeaderItem>Aangemaakt door</TableHeaderItem>
                    </TableHeader>
                    {loading ? (
                        <TableRow key={`loading`}>
                            <TableCell colSpan={totalColSpan} isLoading />
                        </TableRow>
                    ) : inflowMoments && inflowMoments.length > 0 ? (
                        this.renderInflowMomentsRows(inflowMoments)
                    ) : (
                        <TableRow key={`emptyresult`}>
                            <TableCell colSpan={totalColSpan}>
                                <Subtle>Er zijn geen instroommodules gevonden.</Subtle>
                            </TableCell>
                        </TableRow>
                    )}
                </Table>
            </TableView>
        )
    }

    private renderInflowMomentsRows(InflowMoments: ConceptInflowMoment[]) {
        return InflowMoments.map(inflowMoment => {
            return (
                <TableRow key={inflowMoment._id}>
                    <TableCell>
                        <Link route={`/inflow-moments/concept/${inflowMoment._id}`}>
                            {inflowMoment.inflowModule ? inflowMoment.inflowModule.name : 'Geen naam gevonden'}
                        </Link>
                    </TableCell>
                    <TableCell>{this.renderStartsIn(inflowMoment)}</TableCell>
                    <TableCell>{this.renderStartDate(inflowMoment)}</TableCell>
                    <TableCell>{inflowMoment.location ? inflowMoment.location.name : '-'}</TableCell>
                    <TableCell>{inflowMoment.organization ? inflowMoment.organization.name : '-'}</TableCell>
                    <TableCell>{inflowMoment.createdByUser.profile.name}</TableCell>
                </TableRow>
            )
        })
    }

    private onFilter = (event: any) => {
        this.filter.applyFromInputEvent(event)
    }

    private onFilterTagPicker = (values: any[], tagPicker: any) => {
        this.filter.applyFromTagPicker(tagPicker)
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.inflowMomentsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.inflowMoments.length === 0,
                })

                return {
                    inflowMoments: removeDuplicateDocuments([
                        ...(prevData.inflowMoments || []),
                        ...moreData.inflowMoments,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private renderStartDate(inflowMoment: ConceptInflowMoment) {
        if (!inflowMoment.dateRange || !inflowMoment.dateRange.from || !inflowMoment.dateRange.to) {
            return ''
        }

        const startDate = moment(inflowMoment.dateRange.from)
        const endDate = moment(inflowMoment.dateRange.to)

        return `${capitalize(startDate.format('ddd DD MMMM YYYY HH:mm'))} - ${endDate.format('H:mm')}`
    }

    private renderStartsIn(inflowMoment: ConceptInflowMoment) {
        if (!inflowMoment.dateRange || !inflowMoment.dateRange.from) {
            return ''
        }

        const now = moment()
        const startDate = moment(inflowMoment.dateRange.from)

        const difference = startDate.diff(now, 'days')

        if (difference <= 0) {
            return <Emphasis warning={true}>{Math.abs(difference)} dagen geleden</Emphasis>
        }

        if (difference <= 7) {
            return <Emphasis warning={true}>{difference} dagen</Emphasis>
        }

        return `${startDate.diff(now, 'days')} dagen`
    }
}
