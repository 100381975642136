import gql from 'graphql-tag'
import { get, sortBy } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    Button,
    DrawerHeader,
    Icon,
    Input,
    Link,
    Option,
    Select,
    Spinner,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
    Field,
} from '~/components'
import { TableView } from '~/components/TableView'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { View } from '~/components/View'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { translateType } from '~/shared/utils'
import { Fetcher, Mutator, transformFormFields } from '~/utils'
import { LessonReasonOfAbsence, LessonAttendance } from '~/types/Lesson'
import { AttendanceTableRowInputSpacer } from '~/components/Lesson/Attendance/AttendanceTableRowInputSpacer'

export default class ChangeAttendanceForm extends Component {
    static propTypes = {
        getRouteForUserId: PropTypes.func,
        group: PropTypes.object.isRequired,
        lesson: PropTypes.object.isRequired,
        onDone: PropTypes.func.isRequired,
    }

    static defaultProps = {
        getRouteForUserId: userId => `users/learners/${userId}`,
    }

    constructor(props) {
        super(props)

        this.successTimeoutByLearnerUserId = {}
        this.state = {
            loadingByLearnerUserId: {},
            successByLearnerUserId: {},
            errorByLearnerUserId: {},
        }

        this.lessonUsersByLearnerUserId = {}
        this.lessonUsersFetcher = new Fetcher({
            query: GET_LESSONUSERS_QUERY,
            variables: {
                groupFilters: {
                    filterById: this.props.group._id,
                },
                lessonId: this.props.lesson._id,
            },
            onChange: () => this.forceUpdate(),
            transformData: data => {
                const lessonUsers = get(data, 'groups[0].lessons[0].lessonUsers') || []

                const lessonUsersByLearnerUserId = {}
                lessonUsers.forEach(lessonUser => {
                    lessonUsersByLearnerUserId[lessonUser.learnerUser._id] = {
                        _id: lessonUser._id,
                        attendance: lessonUser.attendance,
                        reasonOfAbsence: lessonUser.reasonOfAbsence,
                        attendanceNote: lessonUser.attendanceNote,
                    }
                })

                this.lessonUsersByLearnerUserId = lessonUsersByLearnerUserId

                return {
                    lessonUsers: sortBy(lessonUsers, lu => lu.learnerUser.profile.name),
                }
            },
        })

        this.attendanceMutator = new Mutator({
            mutation: CHANGE_ATTENCANCE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onChangeAttendance = (
        {
            nativeEvent: {
                target: { value },
            },
        },
        userId
    ) => {
        const { lessonUsersByLearnerUserId } = this
        lessonUsersByLearnerUserId[userId] = lessonUsersByLearnerUserId[userId] || {}
        const lessonUser = lessonUsersByLearnerUserId[userId]

        if (lessonUser.attendance === value) return // nothing has changed
        lessonUser.attendance = value
        this.saveAttendance(userId)
    }

    onChangeReasonOfAbsence = (
        {
            nativeEvent: {
                target: { value },
            },
        },
        userId
    ) => {
        const { lessonUsersByLearnerUserId } = this
        lessonUsersByLearnerUserId[userId] = lessonUsersByLearnerUserId[userId] || {}
        const lessonUser = lessonUsersByLearnerUserId[userId]

        if (lessonUser.reasonOfAbsence === value) return // nothing has changed
        lessonUser.reasonOfAbsence = value
        this.saveAttendance(userId)
    }

    onChangeAttendanceNote = (
        {
            nativeEvent: {
                target: { value },
            },
        },
        userId
    ) => {
        const { lessonUsersByLearnerUserId } = this
        lessonUsersByLearnerUserId[userId] = lessonUsersByLearnerUserId[userId] || {}
        const lessonUser = lessonUsersByLearnerUserId[userId]

        if (lessonUser.attendanceNote === value) return // nothing has changed
        lessonUser.attendanceNote = value
        this.saveAttendance(userId)
    }

    saveAttendance = learnerUserId => {
        const { lesson } = this.props
        const { lessonUsersByLearnerUserId } = this
        const lessonUser = lessonUsersByLearnerUserId[learnerUserId]

        if (lessonUser) {
            this.setState(({ loadingByLearnerUserId, successByLearnerUserId, errorByLearnerUserId }) => {
                if (this.successTimeoutByLearnerUserId[learnerUserId]) {
                    clearTimeout(this.successTimeoutByLearnerUserId[learnerUserId])
                }

                loadingByLearnerUserId[learnerUserId] = true
                successByLearnerUserId[learnerUserId] = false
                errorByLearnerUserId[learnerUserId] = false

                return { loadingByLearnerUserId, successByLearnerUserId, errorByLearnerUserId }
            })

            this.attendanceMutator
                .mutate({
                    lessonUser: {
                        lessonId: lesson._id,
                        learnerUserId,
                        ...transformFormFields({
                            attendance: lessonUser.attendance,
                            reasonOfAbsence: lessonUser.reasonOfAbsence,
                            attendanceNote: lessonUser.attendanceNote,
                        }),
                    },
                })
                .then(res => {
                    this.setState(({ loadingByLearnerUserId, successByLearnerUserId, errorByLearnerUserId }) => {
                        loadingByLearnerUserId[learnerUserId] = false
                        successByLearnerUserId[learnerUserId] = !!res
                        errorByLearnerUserId[learnerUserId] = !res

                        if (res) {
                            this.successTimeoutByLearnerUserId[learnerUserId] = setTimeout(() => {
                                this.setState(({ successByLearnerUserId }) => {
                                    successByLearnerUserId[learnerUserId] = false
                                    return { successByLearnerUserId }
                                })
                            }, 2500)
                            // Since we autosave onChange and render based on new data, we can refetch right away.
                            this.lessonUsersFetcher.refetch({ silent: true })
                        }

                        return { loadingByLearnerUserId, successByLearnerUserId, errorByLearnerUserId }
                    })
                })
        }
    }

    render() {
        const { data, loading } = this.lessonUsersFetcher
        const { lessonUsers } = data
        const { onDone } = this.props

        return (
            <View>
                <DrawerHeader hasCloseButton={false} title="Presentie bewerken">
                    <List horizontal>
                        <ListItem right>
                            <Button onClick={onDone}>Sluiten</Button>
                        </ListItem>
                    </List>
                </DrawerHeader>
                <ContentView>
                    <TableView>
                        <TableWrap>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem>Name</TableHeaderItem>
                                    <TableHeaderItem>Presentie</TableHeaderItem>
                                    <TableHeaderItem>Notitie</TableHeaderItem>
                                </TableHeader>
                                {loading && this.renderLoadingStateRow()}
                                {!loading && lessonUsers.length === 0 && this.renderEmptyStateRow()}
                                {!loading && lessonUsers.length > 0 && this.renderLessonUserRows(lessonUsers)}
                            </Table>
                        </TableWrap>
                    </TableView>
                </ContentView>
            </View>
        )
    }

    renderEmptyStateRow() {
        return (
            <TableRow>
                <TableCell colSpan={3}>
                    <Subtle>Deze les bevat geen deelnemers.</Subtle>
                </TableCell>
            </TableRow>
        )
    }

    renderLoadingStateRow() {
        return (
            <TableRow>
                <TableCell colSpan={3} isLoading />
            </TableRow>
        )
    }

    renderLessonUserRows(lessonUsers) {
        const { loadingByLearnerUserId } = this.state
        const { getRouteForUserId } = this.props

        return lessonUsers.map(lessonUser => {
            const { learnerUser } = lessonUser

            const route = getRouteForUserId(learnerUser._id)
            const isLoading = loadingByLearnerUserId[learnerUser._id]

            const inlineState = this.renderInlineState(learnerUser._id)

            return (
                <TableRow key={learnerUser._id}>
                    <TableCell>
                        {route && (
                            <Link route={route} target="_blank">
                                {get(learnerUser, 'profile.name')}
                            </Link>
                        )}
                        {!route && get(learnerUser, 'profile.name')}
                    </TableCell>
                    <TableCell hasInlineState={!!inlineState} hasOverflow>
                        <div className="tt-TableCell--inline-form-state">{inlineState}</div>
                        <Select
                            isReadonly={isLoading}
                            defaultValue={lessonUser && lessonUser.attendance}
                            placeholder={`Selecteer`}
                            onChange={e => this.onChangeAttendance(e, learnerUser._id)}
                        >
                            {['PRESENT', 'ABSENT_WITH_REASON', 'ABSENT_WITHOUT_REASON'].map((key, i) => (
                                <Option value={key} key={i}>
                                    {translateType('lessonAttenance', key)}
                                </Option>
                            ))}
                        </Select>
                    </TableCell>
                    {lessonUser && lessonUser.attendance ? (
                        <TableCell>
                            <AttendanceTableRowInputSpacer>
                                {(lessonUser.attendance === LessonAttendance.AbsentWithReason ||
                                    lessonUser.attendance === LessonAttendance.AbsentWithoutReason) && (
                                    <Field>
                                        <Select
                                            placeholder={`Selecteer`}
                                            defaultValue={lessonUser && lessonUser.reasonOfAbsence}
                                            onChange={e => this.onChangeReasonOfAbsence(e, learnerUser._id)}
                                        >
                                            {[
                                                LessonReasonOfAbsence.Other_Integrtion_Activity,
                                                LessonReasonOfAbsence.Sick,
                                                LessonReasonOfAbsence.Work_Study,
                                                LessonReasonOfAbsence.Vacation,
                                                LessonReasonOfAbsence.Private,
                                                LessonReasonOfAbsence.Transport_Issues,
                                                LessonReasonOfAbsence.Exam,
                                                LessonReasonOfAbsence.Other,
                                            ].map((key, i) => (
                                                <Option value={key} key={`${key} - ${i}`}>
                                                    {translateType('lessonReasonOfAbsence', key)}
                                                </Option>
                                            ))}
                                        </Select>
                                    </Field>
                                )}
                                <Field>
                                    <Input
                                        isReadonly={isLoading}
                                        defaultValue={lessonUser && lessonUser.attendanceNote}
                                        placeholder={`Notitie`}
                                        onBlur={e => this.onChangeAttendanceNote(e, learnerUser._id)}
                                    />
                                </Field>
                            </AttendanceTableRowInputSpacer>
                        </TableCell>
                    ) : (
                        <TableCell />
                    )}
                </TableRow>
            )
        })
    }

    renderInlineState(learnerUserId) {
        const { loadingByLearnerUserId, successByLearnerUserId, errorByLearnerUserId } = this.state
        const loading = loadingByLearnerUserId[learnerUserId]
        const success = successByLearnerUserId[learnerUserId]
        const error = errorByLearnerUserId[learnerUserId]

        let content = null
        if (loading) {
            content = <Spinner size="small" />
        } else if (success) {
            content = <Icon name="check" />
        } else if (error) {
            content = <Icon name="times" />
        }

        return content
    }
}

const CHANGE_ATTENCANCE_MUTATION = gql`
    mutation _($lessonUser: LessonUserInputType!) {
        lessons_setPresence(lessonUser: $lessonUser) {
            _id
        }
    }
`

const GET_LESSONUSERS_QUERY = gql`
    query _($groupFilters: GroupsFilterInputType, $lessonId: MongoID!) {
        groups(filters: $groupFilters) {
            lessons(byId: $lessonId) {
                lessonUsers {
                    _id
                    attendance
                    reasonOfAbsence
                    attendanceNote
                    learnerUser {
                        _id
                        profile {
                            name
                        }
                    }
                }
            }
        }
    }
`
