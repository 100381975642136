import React from 'react'

import Link from '~/components/Link'
import { Wrap } from '~/components/Wrap'
import { ProcessList } from '~/components/User/Learner/Process/ProcessList'
import { View } from '~/components/View'
import { StatisticWrap } from '~/components/Core/StatisticWrap/StatisticWrap'
import { StatisticTable } from '~/components/Table/StatisticTable/StatisticTable'
import { StatisticTableRow } from '~/components/Table/StatisticTable/StatisticTableRow'
import { StatisticTableCell } from '~/components/Table/StatisticTable/StatisticTableCell'
import TableRow from '~/components/TableRow'
import { TableCell } from '~/components/TableCell'
import Subtle from '~/components/Subtle'
import Paragraph from '~/components/Paragraph'
import gql from 'graphql-tag'
import { Fetcher } from '~/utils'
import { get, sortBy, isNumber } from 'lodash'
import { Contract } from '~/types/Contracts'
import { ContractTableCell } from '~/components/Contracts/ContractTableCell'
import { LearnerEnrollmentType, User, UserLearnerHoursSummary } from '~/types/User'
import translateType from '~/shared/utils/translateType'
import { Spinner } from '~/components'

interface Props {
    userId: string
    refetch: () => void
}

interface State {
    selectedCandidate: string | undefined
    type: LearnerEnrollmentType | undefined
    date: Date | undefined
}

export default class ProcessOverviewView extends React.Component<Props> {
    public state: State = {
        selectedCandidate: undefined,
        type: undefined,
        date: undefined,
    }

    private userFetcher: Fetcher

    constructor(props: Props) {
        super(props)

        this.userFetcher = new Fetcher({
            query: GET_USER_DATA_QUERY,
            variables: {
                filters: {
                    byId: props.userId,
                },
                filterByActiveAndNeeded: true,
            },

            onChange: () => this.forceUpdate(),
            transformData: data => ({
                user: get(data, 'users[0]'),
            }),
        })
    }

    public componentDidMount() {
        const { userId } = this.props

        if (userId) {
            this.setState({
                selectedCandidate: this.props.userId,
            })
        }
    }

    public render() {
        const { userId } = this.props
        const { data } = this.userFetcher

        const user = data && (data.user as User)
        const contractCount = (user && user.learner && user.learner.contractCount) || 0
        const hoursSummary = this.getHoursSummary()

        return (
            <View>
                <Wrap full={true} isInset={true}>
                    <StatisticWrap
                        title={`Contracten`}
                        renderAction={() => (
                            <Link small={true} route={`/users/learners/${userId}/process/contracts`}>
                                Bekijk alle contracten ({contractCount})
                            </Link>
                        )}
                    >
                        {this.renderContracts()}
                    </StatisticWrap>
                    {hoursSummary && !!hoursSummary.hoursTotal && hoursSummary.hoursTotal > 0 && (
                        <StatisticWrap
                            title={`Urenverantwoording inburgering`}
                            renderAction={() => (
                                <Link small={true} route={`/users/learners/${userId}/process/hours`}>
                                    Bekijk alle uren
                                </Link>
                            )}
                        >
                            {this.renderUserLearnerHours()}
                        </StatisticWrap>
                    )}
                </Wrap>

                <Wrap full={true}>
                    <ProcessList userId={userId} />
                </Wrap>
            </View>
        )
    }

    private renderContracts() {
        const { data, loading } = this.userFetcher
        const contracts = ((data.user && data.user.learner && data.user.learner.contracts) || []) as Contract[]

        if (loading) {
            return <Spinner />
        }

        if (contracts.length <= 0) {
            return <Subtle>Geen nieuwe of lopende contracten gevonden</Subtle>
        }

        return (
            <StatisticTable>
                {sortBy(contracts, contract => contract.isSigned).map(contract => (
                    <StatisticTableRow key={contract._id}>
                        <ContractTableCell contract={contract} refetch={this.props.refetch} />
                    </StatisticTableRow>
                ))}
            </StatisticTable>
        )
    }

    private renderUserLearnerHours() {
        const { loading } = this.userFetcher
        const hoursSummary = this.getHoursSummary()

        if (loading) {
            return <Spinner />
        }

        if (!hoursSummary) {
            return <Subtle>Geen uren gevonden</Subtle>
        }

        return (
            <StatisticTable>
                <StatisticTableRow>
                    {isNumber(hoursSummary.hoursCitizenship) && (
                        <StatisticTableCell
                            statistic={String(hoursSummary.hoursCitizenship)}
                            subStatistic={translateType(
                                'userLearnerHourDescription',
                                'CITIZENSHIP_OF_WHICH_ONA_SHORT',
                                hoursSummary.hoursCitizenshipOfWhichONA
                            )}
                            description={translateType('userLearnerHourDescription', 'CITIZENSHIP')}
                        />
                    )}
                    {isNumber(hoursSummary.hoursAlfa) && (
                        <StatisticTableCell
                            statistic={String(hoursSummary.hoursAlfa)}
                            description={translateType('userLearnerHourDescription', 'ALFA')}
                        />
                    )}
                    {isNumber(hoursSummary.hoursOther) && (
                        <StatisticTableCell
                            statistic={String(hoursSummary.hoursOther)}
                            subStatistic={translateType(
                                'userLearnerHourDescription',
                                'OTHER_OF_WHICH_ONA_SHORT',
                                hoursSummary.hoursOtherOfWhichONA
                            )}
                            description={translateType('userLearnerHourDescription', 'OTHER')}
                        />
                    )}
                </StatisticTableRow>
                <TableRow isStatisticRow={true}>
                    <TableCell colSpan={3}>
                        <Paragraph>
                            <Subtle>Totaal aantal uren inburgering</Subtle> {hoursSummary.hoursTotal || 0}
                        </Paragraph>
                    </TableCell>
                </TableRow>
            </StatisticTable>
        )
    }

    private getHoursSummary(): UserLearnerHoursSummary | undefined {
        const { data } = this.userFetcher
        const user = data && (data.user as User)
        return user && user.learner && user.learner.hoursSummary
    }
}

const GET_USER_DATA_QUERY = gql`
    query _($filters: UsersFilterInputType, $filterByActiveAndNeeded: Boolean) {
        users(filters: $filters) {
            learner {
                enrollmentType
                enrollmentDate
                isAvailableForInflow
                hoursSummary {
                    hoursCitizenship
                    hoursCitizenshipOfWhichONA
                    hoursAlfa
                    hoursOther
                    hoursOtherOfWhichONA
                    hoursTotal
                }
                contractCount
                contracts(filterByActiveAndNeeded: $filterByActiveAndNeeded) {
                    _id
                    contractNumber
                    type
                    dateFrom
                    dateTo
                    isSigned
                    isFinal
                    contract {
                        fileId
                        fileName
                    }
                    signedContract {
                        fileId
                        fileName
                    }
                }
            }
        }
    }
`
