import React from 'react'
import gql from 'graphql-tag'
import { compact, times } from 'lodash'
import classnames from 'classnames'

import DrawerHeader from '~/components/DrawerHeader'
import { List } from '~/components/List'
import { ContentView } from '~/components/ContentView'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components/buttons/Button/Button'
import TableWrap from '~/components/TableWrap'
import GroupLearnersSelectionTable, {
    GroupLearnersSelectionTableRow,
} from '~/implementations/GroupLearnersSelectionTable'
import { TableView } from '~/components/TableView'
import TableHeader from '~/components/TableHeader'
import TableHeaderItem from '~/components/TableHeaderItem'
import { TableCell } from '~/components/TableCell'
import { ModalManager } from '~/components/ModalManager'
import Icon from '~/components/Icon'
import CenterModal from '~/components/CenterModal'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import Search from '~/components/Search'
import FilterFieldCollection from '~/components/FilterFieldCollection'
import { TagPicker } from '~/components/TagPicker'
import Field from '~/components/Field'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import { Paginator } from '~/utils/Paginator'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import Fetcher from '~/utils/Fetcher'
import Filter from '~/utils/Filter'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import Spinner from '~/components/Spinner'
import Subtle from '~/components/Subtle'
import { User } from '~/types/User'
import Link from '~/components/Link'
import translateType from '~/shared/utils/translateType'
import MultiInput from '~/components/MultiInput'
import CheckBox from '~/components/CheckBox'
import { Group } from '~/types/Group'
import { Organization } from '~/types/Organization'
import { AddSingleLearnerUserToGroupForm } from '~/components/groups/participants/AddSingleLearnerUserToGroupForm'
import DrawerFooter from '~/components/DrawerFooter'

interface Props {
    groupId: string
    onCancel: () => void
    onSuccess: () => void
}

interface State {
    limit: number
    skip: number
}

const START = 40
const INCREASE = 40
const STATIC_LEARNER_USER_FILTERS = {
    roles: ['LEARNER'],
    filterLearnerActive: true,
}

export class AddLearnerGroupUserDrawerForm extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
    }

    private learnerUsersPaginator: Paginator
    private learnerUsersFetcher: Fetcher
    private learnerUsersFilter: Filter
    private filterSuggestionsFetcher: Fetcher

    public constructor(props: Props) {
        super(props)

        const { limit, skip } = this.state

        this.learnerUsersPaginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.learnerUsersFilter = new Filter({
            useHistory: false,
            allowedKeys: [
                'searchText',
                'filterByCityNames',
                'filterByAdviceModuleIds',
                'filterByPreferredLocationIds',
                'filterLearnerStatuses',
                'filterByLearnabiltyMin',
                'filterByLearnabiltyMax',
                'filterByLearnabiltyMax',
                'filterByOrganizationIds',
                'filterByGroupIds',
            ],
            onChange: (filters: any) => {
                this.learnerUsersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_LEARNER_USER_FILTERS,
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsRegistered', 'filterIsUnassigned', 'filterIsAssigned'],
                    transform: (filters: any) => {
                        const statuses = []

                        if (filters.filterIsRegistered) {
                            statuses.push('REGISTERED')
                        }
                        if (filters.filterIsUnassigned) {
                            statuses.push('UNASSIGNED')
                        }
                        if (filters.filterIsAssigned) {
                            statuses.push('ASSIGNED')
                        }

                        return {
                            filterLearnerStatuses: statuses.length > 0 && statuses.length < 3 ? statuses : null,
                        }
                    },
                    historyTriggerKeys: ['filterLearnerStatuses'],
                    parseFromHistory: (historyFilters: any) => {
                        const statuses = historyFilters.filterLearnerStatuses || []

                        return {
                            filterIsRegistered: statuses.indexOf('REGISTERED'),
                            filterIsUnassigned: statuses.indexOf('UNASSIGNED'),
                            filterIsAssigned: statuses.indexOf('ASSIGNED'),
                        }
                    },
                },
            ],
        })

        this.learnerUsersFetcher = new Fetcher({
            query: GET_LEARNER_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_LEARNER_USER_FILTERS,
                    ...this.learnerUsersFilter.getFilters(),
                },
                limit,
                skip,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.learnerUsersPaginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { onCancel } = this.props
        const { loading, data } = this.learnerUsersFetcher
        const users = data.users || []

        return (
            <React.Fragment>
                <DrawerHeader title={'Kandidaat toevoegen'} close={onCancel} />
                <ContentView>
                    <InfiniteScroll paginator={this.learnerUsersPaginator} preventLoad={loading}>
                        <Wrap full>
                            <ActionBar
                                getButtons={(toggleDrawer, drawerActive) => {
                                    return (
                                        <List horizontal>
                                            <ListItem>
                                                <Button
                                                    leftIcon={<Icon name={`filter`} />}
                                                    onClick={() => toggleDrawer()}
                                                    isActive={drawerActive}
                                                >
                                                    Filteropties
                                                </Button>
                                            </ListItem>
                                            <ListItem>
                                                <Search isLoading={loading} onSearch={this.onSearch} />
                                            </ListItem>
                                        </List>
                                    )
                                }}
                                getDrawer={this.renderActionBarDrawer}
                            />
                        </Wrap>
                        <TableWrap>
                            <TableView>
                                <GroupLearnersSelectionTable>
                                    <TableHeader>
                                        <TableHeaderItem forIconButton={true} />
                                        <TableHeaderItem width="35%">Naam</TableHeaderItem>
                                        <TableHeaderItem>Woonplaats</TableHeaderItem>
                                        <TableHeaderItem width={'20%'}>Advies-lesmodule</TableHeaderItem>
                                        <TableHeaderItem>Leerbaarheid</TableHeaderItem>
                                        <TableHeaderItem>Voorkeurslocatie</TableHeaderItem>
                                        <TableHeaderItem className="tt-GroupLearnersSelectionTableRow__levels">
                                            <span>Gespr.</span>
                                            <span>Spr.</span>
                                            <span>Schr.</span>
                                            <span>Lui.</span>
                                            <span>Lez.</span>
                                        </TableHeaderItem>
                                        <TableHeaderItem />
                                    </TableHeader>
                                    {loading && (
                                        <GroupLearnersSelectionTableRow>
                                            <TableCell colSpan={8}>
                                                <Spinner instant />
                                            </TableCell>
                                        </GroupLearnersSelectionTableRow>
                                    )}
                                    {!loading &&
                                        (users.length > 0 ? (
                                            users.map((user: any) => (
                                                <React.Fragment key={user._id}>
                                                    {this.renderLearnerRow(user)}
                                                </React.Fragment>
                                            ))
                                        ) : (
                                            <GroupLearnersSelectionTableRow>
                                                <TableCell colSpan={8}>
                                                    <Subtle>Er zijn geen kandidaten gevonden.</Subtle>
                                                </TableCell>
                                            </GroupLearnersSelectionTableRow>
                                        ))}
                                </GroupLearnersSelectionTable>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter hasCancelSaveBtns={false}>
                    <List horizontal>
                        <ListItem right>
                            <Button onClick={onCancel}>Annuleer</Button>
                        </ListItem>
                    </List>
                </DrawerFooter>
            </React.Fragment>
        )
    }

    private renderActionBarDrawer = () => {
        const { data: filterParams } = this.filterSuggestionsFetcher
        const {
            addresses_usedCities = [],
            modules = [],
            locations = [],
            organizations = [],
            groups = [],
        } = filterParams

        const addressOptions = addresses_usedCities.map((city: any) => ({ value: city, label: city }))
        const moduleOptions = modules.map(({ name, _id }: any) => ({ value: _id, label: name }))
        const locationOptions = locations.map(({ name, _id }: any) => ({ value: _id, label: name }))
        const scoreOptions = times(26, n => ({ value: n, label: n }))
        const organizationOptions = organizations.map((organization: Organization) => ({
            value: organization._id,
            label: organization.name,
        }))
        const groupOptions = groups.map((group: Group) => ({ value: group._id, label: group.name }))

        const filters = this.learnerUsersFilter.getFilters()

        return (
            <FilterFieldCollection>
                <Field title="Woonplaats" style="compact">
                    <TagPicker onChange={this.onFilterTagPicker} name="filterByCityNames" options={addressOptions} />
                </Field>
                <Field title="Advies lesmodule" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByAdviceModuleIds"
                        options={moduleOptions}
                    />
                </Field>
                <Field title="Voorkeurslocatie" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByPreferredLocationIds"
                        options={locationOptions}
                    />
                </Field>
                <Field title="Organisatie" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByOrganizationIds"
                        defaultValue={filters.filterByOrganizationIds}
                        options={organizationOptions}
                        placeholder="Selecteer organisatie"
                    />
                </Field>
                <Field title="Groepen" style="compact">
                    <TagPicker
                        onChange={this.onFilterTagPicker}
                        name="filterByGroupIds"
                        defaultValue={filters.filterByGroupIds}
                        options={groupOptions}
                        placeholder="Selecteer groep"
                    />
                </Field>
                <Field title="Leerbaarheid" style="compact">
                    <MultiInput type="select-range">
                        <TagPicker
                            multi={false}
                            onChange={this.onFilterTagPicker}
                            name="filterByLearnabiltyMin"
                            options={scoreOptions}
                        />
                        <label>tot</label>
                        <TagPicker
                            multi={false}
                            onChange={this.onFilterTagPicker}
                            name="filterByLearnabiltyMax"
                            options={scoreOptions}
                        />
                    </MultiInput>
                </Field>
                <Field title="Status" style="compact">
                    <MultiInput type="checkbox">
                        <CheckBox name="filterIsAssigned" onChange={this.onFilter}>
                            Ingedeeld
                        </CheckBox>
                        <CheckBox name="filterIsUnassigned" onChange={this.onFilter}>
                            Niet ingedeeld
                        </CheckBox>
                        <CheckBox name="filterIsRegistered" onChange={this.onFilter}>
                            Aangemeld
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    private renderLearnerRow = (user: User) => {
        const { groupId, onSuccess } = this.props

        const preferredLocations = (user.learner && user.learner.preferredLocations) || []

        const levels =
            (user.learner &&
                user.learner.latestAbilityLevels &&
                [
                    user.learner.latestAbilityLevels.converse,
                    user.learner.latestAbilityLevels.talk,
                    user.learner.latestAbilityLevels.write,
                    user.learner.latestAbilityLevels.listen,
                    user.learner.latestAbilityLevels.read,
                ].map(l => l.level)) ||
            []

        const canExpandRow = !!(
            user.profile.notes ||
            (user.learner &&
                (user.learner.adviceModule || user.learner.adviceModuleInfo || user.learner.englishAsSupportLanguage))
        )

        return (
            <GroupLearnersSelectionTableRow
                hasExpandableInfo={canExpandRow}
                getExpansion={() => (
                    <TableCell colSpan={8}>
                        <SimpleTable>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Notities:</SimpleTableCell>
                                <SimpleTableCell>{user.profile.notes || '-'}</SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Advies-lesmodule:</SimpleTableCell>
                                <SimpleTableCell>
                                    {(user.learner && user.learner.adviceModule && (
                                        <Link
                                            route={`/properties/modules/${user.learner.adviceModule._id}`}
                                            target="_blank"
                                        >
                                            {user.learner.adviceModule.name}
                                        </Link>
                                    )) ||
                                        '-'}
                                </SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Toelichting advies-lesmodule:</SimpleTableCell>
                                <SimpleTableCell>
                                    {(user.learner && user.learner.adviceModuleInfo) || 'Geen toelichting'}
                                </SimpleTableCell>
                            </SimpleTableRow>
                            <SimpleTableRow>
                                <SimpleTableCell isBold>Engels als steuntaal:</SimpleTableCell>
                                <SimpleTableCell>
                                    {user.learner && user.learner.englishAsSupportLanguage}
                                </SimpleTableCell>
                            </SimpleTableRow>
                        </SimpleTable>
                    </TableCell>
                )}
            >
                <TableCell forIconButton>
                    <ModalManager
                        render={openModal => (
                            <Button type={`in-row`} leftIcon={<Icon name={`plus`} />} onClick={openModal} />
                        )}
                        getModal={closeModal => (
                            <CenterModal title={`Toevoegen van ${user.profile.name}`} onClose={closeModal}>
                                <AddSingleLearnerUserToGroupForm
                                    groupId={groupId}
                                    userId={user._id}
                                    onSuccess={() => {
                                        closeModal()
                                        onSuccess()
                                    }}
                                    onCancel={closeModal}
                                />
                            </CenterModal>
                        )}
                    />
                </TableCell>
                <TableCell>
                    <Link route={`/users/learners/${user._id}`} target="_blank" type="emphasized">
                        {user.profile.name}
                    </Link>
                </TableCell>
                <TableCell>
                    {user.profile.address &&
                        user.profile.address.nl &&
                        user.profile.address.nl.extraInfo &&
                        user.profile.address.nl.extraInfo.city}
                </TableCell>
                <TableCell>{user.learner && user.learner.adviceModule && user.learner.adviceModule.name}</TableCell>
                <TableCell>{user.learner && user.learner.intakeGradesLearnability}</TableCell>
                <TableCell>
                    {compact(preferredLocations)
                        .map(pl => pl.name)
                        .join(', ')}
                </TableCell>
                <TableCell className="tt-GroupLearnersSelectionTableRow__levels">
                    {compact(levels).length > 0 &&
                        levels.map((level, i) => (
                            <span
                                key={i}
                                className={classnames({
                                    'tt-GroupLearnersSelectionTableRow__levels--is-empty': !level,
                                })}
                            >
                                {translateType('level', level) || '--'}
                            </span>
                        ))}
                </TableCell>
            </GroupLearnersSelectionTableRow>
        )
    }

    private loadMore = (skip: number, limit: number, callback: (options?: { finished: boolean }) => void) => {
        this.learnerUsersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSearch = ({ searchText }: any) => {
        this.learnerUsersFilter.apply('searchText', searchText)
    }

    private onFilter = (event: any) => {
        this.learnerUsersFilter.applyFromInputEvent(event)
    }

    private onFilterTagPicker = (values: any, tagPicker: any) => {
        this.learnerUsersFilter.applyFromTagPicker(tagPicker)
    }
}

const GET_LEARNER_USERS_QUERY = gql`
    query _($skip: Int, $limit: Int, $filters: UsersFilterInputType) {
        users(limit: $limit, skip: $skip, sortBy: "profile.firstName", filters: $filters) {
            _id
            profile {
                name
                notes
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            learner {
                intakeGradesLearnability
                intakeGrades {
                    levelConversations
                    levelTalking
                    levelWriting
                    levelListening
                    levelReading
                }
                latestAbilityLevels {
                    converse {
                        ...abilityLevelFields
                    }
                    talk {
                        ...abilityLevelFields
                    }
                    write {
                        ...abilityLevelFields
                    }
                    listen {
                        ...abilityLevelFields
                    }
                    read {
                        ...abilityLevelFields
                    }
                }
                englishAsSupportLanguage
                adviceModule {
                    _id
                    name
                }
                adviceModuleInfo
                preferredLocations {
                    _id
                    name
                }
            }
        }
    }

    fragment abilityLevelFields on AbilityLevelType {
        level
        accomplishedAt
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities

        modules(sortBy: "name") {
            _id
            name
        }

        locations(sortBy: "name") {
            _id
            name
        }

        organizations(sortBy: "name") {
            _id
            name
        }

        groups(sortBy: "name") {
            _id
            name
        }
    }
`
