import * as React from 'react'
import Form from '~/components/Form'
import FieldCollection from '~/components/FieldCollection'
import { FieldGroup } from '~/components/FieldGroup'
import Field from '~/components/Field'
import DatePicker from '~/components/DatePicker'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { Button } from '~/components'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { toast, Mutator } from '~/utils'
import gql from 'graphql-tag'
import { User } from '~/types/User'

interface Props {
    onClose: () => void
    onSubmitSuccess: () => void
    user?: User
    isLoading: boolean
}

interface FormFields {
    user: {
        learner: {
            examDignityDate: Date
        }
    }
}

const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`

export class ExamDignityModalForm extends React.Component<Props> {
    private userMutator = new Mutator({
        mutation: EDIT_USER_MUTATION,
        reactComponentToUpdate: this,
    })

    public render() {
        const { onClose, user, isLoading } = this.props

        if (!user) {
            return null
        }

        const defaultExamDignityDateValue = user && user.learner && user.learner.examDignityDate

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field title={`Examenwaardig op`}>
                            <DatePicker
                                name={`user.learner.examDignityDate`}
                                defaultValue={defaultExamDignityDateValue}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem>
                                {defaultExamDignityDateValue && (
                                    <Button
                                        onClick={this.onRemove}
                                        linkStyle={['default', 'danger']}
                                        confirm={{
                                            title: 'Verwijderen',
                                            message: 'Weet je zeker dat je de datum wilt verwijderen?',
                                            execute: {
                                                buttonType: 'danger',
                                                title: 'Verwijderen',
                                            },
                                        }}
                                    >
                                        Verwijderen
                                    </Button>
                                )}
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`button`} onClick={onClose}>
                                    Annuleren
                                </Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={isLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: FormFields) => {
        const { onClose, onSubmitSuccess, user } = this.props

        const userId = user && user._id

        const data = await this.userMutator.mutate({
            user: {
                _id: userId,
                ...transformFormFields(fields.user, {
                    learner: {
                        fields: (v: any) => ({
                            ...transformFormFields(v, {
                                examDignityDate: transformLib.date(),
                            }),
                        }),
                    },
                }),
            },
        })

        if (data && data.users_edit) {
            toast.success(`De datum is aangepast`)
            onSubmitSuccess()
            onClose()
        }
    }

    private onRemove = async () => {
        const { onClose, onSubmitSuccess, user } = this.props

        const userId = user && user._id

        const data = await this.userMutator.mutate({
            user: {
                _id: userId,
                learner: {
                    examDignityDate: null,
                    nulls: ['examDignityDate'],
                },
            },
        })

        if (data && data.users_edit) {
            toast.success(`De datum is verwijderd`)
            onSubmitSuccess()
            onClose()
        }
    }
}
