import gql from 'graphql-tag'
import { get } from 'lodash/object'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { Header } from '~/components'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { View } from '~/components/View'
import { Fetcher } from '~/utils'

const STATIC_ORGANIZATION_CONTACT_USER_FILTERS = {
    roles: ['ORGANIZATION_CONTACT'],
}

export default class DetailView extends Component {
    static propTypes = {
        params: PropTypes.object,
        children: PropTypes.any,
    }

    constructor(props) {
        super(props)

        const {
            params: { id },
        } = props

        this.userFetcher = new Fetcher({
            query: GET_USER_QUERY,
            variables: {
                filters: {
                    ...STATIC_ORGANIZATION_CONTACT_USER_FILTERS,
                    byId: id,
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    render() {
        const { children, ...routeProps } = this.props
        const { data, refetch, loading } = this.userFetcher
        const { users = [] } = data
        const [user] = users

        return (
            <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps} isLoading={loading}>
                <Header>
                    <BreadCrumbs />
                </Header>
                <View>{user && cloneElement(children, { user, userId: user._id, refetch })}</View>
            </RouteView>
        )
    }
}

const GET_USER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            email
            lastActivityAt
            invitedAt
            profile {
                name
                firstName
                initials
                surNamePrefix
                surName
                gender
                phoneNumbers {
                    _id
                    phoneNumber
                    internationalPhoneNumber
                    country
                    info
                }
                notes
                isMfaConfigured
                cognitoUserId
                mfaMobileNumber
            }
            organizationContact {
                organization {
                    _id
                    name
                    notes
                }
                isActive
            }
        }
    }
`
