import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { cloneElement, Component } from 'react'

import { RouteView } from '~/components/Chrome/Navigation/RouteView/RouteView'
import { View } from '~/components/View'
import { Fetcher } from '~/utils'

export class DefinitiveInflowMomentsDetailIntakeDetailView extends Component {
    static propTypes = {
        params: PropTypes.shape({
            userId: PropTypes.string.isRequired,
        }).isRequired,
        children: PropTypes.any,
    }

    constructor(props) {
        super(props)

        this.learnerFetcher = new Fetcher({
            query: GET_LEARNER_QUERY,
            variables: {
                filters: {
                    byId: props.params.userId,
                },
            },

            onChange: () => this.forceUpdate(),
        })
    }

    componentDidUpdate({ params: prevParams }) {
        const { params } = this.props

        if (prevParams.userId !== params.userId) {
            this.learnerFetcher.refetch({
                filters: {
                    byId: params.userId,
                },
            })
        }
    }

    render() {
        const { children, ...routeProps } = this.props
        const { id: inflowMomentId, userId } = routeProps.params
        const { data, loading, refetch } = this.learnerFetcher
        const user = get(data, 'users[0]')

        return (
            <RouteView crumbLabel={get(user, 'profile.name')} routeProps={routeProps} isLoading={loading}>
                <View>
                    {user &&
                        cloneElement(children, {
                            userId,
                            inflowMomentId,
                            refetch,
                            isViewedByIntakerOfUser: true,
                        })}
                </View>
            </RouteView>
        )
    }
}

const GET_LEARNER_QUERY = gql`
    query _($filters: UsersFilterInputType) {
        users(filters: $filters) {
            _id
            profile {
                name
            }
        }
    }
`
