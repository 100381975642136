import React from 'react'
import gql from 'graphql-tag'
import { InfiniteScroll } from '../Core/InfiniteScroll/InfiniteScroll'
import { Wrap } from '../Wrap'
import { Paginator } from '~/utils/Paginator'
import { View } from '../View'
import { Fetcher, removeDuplicateDocuments, Mutator } from '~/utils'
import { TableWrap, TableHeader, TableHeaderItem, TableRow, Subtle, Link, ReadableDate, Icon, PdfModal } from '..'
import { Table } from '../Table'
import { TableCell } from '../TableCell'
import { AdviceReport } from '~/types/AdviceReports'
import { Button } from '../buttons/Button/Button'
import { getCurrentUser } from '~/services/session'
import { getUserDetailRoute } from '~/utils/getUserDetailRoute'
import { ModalManager } from '../ModalManager'
import { AdviceReportsDocument } from '~/generated/graphql'

const START = 40
const INCREASE = 40

interface Props {
    inflowMomentId: string
    refetchInflowMoment: (options?: { silent: boolean }) => void
}

interface State {
    limit: number
    skip: number
    isRevokingLoadingById: {
        [adviceReportId: string]: boolean
    }
    isReleasingLoadingById: {
        [adviceReportId: string]: boolean
    }
}

const RELEASE_ADVICE_REPORT_MUTATION = gql`
    mutation _($adviceReportId: MongoID) {
        adviceReport_release(adviceReportId: $adviceReportId) {
            _id
        }
    }
`

const REVOKE_ADVICE_REPORT_MUTATION = gql`
    mutation _($adviceReportId: MongoID) {
        adviceReport_revoke(adviceReportId: $adviceReportId) {
            _id
        }
    }
`

const CREATE_ADVICE_REPORT_FILE_MUTATION = gql`
    mutation _($adviceReportId: MongoID!) {
        adviceReports_generateFile(adviceReportId: $adviceReportId) {
            fileId
        }
    }
`

export class AdviceReportsTable extends React.Component<Props, State> {
    public state: State = {
        limit: START,
        skip: 0,
        isRevokingLoadingById: {},
        isReleasingLoadingById: {},
    }

    private paginator: Paginator
    private adviceReportsFetcher: Fetcher
    private adviceRapportReleaseMutator: Mutator
    private adviceRapportRevokeMutator: Mutator
    private createAdviceReportFileMutator: Mutator

    constructor(props: Props) {
        super(props)

        const { limit, skip } = this.state

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.adviceReportsFetcher = new Fetcher({
            query: AdviceReportsDocument,
            variables: {
                limit,
                skip,
                inflowMomentId: this.props.inflowMomentId,
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.adviceRapportReleaseMutator = new Mutator({
            mutation: RELEASE_ADVICE_REPORT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.adviceRapportRevokeMutator = new Mutator({
            mutation: REVOKE_ADVICE_REPORT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.createAdviceReportFileMutator = new Mutator({
            mutation: CREATE_ADVICE_REPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { data, loading } = this.adviceReportsFetcher
        const { adviceReports } = data
        const currentUser = getCurrentUser()
        const { isEmployee } = currentUser

        //  All advice reports for inflow moment have the same module
        const checkAdviceReportNeeded =
            adviceReports && adviceReports[0] && adviceReports[0].inflowMoment.inflowModule.checkAdviceReportNeeded

        const totalColSpan = checkAdviceReportNeeded ? 5 : 4

        return (
            <InfiniteScroll paginator={this.paginator} component={View}>
                <Wrap full={true}>
                    <TableWrap>
                        <Table>
                            <TableHeader>
                                <TableHeaderItem>Rapport</TableHeaderItem>
                                <TableHeaderItem>Naam</TableHeaderItem>
                                <TableHeaderItem>Afgenomen door</TableHeaderItem>
                                <TableHeaderItem>Datum</TableHeaderItem>
                                {isEmployee && checkAdviceReportNeeded && <TableHeaderItem />}
                            </TableHeader>
                            {loading ? (
                                <TableRow key={`loading`}>
                                    <TableCell colSpan={totalColSpan} isLoading />
                                </TableRow>
                            ) : adviceReports && adviceReports.length > 0 ? (
                                this.renderAdviceReportRows(adviceReports)
                            ) : (
                                <TableRow key={`emptyresult`}>
                                    <TableCell colSpan={totalColSpan}>
                                        <Subtle>Er zijn geen adviesrapporten gevonden.</Subtle>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableWrap>
                </Wrap>
            </InfiniteScroll>
        )
    }

    private renderAdviceReportRows(adviceReports: AdviceReport[]) {
        const currentUser = getCurrentUser()
        const { isEmployee } = currentUser

        const DynamicComponent = isEmployee ? Link : 'span'

        return adviceReports.map(adviceReport => {
            const checkAdviceReportNeeded = adviceReport.inflowMoment.inflowModule.checkAdviceReportNeeded
            const isReleasingLoading = this.state.isReleasingLoadingById[adviceReport._id]
            const isRevokingLoading = this.state.isRevokingLoadingById[adviceReport._id]

            return (
                <TableRow key={adviceReport._id}>
                    <TableCell>{this.renderAdviceReportModal(adviceReport)}</TableCell>
                    <TableCell>{adviceReport.learnerUser.profile.name}</TableCell>
                    <TableCell>
                        <DynamicComponent route={getUserDetailRoute(adviceReport.intakerUser)}>
                            {adviceReport.intakerUser.profile.name}
                        </DynamicComponent>
                    </TableCell>
                    <TableCell>
                        <ReadableDate date={adviceReport.createdAt} format={'DD-MM-YYYY'} />
                    </TableCell>
                    {isEmployee && checkAdviceReportNeeded && (
                        <TableCell>
                            {adviceReport.releasedAt ? (
                                <Button
                                    small={true}
                                    onClick={() => this.revokeAdviceReport(adviceReport._id)}
                                    isLoading={isRevokingLoading}
                                >
                                    Intrekken
                                </Button>
                            ) : (
                                <Button
                                    small={true}
                                    onClick={() => this.releaseAdviceReport(adviceReport._id)}
                                    leftIcon={<Icon name={'status_done'} color={'#2cc472'} />}
                                    isLoading={isReleasingLoading}
                                >
                                    Vrijgeven
                                </Button>
                            )}
                        </TableCell>
                    )}
                </TableRow>
            )
        })
    }

    private renderAdviceReportModal = (adviceReport: AdviceReport) => {
        const currentUser = getCurrentUser()
        const { isOrganizationContact } = currentUser

        return (
            <ModalManager
                render={openModal => (
                    <Link
                        onClick={openModal}
                        rightIcon={
                            !isOrganizationContact && adviceReport.releasedAt ? (
                                <Icon name={'status_done'} color={'#2cc472'} />
                            ) : (
                                ''
                            )
                        }
                    >
                        {this.getAdviceReportFileName(adviceReport)}
                    </Link>
                )}
                getModal={closeModal => (
                    <PdfModal
                        title={this.getAdviceReportFileName(adviceReport)}
                        fileName={this.getAdviceReportFileName(adviceReport)}
                        getFileId={async () => await this.getAdviceReportFileId(adviceReport)}
                        onClose={closeModal}
                    />
                )}
            />
        )
    }

    private getAdviceReportFileName = (adviceReport: AdviceReport): string => {
        return `${adviceReport.learnerUser.profile.name}-advies-rapport.pdf`
    }

    private getAdviceReportFileId = async (adviceReport: AdviceReport) => {
        if (adviceReport.file) {
            return adviceReport.file.fileId
        }

        const res = await this.createAdviceReportFileMutator.mutate({
            adviceReportId: adviceReport._id,
        })

        if (res && res.adviceReports_generateFile) {
            return res.adviceReports_generateFile.fileId
        }
    }

    private loadMore = (skip: number, limit: number, callback: (opts?: { finished: boolean }) => void) => {
        this.adviceReportsFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.adviceReports.length === 0,
                })

                return {
                    adviceReports: removeDuplicateDocuments([
                        ...(prevData.adviceReports || []),
                        ...moreData.adviceReports,
                    ]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private releaseAdviceReport = async (adviceReportId: string) => {
        const { refetch } = this.adviceReportsFetcher
        const { refetchInflowMoment } = this.props

        this.setState(prevState => ({
            isReleasingLoadingById: {
                ...prevState.isReleasingLoadingById,
                [adviceReportId]: true,
            },
        }))

        const data = await this.adviceRapportReleaseMutator.mutate({
            adviceReportId: adviceReportId,
        })

        this.setState(prevState => ({
            isReleasingLoadingById: {
                ...prevState.isReleasingLoadingById,
                [adviceReportId]: false,
            },
        }))

        if (data) {
            refetch({ silent: true })
            refetchInflowMoment({ silent: true })
        }
    }

    private revokeAdviceReport = async (adviceReportId: string) => {
        const { refetch } = this.adviceReportsFetcher
        const { refetchInflowMoment } = this.props

        this.setState(prevState => ({
            isRevokingLoadingById: {
                ...prevState.isRevokingLoadingById,
                [adviceReportId]: true,
            },
        }))

        const data = await this.adviceRapportRevokeMutator.mutate({
            adviceReportId: adviceReportId,
        })

        this.setState(prevState => ({
            isRevokingLoadingById: {
                ...prevState.isRevokingLoadingById,
                [adviceReportId]: false,
            },
        }))

        if (data) {
            refetch({ silent: true })
            refetchInflowMoment({ silent: true })
        }
    }
}
