import gql from 'graphql-tag'
import { get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Label } from '~/components/Label/Label'
import {
    ActionBar,
    Button,
    DrawerModal,
    Icon,
    Link,
    PdfModal,
    ReadableDate,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { ChangeAttendanceForm } from '~/forms'
import { Fetcher, Mutator } from '~/utils'
import translateType from '~/shared/utils/translateType'

export default class ExternalTeacherLessonsTable extends Component {
    static propTypes = {
        group: PropTypes.object,
    }

    constructor(props) {
        super(props)

        const { _id } = props.group

        this.lessonsFetcher = new Fetcher({
            query: GET_GROUP_LESSONS_QUERY,
            variables: {
                filters: {
                    filterById: _id,
                },
            },

            onChange: () => this.forceUpdate(),
        })

        this.createLessonAttendanceFileMutator = new Mutator({
            mutation: CREATE_LEARNERS_LESSON_ATTENDANCE_FILE,
            reactComponentToUpdate: this,
        })

        this.createLessonTimeTableFileMutator = new Mutator({
            mutation: CREATE_LEARNERS_LESSON_TIMETABLE_FILE,
            reactComponentToUpdate: this,
        })
    }

    onLessonEdit(closeModal) {
        this.lessonsFetcher.refetch()
        closeModal()
    }

    getLessonTimetableFileId = async () => {
        const data = await this.createLessonTimeTableFileMutator.mutate({
            groupId: this.props.group._id,
        })

        if (data && data.users_createLessonTimetableFile) {
            return data.users_createLessonTimetableFile.fileId
        }
    }

    getLessonAttendanceFileId = async () => {
        const data = await this.createLessonAttendanceFileMutator.mutate({
            groupId: this.props.group._id,
        })

        if (data && data.users_createLessonAttendanceFile) {
            return data.users_createLessonAttendanceFile.fileId
        }
    }

    render() {
        const { data, loading } = this.lessonsFetcher
        const group = get(data, 'groups[0]') || {}
        const isEnded = group.endedAt
        const lessons = group.lessons || []

        return (
            <View>
                <Wrap full>
                    {!isEnded && (
                        <ActionBar
                            getButtons={() => {
                                return (
                                    <List horizontal>
                                        <ListItem left>
                                            <ModalManager
                                                render={openModal => {
                                                    return (
                                                        <Button leftIcon={<Icon name={`eye`} />} onClick={openModal}>
                                                            Rooster PDF
                                                        </Button>
                                                    )
                                                }}
                                                getModal={onClose => {
                                                    return (
                                                        <PdfModal
                                                            title={`Rooster van ${this.props.group.name}`}
                                                            fileName={`${this.props.group.name}_les-rooster.pdf`}
                                                            getFileId={this.getLessonTimetableFileId}
                                                            onClose={onClose}
                                                        />
                                                    )
                                                }}
                                            />
                                        </ListItem>
                                        <ListItem left>
                                            <ModalManager
                                                render={openModal => {
                                                    return (
                                                        <Button leftIcon={<Icon name={`eye`} />} onClick={openModal}>
                                                            Presentielijst PDF
                                                        </Button>
                                                    )
                                                }}
                                                getModal={onClose => {
                                                    return (
                                                        <PdfModal
                                                            title={`Presentielijst van ${this.props.group.name}`}
                                                            fileName={`${this.props.group.name}_les-presentie.pdf`}
                                                            getFileId={this.getLessonAttendanceFileId}
                                                            onClose={onClose}
                                                        />
                                                    )
                                                }}
                                            />
                                        </ListItem>
                                    </List>
                                )
                            }}
                        />
                    )}
                </Wrap>
                <TableWrap>
                    <TableView>
                        <Table hasAutoLayout>
                            <TableHeader>
                                <TableHeaderItem sorter={this.sorter} sortBy={`name`} width="15%">
                                    Naam
                                </TableHeaderItem>
                                <TableHeaderItem sorter={this.sorter} sortBy={`date`}>
                                    Datum
                                </TableHeaderItem>
                                <TableHeaderItem sorter={this.sorter} sortBy={`date`}>
                                    Locatie
                                </TableHeaderItem>
                                <TableHeaderItem sorter={this.sorter} sortBy={`teacher`}>
                                    Docent
                                </TableHeaderItem>
                            </TableHeader>
                            {loading ? (
                                <TableRow key={`loading`}>
                                    <TableCell colSpan={6} isLoading />
                                </TableRow>
                            ) : lessons.length > 0 ? (
                                this.renderLessonsRows(lessons, group)
                            ) : (
                                <TableRow key={`emptyresult`}>
                                    <TableCell colSpan={6}>
                                        <Subtle>Er zijn geen lessen gevonden.</Subtle>
                                    </TableCell>
                                </TableRow>
                            )}
                        </Table>
                    </TableView>
                </TableWrap>
            </View>
        )
    }

    renderLessonsRows(lessons, group) {
        return lessons.map((lesson, index) => {
            return (
                <TableRow key={lesson._id}>
                    <TableCell hasInlineIcon>
                        {group.endedAt ? (
                            <span>Les {index + 1}</span>
                        ) : (
                            <ModalManager
                                render={openModal => <Link onClick={openModal}>Les {index + 1}</Link>}
                                getModal={closeModal => {
                                    return (
                                        <DrawerModal onClose={closeModal}>
                                            <ChangeAttendanceForm
                                                getRouteForUserId={userId => `/groups/${group._id}/learners/${userId}`}
                                                group={group}
                                                lesson={lesson}
                                                onDone={() => this.onLessonEdit(closeModal)}
                                            />
                                        </DrawerModal>
                                    )
                                }}
                            />
                        )}
                        {lesson.specialActivity && (
                            <Label>{translateType('lessonSpecialActivity', lesson.specialActivity)}</Label>
                        )}
                        {lesson.areAllAttendancesSet && <Icon name={`status_done`} color={'#2bc572'} />}
                    </TableCell>
                    <TableCell>{lesson.date && <ReadableDate date={lesson.date} showTime showDayOfWeek />}</TableCell>
                    <TableCell>
                        {lesson.location && !lesson.room && get(lesson.location, 'name')}
                        {lesson.room && `${lesson.room.name} te ${get(lesson.room.location, 'name')}`}
                    </TableCell>
                    <TableCell>{lesson.teacherUser && get(lesson.teacherUser.profile, 'name')}</TableCell>
                </TableRow>
            )
        })
    }
}

const GET_GROUP_LESSONS_QUERY = gql`
    query _($filters: GroupsFilterInputType) {
        groups(filters: $filters) {
            _id
            endedAt
            lessons {
                _id
                date
                areAllAttendancesSet
                specialActivity
                location {
                    _id
                    name
                }
                room {
                    _id
                    name
                    location {
                        _id
                        name
                    }
                }
                teacherUser {
                    _id
                    profile {
                        name
                    }
                }
            }
        }
    }
`

const CREATE_LEARNERS_LESSON_ATTENDANCE_FILE = gql`
    mutation _($groupId: MongoID!) {
        users_createLessonAttendanceFile(groupId: $groupId) {
            fileId
        }
    }
`

const CREATE_LEARNERS_LESSON_TIMETABLE_FILE = gql`
    mutation _($groupId: MongoID!) {
        users_createLessonTimetableFile(groupId: $groupId) {
            fileId
        }
    }
`
