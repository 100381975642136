import gql from 'graphql-tag'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, FieldSeparator, Form, Input, Paragraph } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator, transformFormFields } from '~/utils'

export class SendInvoiceWithMissingEmailForm extends Component {
    static propTypes = {
        invoice: PropTypes.shape({
            _id: PropTypes.string.isRequired,
            user: PropTypes.shape({
                _id: PropTypes.string.isRequired,
            }).isRequired,
        }).isRequired,
        onCancel: PropTypes.func,
        onSubmitSuccess: PropTypes.func,
    }

    state = {
        primaryEmailValue: '',
        isPrimaryEmailValid: false,
        secondaryEmailValue: '',
        isSecondaryEmailValid: false,
    }

    constructor(props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: EDIT_USER_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = async (event, fields, opts) => {
        const { onSubmitSuccess, invoice } = this.props
        const { confirmManualSend = false } = opts || {}

        if (!confirmManualSend && this.isEmailValid()) {
            const data = await this.userMutator.mutate({
                user: {
                    _id: invoice.user._id,
                    ...transformFormFields(fields.user, {
                        profile: {
                            fields: v => transformFormFields(v),
                        },
                    }),
                },
            })

            if (!data.users_edit) {
                return
            }
        }

        if (onSubmitSuccess) {
            onSubmitSuccess({ confirmManualSend })
        }
    }

    isEmailValid() {
        const { isPrimaryEmailValid, primaryEmailValue, isSecondaryEmailValid, secondaryEmailValue } = this.state

        return (
            (isPrimaryEmailValid && primaryEmailValue.length > 0) ||
            (isSecondaryEmailValid && secondaryEmailValue.length > 0)
        )
    }

    onChangePrimaryEmail = event => {
        const { value } = event.target

        this.setState({
            primaryEmailValue: value,
            isPrimaryEmailValid: event.target.checkValidity(),
        })
    }

    onChangeSecondaryEmail = event => {
        const { value } = event.target

        this.setState({
            secondaryEmailValue: value,
            isSecondaryEmailValid: event.target.checkValidity(),
        })
    }

    render() {
        const { onCancel } = this.props
        const { errors, loading: isSubmitting } = this.userMutator

        // Store the form reference so we can pass the fields
        // to the endGroup method.
        let formRef

        return (
            <Form ref={ref => (formRef = ref)}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field>
                            <Paragraph>
                                Factuur kan niet direct verzonden worden, omdat er een e-mailadres mist. Voer een
                                e-mailadres in en deze wordt automatisch opgeslagen in het profiel.
                            </Paragraph>
                        </Field>
                        <FieldSeparator />
                        <Field isLabel title={`Primair e-mailadres`} errors={errors}>
                            <Input
                                type={`email`}
                                name={`user.email`}
                                placeholder={`E-mailadres van de kandidaat`}
                                onChange={this.onChangePrimaryEmail}
                            />
                        </Field>
                        <Field isLabel title="Secundair e-mailadres" errors={errors}>
                            <Input
                                type={`email`}
                                name={`user.profile.alternativeEmail`}
                                placeholder={``}
                                onChange={this.onChangeSecondaryEmail}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    styleOverride={`secondary-submit`}
                                    isLoading={isSubmitting}
                                    shouldPreventSubmit
                                    onClick={() => {
                                        this.onSubmit(undefined, formRef.getFormValues(), { confirmManualSend: true })
                                    }}
                                    confirm={{
                                        title: 'Definitief maken en downloaden',
                                        message:
                                            'Weet je zeker dat je de factuur definitief wilt maken? De factuur moet handmatig naar de kandidaat verzonden worden.',
                                        execute: {
                                            buttonType: 'submit',
                                            title: 'Definitief maken en downloaden',
                                        },
                                    }}
                                    isDisabled={this.isEmailValid()}
                                >
                                    Geen e-mailadres bekend
                                </Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    type={`submit`}
                                    isLoading={isSubmitting}
                                    shouldPreventSubmit
                                    onClick={() => {
                                        this.onSubmit(undefined, formRef.getFormValues())
                                    }}
                                    confirm={{
                                        title: 'Factuur versturen',
                                        message: 'Weet je zeker dat je de factuur definitief wilt maken en versturen?',
                                        execute: {
                                            buttonType: 'submit',
                                            title: 'Factuur versturen',
                                        },
                                    }}
                                    isDisabled={!this.isEmailValid()}
                                >
                                    Factuur versturen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`
