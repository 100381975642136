import gql from 'graphql-tag'
import { get } from 'lodash'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker, TagPickerOption } from '~/components/TagPicker'
import { Fetcher, Mutator, Filter } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { ContractProjectType } from '~/types/Contracts'
import { Program } from '~/types/Program'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed: () => void
    onCancel: () => void
}

interface State {
    projectType: ContractProjectType | null
    selectedProgramIds: string[]
}

export default class CreateProjectForm extends Component<Props, State> {
    public state: State = {
        projectType: null,
        selectedProgramIds: [],
    }

    private projectMutator: Mutator
    private programsFetcher: Fetcher
    private programsFilter: Filter

    constructor(props: Props) {
        super(props)

        this.projectMutator = new Mutator({
            mutation: CREATE_PROJECT_MUTATION,
            reactComponentToUpdate: this,
        })

        this.programsFilter = new Filter({
            allowedKeys: ['filterByProjectType'],
            onChange: (filters: any) => {
                this.programsFetcher.refetch({
                    silent: true,
                    filters: {
                        ...filters,
                    },
                } as any)
            },
        } as any)

        this.programsFetcher = new Fetcher({
            query: GET_PROGRAMS_QUERY,
            variables: {
                filters: {
                    ...this.programsFilter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { errors, loading: mutateLoading } = this.projectMutator
        const { onCancel } = this.props
        const { loading, data } = this.programsFetcher
        const { programs } = data
        const { projectType, selectedProgramIds } = this.state

        const programsOptions = (programs || []).map((program: Program) => ({
            value: program._id,
            label: program.name,
        }))

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input name={`project.name`} type={`text`} placeholder={`Projectnaam`} autoFocus={true} />
                        </Field>
                        <Field title={'Type project'} errors={errors}>
                            <TagPicker
                                name={'project.projectType'}
                                placeholder={`Selecteer een type`}
                                options={[
                                    { label: 'Inburgering', value: ContractProjectType.Integration },
                                    { label: 'Organisatie', value: ContractProjectType.Organization },
                                    { label: 'Overig', value: ContractProjectType.Other },
                                ]}
                                multi={false}
                                onChange={this.onProjectTypeChange}
                            />
                        </Field>
                        <Field isLabel title={`E-mailadres projectteam`} errors={errors}>
                            <Input type="email" name={`project.lessonInviteReplyTo`} placeholder={`E-mailadres`} />
                        </Field>
                        {projectType && (
                            <FieldGroup isInsetGroup={true}>
                                <Field isLabel title={`Opleidingen`}>
                                    <TagPicker
                                        name={`project.programIds`}
                                        options={programsOptions}
                                        placeholder="Selecteer opleidingen"
                                        onChange={this.onProgramChange}
                                        isLoading={loading}
                                        value={selectedProgramIds}
                                    />
                                </Field>
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={mutateLoading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.projectMutator
            .mutate({
                project: transformFormFields(fields.project, {
                    programIds: transformLib.split(),
                }),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/projects/${get(data, 'projects_create._id')}`)
                } else {
                    if (onSubmitFailed) {
                        onSubmitFailed()
                    }
                }
            })
    }

    private onProjectTypeChange = (option: any) => {
        this.setState(
            {
                projectType: option.value,
                selectedProgramIds: [],
            },
            () => {
                this.programsFilter.apply('filterByProjectType', option.value)
            }
        )
    }

    private onProgramChange = (options: TagPickerOption[]) => {
        this.setState({
            selectedProgramIds: options.map(option => option.value),
        })
    }
}

const GET_PROGRAMS_QUERY = gql`
    query _($filters: ProgramsFiltersInputType) {
        programs(filters: $filters) {
            _id
            name
        }
    }
`

const CREATE_PROJECT_MUTATION = gql`
    mutation _($project: ProjectInputType!) {
        projects_create(project: $project) {
            _id
            name
        }
    }
`
