import * as React from 'react'
import { View } from '~/components/View'
import Header from '~/components/Header'
import { BreadCrumbs } from '~/components/BreadCrumbs'
import { ContentView } from '~/components/ContentView'
import { Wrap } from '~/components/Wrap'
import ActionBar from '~/components/ActionBar'
import {
    Link,
    TableWrap,
    TableHeader,
    TableHeaderItem,
    TableRow,
    Subtle,
    Button,
    Search,
    ReadablePhoneNumbers,
    CenterModal,
} from '~/components'
import { TableView } from '~/components/TableView'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import gql from 'graphql-tag'
import Fetcher from '~/utils/Fetcher'
import { getCurrentUser } from '~/services/session'
import Filter from '~/utils/Filter'
import removeDuplicateDocuments from '~/utils/removeDuplicateDocuments'
import { Sorter } from '~/utils'
import { PhoneNumber } from '~/types/User'
import { ModalManager } from '~/components/ModalManager'
import CreateOrganizationContactForm from '~/forms/CreateOrganizationContactForm'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const GET_USERS_QUERY = gql`
    query _($filters: UsersFilterInputType, $limit: Int, $skip: Int, $sortBy: String, $sortDir: String) {
        users(filters: $filters, limit: $limit, skip: $skip, sortBy: $sortBy, sortDir: $sortDir) {
            _id
            profile {
                name
                phoneNumbers {
                    _id
                    country
                    internationalPhoneNumber
                    phoneNumber
                    info
                }
            }
            email
        }
    }
`

interface OrganizationContactColleagueUser {
    _id: string
    profile: {
        name: string
        phoneNumbers?: PhoneNumber[]
    }
    email?: string
}

const STATIC_ORGANIZATION_CONTACT_USERS_FILTERS = {
    roles: ['ORGANIZATION_CONTACT'],
}

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'profile.name'
const DEFAULT_SORT_DIR = 'ASC'

interface Props {}

export class AppOrganizationContactColleaguesMaster extends React.Component<Props> {
    private usersFetcher: Fetcher
    private paginator: Paginator
    private filter: Filter
    private sorter: Sorter

    constructor(props: Props) {
        super(props)

        const currentUser = getCurrentUser()
        const organizationId =
            currentUser &&
            currentUser.organizationContact &&
            currentUser.organizationContact.organization &&
            currentUser.organizationContact.organization._id

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.onLoadMore,
        })

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.onSort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: ['searchText'],
            onChange: (filters: any) => {
                this.usersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_ORGANIZATION_CONTACT_USERS_FILTERS,
                        filterByContactOrganizationIds: [organizationId],
                        ...filters,
                    },
                } as any)
            },
            customTransformers: [],
        } as any)

        this.usersFetcher = new Fetcher({
            query: GET_USERS_QUERY,
            variables: {
                filters: {
                    ...STATIC_ORGANIZATION_CONTACT_USERS_FILTERS,
                    filterByContactOrganizationIds: [organizationId],
                    ...this.filter.getFilters(),
                },
                limit: START,
                skip: 0,
                sortDir: DEFAULT_SORT_DIR,
                sortBy: DEFAULT_SORT_BY,
            },

            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })
    }

    public render() {
        const { data, loading } = this.usersFetcher
        const { users = [] } = data

        return (
            <View>
                <Header>
                    <BreadCrumbs />
                </Header>
                <ContentView>
                    <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                        <Wrap full={true}>
                            <ActionBar getButtons={this.renderActionBarButtons} />
                        </Wrap>
                        <TableWrap>
                            <TableView>
                                <Table>
                                    <TableHeader>
                                        <TableHeaderItem sorter={this.sorter} sortBy={`profile.name`}>
                                            Naam
                                        </TableHeaderItem>
                                        <TableHeaderItem sorter={this.sorter} sortBy={`email`}>
                                            E-mail
                                        </TableHeaderItem>
                                        <TableHeaderItem>Telefoonnummer</TableHeaderItem>
                                    </TableHeader>
                                    {loading ? (
                                        <TableRow key={`loading`}>
                                            <TableCell colSpan={3} isLoading={true} />
                                        </TableRow>
                                    ) : users.length > 0 ? (
                                        this.renderColleagueRows(users)
                                    ) : (
                                        <TableRow key={`emptyresult`}>
                                            <TableCell colSpan={3}>
                                                <Subtle>Er zijn geen collega&apos;s gevonden.</Subtle>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
            </View>
        )
    }

    private renderActionBarButtons = () => {
        const { loading } = this.usersFetcher
        const filters = this.filter.getFilters()

        return (
            <List horizontal={true}>
                <ListItem>
                    <Search
                        placeholder={'Zoeken'}
                        isLoading={loading}
                        onSearch={this.onSearch}
                        defaultValue={filters.searchText}
                    />
                </ListItem>
                <ListItem right={true}>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type={`edit`}>
                                Contactpersoon toevoegen
                            </Button>
                        )}
                        getModal={closeModal => (
                            <CenterModal onClose={closeModal} title={`Contactpersoon toevoegen`}>
                                <CreateOrganizationContactForm
                                    isForCurrentUserHisOrganization={true}
                                    onSubmitSuccess={closeModal}
                                    onCancel={closeModal}
                                />
                            </CenterModal>
                        )}
                    />
                </ListItem>
            </List>
        )
    }

    private renderColleagueRows = (users: OrganizationContactColleagueUser[]) => {
        return users.map(user => {
            const { _id, email, profile } = user
            const { name, phoneNumbers } = profile
            const phoneNumber = phoneNumbers && phoneNumbers[0]

            return (
                <TableRow key={_id}>
                    <TableCell>
                        <Link route={`/colleagues/${_id}`}>{name}</Link>
                    </TableCell>
                    <TableCell>{email}</TableCell>
                    <TableCell>{phoneNumber && <ReadablePhoneNumbers phoneNumbers={[phoneNumber]} />}</TableCell>
                </TableRow>
            )
        })
    }

    private onLoadMore = (skip: number, limit: number, callback: (props?: any) => void) => {
        this.usersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData: any, moreData: any) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        } as any)
    }

    private onSort = ({ sortDir, sortBy }: { sortDir: string; sortBy: string }) => {
        this.usersFetcher.refetch({
            sortBy,
            sortDir,
            silent: true,
        } as any)
    }

    private onSearch = ({ searchText }: { searchText: string }) => {
        this.filter.apply('searchText', searchText)
    }
}
