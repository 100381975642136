import gql from 'graphql-tag'
import { get } from 'lodash'
import React, { Component } from 'react'
import { browserHistory } from 'react-router'

import { Button, Field, FieldCollection, Form, Input, Spinner } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher, Mutator, Filter } from '~/utils'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import { ContractProjectType, ContractType } from '~/types/Contracts'
import { Module, ModuleIntegrationType } from '~/types/Module'
import { translateType } from '~/shared/utils'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed: () => void
    onCancel: () => void
}

interface State {
    projectType: ContractProjectType | null
    integrationType: ContractType | null
}

export default class CreateProgramForm extends Component<Props, State> {
    public state: State = {
        projectType: null,
        integrationType: null,
    }

    private programMutator: Mutator
    private modulesFetcher: Fetcher
    private modulesFilter: Filter

    constructor(props: Props) {
        super(props)

        this.programMutator = new Mutator({
            mutation: CREATE_PROGRAM_MUTATION,
            reactComponentToUpdate: this,
        })

        this.modulesFilter = new Filter({
            allowedKeys: ['filterByProjectType', 'filterByIntegrationType'],
            onChange: (filters: any) => {
                this.modulesFetcher.refetch({
                    silent: true,
                    filters: {
                        ...filters,
                    },
                } as any)
            },
        } as any)

        this.modulesFetcher = new Fetcher({
            query: GET_MODULES_QUERY,
            variables: {
                filters: {
                    ...this.modulesFilter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
        })
    }

    public render() {
        const { errors, loading: mutateLoading } = this.programMutator
        const { onCancel } = this.props
        const { projectType } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isLabel title={`Naam`} errors={errors}>
                            <Input
                                name={`program.name`}
                                type={`text`}
                                placeholder={`Naam opleiding`}
                                autoFocus={true}
                            />
                        </Field>
                        <Field>
                            <Field title={'Type project'} errors={errors}>
                                <TagPicker
                                    name={'program.projectType'}
                                    placeholder={`Selecteer een type`}
                                    options={[
                                        { label: 'Inburgering', value: ContractProjectType.Integration },
                                        { label: 'Organisatie', value: ContractProjectType.Organization },
                                        { label: 'Overig', value: ContractProjectType.Other },
                                    ]}
                                    multi={false}
                                    onChange={this.onProjectTypeChange}
                                />
                            </Field>
                        </Field>
                        {projectType && (
                            <FieldGroup isForm isInsetGroup={true}>
                                {projectType === ContractProjectType.Integration && (
                                    <Field title={'Type inburgering'} errors={errors}>
                                        <TagPicker
                                            name={'program.integrationType'}
                                            placeholder={`Selecteer een type`}
                                            options={Object.values(ModuleIntegrationType).map(value => ({
                                                label: translateType('lessonModuleIntegrationType', value),
                                                value: value,
                                            }))}
                                            multi={false}
                                            onChange={this.onIntegrationTypeChange}
                                        />
                                    </Field>
                                )}
                                {this.renderModulesField(errors)}
                            </FieldGroup>
                        )}
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={mutateLoading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private renderModulesField = (errors: any) => {
        const { loading, data } = this.modulesFetcher
        const { projectType, integrationType } = this.state
        const { modules } = data

        if (!projectType) {
            return null
        }

        const modulesOptions = (modules || []).map((module: Module) => ({
            value: module._id,
            label: module.name,
        }))

        return (
            <Field isLabel title={`Lesmodules`} errors={errors}>
                {loading ? (
                    <Spinner size={'small'} />
                ) : (
                    <TagPicker
                        name={`program.moduleIds`}
                        options={modulesOptions}
                        placeholder={integrationType === ContractType.ONA ? 'ONA lesmodules' : 'Lesmodules'}
                    />
                )}
            </Field>
        )
    }

    private onSubmit = (event: any, fields: any) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        this.programMutator
            .mutate({
                program: transformFormFields(fields.program, {
                    moduleIds: transformLib.split(),
                }),
            })
            .then(data => {
                if (data) {
                    onSubmitSuccess()
                    browserHistory.push(`/properties/programs/${get(data, 'programs_create._id')}`)
                } else {
                    if (onSubmitFailed) {
                        onSubmitFailed()
                    }
                }
            })
    }

    private onProjectTypeChange = (option: any) => {
        this.setState(
            {
                projectType: option.value,
                integrationType: null,
            },
            () => {
                this.modulesFilter.apply('filterByProjectType', option.value)
                this.modulesFilter.apply('filterByIntegrationType', this.state.integrationType)
            }
        )
    }

    private onIntegrationTypeChange = (option: any) => {
        this.setState(
            {
                integrationType: option.value || null,
            },
            () => this.modulesFilter.apply('filterByIntegrationType', this.state.integrationType)
        )
    }
}

const GET_MODULES_QUERY = gql`
    query _($filters: ModulesFiltersInputType) {
        modules(filters: $filters) {
            _id
            name
        }
    }
`

const CREATE_PROGRAM_MUTATION = gql`
    mutation _($program: ProgramInputType!) {
        programs_create(program: $program) {
            _id
            name
        }
    }
`
