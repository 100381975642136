import gql from 'graphql-tag'
import React from 'react'
import { Button, Field, FieldCollection, Form } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator, toast } from '~/utils'
import { User } from '~/types/User'

interface Props {
    onCancel: () => void
    onSubmitSuccess: () => void
    user: User
    type: 'employeeToTeacher' | 'teacherToEmployee'
}

export default class EmployeeExternalTeacherForm extends React.Component<Props> {
    private employeeToExternalTeacherMutator: Mutator
    private externalTeachertoEmployeeMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.employeeToExternalTeacherMutator = new Mutator({
            mutation: EMPLOYEE_TO_EXTERNAL_TEACHER_MUTATION,
            reactComponentToUpdate: this,
        })

        this.externalTeachertoEmployeeMutator = new Mutator({
            mutation: EXTERNAL_TEACHER_TO_EMPLOYEE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    private onSubmit = (): void => {
        const { user, onSubmitSuccess, type } = this.props

        if (type === 'employeeToTeacher') {
            this.employeeToExternalTeacherMutator
                .mutate({
                    userId: user._id,
                })
                .then(data => {
                    if (data) {
                        onSubmitSuccess()
                        toast.success('Succesvol gewijzigd naar externe docent.')
                    }
                })
        } else {
            this.externalTeachertoEmployeeMutator
                .mutate({
                    userId: user._id,
                })
                .then(data => {
                    if (data) {
                        onSubmitSuccess()
                        toast.success('Succesvol gewijzigd naar medewerker.')
                    }
                })
        }
    }

    public render() {
        const { user, type, onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup>
                        <Field>{`Je staat op het punt ${user.profile.name} om te zetten naar een ${
                            type === 'employeeToTeacher' ? 'externe docent' : 'medewerker'
                        }. Wil je doorgaan?`}</Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`}>Breng de wijziging aan</Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EMPLOYEE_TO_EXTERNAL_TEACHER_MUTATION = gql`
    mutation _($userId: MongoID!) {
        employee_to_external_teacher(userId: $userId) {
            _id
        }
    }
`

const EXTERNAL_TEACHER_TO_EMPLOYEE_MUTATION = gql`
    mutation _($userId: MongoID!) {
        external_teacher_to_employee(userId: $userId) {
            _id
        }
    }
`
