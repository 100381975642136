import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import {
    Bold,
    Button,
    CheckBox,
    EdisaImportResult,
    Field,
    FieldCollection,
    FileInput,
    Form,
    Icon,
    Input,
    MultiInput,
    Paragraph,
    RadioButton,
    ReadableDate,
    Spinner,
    TableHeader,
    TableHeaderItem,
} from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TableCell } from '~/components/TableCell'
import EdisaImportTable, { EdisaImportTableRow } from '~/implementations/EdisaImportTable'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'

export default class ImportLearnersForm extends Component {
    static propTypes = {
        onCancel: PropTypes.func.isRequired,
    }

    state = {
        hasFinishedImport: false,
        importResults: undefined,
        useAlternativeStartingPoint: undefined,
        isAlternativeStartingPointConfirmed: undefined,
        alternativeStartingPoint: undefined,
        isResetIntakeDoneByUserId: {},
    }

    constructor(props) {
        super(props)

        bindAll(this, [
            'onSubmitImport',
            'resetIntakeForUserId',
            'onChangeUseAlternativeStartingPoint',
            'onChangeAlternativeStartingPoint',
            'onChangeConfirmImportRowFrom',
            'onChangeFile',
            'renderResultRow',
        ])

        this.lastImportRowFetcher = new Fetcher({
            query: LAST_IMPORT_ROW_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.importMutator = new Mutator({
            mutation: IMPORT_LEARNERS_FROM_EDISA_MUTATION,
            reactComponentToUpdate: this,
        })

        this.resetIntakeMutator = new Mutator({
            mutation: LEARNERS_RESET_INTAKE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    async onSubmitImport(event, fields) {
        const data = await this.importMutator.mutate({
            edisaImport: {
                ...transformFormFields(fields.edisaImport),
            },
        })

        if (data) {
            const { users_importFromEdisaFile } = data

            this.setState({
                hasFinishedImport: true,
                importedRows: users_importFromEdisaFile.rows,
            })
        }
    }

    async resetIntakeForUserId(userId) {
        const data = await this.resetIntakeMutator.mutate({
            learnerUserId: userId,
        })

        if (data) {
            this.state.isResetIntakeDoneByUserId[userId] = true
            this.forceUpdate()
        }
    }

    onChangeUseAlternativeStartingPoint(event) {
        const { value } = event.currentTarget

        this.setState({
            useAlternativeStartingPoint: value === 'true',
        })
    }

    onChangeConfirmImportRowFrom(event) {
        const { checked } = event.currentTarget

        this.setState({
            isAlternativeStartingPointConfirmed: checked,
        })
    }

    onChangeAlternativeStartingPoint(event) {
        const { value } = event.currentTarget
        const row = parseInt(value, 10)

        this.setState({
            alternativeStartingPoint: row,
        })
    }

    onChangeFile(event) {
        const { value } = event.currentTarget

        this.setState({
            isFileSelected: !!value,
        })
    }

    render() {
        const { hasFinishedImport } = this.state

        if (hasFinishedImport) {
            return this.renderImportResults()
        }

        return this.renderImportForm()
    }

    renderImportForm() {
        const {
            data: { edisaImports_lastImport: lastImport },
            loading: lastImportRowLoading,
        } = this.lastImportRowFetcher
        const { errors, loading } = this.importMutator
        const { onCancel } = this.props
        const {
            useAlternativeStartingPoint: _useAlternativeStartingPoint,
            alternativeStartingPoint,
            isAlternativeStartingPointConfirmed,
            isFileSelected,
        } = this.state

        const useAlternativeStartingPoint = _useAlternativeStartingPoint || !lastImport

        return (
            <Form onSubmit={this.onSubmitImport}>
                <FieldCollection style={`modal`}>
                    {(loading || lastImportRowLoading) && (
                        <FieldGroup isForm>
                            <Field isCompact>
                                <Spinner />
                            </Field>
                        </FieldGroup>
                    )}
                    {!loading && (
                        <FieldGroup isForm separateFields>
                            <Field isCompact errors={errors}>
                                <FileInput name={`edisaImport.file`} onChange={this.onChangeFile}>
                                    Selecteer Edisa bestand
                                </FileInput>
                            </Field>
                            {!isFileSelected && (
                                <Field isCompact>
                                    <Paragraph>Meer uitleg volgt na het selecteren van het bestand.</Paragraph>
                                </Field>
                            )}
                            {isFileSelected && !lastImport && (
                                <Field isCompact>
                                    <Paragraph>
                                        Er is geen informatie bekend over de vorige import. Dit betekent dat je eenmalig
                                        handmatig het startpunt voor deze import moet aangeven.
                                    </Paragraph>
                                </Field>
                            )}
                            {isFileSelected && lastImport && (
                                <Field isCompact>
                                    <Paragraph>
                                        Bij deze import wordt er gekeken naar het laatste regelnummer van de vorige
                                        import. Deze vond plaats op{' '}
                                        <ReadableDate date={lastImport.importedAt} showTime /> door{' '}
                                        {get(lastImport.importedByUser, 'profile.name')}. De laatste regel van de vorige
                                        import was:
                                    </Paragraph>
                                    <EdisaImportResult edisaImport={lastImport} />
                                </Field>
                            )}
                            {isFileSelected && lastImport && (
                                <Field isCompact>
                                    <Paragraph>
                                        Het startpunt voor deze import wordt regel{' '}
                                        {lastImport.lastRow.excelRowNumber + 1}. Wil je een ander regelnummer aangeven
                                        als startpunt voor deze import?
                                    </Paragraph>
                                    <MultiInput type="radio">
                                        <RadioButton
                                            name={`useAlternativeStartingPoint`}
                                            value={true}
                                            onChange={this.onChangeUseAlternativeStartingPoint}
                                            defaultChecked={useAlternativeStartingPoint === true}
                                        >
                                            Ja
                                        </RadioButton>
                                        <RadioButton
                                            name={`useAlternativeStartingPoint`}
                                            value={false}
                                            onChange={this.onChangeUseAlternativeStartingPoint}
                                            defaultChecked={useAlternativeStartingPoint === false}
                                        >
                                            Nee
                                        </RadioButton>
                                    </MultiInput>
                                </Field>
                            )}
                            {isFileSelected && useAlternativeStartingPoint && (
                                <Field isCompact errors={errors}>
                                    <Paragraph>Vanaf welke regel moeten de kandidaten geïmporteerd worden?</Paragraph>
                                    <Input
                                        type={`number`}
                                        style={{ width: '80px' }}
                                        onBlur={this.onChangeAlternativeStartingPoint}
                                        defaultValue={alternativeStartingPoint}
                                        name="edisaImport.alternativeStartingPoint"
                                        min={1}
                                    />
                                </Field>
                            )}
                            {isFileSelected && useAlternativeStartingPoint && !!alternativeStartingPoint && (
                                <Field isCompact>
                                    <Paragraph>De import kan niet ongedaan gemaakt worden.</Paragraph>
                                    <CheckBox
                                        onChange={this.onChangeConfirmImportRowFrom}
                                        defaultChecked={isAlternativeStartingPointConfirmed === true}
                                    >
                                        Ik bevestig dat de kandidaten{' '}
                                        <Bold isHeavy>vanaf Excel regel {alternativeStartingPoint}</Bold> geïmporteerd
                                        moeten worden.
                                    </CheckBox>
                                </Field>
                            )}
                        </FieldGroup>
                    )}
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button
                                    isLoading={loading}
                                    type="submit"
                                    isDisabled={useAlternativeStartingPoint && !isAlternativeStartingPointConfirmed}
                                >
                                    Importeren
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    renderImportResults() {
        const { importedRows } = this.state

        const existingRows = importedRows.filter(r => r.isExisting)

        const failedRows = importedRows.filter(r => r.hasFailed)

        return (
            <Form onSubmit={this.onSubmitResetIntake}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field isCompact>
                            <Paragraph isSuccessMessage>
                                Er zijn <strong>{importedRows.length - failedRows.length} kandidaten</strong>{' '}
                                geïmporteerd.
                            </Paragraph>
                            <Paragraph>
                                {existingRows.length > 0 && (
                                    <span>
                                        {' '}
                                        Hiervan zijn {existingRows.length} kandidaten bestaand. Geef aan of deze opnieuw
                                        een intake moeten doen.
                                    </span>
                                )}
                            </Paragraph>
                            {failedRows.length > 0 && (
                                <Paragraph>
                                    {' '}
                                    <strong>Let op!</strong> Sommige regels konden <em>niet geïmporteerd</em> worden.
                                    Deze regelnummers zijn {failedRows.map(r => r.excelRowNumber).join(', ')}.
                                </Paragraph>
                            )}
                        </Field>
                        {importedRows.length > 0 && (
                            <Field isCompact>
                                <EdisaImportTable hasAutoLayout>
                                    <TableHeader>
                                        <TableHeaderItem>Regel</TableHeaderItem>
                                        <TableHeaderItem>Naam</TableHeaderItem>
                                        <TableHeaderItem>Edisa nummer</TableHeaderItem>
                                        <TableHeaderItem>BSN</TableHeaderItem>
                                        <TableHeaderItem>Actie</TableHeaderItem>
                                    </TableHeader>
                                    {importedRows.map(this.renderResultRow)}
                                </EdisaImportTable>
                            </Field>
                        )}
                    </FieldGroup>
                </FieldCollection>
            </Form>
        )
    }

    renderResultRow(row, i) {
        const intakeHasBeenReset = this.state.isResetIntakeDoneByUserId[row.userId]

        return (
            <EdisaImportTableRow key={i} importHasFailed={row.hasFailed} intakeHasBeenReset={intakeHasBeenReset}>
                <TableCell>{row.excelRowNumber}</TableCell>
                <TableCell>{row.name}</TableCell>
                <TableCell>{row.edisaNumber}</TableCell>
                <TableCell>{row.bsn}</TableCell>
                {row.hasFailed && (
                    <TableCell>
                        <Icon name="status_attention" />
                        Import mislukt
                    </TableCell>
                )}
                {!row.hasFailed && (
                    <TableCell>
                        {row.isExisting
                            ? intakeHasBeenReset
                                ? this.renderResetIntakeSuccess(row)
                                : this.renderResetIntakeButton(row)
                            : 'Nieuwe kandidaat'}
                    </TableCell>
                )}
            </EdisaImportTableRow>
        )
    }

    renderResetIntakeButton(row) {
        return (
            <Button
                leftIcon={<Icon name="replay" />}
                confirm={{
                    title: 'Intakeresultaten verwijderen',
                    message: (
                        <span>
                            Hiermee worden alle intakeresultaten van <Bold>{row.name}</Bold> verwijderd.
                        </span>
                    ),
                    execute: {
                        buttonType: 'danger',
                        title: 'Reset intake',
                    },
                }}
                onClick={() => this.resetIntakeForUserId(row.userId)}
            >
                Reset intake
            </Button>
        )
    }

    renderResetIntakeSuccess() {
        return (
            <span>
                <Icon name={`checkmark`} />
                Intake gereset
            </span>
        )
    }
}

const LAST_IMPORT_ROW_QUERY = gql`
    query _ {
        edisaImports_lastImport {
            _id
            importedAt
            importedByUser {
                _id
                profile {
                    name
                }
            }
            lastRow {
                userId
                edisaNumber
                learnerName
                excelRowNumber
            }
        }
    }
`

const IMPORT_LEARNERS_FROM_EDISA_MUTATION = gql`
    mutation _($edisaImport: EdisaImportInputType!) {
        users_importFromEdisaFile(edisaImport: $edisaImport) {
            rows {
                isExisting
                hasFailed
                name
                edisaNumber
                bsn
                excelRowNumber
                userId
            }
        }
    }
`

const LEARNERS_RESET_INTAKE_MUTATION = gql`
    mutation _($learnerUserId: MongoID!) {
        users_resetIntake(learnerUserId: $learnerUserId) {
            _id
        }
    }
`
