import * as React from 'react'
import gql from 'graphql-tag'
import { VariableMultiField } from '~/components/Core/Form/VariableMultiField/VariableMultiField'
import { ModuleExam } from '~/types/Module'
import { Props as FieldProps } from '~/components/Field'
import Fetcher from '~/utils/Fetcher'
import { ModuleExamField } from '~/components/Module/ModuleExamField'
import { Exam } from '~/types/Exam'

interface Props extends FieldProps {
    title?: string
    name?: string
    defaultModuleExams?: ModuleExam[]
}

interface State {
    selectedModuleExamByKey: { [index: number]: ModuleExam | null }
}

export class ModuleExamsField extends React.Component<Props, State> {
    public state: State = {
        selectedModuleExamByKey: {},
    }

    private examsFetcher = new Fetcher({
        query: EXAMS_QUERY,
        onChange: () => this.forceUpdate(),
    })

    constructor(props: Props) {
        super(props)

        const { defaultModuleExams = [] } = this.props
        defaultModuleExams.forEach((defaultModuleExam, index) => {
            // TODO: Fix this issue and re-enable the 'react/no-direct-mutation-state' rule
            // eslint-disable-next-line react/no-direct-mutation-state
            this.state.selectedModuleExamByKey[index] = defaultModuleExam
        })
    }

    public render() {
        const { name, defaultModuleExams, errors, title } = this.props
        const { loading } = this.examsFetcher

        return (
            <VariableMultiField name={name} defaultValues={defaultModuleExams} addButtonLabel={'Toets toevoegen'}>
                {({ index, key, name, remove, defaultValue }) => (
                    <ModuleExamField
                        key={key}
                        errors={errors}
                        title={index === 0 ? title : ' '}
                        name={name}
                        exams={this.getSelectableExamsForKey(key)}
                        onRemove={
                            remove &&
                            (() => {
                                remove()
                                this.setSelectedModuleExam(null, key)
                            })
                        }
                        defaultModuleExam={defaultValue || null}
                        isLoading={loading}
                        onChange={moduleExam => this.setSelectedModuleExam(moduleExam, key)}
                    />
                )}
            </VariableMultiField>
        )
    }

    /**
     * Get exams filtered by 'not yet selected'
     */
    private getSelectableExamsForKey = (keyOfThisField: number): Exam[] => {
        const { selectedModuleExamByKey } = this.state
        const {
            data: { exams = [] },
        } = this.examsFetcher

        // Get keys of all selected module exams
        const examIdsSelectedInOtherFields = Object.keys(selectedModuleExamByKey)
            // ... filter those keys to only have "other" fields (based on given key in function)
            .filter(key => parseInt(key, 10) !== keyOfThisField)
            // ... map to examIds
            .map(key => selectedModuleExamByKey[key].examId)

        // Return left over (selectable) exams
        const selectableExams = (exams as Exam[]).filter(exam => {
            return !examIdsSelectedInOtherFields.includes(exam._id)
        })

        return selectableExams
    }

    /**
     * When the selected module exam is updated
     */
    private setSelectedModuleExam = (moduleExam: ModuleExam | null, index: number) => {
        this.setState(prevState => {
            const selectedModuleExamByKey = Object.assign({}, prevState.selectedModuleExamByKey, {
                [index]: moduleExam,
            })

            if (!moduleExam && index in selectedModuleExamByKey) {
                delete selectedModuleExamByKey[index]
            }

            return { selectedModuleExamByKey }
        })
    }
}

export const EXAMS_QUERY = gql`
    query _ {
        exams(sortBy: "name") {
            _id
            name
            type
        }
    }
`
