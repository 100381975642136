import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, CheckBox, Field, FieldCollection, Form, MultiInput } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { downloadFile, Mutator, toast } from '~/utils'

export class ExportGroupsForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        onCancel: PropTypes.func,
        groupsFilter: PropTypes.object,
    }

    constructor(props) {
        super(props)

        bindAll(this, ['checkLearners', 'checkTeachers', 'onSubmit'])

        this.state = {
            disableExportButton: false,
            exportLearners: true,
            exportTeachers: true,
        }

        this.createExportMutator = new Mutator({
            mutation: CREATE_USERS_EXPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    onSubmit = () => {
        const { onSubmitSuccess, onSubmitFailed, groupsFilter } = this.props
        const { exportLearners, exportTeachers } = this.state

        const columns = [
            'FirstName',
            'SurName',
            ...(exportLearners ? ['Bsn'] : []),
            'Email',
            ...(exportLearners
                ? [
                      'PhoneNumber',
                      'Groups',
                      'CityOfBirth',
                      'CountryOfBirth',
                      'DateOfBirth',
                      'HighestEducationLocal',
                      'HighestEducationLocalYear',
                      'HighestEducationCountryOfOrigin',
                      'HighestEducationCountryOfOriginYear',
                  ]
                : []),
            'Gender',
            'Country',
            'Street',
            'Number',
            'NumberAddition',
            'Zipcode',
            'City',
            'State',
            'AddressLine1',
            'AddressLine2',
            ...(exportTeachers ? ['IsNT2Certified', 'LessonCompetence'] : []),
            'CountryOfOrigin',
            'CountriesOfNationality',
            'HasOwnCompany',
            'FileNumbers',
            'TwinFieldCode',
            'Payment',
        ]

        this.createExportMutator
            .mutate({
                groupsFilter: groupsFilter,
                filterUsersByGroupTeachers: exportTeachers,
                filterUsersByGroupLearners: exportLearners,
                columns: columns,
            })
            .then(data => {
                if (data) {
                    const res = get(data, 'groups_createUsersExportFile')
                    if (res) {
                        if (res.fileId) {
                            downloadFile(res.fileId, 'groepen-gebruikers-export.xlsx')
                        } else {
                            toast.error('Geen gebruikers gevonden om te exporteren')
                            if (onSubmitFailed) onSubmitFailed()
                        }
                    } else {
                        toast.error('XLS downloaden mislukt')
                        if (onSubmitFailed) onSubmitFailed()
                    }

                    onSubmitSuccess()
                } else {
                    toast.error('XLS downloaden mislukt')
                    if (onSubmitFailed) onSubmitFailed()
                }
            })
    }

    checkLearners() {
        const exportLearners = !this.state.exportLearners
        const shouldDisableExport = !exportLearners && !this.state.exportTeachers

        this.setState({
            exportLearners: exportLearners,
            disableExportButton: shouldDisableExport,
        })
    }

    checkTeachers() {
        const exportTeachers = !this.state.exportTeachers
        const shouldDisableExport = !this.state.exportLearners && !exportTeachers

        this.setState({
            exportTeachers: exportTeachers,
            disableExportButton: shouldDisableExport,
        })
    }

    render() {
        const { onCancel } = this.props
        const { loading } = this.createExportMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm>
                        <Field title={`Wat wil je exporteren?`}>
                            <List horizontal>
                                <ListItem left>
                                    <MultiInput type={`checkbox`}>
                                        <CheckBox
                                            name={`learners`}
                                            onClick={this.checkLearners}
                                            checked={this.state.exportLearners}
                                        >
                                            Exporteer kandidaten
                                        </CheckBox>
                                        <CheckBox
                                            name={`teachers`}
                                            onClick={this.checkTeachers}
                                            checked={this.state.exportTeachers}
                                        >
                                            Exporteer docenten
                                        </CheckBox>
                                    </MultiInput>
                                </ListItem>
                            </List>
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading} isDisabled={this.state.disableExportButton}>
                                    Exporteren
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const CREATE_USERS_EXPORT_FILE_MUTATION = gql`
    mutation _(
        $filterUsersByGroupLearners: Boolean
        $filterUsersByGroupTeachers: Boolean
        $groupsFilter: GroupsFilterInputType
        $columns: [String!]
    ) {
        groups_createUsersExportFile(
            filterUsersByGroupLearners: $filterUsersByGroupLearners
            filterUsersByGroupTeachers: $filterUsersByGroupTeachers
            groupsFilter: $groupsFilter
            columns: $columns
        ) {
            fileId
        }
    }
`
