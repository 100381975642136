import gql from 'graphql-tag'
import * as React from 'react'
import { browserHistory } from 'react-router'
import { Button, Field, FieldCollection, Form, Input } from '~/components'
import { List } from '~/components/List'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { ListItem } from '~/components/ListItem'
import { FieldGroup } from '~/components/FieldGroup'
import { Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { UnitInput, unitType } from '~/components/UnitInput'

interface Props {
    onSubmitSuccess: () => void
    onSubmitFailed?: () => void
    onCancel?: () => void
}

interface CreateCourseMaterialFormFields {
    courseMaterial: {
        name: string
    }
}

export class CreateCourseMaterialForm extends React.Component<Props> {
    private courseMaterialMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.courseMaterialMutator = new Mutator({
            mutation: CREATE_COURSEMATERIALS_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    public render() {
        const { errors, loading } = this.courseMaterialMutator
        const { onCancel } = this.props

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <FieldGroup isForm={true}>
                        <Field isLabel={true} title={`Naam`} errors={errors}>
                            <Input
                                name={`courseMaterial.name`}
                                type={`text`}
                                placeholder={`Naam van lesmateriaal`}
                                autoFocus={true}
                            />
                        </Field>
                        <Field isLabel={true} title={`Bedrag`} errors={errors}>
                            <UnitInput
                                name={`courseMaterial.amount`}
                                placeholder={`Lesmateriaal bedrag`}
                                unitType={unitType.euro}
                            />
                        </Field>
                    </FieldGroup>
                    <FieldCollectionFooter>
                        <List horizontal={true}>
                            <ListItem right={true}>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right={true}>
                                <Button type={`submit`} isLoading={loading}>
                                    Toevoegen
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: CreateCourseMaterialFormFields) => {
        const { onSubmitSuccess, onSubmitFailed } = this.props

        const data = await this.courseMaterialMutator.mutate({
            courseMaterial: transformFormFields(fields.courseMaterial, {}),
        })

        if (data && data.courseMaterials_create && data.courseMaterials_create._id) {
            onSubmitSuccess()
            browserHistory.push(`/properties/courseMaterials/${data.courseMaterials_create._id}`)
        } else {
            if (onSubmitFailed) {
                onSubmitFailed()
            }
        }
    }
}

const CREATE_COURSEMATERIALS_MUTATION = gql`
    mutation _($courseMaterial: CourseMaterialInputType!) {
        courseMaterials_create(courseMaterial: $courseMaterial) {
            _id
            name
        }
    }
`
