import c from 'classnames'
import { times } from 'lodash'
import * as React from 'react'

import { Button } from '~/components'
import Icon from './Icon'
import MultiInput from './MultiInput'
import { isNumber } from 'util'

interface Props {
    addButtonLabel?: string
    baseAmount?: number
    className?: string
    defaultAmount?: number
    isDisabled?: boolean
    limit?: number
    amountOfPrefilledInputs?: number
    onAdd?: (event: React.SyntheticEvent<HTMLButtonElement>) => void
    onRemove?: (event: React.SyntheticEvent<HTMLButtonElement>, index: number) => void
    getNewInput: (iteration: number) => React.ReactNode
}

interface State {
    totalInputs: number
}

export class VariableMultiInput extends React.Component<Props, State> {
    public state: State = {
        totalInputs: isNumber(this.props.baseAmount) ? this.props.baseAmount : this.props.defaultAmount || 1,
    }

    public reset = () => {
        // Reset to the base amount of fields.
        const totalInputs = isNumber(this.props.baseAmount) ? this.props.baseAmount : this.props.defaultAmount || 1
        this.setState({
            totalInputs,
        })
    }

    public render() {
        const { addButtonLabel } = this.props

        return (
            <MultiInput className={this.getClassName()}>
                {this.renderInputs()}
                <Button
                    className={`tt-VariableMultiInput__add`}
                    isDisabled={this.isAddDisabled()}
                    type={`button`}
                    linkStyle={`default`}
                    onClick={this.onAddButtonClick}
                    leftIcon={<Icon name={`plus-bold`} />}
                >
                    {addButtonLabel}
                </Button>
            </MultiInput>
        )
    }

    private onAddButtonClick: React.MouseEventHandler<HTMLButtonElement> = event => {
        const { onAdd } = this.props
        const { totalInputs } = this.state

        this.setState({ totalInputs: totalInputs + 1 })

        if (onAdd) {
            onAdd(event)
        }
    }

    private onRemoveButtonClick = (index: number, event: React.SyntheticEvent<HTMLButtonElement>) => {
        const { onRemove } = this.props
        const { totalInputs } = this.state

        this.setState({ totalInputs: totalInputs - 1 })

        if (onRemove) {
            onRemove(event, index)
        }
    }

    private isAddDisabled = () => {
        const { isDisabled, limit } = this.props
        const { totalInputs } = this.state

        return isDisabled ? isDisabled : limit ? totalInputs === limit : undefined
    }

    private renderInputs = () => {
        const { getNewInput, baseAmount, amountOfPrefilledInputs } = this.props
        const { totalInputs } = this.state

        const firstButtonIndex = baseAmount ? baseAmount - 1 : 0

        return times(totalInputs, n => {
            const haveButton =
                !!(n > firstButtonIndex && n + 1 === totalInputs) || n === totalInputs - (amountOfPrefilledInputs || 0)

            return (
                <div
                    key={n}
                    className={c('tt-VariableMultiInput__item', {
                        'tt-VariableMultiInput__item--has-button': haveButton,
                    })}
                >
                    <div className={`tt-VariableMultiInput__item__content`}>{getNewInput(n)}</div>
                    {haveButton && (
                        <Button
                            className={`tt-VariableMultiInput__item__remove`}
                            type={`button`}
                            onClick={e => this.onRemoveButtonClick(n, e)}
                            leftIcon={<Icon name={`trash`} />}
                        />
                    )}
                </div>
            )
        })
    }

    private getClassName = () => {
        return c('tt-VariableMultiInput', this.props.className)
    }
}
