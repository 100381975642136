import * as React from 'react'
import { default as Select, createFilter } from 'react-select'
import { Props as SelectProps } from 'react-select/base'
import { default as CreatableReactSelect } from 'react-select/creatable'
import { ValueType } from 'react-select/src/types'
import { Row } from './Core/Layout/Row'
import { BEM } from '~/services/BEMService'
import { Icon } from '.'

type DefaultTData = any
type DefaultTValue = string

export interface TagPickerOption<TData = DefaultTData, TValue = DefaultTValue> {
    value: TValue
    label: React.ReactNode
    data?: TData
}

export type TagPickerOptions<TData = DefaultTData, TValue = DefaultTValue> = TagPickerOption<TData, TValue>[]

export type TagPickerChangeHandler<TData = DefaultTData, TValue = DefaultTValue> = (
    option: any,
    tagPicker: TagPicker<TData, TValue>
) => void

export interface TagPickerProps<TData = DefaultTData, TValue = DefaultTValue> {
    className?: string
    multi?: boolean
    defaultValue?: any
    onChange?: TagPickerChangeHandler<TData, TValue>
    onEndReached?: () => void
    onSearch?: (value: string) => void
    hasError?: boolean
    isDisabled?: boolean
    isReadonly?: boolean
    options?: TagPickerOptions<TData, TValue>
    placeholder?: string
    name?: string
    value?: any
    isClearable?: boolean
    errorKey?: string
    isLoading?: boolean
    required?: boolean
    creatable?: boolean
    createValue?: string
    disableBuiltInFiltering?: boolean
    creatableHandler?: (inputValue: string) => void
}

interface State {
    value?: any
}

interface OptionType {
    [key: string]: any
}
type SelectValueType = ValueType<OptionType, false>

export class TagPicker<TData = DefaultTData, TValue = DefaultTValue> extends React.Component<
    TagPickerProps<TData, TValue>,
    State
> {
    public static defaultProps: TagPickerProps<any, any> = {
        multi: true,
        isClearable: true,
    }

    public state: State = {}

    private bem = new BEM('TagPicker', () => ({
        'is-disabled': this.props.isDisabled,
        'is-readonly': this.props.isReadonly,
        'has-error': this.props.hasError,
        'is-creatable': this.props.creatable,
        'is-multi': !!this.props.multi,
    }))

    private isMulti = !!this.props.multi
    private isControlled = this.props.value !== undefined

    constructor(props: TagPickerProps<TData, TValue>) {
        super(props)

        if (this.isControlled && props.defaultValue !== undefined) {
            // tslint:disable-next-line:no-console
            console.error('Cannot use `defaultValue` and `value` at the same time.')
        }

        const defaultValue = this.isControlled ? props.value : props.defaultValue

        this.state = {
            value: defaultValue,
        }
    }

    public render() {
        const { className, name, errorKey } = this.props

        return (
            <div className={this.bem.getClassName(className)}>
                <span data-errorkey={errorKey || name} />
                {this.renderSelect()}
            </div>
        )
    }

    private renderSelect = () => {
        const { creatable, creatableHandler } = this.props

        const selectProps = this.getSelectProps()

        if (creatable) {
            return (
                <CreatableReactSelect
                    {...selectProps}
                    noOptionsMessage={({ inputValue }) =>
                        inputValue ? `Geen resultaten voor "${inputValue}"` : `Geen resultaten`
                    }
                    formatCreateLabel={this.getCreateMessage}
                    createOptionPosition={'first'}
                    onCreateOption={creatableHandler}
                    styles={{
                        option: (base, state) => ({
                            ...base,
                            backgroundColor: state.data.__isNew__ && '#f7f9fa !important',
                        }),
                    }}
                />
            )
        }

        return <Select {...selectProps} />
    }

    private getCreateMessage = () => {
        const { createValue } = this.props
        return (
            <Row className={this.bem.getElement('create-row')}>
                <Icon name={`plus`} />
                <span>{createValue}</span>
            </Row>
        )
    }

    private getSelectProps = (): SelectProps<OptionType> => {
        const {
            className,
            isDisabled,
            isReadonly,
            options,
            placeholder,
            name,
            isClearable,
            isLoading,
            required,
            onEndReached,
            onSearch,
            disableBuiltInFiltering,
        } = this.props

        const props: SelectProps<OptionType> = {
            isMulti: this.isMulti as false | undefined,
            className: this.bem.getClassName(className),
            classNamePrefix: 'tt-TagPicker',
            delimiter: ',',
            value: this.isControlled ? this.getSelectValueByPossibleStringValue(this.props.value) : undefined,
            defaultValue: this.isControlled ? undefined : this.getSelectValueByPossibleStringValue(this.state.value),
            isDisabled: isDisabled || isReadonly,
            onChange: this.handleSelectChange,
            noResultsText: `Geen resultaten gevonden`,
            name: name,
            isClearable: isClearable,
            onMenuScrollToBottom: onEndReached,
            onInputChange: onSearch,
            placeholder: placeholder,
            required: required,
            inputProps: {
                autoComplete: 'off',
            },
            noOptionsMessage: ({ inputValue }) =>
                inputValue ? `Geen resultaten voor "${inputValue}"` : `Geen resultaten`,
            options: options,
            filterOption: disableBuiltInFiltering
                ? () => true
                : createFilter({
                      stringify: this.stringifyOptionForSearch,
                  }),
            components: {
                ClearIndicator: ({ innerProps }) => (
                    <div {...innerProps} className={this.bem.getElement('clear-indicator')}>
                        <Icon name={`times`} />
                    </div>
                ),
            },
            isLoading: isLoading,

            /**
             * `key` prop is needed since React does not update in a specific case
             * where options are updated: Default value(s) is/are not rendered,
             * unless the key changes.
             */
            key: isLoading ? `${name}-loading` : undefined,
        }

        return props
    }

    private handleSelectChange = (selectedValueOrValues: any) => {
        const { onChange } = this.props

        if (!this.isMulti && Array.isArray(selectedValueOrValues)) {
            selectedValueOrValues = selectedValueOrValues[0]
        }

        this.setState({
            value: selectedValueOrValues,
        })

        setTimeout(() => {
            if (onChange) {
                onChange(selectedValueOrValues, this)
            }
        })
    }

    private getSelectValueByPossibleStringValue(
        stringValue: string | string[] | null | OptionType | undefined
    ): SelectValueType {
        const { multi, options } = this.props

        if (multi) {
            return Array.isArray(stringValue)
                ? stringValue.map(singleStringValue => this.getOptionByValue(singleStringValue, options))
                : []
        } else {
            return this.getOptionByValue(stringValue as string | null | undefined, options)
        }
    }

    private getOptionByValue(
        stringValue: string | null | OptionType | undefined,
        options?: SelectValueType[]
    ): SelectValueType {
        if (typeof stringValue === 'object') {
            return stringValue
        }

        if (!options || options.length === 0) {
            return undefined
        }

        return options.find(this.matchOptionWithValueString(stringValue))
    }

    private matchOptionWithValueString(stringValue: string | null | undefined) {
        return (option: SelectValueType) => {
            return (option && 'value' in option && option.value === stringValue) || false
        }
    }

    private stringifyOptionForSearch = (option: TagPickerOption<TData, TValue>): string => {
        const data: TData = option.data && (option.data as any).data
        const match = data && 'match' in data ? (data as any).match : ''

        return `${option.label} ${option.value} ${match}`
    }
}
