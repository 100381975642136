import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import React, { Component } from 'react'

import {
    ActionBar,
    Button,
    CenterModal,
    CheckBox,
    Field,
    FilterFieldCollection,
    Icon,
    Link,
    MultiInput,
    Search,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { ModalManager } from '~/components/ModalManager'
import { Table } from '~/components/Table'
import { TableCell } from '~/components/TableCell'
import { TableView } from '~/components/TableView'
import { TagPicker } from '~/components/TagPicker'
import { View } from '~/components/View'
import { Wrap } from '~/components/Wrap'
import { CreateTeacherForm } from '~/forms'
import { downloadFile, Fetcher, Filter, Mutator, removeDuplicateDocuments, Sorter, toast } from '~/utils'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'

const START = 40
const INCREASE = 40
const DEFAULT_SORT_BY = 'profile.firstName'
const DEFAULT_SORT_DIR = 'ASC'

const STATIC_TEACHER_USERS_FILTERS = {
    roles: ['TEACHER'],
}

export default class DataView extends Component {
    state = {
        limit: START,
        skip: 0,
        sortDir: DEFAULT_SORT_DIR,
        sortBy: DEFAULT_SORT_BY,
    }

    constructor(props) {
        super(props)

        const { limit, skip, sortDir, sortBy } = this.state

        bindAll(this, ['onFilterTagPicker', 'onFilter', 'exportTeachers', 'getActionBarButtons', 'getActionBarDrawer'])

        this.sorter = new Sorter({
            sortBy: DEFAULT_SORT_BY,
            onSort: this.sort,
        })

        this.filter = new Filter({
            useHistory: true,
            allowedKeys: [
                'searchText',
                'filterByCityNames',
                'filterTeacherActive',
                'filterTeacherNT2Certification',
                'filterTeacherLessonCompetence',
                'filterByTeacherHasOwnCompany',
            ],
            onChange: filters => {
                this.teachersFetcher.refetch({
                    silent: true,
                    filters: {
                        ...STATIC_TEACHER_USERS_FILTERS,
                        ...filters,
                    },
                })
            },
            customTransformers: [
                {
                    transformTriggerKeys: ['filterIsActive', 'filterIsInactive'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterIsActive) activeStates.push(true)
                        if (filters.filterIsInactive) activeStates.push(false)

                        return {
                            filterTeacherActive: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterTeacherActive'],
                    parseFromHistory: historyFilters => {
                        if ('filterTeacherActive' in historyFilters) {
                            if (historyFilters.filterTeacherActive) {
                                return { filterIsActive: true }
                            } else {
                                return { filterIsInactive: true }
                            }
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterTeacherHasNT2Certification', 'filterTeacherHasNoNT2Certification'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterTeacherHasNT2Certification) activeStates.push(true)
                        if (filters.filterTeacherHasNoNT2Certification) activeStates.push(false)

                        return {
                            filterTeacherNT2Certification: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterTeacherNT2Certification'],
                    parseFromHistory: historyFilters => {
                        if ('filterTeacherNT2Certification' in historyFilters) {
                            if (historyFilters.filterTeacherNT2Certification) {
                                return { filterTeacherHasNT2Certification: true }
                            } else {
                                return { filterTeacherHasNoNT2Certification: true }
                            }
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterTeacherHasLessonCompetence', 'filterTeacherHasNoLessonCompetence'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterTeacherHasLessonCompetence) activeStates.push(true)
                        if (filters.filterTeacherHasNoLessonCompetence) activeStates.push(false)

                        return {
                            filterTeacherLessonCompetence: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterTeacherLessonCompetence'],
                    parseFromHistory: historyFilters => {
                        if ('filterTeacherLessonCompetence' in historyFilters) {
                            if (historyFilters.filterTeacherLessonCompetence) {
                                return { filterTeacherHasLessonCompetence: true }
                            } else {
                                return { filterTeacherHasNoLessonCompetence: true }
                            }
                        }
                    },
                },
                {
                    transformTriggerKeys: ['filterByTeacherHasOwnCompanyYes', 'filterByTeacherHasOwnCompanyNo'],
                    transform: filters => {
                        const activeStates = []
                        if (filters.filterByTeacherHasOwnCompanyYes) activeStates.push(true)
                        if (filters.filterByTeacherHasOwnCompanyNo) activeStates.push(false)

                        return {
                            filterByTeacherHasOwnCompany: activeStates.length === 1 ? activeStates[0] : null,
                        }
                    },
                    historyTriggerKeys: ['filterByTeacherHasOwnCompany'],
                    parseFromHistory: historyFilters => {
                        if ('filterByTeacherHasOwnCompany' in historyFilters) {
                            if (historyFilters.filterByTeacherHasOwnCompany) {
                                return { filterByTeacherHasOwnCompanyYes: true }
                            } else {
                                return { filterByTeacherHasOwnCompanyNo: true }
                            }
                        }
                    },
                },
            ],
            defaultFilterState: {
                filterIsActive: true,
            },
        })

        this.paginator = new Paginator({
            start: START,
            increase: INCREASE,
            onLoadMore: this.loadMore,
        })

        this.teachersFetcher = new Fetcher({
            query: GET_TEACHERS_QUERY,

            variables: {
                limit,
                skip,
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_TEACHER_USERS_FILTERS,
                    ...this.filter.getFilters(),
                },
            },
            onChange: () => this.forceUpdate(),
            onRefetch: () => {
                this.paginator.reset()
            },
        })

        this.filterSuggestionsFetcher = new Fetcher({
            query: GET_FILTER_SUGGESTIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        this.createExportMutator = new Mutator({
            mutation: CREATE_TEACHERS_EXPORT_FILE_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    loadMore = (skip, limit, callback) => {
        this.teachersFetcher.fetchMore({
            variables: { limit, skip },
            getMergedData: (prevData, moreData) => {
                callback({
                    finished: moreData.users.length === 0,
                })

                return {
                    users: removeDuplicateDocuments([...(prevData.users || []), ...moreData.users]),
                }
            },
            onError: () => {
                callback()
            },
        })
    }

    sort = ({ sortBy, sortDir }) => {
        this.teachersFetcher.refetch({
            sortDir,
            sortBy,
            silent: true,
        })

        this.setState({ sortDir, sortBy })
    }

    onSearch = ({ searchText }) => {
        this.filter.apply('searchText', searchText)
    }

    onFilter(event) {
        this.filter.applyFromInputEvent(event)
    }

    onFilterTagPicker(values, tagPicker) {
        this.filter.applyFromTagPicker(tagPicker)
    }

    exportTeachers(event) {
        event.preventDefault()

        const { sortDir, sortBy } = this.state

        this.createExportMutator
            .mutate({
                sortDir,
                sortBy,
                filters: {
                    ...STATIC_TEACHER_USERS_FILTERS,
                    ...this.filter.getFilters(),
                },
                columns: [
                    'Name',
                    'Email',
                    'Country',
                    'Street',
                    'Number',
                    'NumberAddition',
                    'Zipcode',
                    'City',
                    'State',
                    'AddressLine1',
                    'AddressLine2',
                    'IsNT2Certified',
                    'LessonCompetence',
                    'HasOwnCompany',
                ],
            })
            .then(data => {
                if (data) {
                    const res = get(data, 'users_createExportFile')

                    if (res) {
                        if (res.fileId) {
                            downloadFile(res.fileId, 'docenten-export.xlsx')
                        } else {
                            toast.error('Geen docenten gevonden om te exporteren')
                        }
                    } else {
                        toast.error('XLS downloaden mislukt')
                    }
                }
            })
    }

    getActionBarButtons(toggleDrawer, isDrawerActive) {
        const filters = this.filter.getFilters()
        const { loading: loadingExport } = this.createExportMutator
        const { loading } = this.teachersFetcher
        const hasFilters = this.filter.hasFilters({
            excludeKeys: ['searchText'],
        })

        return (
            <List horizontal>
                <ListItem>
                    <Button
                        leftIcon={<Icon name={`filter`} />}
                        onClick={toggleDrawer}
                        isActive={isDrawerActive}
                        hasNotification={hasFilters}
                    >
                        Filteropties
                    </Button>
                </ListItem>
                <ListItem>
                    <Search isLoading={loading} onSearch={this.onSearch} defaultValue={filters.searchText} />
                </ListItem>
                <ListItem right>
                    <ModalManager
                        render={openModal => (
                            <Button onClick={openModal} type={`edit`}>
                                Docent toevoegen
                            </Button>
                        )}
                        getModal={closeModal => {
                            return (
                                <CenterModal onClose={closeModal} title={`Docent toevoegen`}>
                                    <CreateTeacherForm onSubmitSuccess={closeModal} onCancel={closeModal} />
                                </CenterModal>
                            )
                        }}
                    />
                </ListItem>
                <ListItem right>
                    <Button
                        leftIcon={<Icon name={`download`} />}
                        onClick={this.exportTeachers}
                        isLoading={loadingExport}
                        confirm={{
                            title: 'Exporteren',
                            message: 'Je staat op het punt om een XLS export te maken. Weet je het zeker?',
                            execute: {
                                buttonType: 'submit',
                                title: 'Export maken',
                            },
                        }}
                    >
                        Export van huidige selectie
                    </Button>
                </ListItem>
            </List>
        )
    }

    getActionBarDrawer() {
        const filters = this.filter.getFilters()
        const customFilters = this.filter.getCustomFilters()
        const { data: filterParams } = this.filterSuggestionsFetcher
        const { addresses_usedCities } = filterParams

        const addressOptions = addresses_usedCities && addresses_usedCities.map(city => ({ value: city, label: city }))

        return (
            <FilterFieldCollection>
                <Field isLabel title={`Plaats`} style={`compact`}>
                    {addressOptions && (
                        <TagPicker
                            onChange={this.onFilterTagPicker}
                            name={`filterByCityNames`}
                            defaultValue={filters.filterByCityNames}
                            options={addressOptions}
                            placeholder={`Selecteer plaats`}
                        />
                    )}
                </Field>
                <Field title={`Status`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox
                            name={`filterIsActive`}
                            defaultChecked={customFilters.filterIsActive}
                            onChange={this.onFilter}
                        >
                            Actief
                        </CheckBox>
                        <CheckBox
                            name={`filterIsInactive`}
                            defaultChecked={customFilters.filterIsInactive}
                            onChange={this.onFilter}
                        >
                            Non-actief
                        </CheckBox>
                    </MultiInput>
                </Field>
                <Field title={`NT2 Gecertificeerd`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox
                            name={`filterTeacherHasNT2Certification`}
                            defaultChecked={customFilters.filterTeacherHasNT2Certification}
                            onChange={this.onFilter}
                        >
                            Ja
                        </CheckBox>
                        <CheckBox
                            name={`filterTeacherHasNoNT2Certification`}
                            defaultChecked={customFilters.filterTeacherHasNoNT2Certification}
                            onChange={this.onFilter}
                        >
                            Nee
                        </CheckBox>
                    </MultiInput>
                </Field>
                <Field title={`Lesbevoegd`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox
                            name={`filterTeacherHasLessonCompetence`}
                            defaultChecked={customFilters.filterTeacherHasLessonCompetence}
                            onChange={this.onFilter}
                        >
                            Ja
                        </CheckBox>
                        <CheckBox
                            name={`filterTeacherHasNoLessonCompetence`}
                            defaultChecked={customFilters.filterTeacherHasNoLessonCompetence}
                            onChange={this.onFilter}
                        >
                            Nee
                        </CheckBox>
                    </MultiInput>
                </Field>
                <Field title={`Is Freelancer`} style={`compact`}>
                    <MultiInput type={`checkbox`} isVertical>
                        <CheckBox
                            name={`filterByTeacherHasOwnCompanyYes`}
                            defaultChecked={customFilters.filterByTeacherHasOwnCompanyYes}
                            onChange={this.onFilter}
                        >
                            Ja
                        </CheckBox>
                        <CheckBox
                            name={`filterByTeacherHasOwnCompanyNo`}
                            defaultChecked={customFilters.filterByTeacherHasOwnCompanyNo}
                            onChange={this.onFilter}
                        >
                            Nee
                        </CheckBox>
                    </MultiInput>
                </Field>
            </FilterFieldCollection>
        )
    }

    render() {
        const { data, loading } = this.teachersFetcher
        const { users = [] } = data
        const hasFiltersOtherThanTheDefault = this.filter.hasFilters({
            excludeKeys: ['searchText'],
            excludeKeyValuePairs: [{ key: 'filterTeacherActive', value: true }],
        })

        return (
            <InfiniteScroll paginator={this.paginator} preventLoad={loading} component={View}>
                <ContentView>
                    <Wrap full>
                        <ActionBar
                            isActiveByDefault={hasFiltersOtherThanTheDefault}
                            getButtons={this.getActionBarButtons}
                            getDrawer={this.getActionBarDrawer}
                        />
                    </Wrap>
                    <TableWrap>
                        <TableView>
                            <Table>
                                <TableHeader>
                                    <TableHeaderItem sorter={this.sorter} sortBy={`profile.firstName`}>
                                        Voornaam
                                    </TableHeaderItem>
                                    <TableHeaderItem sorter={this.sorter} sortBy={`profile.surName`}>
                                        Achternaam
                                    </TableHeaderItem>
                                    <TableHeaderItem>Plaats</TableHeaderItem>
                                    <TableHeaderItem>Status</TableHeaderItem>
                                    <TableHeaderItem>Freelancer</TableHeaderItem>
                                </TableHeader>
                                {loading ? (
                                    <TableRow key={'loading'}>
                                        <TableCell colSpan={5} isLoading />
                                    </TableRow>
                                ) : users.length > 0 ? (
                                    this.renderTeacherRows(users)
                                ) : (
                                    <TableRow key={`emptyresult`}>
                                        <TableCell colSpan={5}>
                                            <Subtle>Er zijn geen docenten gevonden.</Subtle>
                                        </TableCell>
                                    </TableRow>
                                )}
                            </Table>
                        </TableView>
                    </TableWrap>
                </ContentView>
            </InfiniteScroll>
        )
    }

    renderTeacherRows(users) {
        return users.map(user => {
            const isActive = get(user, 'teacher.isActive')

            return (
                <TableRow key={user._id}>
                    <TableCell>
                        <Link route={`/users/teachers/${user._id}`}>{user.profile.firstName}</Link>
                    </TableCell>
                    <TableCell>
                        <Link route={`/users/teachers/${user._id}`}>{user.profile.fullSurName}</Link>
                    </TableCell>
                    <TableCell>{get(user, 'profile.address.nl.extraInfo.city')}</TableCell>
                    <TableCell>{isActive ? 'Inzetbaar' : isActive === false ? 'Bezet' : ''}</TableCell>
                    <TableCell>{!!get(user, 'teacher.hasOwnCompany') ? 'Ja' : 'Nee'}</TableCell>
                </TableRow>
            )
        })
    }
}

const GET_TEACHERS_QUERY = gql`
    query _($limit: Int, $skip: Int, $sortBy: String, $sortDir: String, $filters: UsersFilterInputType) {
        users(limit: $limit, skip: $skip, sortBy: $sortBy, sortDir: $sortDir, filters: $filters) {
            _id
            email
            lastActivityAt
            profile {
                firstName
                fullSurName
                address {
                    _id
                    nl {
                        extraInfo {
                            city
                        }
                    }
                }
            }
            teacher {
                isActive
                hasOwnCompany
            }
        }
    }
`

const GET_FILTER_SUGGESTIONS_QUERY = gql`
    query _ {
        addresses_usedCities
    }
`

const CREATE_TEACHERS_EXPORT_FILE_MUTATION = gql`
    mutation _($sortBy: String, $sortDir: String, $filters: UsersFilterInputType, $columns: [String!]) {
        users_createExportFile(sortDir: $sortDir, sortBy: $sortBy, filters: $filters, columns: $columns) {
            fileId
        }
    }
`
