import * as React from 'react'
import { Form, FieldCollection, Button } from '~/components'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { User } from '~/types/User'
import { UserLearnerPaymentDetailsFieldGroup } from './FieldGroups/PaymentDetailsFieldGroup'
import { debounce, get, omit } from 'lodash'
import Fetcher from '~/utils/Fetcher'
import gql from 'graphql-tag'
import transformFormFields, { lib as transformLib } from '~/utils/transformFormFields'
import Mutator from '~/utils/Mutator'
import { UserTypeOfLearnerTypesEnum } from '~/generated/graphql'

const LOOKUP_TWINFIELD_CUSTOMER_BY_CODE_QUERY = gql`
    query _($twinfieldCustomerCode: String!) {
        twinfield_customer(twinfieldCustomerCode: $twinfieldCustomerCode) {
            name
        }
    }
`

const EDIT_USER_MUTATION = gql`
    mutation _($user: UserInputType!) {
        users_edit(user: $user) {
            _id
        }
    }
`

interface Props {
    user: User
    onSubmitSuccess?: () => void
    onCancel?: () => void
}

export enum IntegrationLawTypes {
    law2013 = 'law2013',
    law2021 = 'law2021',
}

export enum PaymentByType {
    Organization = 'Organization',
    Private = 'Private', // Particulier
    SelfReporter = 'SelfReporter', // Zelfmelder
    FamilyMigrant = 'FamilyMigrant', // Gezinsmigrant
}
interface State {
    hasPrivatePayment?: boolean
    paymentBy?: PaymentByType
    privatePaymentIsDUO?: boolean
    privatePaymentDUOHasApprovedFund?: boolean
    useAddressAsPrivatePaymentAddress?: boolean
    lookupTwinfieldCustomerExists?: boolean
    lookupTwinfieldCustomerName?: string
    twinfieldCustomerCode?: string
    doesNotExistInTwinfield?: boolean
    lookupTwinfieldCustomerLoading?: boolean
    typeOfLearner: UserTypeOfLearnerTypesEnum
}

export class UserLearnerAddInvoicingForm extends React.Component<Props, State> {
    public state: State = {
        hasPrivatePayment: !!get(this.props.user, 'learner.hasPrivatePayment', true),
        paymentBy: get(this.props.user, 'learner.paymentBy'),
        useAddressAsPrivatePaymentAddress: !get(this.props.user, 'learner.privatePaymentAddress'),
        privatePaymentIsDUO: !!get(this.props.user, 'learner.privatePaymentIsDUO'),
        privatePaymentDUOHasApprovedFund: !!get(this.props.user, 'learner.privatePaymentDUOHasApprovedFund'),
        twinfieldCustomerCode: get(this.props.user, 'learner.twinfieldCustomer.code'),
        doesNotExistInTwinfield: !!get(this.props.user, 'learner.twinfieldCustomer.code'),
        lookupTwinfieldCustomerExists: !!get(this.props.user, 'learner.twinfieldCustomer.code'),
        lookupTwinfieldCustomerName: get(this.props.user, 'learner.twinfieldCustomer.name'),
        typeOfLearner: get(this.props.user, 'learner.typeOfLearner'),
    }

    private debouncedLookupTwinfieldCustomer: any
    private lookupTwinfieldCustomerByCodeFetcher: Fetcher
    private userMutator: Mutator

    constructor(props: Props) {
        super(props)

        this.userMutator = new Mutator({
            mutation: EDIT_USER_MUTATION,
            reactComponentToUpdate: this,
        })

        this.lookupTwinfieldCustomerByCodeFetcher = new Fetcher({
            query: LOOKUP_TWINFIELD_CUSTOMER_BY_CODE_QUERY,
            preventInitialFetch: true,
        })

        this.debouncedLookupTwinfieldCustomer = debounce(this.lookupTwinfieldCustomer, 1000)
    }

    public render() {
        const { onCancel, user } = this.props
        const { loading, errors: graphqlErrors } = this.userMutator

        const {
            hasPrivatePayment,
            paymentBy,
            useAddressAsPrivatePaymentAddress,
            privatePaymentIsDUO,
            twinfieldCustomerCode,
            doesNotExistInTwinfield,
            lookupTwinfieldCustomerLoading,
            lookupTwinfieldCustomerExists,
            lookupTwinfieldCustomerName,
            privatePaymentDUOHasApprovedFund,
            typeOfLearner,
        } = this.state

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    <UserLearnerPaymentDetailsFieldGroup
                        user={user}
                        errors={graphqlErrors}
                        hasPrivatePayment={hasPrivatePayment}
                        privatePaymentIsDUO={privatePaymentIsDUO}
                        privatePaymentDUOHasApprovedFund={privatePaymentDUOHasApprovedFund}
                        useAddressAsPrivatePaymentAddress={useAddressAsPrivatePaymentAddress}
                        twinfieldCustomerCode={twinfieldCustomerCode}
                        doesNotExistInTwinfield={doesNotExistInTwinfield}
                        lookupTwinfieldCustomerLoading={lookupTwinfieldCustomerLoading}
                        lookupTwinfieldCustomerExists={lookupTwinfieldCustomerExists}
                        lookupTwinfieldCustomerName={lookupTwinfieldCustomerName}
                        onChangeDoesNotExistInTwinfield={this.onChangeDoesNotExistInTwinfield}
                        setDoesNotExistInTwinfield={this.setDoesNotExistInTwinfield}
                        onChangeTwinfieldCustomerCode={this.onChangeTwinfieldCustomerCode}
                        changePrivatePaymentIsDUO={value => this.setState({ privatePaymentIsDUO: value })}
                        changePrivatePaymentDUOHasApprovedFund={value =>
                            this.setState({ privatePaymentDUOHasApprovedFund: value })
                        }
                        changeUseAddressAsPrivatePaymentAddress={value =>
                            this.setState({ useAddressAsPrivatePaymentAddress: value })
                        }
                        privatePaymentCannotChange={true}
                        paymentBy={paymentBy}
                        changePaymentBy={value => this.setState({ paymentBy: value })}
                        typeOfLearner={typeOfLearner}
                    />
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={loading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }

    private setDoesNotExistInTwinfield() {
        this.setState({
            doesNotExistInTwinfield: true,
            twinfieldCustomerCode: undefined,
        })
    }

    private onChangeDoesNotExistInTwinfield(event: React.ChangeEvent<HTMLInputElement>) {
        const { checked } = event.currentTarget

        this.setState({
            doesNotExistInTwinfield: checked,
            twinfieldCustomerCode: checked ? undefined : this.state.twinfieldCustomerCode,
        })
    }

    private onChangeTwinfieldCustomerCode(event: React.ChangeEvent<HTMLInputElement>) {
        const { value } = event.currentTarget

        this.setState({
            twinfieldCustomerCode: value,
            lookupTwinfieldCustomerLoading: true,
        })

        if (value) {
            this.debouncedLookupTwinfieldCustomer(value)
        }
    }

    private async lookupTwinfieldCustomer(code: string) {
        this.setState({
            lookupTwinfieldCustomerLoading: true,
            lookupTwinfieldCustomerExists: false,
            lookupTwinfieldCustomerName: undefined,
        })

        try {
            const result = await this.lookupTwinfieldCustomerByCodeFetcher.fetch({
                twinfieldCustomerCode: code,
            })

            this.setState({ lookupTwinfieldCustomerLoading: false })

            if (result) {
                const { twinfield_customer } = result

                if (twinfield_customer) {
                    this.setState({
                        lookupTwinfieldCustomerExists: true,
                        lookupTwinfieldCustomerName: twinfield_customer.name,
                    })
                }
            }
        } catch (err) {
            this.setState({ lookupTwinfieldCustomerLoading: false })
            throw err
        }
    }

    private onSubmit = async (event: React.FormEvent<HTMLFormElement>, fields: any) => {
        const { hasPrivatePayment, privatePaymentIsDUO, doesNotExistInTwinfield, twinfieldCustomerCode } = this.state
        const { onSubmitSuccess, user } = this.props

        fields = omit(fields, [
            'addressAsVisitingAddress',
            'addressAsPrivatePaymentAddress',
            'user.learner.hasPrivatePayment',
        ])

        const transformedFormFields = {
            _id: user._id,
            ...transformFormFields(
                fields.user,
                {
                    learner: {
                        fields: (v: any) => ({
                            ...transformFormFields(
                                v,
                                {
                                    hasPrivatePayment: { value: hasPrivatePayment },
                                    // tslint:disable-next-line:max-line-length
                                    twinfieldCustomerCode: {
                                        value: twinfieldCustomerCode,
                                        compare: (v: any) => v === get(user, 'learner.twinfieldCustomer.code'),
                                    },
                                    doesNotExistInTwinfield: { value: doesNotExistInTwinfield },
                                    privatePaymentIsDUO: {
                                        value: privatePaymentIsDUO,
                                        isNull: (_v: any) => !_v || !hasPrivatePayment,
                                    },
                                    // tslint:disable-next-line:max-line-length
                                    privatePaymentAddress: {
                                        fields: transformLib.address(),
                                        isNull: (_v: any) =>
                                            transformLib.address().isNull(_v) ||
                                            !hasPrivatePayment ||
                                            privatePaymentIsDUO,
                                    },
                                    privatePaymentDUOHasApprovedFund: {
                                        isNull: (_v: any) => !_v || !hasPrivatePayment || !privatePaymentIsDUO,
                                    },
                                },
                                [] as any
                            ),
                        }),
                    },
                },
                [] as any
            ),
        }

        const data = await this.userMutator.mutate({
            user: transformedFormFields,
        })

        if (onSubmitSuccess && data) {
            onSubmitSuccess()
        }
    }
}
