import gql from 'graphql-tag'
import { times } from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component, Fragment } from 'react'

import {
    DrawerHeader,
    Form,
    RadioButton,
    Subtle,
    TableHeader,
    TableHeaderItem,
    TableRow,
    TableWrap,
} from '~/components'
import { ContentView } from '~/components/ContentView'
import { TableCell } from '~/components/TableCell'
import { Table } from '~/components/Table'
import { Mutator, transformFormFields } from '~/utils'
import { TableView } from '~/components/TableView'
import { Paginator } from '~/utils/Paginator'
import { InfiniteScroll } from '~/components/Core/InfiniteScroll/InfiniteScroll'
import DrawerFooter from '~/components/DrawerFooter'

export default class SelectStartWeekForm extends Component {
    static propTypes = {
        closeModal: PropTypes.func,
        refetch: PropTypes.func,
        group: PropTypes.object.isRequired,
    }

    startWeek = moment().utc().startOf('week').subtract(6, 'weeks').toDate()

    constructor(props) {
        super(props)

        this.state = {
            dates: this.get52WeeksFrom(this.startWeek),
        }

        this.paginator = new Paginator({
            start: 0,
            increase: 0,
            onLoadMore: this.loadMore,
        })

        this.groupEditMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })
    }

    get52WeeksFrom(from) {
        return times(52, i => {
            return moment(from).utc().add(i, 'weeks').toDate()
        })
    }

    loadMore = (skip, limit, callback) => {
        const lastDate = this.state.dates[this.state.dates.length - 1]

        this.setState({
            dates: [...this.state.dates, ...this.get52WeeksFrom(lastDate)],
        })

        // Never stop
        callback({ finished: false })
    }

    onSubmit = (event, fields) => {
        const { closeModal, group } = this.props

        this.groupEditMutator
            .mutate({
                group: {
                    _id: group._id,
                    ...transformFormFields(fields.group),
                },
            })
            .then(data => {
                const { refetch } = this.props

                if (data) {
                    closeModal()
                    refetch()
                }
            })
    }

    render() {
        const { dates } = this.state
        const { closeModal } = this.props
        const { loading: mutationLoading } = this.groupEditMutator

        return (
            <Form onSubmit={this.onSubmit}>
                <DrawerHeader title={`Startweek instellen`} close={closeModal} />
                <ContentView>
                    <InfiniteScroll paginator={this.paginator}>
                        <TableWrap>
                            <TableView>
                                <Table>
                                    <TableHeader>
                                        <TableHeaderItem colSpan={2}>Weeknummer</TableHeaderItem>
                                    </TableHeader>
                                    {dates.map((date, index) => (
                                        <Fragment key={index}>{this.renderWeekRow(date)}</Fragment>
                                    ))}
                                </Table>
                            </TableView>
                        </TableWrap>
                    </InfiniteScroll>
                </ContentView>
                <DrawerFooter close={closeModal} isLoading={mutationLoading} />
            </Form>
        )
    }

    renderWeekRow(date) {
        const {
            group: { startWeekDate },
        } = this.props
        const m = moment(date)
        const now = moment().utc()
        const isThisWeek = m.isSame(now, 'week')
        const isDefaultChecked = startWeekDate ? m.isSame(startWeekDate, 'week') : isThisWeek
        const value = m.toISOString()

        return (
            <TableRow className={isThisWeek ? 'tt-TableRow--highlight' : null}>
                <TableCell>
                    <RadioButton name={`group.startWeekDate`} defaultChecked={isDefaultChecked} value={value}>
                        Week {m.week()}
                    </RadioButton>
                </TableCell>
                <TableCell>
                    <Subtle>
                        {m.startOf('week').format('DD-MM-YYYY')} - {m.endOf('week').format('DD-MM-YYYY')}
                    </Subtle>
                </TableCell>
            </TableRow>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`

window.moment = moment
