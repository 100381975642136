import './FinalExamsExpansionContent.scss'

import * as React from 'react'
import { SimpleTable } from '~/components/SimpleTable'
import { SimpleTableRow } from '~/components/SimpleTableRow'
import { SimpleTableCell } from '~/components/SimpleTableCell'
import ReadableDate from '~/components/ReadableDate'
import Subtle from '~/components/Subtle'
import { ModalManager } from '~/components/ModalManager'
import { Button } from '~/components'
import CenterModal from '~/components/CenterModal'
import { WaitIcon } from '~/components/InvoiceStatusIndicator/icons'
import { RegisterFinalExamForm } from '~/forms/RegisterFinalExamForm'
import { groupBy, get, find, orderBy } from 'lodash'
import { User } from '~/types/User'
import { FinalExam, FinalExamResult } from '~/types/FinalExam'
import c from 'classnames'
import translateType from '~/shared/utils/translateType'
import Fetcher from '~/utils/Fetcher'
import gql from 'graphql-tag'
import Spinner from '~/components/Spinner'
const moment = require('moment')

interface Props {
    className?: string
    user: User
    onSubmitSuccess: () => void
}

const GET_FINAL_EXAMS_PLANNED_QUERY = gql`
    query finalExamsPlanned($filters: UsersFilterInputType, $finalExamsFilter: FinalExamsFilterInputType) {
        users(filters: $filters) {
            _id
            learner {
                finalExamNeeded {
                    finalExams(filters: $finalExamsFilter) {
                        _id
                        date
                        part
                        level
                        result
                        attempt
                    }
                }
            }
        }
    }
`

export class FinalExamsExpansionContent extends React.Component<Props> {
    private finalExamsPlannedFetcher = new Fetcher({
        query: GET_FINAL_EXAMS_PLANNED_QUERY,
        variables: {
            filters: {
                byId: this.props.user._id,
            },
            finalExamsFilter: {
                filterByExamIsNotCanceled: true,
            },
        },
        onChange: () => this.forceUpdate(),
    })

    public render() {
        const { user } = this.props
        const { data, loading } = this.finalExamsPlannedFetcher

        if (loading) {
            return <Spinner size="small" />
        }

        const finalExams =
            (data &&
                data.users &&
                data.users[0] &&
                data.users[0].learner &&
                data.users[0].learner.finalExamNeeded &&
                data.users[0].learner.finalExamNeeded.finalExams) ||
            []

        const groupedFinalExams = groupBy(finalExams, 'part')

        return (
            <div className={this.getClassName()}>
                <SimpleTable>
                    <SimpleTableRow isDoubleTableRow={true}>
                        <SimpleTableCell isBold={true}>Spreken</SimpleTableCell>
                        <SimpleTableCell>{this.renderTableDataContent(get(groupedFinalExams, 'talk'))}</SimpleTableCell>

                        <SimpleTableCell isBold={true}>Lezen</SimpleTableCell>
                        <SimpleTableCell>{this.renderTableDataContent(get(groupedFinalExams, 'read'))}</SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow isDoubleTableRow={true}>
                        <SimpleTableCell isBold={true}>Schrijven</SimpleTableCell>
                        <SimpleTableCell>
                            {this.renderTableDataContent(get(groupedFinalExams, 'write'))}
                        </SimpleTableCell>

                        <SimpleTableCell isBold={true}>KNM</SimpleTableCell>
                        <SimpleTableCell>{this.renderTableDataContent(get(groupedFinalExams, 'knm'))}</SimpleTableCell>
                    </SimpleTableRow>
                    <SimpleTableRow isDoubleTableRow={true}>
                        <SimpleTableCell isBold={true}>Luisteren</SimpleTableCell>
                        <SimpleTableCell>
                            {this.renderTableDataContent(get(groupedFinalExams, 'listen'))}
                        </SimpleTableCell>

                        <SimpleTableCell isBold={true}>ONA</SimpleTableCell>
                        <SimpleTableCell>{this.renderTableDataContent(get(groupedFinalExams, 'ona'))}</SimpleTableCell>
                    </SimpleTableRow>
                </SimpleTable>
                <ModalManager
                    render={openModal => (
                        <Button onClick={openModal} leftIcon={<WaitIcon />}>
                            Examenaanvraag registreren
                        </Button>
                    )}
                    getModal={closeModal => {
                        return (
                            <CenterModal
                                onClose={closeModal}
                                title={`Examenaanvraag registreren voor ${user.profile.name}`}
                            >
                                <RegisterFinalExamForm
                                    onSubmitSuccess={this.onSubmitSuccess}
                                    user={user}
                                    finalExams={groupedFinalExams}
                                    onClose={closeModal}
                                />
                            </CenterModal>
                        )
                    }}
                />
            </div>
        )
    }

    private onSubmitSuccess = () => {
        this.props.onSubmitSuccess()
    }

    private renderTableDataContent = (finalExams: FinalExam[]) => {
        if (!finalExams || !finalExams.length) {
            return `-`
        }

        const passedFinalExam = find(
            finalExams,
            finalExam => !!finalExam.result && finalExam.result === FinalExamResult.Passed
        )

        if (passedFinalExam) {
            return this.renderFinalExamResult(passedFinalExam)
        }

        const sortedExams = orderBy(finalExams, 'date', 'ASC')

        return sortedExams
            .filter(finalExam => !!finalExam)
            .map(finalExam => <div key={finalExam._id}>{this.renderFinalExamResult(finalExam)}</div>)
    }

    private renderFinalExamResult = (finalExam: FinalExam) => {
        return (
            <React.Fragment>
                {this.renderExamText(finalExam)}
                <Subtle>
                    <ReadableDate date={finalExam.date} />
                </Subtle>
            </React.Fragment>
        )
    }

    private renderExamText = (finalExam: FinalExam) => {
        const { result, level } = finalExam

        const plannedOrWait = moment(finalExam.date).isBefore(moment()) ? 'Wachten op resultaat' : 'Examen gepland'

        const text = finalExam.canceledAt ? 'Geannuleerd' : plannedOrWait

        return `${result ? translateType('finalExamResult', result) : text} ${level ? `(${level})` : ''}`
    }

    private getClassName = () => {
        const { className } = this.props

        return c('tt-FinalExamsExpansionContent', className)
    }
}
