import gql from 'graphql-tag'
import { bindAll, get } from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'

import { Button, Field, FieldCollection, Form, Spinner } from '~/components'
import { List } from '~/components/List'
import { ListItem } from '~/components/ListItem'
import { FieldCollectionFooter } from '~/components/FieldCollectionFooter'
import { FieldGroup } from '~/components/FieldGroup'
import { TagPicker } from '~/components/TagPicker'
import { Fetcher, Mutator } from '~/utils'
import transformFormFields from '~/utils/transformFormFields'
import { VariableMultiInput } from '~/components/VariableMultiInput'

export default class EditGroupLocationForm extends Component {
    static propTypes = {
        onSubmitSuccess: PropTypes.func.isRequired,
        onSubmitFailed: PropTypes.func,
        group: PropTypes.object.isRequired,
        onCancel: PropTypes.func.isRequired,
        refetch: PropTypes.func.isRequired,
        hideRoomField: PropTypes.bool,
    }

    constructor(props) {
        super(props)

        this.state = {
            selectedLocationId: null,
            selectedRooms: {},
        }

        this.groupEditMutator = new Mutator({
            mutation: EDIT_GROUP_MUTATION,
            reactComponentToUpdate: this,
        })

        this.dataFetcher = new Fetcher({
            query: GET_LOCATIONS_QUERY,
            onChange: () => this.forceUpdate(),
        })

        bindAll(this, ['onRemoveRoomField'])
    }

    onSubmit = (event, fields) => {
        const { onSubmitSuccess, group, hideRoomField } = this.props

        const spec = {}

        if (!hideRoomField) {
            spec['roomIds'] = {
                // Set the value to null if there are no rooms set.
                isNull: !(fields.group.roomIds && fields.group.roomIds.filter(v => v).length),
                value: v => {
                    // Filter out empty strings (this happens when you don't select anything in the tag picker).
                    return v.filter(v => v)
                },
            }
        }

        this.groupEditMutator
            .mutate({
                group: {
                    _id: group._id,
                    ...transformFormFields(fields.group, spec),
                },
            })
            .then(data => {
                const { refetch } = this.props

                if (data) {
                    refetch()
                    onSubmitSuccess()
                }
            })
    }

    onLocationChange(selectedLocation) {
        if (this.props.hideRoomField) {
            return
        }

        this.setState({ selectedLocationId: selectedLocation ? selectedLocation.value : null })

        // Reset the variable multi input
        if (this.refs.roomSelectionInput) {
            this.refs.roomSelectionInput.reset()
        }
    }

    onRoomSelect(fieldIndex, selectedOption) {
        this.setState({
            selectedRooms: {
                ...this.state.selectedRooms,
                [fieldIndex]: selectedOption ? selectedOption.value : null,
            },
        })
    }

    onRemoveRoomField(event, fieldIndex) {
        this.setState({
            selectedRooms: {
                ...this.state.selectedRooms,
                [fieldIndex]: null,
            },
        })
    }

    render() {
        const { onCancel, group, hideRoomField } = this.props

        const groupLocation = get(group, 'location') || null
        const groupRooms = get(group, 'rooms') || []

        const { data, isFetchLoading } = this.dataFetcher
        const { errors, isMutationLoading } = this.groupEditMutator
        const locations = get(data, 'locations') || []

        const selectedRoomsIds = Object.values(this.state.selectedRooms)

        let rooms =
            get(
                locations.find(({ _id: locationId }) => {
                    if (!this.state.selectedLocationId && groupLocation) {
                        return locationId === groupLocation._id
                    }

                    return locationId === this.state.selectedLocationId
                }),
                'rooms'
            ) || []

        const totalRoomAmount = rooms.length

        rooms = rooms.filter(({ _id }) => !selectedRoomsIds.includes(_id))

        return (
            <Form onSubmit={this.onSubmit}>
                <FieldCollection style={`modal`}>
                    {isFetchLoading ? (
                        <Spinner />
                    ) : (
                        <FieldGroup isForm>
                            <Field isLabel title={'Locatie'} errors={errors}>
                                <TagPicker
                                    name={`group.locationId`}
                                    placeholder={`Selecteer een locatie`}
                                    onChange={this.onLocationChange.bind(this)}
                                    defaultValue={!this.selectedLocationId && groupLocation ? groupLocation._id : null}
                                    multi={false}
                                    options={locations.map(l => ({ label: l.name, value: l._id }))}
                                />
                            </Field>
                            {!hideRoomField && (
                                <Field title={'Lesruimte'} errors={errors}>
                                    <VariableMultiInput
                                        ref={'roomSelectionInput'}
                                        defaultAmount={!this.state.selectedLocationId ? groupRooms.length : null}
                                        limit={totalRoomAmount}
                                        isDisabled={!totalRoomAmount}
                                        onRemove={this.onRemoveRoomField}
                                        getNewInput={i => {
                                            return (
                                                <TagPicker
                                                    name={`group.roomIds[${i}]`}
                                                    placeholder={
                                                        !totalRoomAmount
                                                            ? `Deze locatie heeft (nog) geen lesruimtes`
                                                            : `Selecteer een lesruimte`
                                                    }
                                                    defaultValue={get(groupRooms, `${i}._id`)}
                                                    isReadonly={!totalRoomAmount}
                                                    multi={false}
                                                    options={rooms
                                                        .map(r => ({ label: r.name, value: r._id }))
                                                        .filter(({ value }) => {
                                                            if (
                                                                !this.state.selectedLocationId &&
                                                                !get(groupRooms, `${i}._id`)
                                                            ) {
                                                                return !groupRooms.map(({ _id }) => _id).includes(value)
                                                            }

                                                            return true
                                                        })}
                                                    onChange={this.onRoomSelect.bind(this, i)}
                                                />
                                            )
                                        }}
                                        addButtonLabel={`Extra lesruimte`}
                                    />
                                </Field>
                            )}
                        </FieldGroup>
                    )}
                    <FieldCollectionFooter>
                        <List horizontal>
                            <ListItem right>
                                <Button onClick={onCancel}>Annuleren</Button>
                            </ListItem>
                            <ListItem right>
                                <Button type={`submit`} isLoading={isMutationLoading}>
                                    Opslaan
                                </Button>
                            </ListItem>
                        </List>
                    </FieldCollectionFooter>
                </FieldCollection>
            </Form>
        )
    }
}

const EDIT_GROUP_MUTATION = gql`
    mutation _($group: GroupInputType!) {
        groups_edit(group: $group) {
            _id
        }
    }
`

const GET_LOCATIONS_QUERY = gql`
    query _ {
        locations(sortBy: "name") {
            _id
            name
            rooms(sortBy: "name") {
                _id
                name
            }
        }
    }
`
